import React from 'react';
import withIcon from '../withIcon';

const SettingIcon = () => {
  return (
    <svg
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.74 11.6411L16.1278 10.7101C16.2906 9.83214 16.2906 8.93143 16.1278 8.05343L17.74 7.12245C17.9255 7.01648 18.0087 6.79698 17.9482 6.59262C17.5281 5.24534 16.8128 4.02673 15.8781 3.01249C15.7342 2.85733 15.4996 2.81948 15.318 2.92545L13.7058 3.85643C13.0283 3.27362 12.2487 2.82326 11.4048 2.52807V0.66989C11.4048 0.457959 11.2572 0.272519 11.0491 0.227105C9.66014 -0.0832233 8.23717 -0.0680854 6.91639 0.227105C6.70824 0.272519 6.56065 0.457959 6.56065 0.66989V2.53186C5.72049 2.83083 4.94088 3.28119 4.25968 3.86021L2.65127 2.92923C2.46583 2.82326 2.23497 2.85733 2.09116 3.01627C1.15639 4.02673 0.441125 5.24534 0.0210463 6.5964C-0.04329 6.80076 0.0437532 7.02026 0.229193 7.12623L1.84139 8.05721C1.67865 8.93522 1.67865 9.83592 1.84139 10.7139L0.229193 11.6449C0.0437532 11.7509 -0.0395056 11.9704 0.0210463 12.1747C0.441125 13.522 1.15639 14.7406 2.09116 15.7549C2.23497 15.91 2.46961 15.9479 2.65127 15.8419L4.26346 14.9109C4.94088 15.4937 5.72049 15.9441 6.56443 16.2393V18.1012C6.56443 18.3132 6.71202 18.4986 6.92017 18.544C8.30908 18.8544 9.73205 18.8392 11.0528 18.544C11.261 18.4986 11.4086 18.3132 11.4086 18.1012V16.2393C12.2487 15.9403 13.0283 15.49 13.7095 14.9109L15.3217 15.8419C15.5072 15.9479 15.738 15.9138 15.8818 15.7549C16.8166 14.7444 17.5319 13.5258 17.952 12.1747C18.0087 11.9666 17.9255 11.7471 17.74 11.6411ZM8.98272 12.4094C7.31376 12.4094 5.95513 11.0507 5.95513 9.38179C5.95513 7.71283 7.31376 6.35419 8.98272 6.35419C10.6517 6.35419 12.0103 7.71283 12.0103 9.38179C12.0103 11.0507 10.6517 12.4094 8.98272 12.4094Z"
        fill="#374151"
      />
    </svg>
  );
};

export default withIcon(SettingIcon);
