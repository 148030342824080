import styled from 'styled-components';
import { Switch as AntdSwitch, Row, Col } from 'antd';

export const Wrapper = styled.div`
  max-width: 1340px;
  margin: auto;
  width: 100%;
`;

export const SwitchWrap = styled.div`
  display: flex;
  border: 1px solid #98a5cd;
  border-radius: 18px;
  width: max-content;
  padding: 10px 32px;
  margin: 32px auto;
  grid-gap: 24px;

  @media (max-width: 576px) {
    padding: 10px 24px;
  }
`;

export const Switch = styled(AntdSwitch)`
  background-color: #ebfcf4;
  box-shadow: none;
  width: 58px;

  .ant-switch-handle {
    &::before {
      background-color: #3ade9b;
    }
  }
`;

export const Title = styled.div`
  font-weight: 700;
  font-size: 28px;
  line-height: 34px;
  text-align: center;
  color: #111827;
  margin-bottom: 28px;
`;

export const SubTitle = styled.div`
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  color: #a9a9aa;
  margin-bottom: 20px;
`;

export const PlanOptions = styled.div`
  font-weight: 400;
  font-size: 15px;
  line-height: 19px;
  color: #35353f;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  grid-gap: 8px;
`;

export const PriceWrap = styled.div`
  padding: 28px 0;
  margin-top: 40px;
  border-top: 1px dashed #a9a9aa;
  margin-bottom: 80px;

  & > span {
    &:first-child {
      font-weight: 700;
      font-size: 28px;
      line-height: 34px;
      color: #0b0914;
    }

    &:last-child {
      color: #0b0914;
      font-size: 12px;
    }
  }
`;

export const Button = styled.button`
  height: 54px;
  background: #532fd8;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 18px;
  line-height: 34px;
  color: #fff;
  width: 100%;
  border: unset !important;
  outline: unset !important;

  &&&:disabled {
    background: #d9d9d9;
    color: #fff;
  }
`;

export const PlansWrapper = styled(Row)``;

export const PlanWrapper = styled(Col)`
  &:first-child {
    ${Button} {
      color: #111827;
      background: #f8f0fb;
    }
  }

  &:nth-child(2) {
    ${Button} {
      background: #9b51e0;
    }
  }
`;

export const PlanWrap = styled.div`
  border: 1px solid #98a5cd;
  border-radius: 18px;
  padding: 32px;
  position: relative;
`;

export const IconWrap = styled.div`
  position: absolute;
  right: 20px;
  top: 8px;
`;
