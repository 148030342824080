import { put, call, takeLatest } from 'redux-saga/effects';
import * as service from '../../service';
import { tagsTypes } from './types';
import {
  getTagsSuccess,
  getTagsFailure,
  createTagSuccess,
  createTagFailure,
  updateTagSuccess,
  updateTagFailure,
  deleteTagSuccess,
  deleteTagFailure,
  deleteTagsSuccess,
  deleteTagsFailure,
} from './actions';

export function* fetchTags({ payload }) {
  try {
    const data = yield call(() => {
      return service.fetchDepartmentTags(payload);
    });
    yield put(getTagsSuccess(data));
  } catch (e) {
    yield put(getTagsFailure(e.message));
  }
}

export function* createTag({ payload }) {
  try {
    const data = yield call(() => {
      return service.createTag(payload);
    });
    yield put(createTagSuccess(data));
  } catch (e) {
    yield put(createTagFailure(e.message));
  }
}

export function* updateTag({ payload }) {
  try {
    const data = yield call(() => {
      return service.updateTag(payload);
    });
    yield put(updateTagSuccess(data));
  } catch (e) {
    yield put(updateTagFailure(e.message));
  }
}

export function* deleteTagField({ id, tagId }) {
  try {
    const data = yield call(() => {
      return service.deleteTagField(id, tagId);
    });
    yield put(deleteTagSuccess(data));
  } catch (e) {
    yield put(deleteTagFailure(e.message));
  }
}

export function* deleteTags({ id, tagId }) {
  try {
    const data = yield call(() => {
      return service.deleteTags(id, tagId);
    });
    yield put(deleteTagsSuccess(data));
  } catch (e) {
    yield put(deleteTagsFailure(e.message));
  }
}

export function* updateTagField({ payload }) {
  try {
    const data = yield call(() => {
      return service.updateTagField(payload);
    });
    yield put(updateTagSuccess(data));
  } catch (e) {
    yield put(updateTagFailure(e.message));
  }
}

export function* tagsSaga() {
  yield takeLatest(tagsTypes.GET_TAGS_START, fetchTags);
  yield takeLatest(tagsTypes.CREATE_TAG_START, createTag);
  yield takeLatest(tagsTypes.UPDATE_TAG_START, updateTag);
  yield takeLatest(tagsTypes.DELETE_TAG_START, deleteTagField);
  yield takeLatest(tagsTypes.DELETE_TAGS_START, deleteTags);
  yield takeLatest(tagsTypes.UPDATE_TAG_FIELD_START, updateTagField);
}
