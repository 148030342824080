import React from 'react';
import withIcon from '../withIcon';

const NotificationIcon = () => {
  return (
    <svg
      width="1em"
      height="1.1em"
      viewBox="0 0 20 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 16.9228C15.6392 16.9228 18.2481 16.1994 18.5 13.2957C18.5 10.394 16.6812 10.5806 16.6812 7.02031C16.6812 4.23934 14.0452 1.0752 10 1.0752C5.95477 1.0752 3.31885 4.23934 3.31885 7.02031C3.31885 10.5806 1.5 10.394 1.5 13.2957C1.75295 16.2104 4.36177 16.9228 10 16.9228Z"
        stroke="#757B8C"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.3889 19.9326C11.0247 21.4473 8.89672 21.4653 7.51953 19.9326"
        stroke="#757B8C"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default withIcon(NotificationIcon);
