import React from 'react';
import withIcon from '../withIcon';

const ArrowRight = () => {
  return (
    <svg
      width="1em"
      height="2.25em"
      viewBox="0 0 8 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.12052 1.21338L7.12793 7.22047L1.12052 13.2279"
        stroke="#3C475D"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default withIcon(ArrowRight);
