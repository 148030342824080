export const boardsTypes = {
    GET_BOARDS_START: 'GET_BOARDS_START',
    GET_BOARDS_SUCCESS: 'GET_BOARDS_SUCCESS',
    GET_BOARDS_FAILURE: 'GET_BOARDS_FAILURE',
    CREATE_BOARD_START: 'CREATE_BOARD_START',
    CREATE_BOARD_SUCCESS: 'CREATE_BOARD_SUCCESS',
    CREATE_BOARD_FAILURE: 'CREATE_BOARD_FAILURE',
    UPDATE_BOARD_START: 'UPDATE_BOARD_START',
    UPDATE_BOARD_SUCCESS: 'UPDATE_BOARD_SUCCESS',
    UPDATE_BOARD_FAILURE: 'UPDATE_BOARD_FAILURE',
    DELETE_BOARD_START: 'DELETE_BOARD_START',
    DELETE_BOARD_SUCCESS: 'DELETE_BOARD_SUCCESS',
    UPDATE_BOARD_CHECKLIST_START: 'UPDATE_BOARD_CHECKLIST_START',
    BOARD_CHECKLIST_COMMENT_START: 'BOARD_CHECKLIST_COMMENT_START',
    UPDATE_BOARD_TAG_START: 'UPDATE_BOARD_TAG_START',
    CREATE_BOARD_NOTES_START: 'CREATE_BOARD_NOTES_START',
};
