import React, { useState, useEffect } from 'react';
import { Modal, Select } from 'antd';
import SetAssets from '../../assets/setAssets';
import { ActionButtons } from '../../shared';
import {
  createAssets,
  fetchAssets,
  fetchBoard,
  updateBoard,
  updateAssets,
} from '../../../service';
import { arrayOfObjectsMergeById } from '../../../helpers/helpers';
import {
  BlockWrap,
  AssetsWrap,
  AssetsHeader,
  AssetsContent,
  GreyButton,
  ButtonWrap,
} from './styles';
import { BOARD_TYPE } from '../../../helpers/variables';

function BoardAssets({ board }) {
  const [options, setOptions] = useState([]);
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [editAssets, setEditAssets] = useState();

  useEffect(() => {
    fetchAssets(0, 1000)
      .then((res) =>
        setOptions(
          res.data.map((item) => ({ label: item.model, value: item._id })),
        ),
      )
      .catch();
    fetchBoard(board._id).then((res) => setData(res.assets));
  }, [board._id]);

  const handleSelect = (value) => {
    if (!data.find((item) => item._id === value)) {
      updateBoard({
        _id: board._id,
        assets: [value, ...data.map((item) => item._id)],
      })
        .then((res) => {
          setOpen(false);
          setData(res.assets);
        })
        .catch();
    }
  };

  const handleCreateAssets = (body) => {
    createAssets(body)
      .then((res) => handleSelect(res._id))
      .catch();
  };

  const handleUpdateAssets = (value) => {
    setEditAssets(data.find((item) => item._id === value));
  };

  const handleDeleteAssets = (value) => {
    updateBoard({
      _id: board._id,
      assets: data.filter((item) => item._id !== value).map((item) => item._id),
    })
      .then((res) => setData(res.assets))
      .catch();
  };

  const handleEditAssets = (body) => {
    updateAssets(body, editAssets._id)
      .then((res) => {
        setData((prev) =>
          arrayOfObjectsMergeById(prev, { ...body, _id: editAssets._id }),
        );
        setEditAssets();
      })
      .catch();
  };

  return (
    <BlockWrap>
      <div>Assets</div>
      <AssetsWrap>
        <AssetsHeader>
          <div>Asset Tag</div>
          <div>Serial</div>
          <div style={{ width: '24%' }}>Model</div>
          <div>Category</div>
          <div style={{ width: '12%' }}>Status</div>
          <div style={{ width: '70px' }}></div>
        </AssetsHeader>
        <div>
          {data.map((item, index) => (
            <AssetsContent key={index}>
              <div>{item.tag}</div>
              <div>{item.serial}</div>
              <div style={{ width: '24%' }}>{item.model}</div>
              <div>{item.category}</div>
              <div style={{ width: '12%', color: '#26DE81' }}>Assigned</div>
              {board.board === BOARD_TYPE.onboard && (
                <div style={{ width: '70px' }}>
                  <ActionButtons
                    handleUpdate={() => handleUpdateAssets(item._id)}
                    handleDelete={() => handleDeleteAssets(item._id)}
                  />
                </div>
              )}
            </AssetsContent>
          ))}
        </div>
        {board.board === BOARD_TYPE.onboard && (
          <ButtonWrap>
            <GreyButton onClick={() => setOpen(true)}>Add</GreyButton>
          </ButtonWrap>
        )}
      </AssetsWrap>

      {editAssets && (
        <SetAssets
          data={editAssets}
          onSubmit={handleEditAssets}
          onCancel={() => setEditAssets()}
        />
      )}
      <Modal
        destroyOnClose
        title="Assets"
        open={open}
        onCancel={() => setOpen(false)}
        footer={null}
      >
        <div style={{ marginBottom: 20 }}>
          <SetAssets onSubmit={handleCreateAssets} />
        </div>

        <Select
          placeholder="Select from existing"
          style={{ width: '100%' }}
          size="large"
          options={options}
          onChange={handleSelect}
        />
      </Modal>
    </BlockWrap>
  );
}

export default BoardAssets;
