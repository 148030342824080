import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ConfirmModal, CustomModal } from '../shared';
import Actions from './actions';
import slack from '../../assets/images/slack.png';
import okta from '../../assets/images/okta-logo.png';
import lever from '../../assets/images/lever-logo.png';
import greenhouse from '../../assets/images/greenhouse-logo.png';
import office365 from '../../assets/images/office-365-sync.png';
import {
  Button,
  AtsName,
  InfoIconWrap,
  AtsWrap,
  AtsImage,
  AtsInfoContent,
  Modal,
  GsuiteConfigWrap,
  DangerButton,
  ButtonWrap,
} from './styles';
import { deleteIntegrations, createIntegration } from '../../service';
import { CheckCircleFilled } from '../../Icons';
import {
  getLeverIntegrationUrl,
  getOffice365IntegrationUrl,
} from '../../helpers/helpers';

const images = {
  okta,
  lever,
  greenhouse,
  office365,
  slack,
};

const redirectUrls = {
  lever: getLeverIntegrationUrl,
  office365: getOffice365IntegrationUrl,
};

function SetIntegration(props) {
  const { profile } = useSelector((state) => state.global);
  const [data, setData] = useState({});
  const [show, setShow] = useState(false);
  const [open, setOpen] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    if (props.data?._id) {
      setData(props.data);
    }
  }, [props.data]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSubmit = () => {
    if (props.onSubmit) {
      props.onSubmit();
      return;
    }

    if (props.body) {
      createIntegration(props.body, props.name)
        .then((res) => {
          setShow(false);
          setData(res);
          props.onSuccess && props.onSuccess(res);
        })
        .catch((e) => {
          setError(e.response?.data?.message);
        });
    }
  };

  const handleClick = () => {
    ['lever', 'office365'].includes(props.name)
      ? (window.location.href = redirectUrls[props.name](profile.company))
      : setShow(true);
  };

  const handleDelete = () => {
    deleteIntegrations(data._id).then(() => {
      setShowConfirm(false);
      setOpen(false);
      setData({});
    });
  };

  return (
    <>
      <AtsWrap>
        <InfoIconWrap>
          {data._id && (
            <Actions
              onDelete={() => setShowConfirm(true)}
              onEdit={props.editConfigs ? () => setOpen(true) : handleClick}
            />
          )}
        </InfoIconWrap>
        <AtsImage
          style={{
            backgroundImage: `url(${images[props.name]})`,
          }}
        />

        <ButtonWrap>
          {data._id ? (
            <CheckCircleFilled style={{ color: '#26DE81', fontSize: 30 }} />
          ) : (
            <Button onClick={handleClick}>Sync</Button>
          )}
        </ButtonWrap>
        <AtsName>{props.label}</AtsName>
      </AtsWrap>
      <CustomModal
        open={show}
        title={props.title || 'Add your OAuth info.'}
        onCancel={() => setShow(false)}
        onOk={handleSubmit}
      >
        <>
          {props.children}
          {error && <div style={{ color: 'red' }}>{error}</div>}
        </>
      </CustomModal>
      <ConfirmModal
        show={showConfirm}
        handleClose={() => setShowConfirm(false)}
        handleConfirm={handleDelete}
      />
      <Modal
        open={open}
        title=""
        footer={null}
        width={750}
        onCancel={() => setOpen(false)}
      >
        <AtsInfoContent style={{ padding: 0 }}>
          <AtsImage>
            <img src={images[props.name]} alt={props.name} />
          </AtsImage>
          {props.editConfigs}
          <GsuiteConfigWrap>
            <DangerButton onClick={() => setShowConfirm(true)}>
              Disconnect
            </DangerButton>
          </GsuiteConfigWrap>
        </AtsInfoContent>
      </Modal>
    </>
  );
}

export default SetIntegration;
