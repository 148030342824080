import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { Col, Form, Row } from 'antd';
import logo from '../../assets/images/white-logo.png';
import SubmitButton from '../shared/submitButton';
import { isValidToken } from '../../helpers/helpers';
import { sendPasswordToken } from '../../service';
import { ROUTES } from '../../helpers/variables';
import { Container, FlexBetween } from '../shared/styles';
import {
  Input,
  Wrapper,
  Link,
  Logo,
  ResendEmail,
  Content,
  Title,
  LinkLogin,
} from './styles';

const ForgotPassword = () => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState('');
  const [message, setMessage] = useState('');
  const [email, setEmail] = useState('');

  const handleSubmit = ({ email }) => {
    setLoading(true);
    setEmail(email);
    sendPasswordToken({ email })
      .then((res) => {
        setSuccess(res.message);
      })
      .catch((e) => {
        setLoading(false);
        setMessage(e.response.data.message);
        setEmail('');
      });
  };
  if (isValidToken()) {
    return <Redirect to={ROUTES.home.path} />;
  }
  return (
    <Wrapper>
      <Container>
        <FlexBetween>
          <Logo>
            <Link to={ROUTES.home.path}>
              <img src={logo} alt="logo" />
            </Link>
          </Logo>
          <div>
            <LinkLogin to={ROUTES.login.path}>Sign In</LinkLogin>
          </div>
        </FlexBetween>
        {success ? (
          <ResendEmail>
            {success}{' '}
            <span onClick={() => sendPasswordToken({ email })}>
              Resend email.
            </span>
          </ResendEmail>
        ) : (
          <Content md={{ span: 12, offset: 6 }}>
            <Title>Reset Password</Title>
            <p>To reset your password, please provide your Thelma email.</p>

            <Form onFinish={handleSubmit}>
              <Form.Item
                name="email"
                placeholder="Email"
                validateStatus={message && 'error'}
                help={message}
                rules={[
                  {
                    required: true,
                    type: 'email',
                    message: 'Please provide a valid email.',
                  },
                ]}
              >
                <Input placeholder="Email" />
              </Form.Item>
              <Row style={{ marginBottom: 12 }}>
                <Col span={24}>
                  <span>Don't have an account? </span>
                  <Link to={ROUTES.register.path}>Create an account.</Link>
                </Col>
              </Row>
              <SubmitButton loading={loading} />
            </Form>
          </Content>
        )}
      </Container>
    </Wrapper>
  );
};
export default ForgotPassword;
