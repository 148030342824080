import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SetAssets from './setAssets';
import Actions from './actions';
import {
  DataTable,
  ConfirmModal,
  ActionButtons,
  UsersDropDown,
} from '../shared';
import {
  deleteAssetsStart,
  getAssetsStart,
  updateAssetsStart,
} from '../../redux/assets/actions';
import { ASSETS_STATUS } from '../../helpers/variables';
import { Wrapper, StatusText, Button } from './styles';

const Assets = () => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [assetID, setAssetId] = useState('');
  const [asset, setAsset] = useState({});
  const [action, setAction] = useState('');
  const [show, setShow] = useState(false);
  const {
    loading,
    isLoading,
    assets: { data = [], total = 0 },
  } = useSelector((state) => state.assets);

  const columns = [
    {
      dataField: 'tag',
      text: 'Asset Tag',
    },
    {
      dataField: 'serial',
      text: 'Serial',
    },
    {
      dataField: 'model',
      text: 'Model',
    },
    {
      dataField: 'category',
      text: 'Category',
    },
    {
      dataField: 'status',
      text: 'Status',
      formatter: (cell, row) => (
        <StatusText
          color={ASSETS_STATUS[cell].color}
          onClick={handleChangeStatus(row._id, cell)}
        >
          {ASSETS_STATUS[cell].label}
        </StatusText>
      ),
    },
    {
      dataField: 'owner',
      text: 'Checked Out To',
      formatter: (cell) => {
        const { firstName = '', lastName = '' } = cell || {};
        return <span>{`${firstName} ${lastName}`}</span>;
      },
    },
    {
      dataField: 'updatedAt',
      text: 'Updated',
      hidden: true,
    },
    {
      dataField: 'createdAt',
      text: 'Created',
      hidden: true,
    },
    {
      dataField: 'checkin',
      text: 'Checkout/Checkin',
      formatter: (cell, row) => (
        <UsersDropDown
          value={row.owner?._id}
          onSelect={(owner) => dispatch(updateAssetsStart({ owner }, row._id))}
        >
          <Button
            type="button"
            isCheckout={row.owner}
            title="Check this item out"
            onClick={(e) => e.stopPropagation()}
          >
            {row.owner ? 'Checkout' : 'Checkin'}
          </Button>
        </UsersDropDown>
      ),
    },
    {
      dataField: 'action',
      text: 'Actions',
      formatter: (cell, row) => (
        <ActionButtons
          handleClone={handleClick(row, 'clone')}
          handleUpdate={handleClick(row, 'update')}
          handleDelete={handleClick(row, 'delete')}
        />
      ),
    },
  ];

  useEffect(() => {
    dispatch(getAssetsStart({ offset: 0, limit: sizePerPage }));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleChangeStatus = (id, status) => (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (!id) return;
    dispatch(updateAssetsStart({ status: ASSETS_STATUS[status].status }, id));
  };

  const handleClick = (row, action) => (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (action === 'delete') {
      setAssetId(row._id);
      setShow(true);
      return;
    }
    setAsset(row);
    setAction(action);
  };

  const handleDelete = () => {
    setAssetId('');
    if (!assetID) return;
    const pagination = total > 1 && data.length === 1 ? page - 1 : page;
    const params = {
      offset: (pagination - 1) * sizePerPage,
      limit: sizePerPage,
    };
    setPage(pagination);
    dispatch(deleteAssetsStart(assetID, params));
  };

  const handleBulkAction = (rows, action) => {
    console.log(action, rows);
  };

  const handleTableChange = (type, { page, sizePerPage, searchText }) => {
    const currentIndex = (page - 1) * sizePerPage;

    if (type === 'search') {
      setPage(1);
      setSizePerPage(sizePerPage);
    } else {
      setPage(page);
      setSizePerPage(sizePerPage);
    }
    dispatch(
      getAssetsStart({
        offset: currentIndex,
        limit: sizePerPage + currentIndex,
        search: searchText,
      }),
    );
  };
  return (
    <Wrapper>
      <SetAssets
        data={asset}
        action={action}
        loading={isLoading}
        reload={() =>
          dispatch(getAssetsStart({ offset: 0, limit: sizePerPage }))
        }
        reset={() => {
          setAsset({});
          setAction('');
        }}
      />
      <DataTable
        data={data}
        page={page}
        columns={columns}
        loading={loading}
        totalSize={total}
        sizePerPage={sizePerPage}
        csvFileName="assets.csv"
        refreshData={() => {
          setPage(1);
          dispatch(getAssetsStart({ offset: 0, limit: sizePerPage }));
        }}
        actionButtons={(selected) => (
          <Actions
            selected={selected}
            reload={() =>
              dispatch(getAssetsStart({ offset: 0, limit: sizePerPage }))
            }
          />
        )}
        handleTableChange={handleTableChange}
        handleBulkAction={(rows, action) => handleBulkAction(rows, action)}
      />
      <ConfirmModal
        show={show}
        handleClose={() => setShow(false)}
        handleConfirm={handleDelete}
      />
    </Wrapper>
  );
};

export default Assets;
