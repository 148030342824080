import { softwareTypes } from './types';
import { arrayOfObjectsMergeById } from '../../helpers/helpers';

const initialState = {
    software: { data: [], total: 0 },
    setData: {},
    error: '',
    loading: false,
    isLoading: false,
};

export const software = (state = initialState, action) => {
    switch (action.type) {
        case softwareTypes.GET_SOFTWARE_START:
            return { ...state, loading: true };
        case softwareTypes.GET_SOFTWARE_SUCCESS:
            return { ...state, software: { ...state.software, ...action.data }, loading: false };
        case softwareTypes.GET_SOFTWARE_FAILURE:
            return { ...state, software: { data: [], total: 0 }, loading: false };
        case softwareTypes.CREATE_SOFTWARE_START:
            return { ...state, isLoading: true };
        case softwareTypes.CREATE_SOFTWARE_SUCCESS:
            return {
                ...state,
                software: {
                    ...state.software,
                    data: [action.data, ...state.software.data],
                    total: state.software.total + 1,
                },
                setData: action.data,
                isLoading: false,
                error: '',
            };
        case softwareTypes.UPDATE_SOFTWARE_START:
            return { ...state, isLoading: true };
        case softwareTypes.UPDATE_SOFTWARE_SUCCESS:
            return {
                ...state,
                software: {
                    ...state.software,
                    data: [...arrayOfObjectsMergeById(state.software.data, action.data)],
                },
                setData: action.data,
                isLoading: false,
                error: '',
            };
        case softwareTypes.DELETE_SOFTWARE_START:
            return { ...state, isLoading: true };
        case softwareTypes.DELETE_SOFTWARE_SUCCESS:
            return {
                ...state,
                setData: action.data,
                isLoading: false,
                error: '',
            };
        case softwareTypes.SET_SOFTWARE_FAILURE:
            return { ...state, error: action.error, isLoading: false };
        default:
            return state;
    }
};
