import React from 'react';
import { Modal } from 'antd';
import styled from 'styled-components';
import { AnnotationIcon, PencilIcon, TrashIcon } from '../../../Icons/index';
import UploadFile from '../UploadFile/index';

export const ActionsWrap = styled.div`
  background: #ffffff;
  display: flex;
  align-items: center;
  margin: 10px 0 10px 10px;

  ${(props) => props.disabled && `opacity: 0.7; pointer-events: none;`}

  & > div {
    width: 46px;
    height: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: 1px solid #d1d5db;
    border-right: unset;

    &:first-child {
      border-radius: 6px 0 0 6px;
    }

    &:last-child {
      border-right: 1px solid #d1d5db;
      border-radius: 0 6px 6px 0;
    }

    svg {
      color: #9ca3af;
      font-size: 14px;
    }
  }
`;

function ToolbarButtonsGroup({
  disabled,
  onEdit,
  onUpload,
  onAnnotate,
  onDelete,
}) {
  const [modal, contextHolder] = Modal.useModal();

  const handleConfirm = () => {
    modal.confirm({
      title: 'Are you sure you wish to delete ?',
      onOk: onDelete,
    });
  };

  return (
    <ActionsWrap disabled={disabled}>
      {onEdit && (
        <div onClick={onEdit}>
          <PencilIcon />
        </div>
      )}
      {onUpload && (
        <div>
          <UploadFile onSelect={onUpload} />
        </div>
      )}
      {onAnnotate && (
        <div onClick={onAnnotate}>
          <AnnotationIcon />
        </div>
      )}
      {onDelete && (
        <div onClick={() => handleConfirm()}>
          <TrashIcon />
        </div>
      )}
      {contextHolder}
    </ActionsWrap>
  );
}

export default ToolbarButtonsGroup;
