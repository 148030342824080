import styled from 'styled-components';
import { Input as AntdInput } from 'antd';

export const Wrapper = styled.div`
  padding: 15px 0;
`;

export const StatusText = styled.div`
  cursor: pointer;
  color: ${(props) => props.color};
`;

export const Button = styled.button`
  width: 96px;
  height: 38px;
  border: unset;
  background: ${(props) => (props.isCheckout ? '#4F46E5' : '#ff4242')};
  border-radius: 8px;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: 0.02em;
  color: #ffffff;
`;

export const ActionsWrap = styled.div`
  background: #ffffff;
  display: flex;
  align-items: center;
  margin: 10px 0 10px 10px;

  ${(props) => props.disabled && `opacity: 0.7; pointer-events: none;`}

  & > div {
    width: 46px;
    height: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: 1px solid #d1d5db;
    border-right: unset;

    &:first-child {
      border-radius: 6px 0 0 6px;
    }

    &:last-child {
      border-right: 1px solid #d1d5db;
      border-radius: 0 6px 6px 0;
    }

    svg {
      color: #9ca3af;
      font-size: 14px;
    }
  }
`;

export const Input = styled(AntdInput).attrs({ size: 'large' })`
  border-radius: 4px;
`;

export const SetWrapper = styled.div`
  text-align: right;
  margin-bottom: 16px;
`;
