export const getUsersState = (state) => state.users;
export const getUsersData = (state) => state.users.users;
export const getUsersList = (state) => state.users.usersList;
export const getUsersError = (state) => state.users.error;
export const getUsersLoading = (state) => state.users.isLoading;
export const getUsersInfo = (state) =>
  state.users.usersList.reduce((acc, cur) => {
    acc[cur._id] = cur;
    return acc;
  }, {});
