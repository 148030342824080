import { put, call, takeLatest } from 'redux-saga/effects';
import {
  getSoftwareSuccess,
  getSoftwareFailure,
  createSoftwareSuccess,
  updateSoftwareSuccess,
  setSoftwareFailure,
  deleteSoftwareSuccess,
  getSoftwareStart,
} from './actions';
import { softwareTypes } from './types';
import * as service from '../../service';

export function* getSoftware({ params }) {
  const { limit, offset, search } = params;
  try {
    const data = yield call(() => service.fetchSoftware(offset, limit, search));
    yield put(getSoftwareSuccess(data));
  } catch (e) {
    yield put(getSoftwareFailure(e.message));
  }
}

export function* create({ body }) {
  try {
    const data = yield call(() => {
      return service.createSoftware(body);
    });
    yield put(createSoftwareSuccess(data));
  } catch (e) {
    yield put(setSoftwareFailure(e.message));
  }
}

export function* update({ body, id }) {
  try {
    const data = yield call(() => {
      return service.updateSoftware(body, id);
    });
    yield put(updateSoftwareSuccess(data));
  } catch (e) {
    yield put(setSoftwareFailure(e.message));
  }
}

export function* deleteAsset({ id, params }) {
  try {
    const data = yield call(() => {
      return service.deleteSoftware(id);
    });
    yield put(deleteSoftwareSuccess(data));
    if (params && params.offset >= 0 && params.limit) {
      yield put(getSoftwareStart(params));
    }
  } catch (e) {
    yield put(setSoftwareFailure(e.message));
  }
}

export function* softwareSaga() {
  yield takeLatest(softwareTypes.GET_SOFTWARE_START, getSoftware);
  yield takeLatest(softwareTypes.CREATE_SOFTWARE_START, create);
  yield takeLatest(softwareTypes.UPDATE_SOFTWARE_START, update);
  yield takeLatest(softwareTypes.DELETE_SOFTWARE_START, deleteAsset);
}
