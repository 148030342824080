import React from 'react';
import withIcon from '../withIcon';

const CopyIcon = () => {
  return (
    <svg
      width="1em"
      height="1.1em"
      viewBox="0 0 18 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.3498 0H6.64977C5.60977 0 4.75977 0.84 4.75977 1.88V2.82C4.75977 3.86 5.59977 4.7 6.63977 4.7H11.3498C12.3898 4.7 13.2298 3.86 13.2298 2.82V1.88C13.2398 0.84 12.3898 0 11.3498 0Z"
        fill="#9B51E0"
      />
      <path
        d="M14.24 2.81998C14.24 4.40998 12.94 5.70998 11.35 5.70998H6.65004C5.06004 5.70998 3.76004 4.40998 3.76004 2.81998C3.76004 2.25998 3.16004 1.90998 2.66004 2.16998C1.25004 2.91998 0.290039 4.40998 0.290039 6.11998V15.53C0.290039 17.99 2.30004 20 4.76004 20H13.24C15.7 20 17.71 17.99 17.71 15.53V6.11998C17.71 4.40998 16.75 2.91998 15.34 2.16998C14.84 1.90998 14.24 2.25998 14.24 2.81998ZM9.38004 14.95H5.00004C4.59004 14.95 4.25004 14.61 4.25004 14.2C4.25004 13.79 4.59004 13.45 5.00004 13.45H9.38004C9.79004 13.45 10.13 13.79 10.13 14.2C10.13 14.61 9.79004 14.95 9.38004 14.95ZM12 10.95H5.00004C4.59004 10.95 4.25004 10.61 4.25004 10.2C4.25004 9.78998 4.59004 9.44998 5.00004 9.44998H12C12.41 9.44998 12.75 9.78998 12.75 10.2C12.75 10.61 12.41 10.95 12 10.95Z"
        fill="#9B51E0"
      />
    </svg>
  );
};

export default withIcon(CopyIcon);
