import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import Checklists from './checklists';
import OffBoardGoogleUser from './offBoardGoogleUser';
import Tags from './tags';
import Notes from './notes';
import OnboardEmail from './onboardEmail';
import BoardAssets from './boardAssets';
import { CaretDownOutlined } from '../../../Icons';
import { BOARD_TYPE, ROLE_VALUES } from '../../../helpers/variables';
import { dateGreaterCompare } from '../../../helpers/helpers';
import { offBoardUser } from '../../../service';
import { SubmitButton } from '../../shared';
import styles from '../table.module.scss';

function BoardData({ board, isEditable, role, googleSync }) {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [showFields, setShowFields] = useState(false);

  const handleOffBoardUser = (id) => {
    setLoading(true);
    offBoardUser(id)
      .then(() => setSuccess(true))
      .finally(() => setLoading(false));
  };

  return (
    <div className="p-2 position-relative">
      <div
        className="position-absolute d-flex justify-content-between align-items-center w-100"
        style={{ right: -10, top: -45, margin: 10 }}
      >
        {showFields && (
          <div className="d-flex flex-wrap">
            <div className="mr-3">
              <span className="font-weight-bold">Location: </span>
              {board.location}
            </div>
            {board.fields.map((item) => (
              <div className="mr-3" key={item._id}>
                <span className="font-weight-bold">{item.label}: </span>
                {item.value}
              </div>
            ))}
            {board.board === BOARD_TYPE.offboard &&
              dateGreaterCompare(board.date, new Date()) &&
              !success && (
                <SubmitButton
                  loading={loading}
                  type={'text'}
                  size="sm"
                  variant="outline-primary"
                  text="Offboard now"
                  style={{ marginLeft: 'auto' }}
                  onClick={() => handleOffBoardUser(board._id)}
                />
              )}
          </div>
        )}
        <CaretDownOutlined
          onClick={() => setShowFields((prev) => !prev)}
          style={{
            color: '#26DE81',
            marginLeft: 'auto',
            transform: `rotate(${showFields ? 180 : 0}deg)`,
          }}
        />
      </div>
      <Row>
        <Col md={12}>
          <div className={`${styles.checklistContent} mb-3`}>
            <Checklists board={board} isEditable={isEditable} />
          </div>
        </Col>
        <Col md={12}>
          <div className={styles.tagContent}>
            <Tags board={board} isEditable={isEditable} />
          </div>
        </Col>
        <Col md={12}>
          <Notes board={board} />

          {ROLE_VALUES.hr === role && (
            <div className="mt-4">
              <Notes board={board} type="hrNotes" title="HR Confidential" />
            </div>
          )}
        </Col>
        {googleSync?._id && board.board === BOARD_TYPE.offboard && (
          <Col md={12}>
            <div className={`${styles.checklistContent} mb-3`}>
              <OffBoardGoogleUser board={board} offBoard={success} />
            </div>
          </Col>
        )}
        {ROLE_VALUES.admin === role && (
          <Col md={12}>
            <BoardAssets board={board} />
          </Col>
        )}
        {board.board === BOARD_TYPE.onboard && (
          <Col md={12}>
            <OnboardEmail board={board} />
          </Col>
        )}
      </Row>
    </div>
  );
}

export default BoardData;
