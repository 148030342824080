import { boardsTypes } from './types';

export const getBoardsStart = (payload) => ({
    type: boardsTypes.GET_BOARDS_START,
    payload,
});

export const getBoardsSuccess = (data, board = 'boards') => ({
    type: boardsTypes.GET_BOARDS_SUCCESS,
    data,
    board,
});

export const getBoardsFailure = (error, board = 'boards') => ({
    type: boardsTypes.GET_BOARDS_FAILURE,
    error,
    board,
});

export const createBoardStart = (body) => ({
    type: boardsTypes.CREATE_BOARD_START,
    body,
});

export const createBoardSuccess = (data) => ({
    type: boardsTypes.CREATE_BOARD_SUCCESS,
    data,
});

export const createBoardFailure = (error) => ({
    type: boardsTypes.CREATE_BOARD_FAILURE,
    error,
});

export const updateBoardStart = (body) => ({
    type: boardsTypes.UPDATE_BOARD_START,
    body,
});

export const updateBoardSuccess = (data) => ({
    type: boardsTypes.UPDATE_BOARD_SUCCESS,
    data,
});

export const updateBoardFailure = (error) => ({
    type: boardsTypes.UPDATE_BOARD_FAILURE,
    error,
});

export const deleteBoardStart = (id) => ({
    type: boardsTypes.DELETE_BOARD_START,
    id,
});

export const deleteBoardSuccess = (data) => ({
    type: boardsTypes.DELETE_BOARD_SUCCESS,
    data,
});

export const updateBoardChecklistStart = (id, body) => ({
    type: boardsTypes.UPDATE_BOARD_CHECKLIST_START,
    id,
    body,
});

export const addBoardChecklistCommentStart = (id, body) => ({
    type: boardsTypes.BOARD_CHECKLIST_COMMENT_START,
    id,
    body,
});

export const updateBoardTagStart = (id, tagId, body) => ({
    type: boardsTypes.UPDATE_BOARD_TAG_START,
    id,
    tagId,
    body,
});

export const createBoardNotesStart = (url, body) => ({
    type: boardsTypes.CREATE_BOARD_NOTES_START,
    url,
    body,
});
