import React, { useState, useTransition } from 'react';
import { useSelector } from 'react-redux';
import { getUsersList } from '../../../redux/users/selectors';
import { getUserFullName, getShortUserName } from '../../../helpers/helpers';
import {
  Avatar,
  Modal,
  Input,
  Wrapper,
  Wrap,
  UserInfo,
  CleareWrap,
} from './styles';

const filterUsers = (users, search) =>
  search
    ? users.filter(
        (user) =>
          user.firstName?.toLowerCase().includes(search) ||
          user.lastName?.toLowerCase().includes(search) ||
          user.email?.toLowerCase().includes(search) ||
          user.personalEmail?.toLowerCase().includes(search),
      )
    : users;

function SelectUserModal({ onSelect, showHiringManager, children }) {
  const users = useSelector(getUsersList);
  const [, startTransition] = useTransition();
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState('');

  const handleClick = (user) => {
    onSelect(user._id, user);
    setOpen(false);
  };

  const handleSearch = (e) => {
    startTransition(() => setSearch(e.target.value));
  };

  return (
    <>
      <div onClick={() => setOpen(true)}>{children || <div>Add</div>}</div>
      <Modal
        width={300}
        title="Select User"
        footer={null}
        open={open}
        onCancel={() => setOpen(false)}
      >
        <Input placeholder="Search" onChange={handleSearch} />

        <Wrapper>
          {showHiringManager && (
            <Wrap
              onClick={() =>
                handleClick({
                  _id: null,
                  firstName: 'Hiring',
                  lastName: 'Manager',
                })
              }
            >
              <Avatar>{getShortUserName('Hiring', 'Manager')}</Avatar>
              <UserInfo>
                <div>Hiring Manager</div>
                <div>{`Assigns {manager} to the task`}</div>
              </UserInfo>
            </Wrap>
          )}
          {filterUsers(users, search?.toLowerCase()).map((user) => (
            <Wrap key={user._id} onClick={() => handleClick(user)}>
              <Avatar>{getShortUserName(user.firstName, user.lastName)}</Avatar>
              <UserInfo>
                <div>{getUserFullName(user)}</div>
                <div>{user.email || user.personalEmail}</div>
              </UserInfo>
            </Wrap>
          ))}
        </Wrapper>
        <CleareWrap>
          <span onClick={() => handleClick({})}>Cleare</span>
        </CleareWrap>
      </Modal>
    </>
  );
}

export default SelectUserModal;
