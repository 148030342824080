import styled from 'styled-components';
import {
  Form as AntdForm,
  Input as AntdInput,
  Select as AntdSelect,
  Button as AntdButton,
} from 'antd';

export const Wrapper = styled.div``;

export const SubTitle = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #6b7280;
  padding-bottom: 20px;
  width: 100%;
  border-bottom: 1px solid #e5e7eb;
`;

export const Form = styled(AntdForm)``;

export const FormItem = styled(Form.Item)`
  padding: 20px 0;
  border-bottom: 1px solid #e5e7eb;
  margin: 0;

  & > div > div {
    &:first-child {
      text-align: left;
    }

    &:last-child {
      max-width: 320px;
    }
  }
`;

export const Input = styled(AntdInput).attrs({ size: 'large' })`
  border-radius: 6px;
`;

export const Button = styled(AntdButton).attrs({ size: 'large' })`
  background: #9b51e0;
  background-color: #9b51e0;
  border-radius: 6px;
  margin-top: 60px;
`;

export const Select = styled(AntdSelect).attrs({ size: 'large' })`
  border-radius: 6px;

  &&& > div {
    border-radius: 6px;
  }
`;
