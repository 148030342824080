import React, { useState } from 'react';
import styled from 'styled-components';
import { Input as AntdInput } from 'antd';
import { ActionIcons } from '../';
import { replaceHyperlinkToUrl } from '../../../helpers/helpers';

const Label = styled.label`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  text-align: left;
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
`;

const Input = styled(AntdInput)`
  border: unset !important;
  box-shadow: none !important;
`;

export default function ({ title, onDelete, onEdit, onClone }) {
  const [show, setShow] = useState(false);
  const [value, setValue] = useState(title);

  const onClickEdit = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setShow((prev) => !prev);
    setValue(replaceHyperlinkToUrl(title));
  };

  const handleSave = (e) => {
    onEdit(value);
    setShow(false);
  };
  return (
    <Label>
      {show ? (
        <Input
          autoFocus
          value={value}
          onChange={({ target }) => setValue(target.value)}
          onBlur={() => setShow(false)}
          onPressEnter={handleSave}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
          }}
        />
      ) : (
        <span dangerouslySetInnerHTML={{ __html: title }}></span>
      )}
      <ActionIcons
        handleDelete={onDelete}
        handleClone={onClone}
        handleEdit={onClickEdit}
      />
    </Label>
  );
}
