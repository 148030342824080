import React from 'react';
import { useSelector } from 'react-redux';
import { Dropdown, Menu as AntdMenu } from 'antd';
import { getUsersList } from '../../../redux/users/selectors';
import { getUserFullName } from '../../../helpers/helpers';
import styled from 'styled-components';

const Menu = styled(AntdMenu)`
  li {
    padding: 0;
  }
`;

const Option = styled.div`
  padding: 6px 12px;
  background: ${(props) => (props.isSelected ? '#e6f4ff' : 'inherit')};
`;

function UsersDropDown({ onSelect, value, children }) {
  const users = useSelector(getUsersList);
  const items = users.map((user) => ({
    key: user._id,
    label: (
      <Option isSelected={value === user._id}>{getUserFullName(user)}</Option>
    ),
  }));

  const menu = (
    <Menu
      onClick={({ key, domEvent }) => {
        domEvent.stopPropagation();
        onSelect(key || null);
      }}
      items={value ? [{ key: '', label: 'Cleare' }, ...items] : items}
    />
  );

  return (
    <Dropdown
      overlay={menu}
      trigger={['click']}
      overlayStyle={{ maxHeight: 300, overflowY: 'auto', minWidth: 180 }}
      getPopupContainer={(triggerNode) => triggerNode.parentElement}
    >
      {children}
    </Dropdown>
  );
}

export default UsersDropDown;
