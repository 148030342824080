import React, { useState, useEffect, memo } from 'react';
import { DropdownAddNewItem, Tags } from '../../../shared';
import {
  arrayOfObjectDeleteItem,
  arrayOfObjectReplaceItem,
  generateRandomId,
  getIosDateItems,
} from '../../../../helpers/helpers';
import { BOARD_TYPE, EMPLOYMENT_TYPES } from '../../../../helpers/variables';
import { Form, Button, FormItem, Input, Select } from './styles';
import { Title } from '../../styles';

function SettingForm({ department, board, details = {}, form }) {
  const [fields, setFields] = useState([]);
  const [locations, setLocations] = useState([]);

  useEffect(() => {
    if (details._id) {
      const { date = '' } = getIosDateItems(details.date);
      setFields(details.fields);
      setLocations(details.locations);
      setFormValues({ ...details, date });
    } else {
      setFields([]);
      setFormValues({});
    }
  }, [department]); // eslint-disable-line react-hooks/exhaustive-deps

  const setFormValues = (data) => {
    form.setFieldsValue({
      name: data.name,
      ooo: data.ooo,
      title: data.title,
      date: data.date,
      location: data.location,
      employmentType: data.employmentType,
      manager: data.manager,
      fields: data.fields,
      locations: data.locations,
    });
  };

  const handleReset = () => {
    setFields([]);
    form.resetFields();
  };

  const handleAddField = (e) => {
    e.stopPropagation();
    e.preventDefault();
    const val = e.target.value?.trim();
    if (val && !fields?.find((item) => item.label === val)) {
      const newFields = fields.concat({
        _id: generateRandomId(),
        label: val,
        names: [],
        selected: false,
      });
      setFields(newFields);
      form.setFieldValue('fields', newFields);
    }
    form.setFieldValue('addTextField', undefined);
  };

  const handleAddLocation = (locations) => {
    setLocations(locations);
    form.setFieldValue('locations', locations);
  };

  const handleDeleteFields = (id) => {
    const newFields = arrayOfObjectDeleteItem(fields, id);
    setFields(newFields);
    form.setFieldValue('fields', newFields);
  };

  const handleChangeFields = (values, item) => {
    const newFields = arrayOfObjectReplaceItem(fields, {
      ...item,
      ...values,
    });
    setFields(newFields);
    form.setFieldValue('fields', newFields);
  };

  const handleEditFields = (label, item) => {
    const newFields = arrayOfObjectReplaceItem(fields, {
      ...item,
      label,
    });
    setFields(newFields);
    form.setFieldValue('fields', newFields);
  };

  return (
    <>
      <Title>
        {board === BOARD_TYPE.onboard
          ? 'Onboarding From Details'
          : 'Offboarding From Details'}
      </Title>
      <Form form={form} layout={'vertical'} autoComplete="off">
        <FormItem label="Full name" name="name">
          <Input />
        </FormItem>
        {BOARD_TYPE.offboard === board && (
          <FormItem label="Set Out of Office Email" name="ooo">
            <Input />
          </FormItem>
        )}

        <FormItem
          label={BOARD_TYPE.offboard === board ? 'End Date' : 'Start Date'}
          name="date"
        >
          <Input type="date" />
        </FormItem>
        <FormItem label="Title" name="title">
          <Input />
        </FormItem>
        <FormItem label="Employment Type" name="employmentType">
          <Select
            options={EMPLOYMENT_TYPES.map((item) => ({
              label: item,
              value: item,
            }))}
          />
        </FormItem>

        <FormItem label="Location" name="location">
          <DropdownAddNewItem
            options={locations}
            placeholder="Location"
            onAdd={handleAddLocation}
          />
        </FormItem>

        <FormItem label="Add Tag Picklist" name="addTextField">
          <Input onBlur={handleAddField} onPressEnter={handleAddField} />
        </FormItem>

        <FormItem name="fields" value={fields} hidden={true}>
          <Input />
        </FormItem>

        <FormItem name="locations" hidden={true}>
          <Input />
        </FormItem>

        {fields?.map((item) => (
          <Tags
            isFields
            key={item._id}
            name={item.label}
            tags={item.names}
            background={'#E7DEFA'}
            activeElement={item.selected}
            onDelete={() => handleDeleteFields(item._id)}
            onChange={(values) => handleChangeFields(values, item)}
            onEdit={(label) => handleEditFields(label, item)}
          />
        ))}

        <Form.Item>
          <div style={{ textAlign: 'right' }}>
            <Button htmlType="button" onClick={handleReset}>
              Reset
            </Button>
          </div>
        </Form.Item>
      </Form>
    </>
  );
}

export default memo(SettingForm);
