import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DatePicker } from 'antd';
import moment from 'moment';
import ChecklistComment from './comment';
import { InputForm, SelectUserModal } from '../../shared';
import { PencilOutlinedIcon } from '../../../Icons';
import {
  addBoardChecklistCommentStart,
  updateBoardChecklistStart,
} from '../../../redux/boards/actions';
import { getUsersInfo } from '../../../redux/users/selectors';
import {
  createTextHyperLink,
  getDateByFormat,
  getItemStatusInfo,
  getUserFullName,
} from '../../../helpers/helpers';
import { CommentIcon } from '../../../Icons';
import styles from '../table.module.scss';

function Checklists({ board = {}, isEditable }) {
  const dispatch = useDispatch();
  const usersInfo = useSelector(getUsersInfo);
  const { checklists = [], _id: boardId = '' } = board;
  const [item, setItem] = useState({});
  const [show, setShow] = useState('');

  const handleChangeStatus = (_id, status) => () => {
    if (!isEditable || !_id) return;
    dispatch(updateBoardChecklistStart(boardId, { _id, status }));
  };

  const addComment = (comment) => {
    if (!isEditable) return;
    dispatch(
      addBoardChecklistCommentStart(boardId, { _id: item._id, comment }),
    );
    setItem({});
  };
  const handleChangeOwner = (_id) => (owner) => {
    if (!isEditable || !_id) return;
    dispatch(updateBoardChecklistStart(boardId, { _id, owner }));
  };

  const handleAddChecklist = (task) => {
    if (!task) return;
    const title = createTextHyperLink(task);
    dispatch(updateBoardChecklistStart(boardId, { title }));
  };

  const handleBlur = (_id) => (e) => {
    const { name, value } = e.target;
    const prevValue = checklists.find((item) => item._id === _id)[name];
    if (prevValue !== value) {
      dispatch(updateBoardChecklistStart(boardId, { [name]: value, _id }));
    }
    setShow('');
  };

  const handleKeyDown = (_id) => (e) => {
    const { name, value } = e.target;
    const prevValue = checklists.find((item) => item._id === _id)[name];
    if (e.keyCode === 13) {
      if (prevValue !== value) {
        dispatch(updateBoardChecklistStart(boardId, { [name]: value, _id }));
      }
      setShow('');
    }
  };

  return (
    <>
      <div className={styles.checklistHeader}>
        <div>Tasks</div>
        <div>Comments</div>
        <div>Owner</div>
        <div>Status</div>
        <div>Due Date</div>
        <div></div>
      </div>
      <div className={styles.checklistBody}>
        {checklists.map((item) => {
          const itemStatus = getItemStatusInfo(item.status);
          return (
            <div className={styles.checklistItem} key={item._id}>
              {show === item._id ? (
                <div>
                  <input
                    name="title"
                    defaultValue={item.title}
                    onBlur={handleBlur(item._id)}
                    onKeyDown={handleKeyDown(item._id)}
                  />
                </div>
              ) : (
                <div dangerouslySetInnerHTML={{ __html: item.title }} />
              )}
              <div
                className={`${styles.comments} ${
                  item.comments.length ? styles.commentsExist : ''
                }`}
              >
                <span onClick={() => setItem(item)}>
                  <CommentIcon />
                </span>
              </div>
              <div className="position-relative">
                {isEditable ? (
                  <SelectUserModal
                    value={item.owner}
                    onSelect={handleChangeOwner(item._id)}
                  >
                    {usersInfo[item.owner] ? (
                      <span>{getUserFullName(usersInfo[item.owner])}</span>
                    ) : (
                      <div className={styles.addBtn}>Add</div>
                    )}
                  </SelectUserModal>
                ) : (
                  <span>{getUserFullName(usersInfo[item.owner], '+')}</span>
                )}
              </div>
              <div>
                <div
                  className={`${styles.status} cursor-pointer`}
                  style={{
                    backgroundColor: itemStatus.background,
                    color: itemStatus.color,
                  }}
                  onClick={handleChangeStatus(item._id, itemStatus.status)}
                >
                  {itemStatus.label}
                </div>
              </div>
              {show === item._id ? (
                <div>
                  <DatePicker
                    format={'MM-DD-YYYY'}
                    defaultValue={
                      item.dueDate
                        ? moment(new Date(item.dueDate), 'MM-DD-YYYY')
                        : ''
                    }
                    onChange={(date, value) => {
                      handleBlur(item._id)({
                        target: {
                          name: 'dueDate',
                          value: new Date(value).getTime() || null,
                        },
                      });
                    }}
                  />
                </div>
              ) : (
                <div>
                  {item.dueDate && getDateByFormat(item.dueDate, 'm-d-y')}
                </div>
              )}
              <div>
                <PencilOutlinedIcon
                  onClick={() => setShow((prev) => (prev ? '' : item._id))}
                />
              </div>
            </div>
          );
        })}
      </div>

      {isEditable && (
        <InputForm
          btnName="+"
          placeholder="Add an item"
          onSubmit={handleAddChecklist}
        />
      )}
      {isEditable && item._id && (
        <ChecklistComment
          data={item}
          onSubmit={addComment}
          onCancel={() => setItem({})}
        />
      )}
    </>
  );
}

export default Checklists;
