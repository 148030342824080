import React from 'react';
import styled from 'styled-components';
import { CopyIcon, DeleteIcon, EditIcon } from '../../../Icons';

const Wrapper = styled.div`
  display: flex;
  grid-gap: 15px;

  & > div {
    cursor: pointer;
  }

  svg {
    font-size: 18px;
  }
`;
export default function ({ handleDelete, handleUpdate, handleClone }) {
  return (
    <Wrapper>
      {handleClone && (
        <div title="Copy Item" onClick={handleClone}>
          <CopyIcon />
        </div>
      )}
      {handleUpdate && (
        <div title="Edit Item" onClick={handleUpdate}>
          <EditIcon />
        </div>
      )}

      {handleDelete && (
        <div title="Delete Item" onClick={handleDelete}>
          <DeleteIcon />
        </div>
      )}
    </Wrapper>
  );
}
