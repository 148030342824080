import { checklistsTypes } from './types';

export const getChecklistsStart = (payload) => ({
    type: checklistsTypes.GET_CHECKLISTS_START,
    payload,
});

export const getChecklistsSuccess = (data) => ({
    type: checklistsTypes.GET_CHECKLISTS_SUCCESS,
    data,
});

export const getChecklistsFailure = (error) => ({
    type: checklistsTypes.GET_CHECKLISTS_FAILURE,
    error,
});

export const createChecklistStart = (payload) => ({
    type: checklistsTypes.CREATE_CHECKLISTS_START,
    payload,
});

export const createChecklistSuccess = (data) => ({
    type: checklistsTypes.CREATE_CHECKLISTS_SUCCESS,
    data,
});

export const createChecklistFailure = (error) => ({
    type: checklistsTypes.CREATE_CHECKLISTS_FAILURE,
    error,
});

export const deleteChecklistStart = (id) => ({
    type: checklistsTypes.DELETE_CHECKLIST_START,
    id,
});

export const deleteChecklistSuccess = (data) => ({
    type: checklistsTypes.DELETE_CHECKLIST_SUCCESS,
    data,
});

export const deleteChecklistFailure = (error) => ({
    type: checklistsTypes.DELETE_CHECKLIST_FAILURE,
    error,
});

export const updateChecklistStart = (body) => ({
    type: checklistsTypes.UPDATE_CHECKLIST_START,
    body,
});

export const updateChecklistSuccess = (data) => ({
    type: checklistsTypes.UPDATE_CHECKLIST_SUCCESS,
    data,
});

export const updateChecklistFailure = (error) => ({
    type: checklistsTypes.UPDATE_CHECKLIST_FAILURE,
    error,
});
