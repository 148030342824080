import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
`;

export const Content = styled.div`
  background: #e5e5e5;
  width: 100%;
  padding: 24px;
`;

export const ContentWrapper = styled.div`
  width: 100%;
  min-height: calc(100vh - 128px) ;
  color: #000;
  display: flex;
  flex-direction: column;
  background: #fff;
  padding: 32px;
  border-radius: 16px;
`;

export const Breadcrumb = styled.div`
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #111827;

  span {
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
`;
