import { combineReducers } from 'redux';
import { global } from '../redux/global/reducers';
import { users } from '../redux/users/reducers';
import { assets } from '../redux/assets/reducers';
import { passwords } from '../redux/passwords/reducers';
import { departments } from '../redux/departments/reducers';
import { checklists } from '../redux/checklists/reducers';
import { tags } from '../redux/tags/reducers';
import { settings } from '../redux/settings/reducers';
import { details } from '../redux/details/reducers';
import { boards } from '../redux/boards/reducers';
import { vendors } from '../redux/vendors/reducers';
import { software } from '../redux/software/reducers';
import { dashboard } from '../redux/dashboard/reducers';
import { notifications } from '../redux/notifications/reducers';

const rootReducer = combineReducers({
  global,
  users,
  assets,
  passwords,
  departments,
  checklists,
  tags,
  settings,
  details,
  boards,
  vendors,
  software,
  dashboard,
  notifications
});

export default rootReducer;
