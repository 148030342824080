import { vendorsTypes } from './types';

export const getVendorsStart = (params) => ({
    type: vendorsTypes.GET_VENDORS_START,
    params,
});

export const getVendorsSuccess = (data) => ({
    type: vendorsTypes.GET_VENDORS_SUCCESS,
    data,
});

export const getVendorsFailure = (error) => ({
    type: vendorsTypes.GET_VENDORS_FAILURE,
    error,
});

export const createVendorsStart = (body) => ({
    type: vendorsTypes.CREATE_VENDORS_START,
    body,
});

export const createVendorsSuccess = (data) => ({
    type: vendorsTypes.CREATE_VENDORS_SUCCESS,
    data,
});

export const updateVendorsStart = (body, id) => ({
    type: vendorsTypes.UPDATE_VENDORS_START,
    body,
    id,
});

export const updateVendorsSuccess = (data) => ({
    type: vendorsTypes.UPDATE_VENDORS_SUCCESS,
    data,
});

export const deleteVendorsStart = (id, params) => ({
    type: vendorsTypes.DELETE_VENDORS_START,
    id,
    params
});

export const deleteVendorsSuccess = (data) => ({
    type: vendorsTypes.DELETE_VENDORS_SUCCESS,
    data,
});

export const setVendorsFailure = (error) => ({
    type: vendorsTypes.SET_VENDORS_FAILURE,
    error,
});
