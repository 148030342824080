import React from 'react';
import SettingsBoard from '../board-settings';
import { BOARD_TYPE } from '../../helpers/variables';

export default function () {
  return (
    <div>
      <SettingsBoard board={BOARD_TYPE.offboard} />
    </div>
  );
}
