export const tagsTypes = {
    GET_TAGS_START: 'GET_TAGS_START',
    GET_TAGS_SUCCESS: 'GET_TAGS_SUCCESS',
    GET_TAGS_FAILURE: 'GET_TAGS_FAILURE',
    CREATE_TAG_START: 'CREATE_TAG_START',
    CREATE_TAG_SUCCESS: 'CREATE_TAG_SUCCESS',
    CREATE_TAG_FAILURE: 'CREATE_TAG_FAILURE',
    UPDATE_TAG_START: 'UPDATE_TAG_START',
    UPDATE_TAG_SUCCESS: 'UPDATE_TAG_SUCCESS',
    UPDATE_TAG_FAILURE: 'UPDATE_TAG_FAILURE',
    DELETE_TAG_START: 'DELETE_TAG_START',
    DELETE_TAG_SUCCESS: 'DELETE_TAG_SUCCESS',
    DELETE_TAG_FAILURE: 'DELETE_TAG_FAILURE',
    DELETE_TAGS_START: 'DELETE_TAGS_START',
    DELETE_TAGS_SUCCESS: 'DELETE_TAGS_SUCCESS',
    DELETE_TAGS_FAILURE: 'DELETE_TAGS_FAILURE',
    UPDATE_TAG_FIELD_START: 'UPDATE_TAG_FIELD_START',
};
