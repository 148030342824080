import styled from 'styled-components';
import { Button as AntdButton } from 'antd';

export const Wrapper = styled.div``;

export const SubTitle = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #111827;
  margin-top: 24px;
  margin-bottom: 14px;
`;

export const Container = styled.div``;

export const AccordionToggle = styled.div`
  button {
    max-width: 582px;
    width: 100%;
    min-height: 50px;
    border: 1px solid #98a5cd;
    border-radius: 8px;
    background: #fff;
  }

  label {
    background: #fff;
    border: unset;
    margin-bottom: 0;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #313131;

    &:hover {
      background-color: #fff;
    }

    &:active {
      background-color: #fff !important;
      border: unset !important;
    }
  }
`;

export const Content = styled.div`
  display: flex;
  margin-top: 58px;

  & > div {
    &:first-child {
      min-width: 376px;
      margin-right: 34px;
    }
    &:last-child {
      width: 100%;
    }
  }
`;

export const ItemWrap = styled.div`
  margin-bottom: 12px;
`;

export const ItemContent = styled.div`
  background: #fff;
  padding: 16px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.06);
  border: 0.5px solid #98a5cd;
  border-radius: 12px;
  margin-bottom: 24px;
`;

export const Title = styled.div`
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #111827;
  margin-bottom: 20px;
`;

export const Button = styled(AntdButton)`
  background: #008f5f !important;
  border-color: #008f5f !important;
  color: #fff !important;
  border-radius: 8px;
`;
