import React from 'react';
import { DownOutlined } from '@ant-design/icons';
import { Dropdown, Menu } from 'antd';
import { TICKET_URGENCY } from '../../helpers/variables';
import { getUserFullName } from '../../helpers/helpers';

function UpdateRowItem({ onClick, data, row, field, issueTypes }) {
  const handleClick = (e) => {
    onClick(row._id, { [field]: e.key })(e.domEvent);
  };

  const owner = (
    <Menu
      items={data?.map((item) => ({
        label: getUserFullName(item),
        key: item._id,
      }))}
      onClick={handleClick}
    />
  );
  const urgency = (
    <Menu
      items={TICKET_URGENCY.map((item) => ({
        label: item.name,
        key: item.value,
      }))}
      onClick={handleClick}
    />
  );
  const issue = (
    <Menu
      items={issueTypes?.map((item) => ({
        label: item,
        key: item,
      }))}
      onClick={handleClick}
    />
  );

  const menu = {
    owner,
    urgency,
    issue,
  };

  let name = row[field];
  if (field === 'owner') {
    name = getUserFullName(row.owner, 'Select user');
  } else if (field === 'urgency') {
    name = (TICKET_URGENCY.find((el) => el.value === row[field]) || {}).name;
  }

  return (
    <Dropdown overlay={menu[field]} trigger={['click']}>
      <div
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        {name}
        <DownOutlined style={{ marginLeft: 8 }} />
      </div>
    </Dropdown>
  );
}

export default UpdateRowItem;
