import qs from 'qs';
import { fetcher, uploadFetcher } from './fetcher';

export const fetchUsersHistoty = async ({ offset = null, limit = null }) =>
  fetcher(
    `/users-history?${qs.stringify({ offset, limit }, { skipNulls: true })}`,
  );
export const exportUserHistory = async (body) =>
  fetcher(`/users-history/export`, 'post', body);

export const fetchCurrentUserCompany = async () => fetcher('/company');

export const singleUpload = async (body) =>
  uploadFetcher('/upload/single', body);
export const imageUpload = async (body) => uploadFetcher('/upload/image', body);
export const fileUpload = async (url, body) => uploadFetcher(url, body);

export const login = async (body) => fetcher('/auth/login', 'POST', body);
export const oauth = async (body) => fetcher('/auth/oauth', 'POST', body);
export const register = async (body) => fetcher('/auth/register', 'POST', body);
export const sendPasswordToken = async (body) =>
  fetcher('/auth/send-password-token', 'POST', body);
export const resetPassword = async (body) =>
  fetcher('/auth/reset-password', 'POST', body);
export const verifiedEmail = async (body) =>
  fetcher('/auth/verified', 'POST', body);

export const fetchInvoicesLists = async (data) =>
  fetcher(`/billing/invoices?${qs.stringify(data, { skipNulls: true })}`);
export const subscribePlan = async (body) =>
  fetcher('/billing/subscribe-plan', 'POST', body);
export const updatePlan = async (body) =>
  fetcher('/billing/subscribe-plan', 'PUT', body);
export const deletePlan = async () =>
  fetcher('/billing/subscribe-plan', 'DELETE');
export const stripePay = async (body) => fetcher('/billing/pay', 'POST', body);
export const fetchPaymentMethod = async () =>
  fetcher('/billing/payment-method');
export const fetchPlans = async () => fetcher('/plans');

export const fetchDashboard = async () => fetcher(`/dashboard`);
export const fetchAllUsers = async () => fetcher(`/users/all`);
export const fetchUsers = async ({ offset, limit, search, status = null }) =>
  fetcher(
    `/users?${qs.stringify(
      { offset, limit, status, search: search || null },
      { skipNulls: true },
    )}`,
  );
export const createUsers = async (body) => fetcher('/users', 'POST', body);
export const updateUsers = async (id, body) =>
  fetcher(`/users/${id}`, 'PUT', body);
export const fetchUser = async (id) => fetcher(`/users/${id}`);
export const deleteUser = async (id) => fetcher(`/users/${id}`, 'DELETE');
export const fetchCurrentUser = async () => fetcher(`/users/profile`);
export const updateCurrentUser = async (body) =>
  fetcher(`/users/profile`, 'PUT', body);
export const fetchUserContacts = async (id) => fetcher(`/users/${id}/contacts`);
export const updateUserContacts = async (id, body) =>
  fetcher(`/users/${id}/contacts`, 'PUT', body);
export const usersBulkUpload = async (body) =>
  uploadFetcher('/users/bulk-upload', body);

export const fetchAllDepartmentsData = async (board) =>
  fetcher(`/departments/all-data?board=${board}`);
export const fetchDepartmentData = async (departmentId) =>
  fetcher(`/departments/department-data/${departmentId}`);
export const fetchDepartments = async (board, setting = null) =>
  fetcher(
    `/departments?${qs.stringify(
      { board: board || null, setting },
      { skipNulls: true },
    )}`,
  );
export const fetchDepartment = async (id) =>
  fetcher(`/departments/single/${id}`);
export const createDepartment = async (body) =>
  fetcher(`/departments`, 'POST', body);
export const deleteDepartment = async (id) =>
  fetcher(`/departments/${id}`, 'DELETE');
export const updateDepartment = async (body) =>
  fetcher(`/departments`, 'PUT', body);
export const cloneDepartment = async (body) =>
  fetcher(`/departments/clone`, 'POST', body);
export const allCompanyDepartment = async (id) =>
  fetcher(`/departments/all-company/${id}`);
export const updateDepartmentSettings = async (id, body) =>
  fetcher(`/departments/update-department-settings/${id}`, 'PUT', body);

export const fetchAllChecklists = async () => fetcher(`/checklists`);
export const fetchDepartmentChecklists = async (departmentId) =>
  fetcher(`/checklists/${departmentId}`);
export const createChecklist = async (body) =>
  fetcher(`/checklists`, 'POST', body);
export const deleteChecklist = async (id) =>
  fetcher(`/checklists/${id}`, 'DELETE');
export const updateChecklist = async (body) =>
  fetcher(`/checklists`, 'PUT', body);

export const fetchAllTags = async () => fetcher(`/tags`);
export const fetchDepartmentTags = async (departmentId) =>
  fetcher(`/tags/${departmentId}`);
export const createTag = async (body) => fetcher(`/tags`, 'POST', body);
export const updateTag = async (body) => fetcher(`/tags`, 'PUT', body);
export const deleteTagField = async (id, tagId) =>
  fetcher(`/tags/${id}/delete/${tagId}`, 'PUT');
export const deleteTags = async (id) => fetcher(`/tags/${id}`, 'DELETE');
export const updateTagField = async ({ id, tagId, body }) =>
  fetcher(`/tags/${id}/update/${tagId}`, 'PUT', body);

export const fetchDepartmentDetails = async (departmentId) =>
  fetcher(`/details/${departmentId}`);
export const createDetail = async (body) => fetcher(`/details`, 'POST', body);
export const updateDetail = async (body) => fetcher(`/details`, 'PUT', body);
export const deleteDetail = async (id) => fetcher(`/details/${id}`, 'DELETE');
export const deleteDetailField = async (id, fieldId) =>
  fetcher(`/details/${id}/delete/${fieldId}`, 'PUT');

export const getBoards = async (params) => {
  const {
    board = null,
    limit = null,
    offset = null,
    search,
    status,
    location,
  } = params;

  const query = qs.stringify(
    {
      board,
      limit,
      offset,
      search: search || null,
      status: status?.length ? status : null,
      location: location?.length ? location : null,
    },
    { skipNulls: true },
  );
  return fetcher(`/boards?${query}`);
};
export const fetchBoard = async (id) => fetcher(`/boards/${id}`);
export const createBoard = async (body) => fetcher('/boards', 'POST', body);
export const updateBoardChecklist = async (id, body) =>
  fetcher(`/boards/${id}/checklist`, 'PUT', body);
export const updateBoardTag = async (id, tagId, body) =>
  fetcher(`/boards/${id}/tag/${tagId}`, 'PUT', body);
export const addBoardChecklistComment = async (id, body) =>
  fetcher(`/boards/${id}/checklist-comment`, 'PUT', body);
export const createBoardNotes = async (url, body) =>
  fetcher(`/boards${url}`, 'POST', body);
export const updateBoard = async (body) => fetcher(`/boards`, 'PUT', body);
export const deleteBoard = async (id) => fetcher(`/boards/${id}`, 'DELETE');
export const fetchBoardByQueryParams = async (params) =>
  fetcher(`/boards/single?${qs.stringify(params)}`);
export const offBoardUser = async (id) =>
  fetcher(`/boards/offboard-user/${id}`);
export const fetchBoardInfo = async (id) => fetcher(`/boards-info/${id}`);
export const setBoardInfo = async (body) =>
  fetcher(`/boards-info`, 'POST', body);
export const sendOnboardEmail = async (id, body) =>
  fetcher(`/boards/${id}/send-onboard-email`, 'POST', body);

export const getPasswords = async ({
  limit,
  offset,
  folderId = null,
  searchText: search = null,
}) =>
  fetcher(
    `/passwords?${qs.stringify(
      { folderId, limit, offset, search },
      { skipNulls: true },
    )}`,
  );

export const getPassword = async (id) => fetcher(`/passwords/${id}`);
export const createPassword = async (body) =>
  fetcher(`/passwords`, 'POST', body);
export const deletePassword = async (id) =>
  fetcher(`/passwords/${id}`, 'DELETE');
export const createPasswordFolder = async (body) =>
  fetcher(`/passwords/folders`, 'POST', body);
export const getPasswordFolders = async () => fetcher(`/passwords/folders`);
export const getFolderPermissions = async (id) =>
  fetcher(`/passwords/permissions/${id}`);
export const deleteManyPassword = async (body) =>
  fetcher(`/passwords/delete`, 'POST', body);
export const updatePasswords = async (id, body) =>
  fetcher(`/passwords/${id}`, 'PUT', body);
export const shareFolder = async (body) =>
  fetcher(`/passwords/share-folder`, 'post', body);
export const updateSharedFolder = async (id, body) =>
  fetcher(`/passwords/share-folder/${id}`, 'PUT', body);
export const deleteSharedFolder = async (id) =>
  fetcher(`/passwords/share-folder/${id}`, 'DELETE');
export const fetchSharedFolderInfo = async (limit = null, offset = null, id) =>
  fetcher(
    `/passwords/shared-folder-info/${id}?${qs.stringify(
      { limit, offset },
      { skipNulls: true },
    )}`,
  );
export const passwordsBulkUpload = async (body) =>
  uploadFetcher('/passwords/bulk-upload', body);

export const fetchIntegrations = async () => fetcher(`/integrations/all`);
export const fetchIntegration = async (name) =>
  fetcher(`/integrations/${name}`);
export const createIntegration = async (body, name) =>
  fetcher(`/integrations/${name}`, 'POST', body);
export const deleteIntegrations = async (id) =>
  fetcher(`/integrations/${id}`, 'DELETE');
export const syncGreenhouse = async () =>
  fetcher(`/integrations/sync-greenhouse`);
export const integrationsLever = async (body) =>
  fetcher(`/integrations/lever`, 'POST', body);
export const fetchLeverAts = async () => fetcher(`/integrations/lever`);
export const syncLeverAts = async () => fetcher(`/integrations/sync-lever`);
export const slackOauthIntegration = async (code) =>
  fetcher(`/integrations/slack`, 'PUT', { code });
export const fetchSlackChannels = async () =>
  fetcher(`/integrations/slack/channels`);
export const fetchSlackAts = async () => fetcher(`/integrations/slack`);
export const fetchOktaGroups = async () => fetcher(`/integrations/okta/groups`);

export const integrationsOffice365 = async (body) =>
  fetcher(`/office365`, 'POST', body);
export const fetchOffice365Groups = async () => fetcher(`/office365/groups`);
export const fetchOffice365Drives = async () => fetcher(`/office365/drives`);

export const checkGSuiteSync = async () => fetcher(`/gsuite/check-gsuite-sync`);
export const fetchGSuiteFolders = async () => fetcher(`/gsuite/folders`);
export const fetchGSuiteGroups = async () => fetcher(`/gsuite/groups`);
export const fetchGSuiteSharedFolders = async () =>
  fetcher(`/gsuite/shared-folders`);
export const fetchGSuiteCalendars = async () => fetcher(`/gsuite/calendars`);
export const gSuiteSync = async (body) => fetcher('/gsuite', 'POST', body);
export const gSuiteCreateAccount = async (body) =>
  fetcher('/gsuite/create-account', 'POST', body);
export const offBoardGSuite = async (body) =>
  fetcher('/gsuite/off-board', 'POST', body);

export const fetchAssets = async (offset, limit, searchText) =>
  fetcher(
    `/assets?offset=${offset}&limit=${limit}${
      searchText ? '&search=' + searchText : ''
    }`,
  );
export const createAssets = async (body) => fetcher('/assets', 'POST', body);
export const updateAssets = async (body, id) =>
  fetcher(`/assets/${id}`, 'PUT', body);
export const updateManyAssets = async (body) =>
  fetcher(`/assets/update-many`, 'PUT', body);
export const deleteManyAssets = async (body) =>
  fetcher(`/assets/delete-many`, 'POST', body);
export const deleteAssets = async (id) => fetcher(`/assets/${id}`, 'DELETE');
export const assetsBulkUpload = async (body) =>
  uploadFetcher('/assets/bulk-upload', body);

export const fetchVendors = async (offset, limit, searchText) =>
  fetcher(
    `/vendors?offset=${offset}&limit=${limit}${
      searchText ? '&search=' + searchText : ''
    }`,
  );
export const createVendors = async (body) => fetcher('/vendors', 'POST', body);
export const updateVendors = async (body, id) =>
  fetcher(`/vendors/${id}`, 'PUT', body);
export const deleteVendors = async (id) => fetcher(`/vendors/${id}`, 'DELETE');
export const vendorsBulkUpload = async (body) =>
  uploadFetcher('/vendors/bulk-upload', body);

export const fetchSoftware = async (offset, limit, searchText) =>
  fetcher(
    `/software?offset=${offset}&limit=${limit}${
      searchText ? '&search=' + searchText : ''
    }`,
  );
export const createSoftware = async (body) =>
  fetcher('/software', 'POST', body);
export const updateSoftware = async (body, id) =>
  fetcher(`/software/${id}`, 'PUT', body);
export const deleteSoftware = async (id) =>
  fetcher(`/software/${id}`, 'DELETE');
export const softwareBulkUpload = async (body) =>
  uploadFetcher('/software/bulk-upload', body);

export const fetchTickets = async (
  offset,
  limit,
  { searchText: search = null, ...filters } = {},
) =>
  fetcher(
    `/tickets?${qs.stringify(
      { limit, offset, search, ...filters },
      { skipNulls: true },
    )}`,
  );
export const createTickets = async (body) => fetcher('/tickets', 'POST', body);
export const updateTickets = async (body, id) =>
  fetcher(`/tickets/${id}`, 'PUT', body);
export const updateManyTickets = async (body) =>
  fetcher(`/tickets/update-many`, 'PUT', body);
export const deleteTickets = async (id) => fetcher(`/tickets/${id}`, 'DELETE');
export const deleteManyTickets = async (body) =>
  fetcher(`/tickets/delete`, 'POST', body);
export const replyTicketMessage = async (id, body) =>
  fetcher(`/tickets/message/${id}`, 'POST', body);
export const fetchTicketSettings = async () => fetcher('/tickets/settings');
export const createTicketSettings = async (body) =>
  fetcher('/tickets/settings', 'POST', body);
export const ticketForward = async (body) =>
  fetcher('/tickets/forward', 'POST', body);

export const fetchNotifications = async (offset = null, limit = null) =>
  fetcher(
    `/notifications?${qs.stringify({ limit, offset }, { skipNulls: true })}`,
  );
export const updateNotifications = async (id, body) =>
  fetcher(`/notifications/${id}`, 'PUT', body);

export const fetchEmailTemplates = async (type = null) =>
  fetcher(`/email-templates/?${qs.stringify({ type }, { skipNulls: true })}`);
export const fetchEmailTemplate = async (id) =>
  fetcher(`/email-templates/${id}`);
export const createEmailTemplate = async (body) =>
  fetcher(`/email-templates/`, 'POST', body);
export const deleteEmailTemplate = async (id) =>
  fetcher(`/email-templates/${id}`, 'DELETE');

export const createMergeDevLinkToken = async (integration = null) =>
  fetcher(
    `/merge-dev/create-link-token?${qs.stringify(
      { integration },
      { skipNulls: true },
    )}`,
  );
export const fetchMergeDevAtsIntegrations = async () =>
  fetcher(`/merge-dev/ats-integrations`);
export const createMergeDevAccountToken = async (body) =>
  fetcher(`/merge-dev/ats-integrations`, 'POST', body);
export const deleteMergeDevAtsIntegration = async (slug) =>
  fetcher(`/merge-dev/ats-integrations/${slug}`, 'DELETE');
export const fetchMergeDevSyncedAts = async () =>
  fetcher(`/merge-dev/synced-ats`);
export const syncMergeDev = async () => fetcher(`/merge-dev/sync`);

export const fetchUserProperties = async () => fetcher(`/user-properties`);

export const setUserProperties = async (body) =>
  fetcher(`/user-properties`, 'PUT', body);
