import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Editor } from '@tinymce/tinymce-react';
import { Dropdown, Menu, Modal } from 'antd';
import { FileUploadBtn } from '../../shared';
import { CaretDownOutlined, CloseIcon } from '../../../Icons';
import { updateBoardSuccess } from '../../../redux/boards/actions';
import { toBase64 } from '../../../helpers/helpers';
import {
  fetchEmailTemplates,
  createEmailTemplate,
  sendOnboardEmail,
  deleteEmailTemplate,
} from '../../../service';
import {
  FlexBetween,
  BlockWrap,
  EmailInput,
  EmailInputWrap,
  EmailWrapper,
  Button,
  EditorFooter,
  DropdownButton,
} from './styles';

function OnboardEmail({ board }) {
  const [modal, contextHolder] = Modal.useModal();
  const dispatch = useDispatch();
  const { email, personalEmail } = board?.name || {};
  const [templates, setTemplates] = useState([]);
  const [body, setBody] = useState({ to: email || personalEmail || '' });
  const [loading, setLoading] = useState(false);
  const [clearFile, setClearFile] = useState(false);

  useEffect(() => {
    fetchEmailTemplates('onboard').then(setTemplates).catch();
  }, []);

  const handleConfirm = (e, id) => {
    e.preventDefault();
    e.stopPropagation();

    modal.confirm({
      title: 'Are you sure you want to delete?',
      onOk: () =>
        deleteEmailTemplate(id)
          .then((res) =>
            setTemplates((prev) => prev.filter((item) => item._id !== res._id)),
          )
          .catch(() => {}),
    });
  };

  const handleChangeTemplate = ({ key }) => {
    if (key === 'save') {
      createEmailTemplate({
        type: 'onboard',
        subject: body.subject,
        content: body.content,
      })
        .then((res) => setTemplates((prev) => [res, ...prev]))
        .catch();
    } else {
      const template = templates.find((item) => item._id === key);
      setBody((prev) => ({
        ...prev,
        content: template.content,
        subject: template.subject,
      }));
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setBody((prev) => ({ ...prev, [name]: value }));
  };

  const handleSend = () => {
    setLoading(true);
    sendOnboardEmail(board._id, body)
      .then((res) => {
        setLoading(false);
        setBody({ to: email || personalEmail || '', content: '' });
        setClearFile(true);
        dispatch(updateBoardSuccess(res));
      })
      .catch(() => setLoading(false));
  };

  const handleUpload = (file) => {
    if (!file) {
      setBody((prev) => ({ ...prev, attachments: undefined }));
      return;
    }
    toBase64(file)
      .then((res) => {
        const content = res.split(',')[1];
        setClearFile(false);
        setBody((prev) => ({
          ...prev,
          attachments: [
            {
              content,
              filename: file.name,
              type: file.type,
              disposition: 'attachment',
            },
          ],
        }));
      })
      .catch();
  };

  const menu = (
    <Menu
      onClick={handleChangeTemplate}
      items={[
        { key: 'save', label: 'Save' },
        ...templates.map((item) => ({
          key: item._id,
          label: (
            <FlexBetween>
              <div>{item.subject}</div>
              <div style={{ display: 'flex' }}>
                <CloseIcon
                  style={{ fontSize: 12 }}
                  onClick={(e) => handleConfirm(e, item._id)}
                />
              </div>
            </FlexBetween>
          ),
        })),
      ]}
    />
  );

  return (
    <BlockWrap>
      <div>Welcome Emails</div>
      <EmailWrapper>
        <EmailInputWrap>
          <div>TO:</div>
          <EmailInput
            name="to"
            type="email"
            value={body.to}
            onChange={handleChange}
          />
        </EmailInputWrap>
        <EmailInputWrap>
          <div>CC:</div>
          <EmailInput
            name="cc"
            placeholder="Write the email addresses comma separated"
            value={body.cc}
            onChange={handleChange}
          />
        </EmailInputWrap>
        <EmailInputWrap>
          <div>Subject</div>
          <EmailInput
            name="subject"
            value={body.subject}
            onChange={handleChange}
          />
        </EmailInputWrap>
        <Editor
          apiKey={process.env.REACT_APP_TINYMCE_API_KEY}
          onEditorChange={(value) =>
            handleChange({ target: { name: 'content', value } })
          }
          value={body.content}
          init={{
            toolbar_location: 'bottom',
            min_height: 200,
            menubar: false,
            branding: false,
            statusbar: false,
            forced_root_block: '',
            plugins: 'anchor tinydrive advlist lists autoresize', //checklist casechange formatpainter
            toolbar: [
              'fontselect | casechange | bold italic underline forecolor | indent numlist bullist outdent |',
            ],
          }}
        />
        <EditorFooter>
          <Dropdown overlay={menu} trigger={['click']}>
            <DropdownButton>
              Email templates <CaretDownOutlined />
            </DropdownButton>
          </Dropdown>
          <FileUploadBtn onFileSelect={handleUpload} clearFile={clearFile} />
          <Button loading={loading} onClick={handleSend}>
            Send
          </Button>
        </EditorFooter>
      </EmailWrapper>
      {contextHolder}
    </BlockWrap>
  );
}
export default OnboardEmail;
