import styled from 'styled-components';
import {
  Input as AntdInput,
  Avatar as AntdAvatar,
  Dropdown as AntdDropdown,
  Menu as AntdMenu,
  Button as AntdButton,
} from 'antd';

export const Wrapper = styled.div`
  height: 80px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #fff;
  padding: 10px 24px;
`;

export const Input = styled(AntdInput)`
  width: 297px;
  height: 42px;
  background: #ffffff;
  border: 1px solid #757b8c;
  border-radius: 10px;
`;

export const LeftWrapp = styled.div`
  display: flex;
  align-items: center;
`;

export const UserName = styled.div`
  margin: 0 20px;
  font-weight: 700;
  font-size: 16px;
  color: #313131;

  div {
    line-height: 1;

    &:last-child {
      font-weight: normal;
    }
  }
`;

export const Avatar = styled(AntdAvatar).attrs({ size: 36 })`
  color: #9b51e0;
  background: #fbf6fd;
`;

export const DropdownWrap = styled.div`
  width: 80px;
  height: 60px;
  background: #f5f5f5;
  border-radius: 13px;
  display: flex;
  align-items: center;
`;

export const DropdownContent = styled.div`
  width: 80px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 8px;
  cursor: pointer;
`;

export const Dropdown = styled(AntdDropdown).attrs({ arrow: true })``;

export const Menu = styled(AntdMenu)``;

export const PlanWrap = styled.div`
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: 0.02em;
  color: #313131;
`;

export const Button = styled(AntdButton)`
  background: #532fd8 !important;
  border-color: #532fd8 !important;
  color: #fff !important;
  padding: 4px 22px;
  margin-left: 16px;
  font-weight: 600;
  font-size: 15px;
  border-radius: 6px;
`;
