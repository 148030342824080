import React from 'react';
import Sidebar from '../sidebar';
import PrivateHeader from '../header/PrivateHeader';
import { ContentWrapper, Wrapper, Breadcrumb, Content } from './styles';

const PrivateLayout = ({ children }) => {
  const { title } = children.props;

  return (
    <Wrapper>
      <Sidebar />
      <div style={{ width: '100%' }}>
        <PrivateHeader />
        <Content>
          <ContentWrapper>
            <div className="d-inline-flex justify-content-between">
              <Breadcrumb>{title}</Breadcrumb>
            </div>
            {children}
          </ContentWrapper>
        </Content>
      </div>
    </Wrapper>
  );
};

export default PrivateLayout;
