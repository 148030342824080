import React, { useEffect, useState } from 'react';
import { fetchIntegrations } from '../../service';
import GSuite from './gSuite';
import Okta from './Okta';
import SimpleIntegration from './simpleIntegration';
import { Slack } from './Slack';
import MergeDev from './MergeDev';
import { Wrapper, Container, Title, AtsWrapper } from './styles';

const atsData = [
  {
    name: 'lever',
    props: { label: 'Lever' },
    Component: SimpleIntegration,
  },
  {
    name: 'greenhouse',
    props: { label: 'Greenhouse' },
    Component: SimpleIntegration,
  },
];

const appsData = [
  {
    name: 'gSuite',
    Component: GSuite,
  },
  {
    name: 'office365',
    props: {
      label: 'Office 365',
    },
    Component: SimpleIntegration,
  },
  {
    name: 'okta',
    Component: Okta,
  },
  {
    name: 'slack',
    Component: Slack,
  },
];

const Integrations = () => {
  const [data, setData] = useState({});

  useEffect(() => {
    fetchIntegrations()
      .then((res) => {
        const data = res.reduce((acc, cur) => {
          acc[cur.name] = cur;
          return acc;
        }, {});
        setData(data);
      })
      .catch(() => setData({}));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Wrapper>
      <Container>
        <Title>Productivity Apps</Title>
        <AtsWrapper>
          {appsData.map((item) => {
            const { Component, name, props } = item;
            return (
              <Component key={name} name={name} data={data[name]} {...props} />
            );
          })}
        </AtsWrapper>
      </Container>
      <Container>
        <Title>Applicant Tracking Systems</Title>
        <AtsWrapper>
          {atsData.map((item) => {
            const { Component, name, props } = item;
            return (
              <Component key={name} name={name} data={data[name]} {...props} />
            );
          })}
          <MergeDev />
        </AtsWrapper>
      </Container>
    </Wrapper>
  );
};

export default Integrations;
