import { detailsTypes } from './types';

const initialState = {
    details: {},
    error: '',
    loading: false,
    isLoading: false,
    deleted: {},
};

export const details = (state = initialState, action) => {
    switch (action.type) {
        case detailsTypes.GET_DETAILS_START:
            return {
                ...state,
                loading: true,
            };
        case detailsTypes.GET_DETAILS_SUCCESS:
            return {
                ...state,
                details: Array.isArray(action.data) ? action.data[0] || {} : action.data,
                loading: false,
            };
        case detailsTypes.GET_DETAILS_FAILURE:
            return {
                ...state,
                details: [],
                loading: false,
                error: action.error,
            };
        case detailsTypes.CREATE_DETAIL_START:
            return {
                ...state,
                loading: true,
            };
        case detailsTypes.CREATE_DETAIL_SUCCESS:
            return {
                ...state,
                details: action.data,
                loading: false,
                // Todo
                //details: [...state.details, action.data],
            };
        case detailsTypes.CREATE_DETAIL_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case detailsTypes.UPDATE_DETAIL_START:
            return { ...state, loading: true };
        case detailsTypes.UPDATE_DETAIL_SUCCESS:
            return {
                ...state,
                loading: false,
                details: action.data,
            };
        case detailsTypes.UPDATE_DETAIL_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case detailsTypes.DELETE_DETAIL_START:
            return { ...state, isLoading: true, deleted: {} };
        case detailsTypes.DELETE_DETAIL_SUCCESS:
            return {
                ...state,
                isLoading: false,
                deleted: action.data,
            };
        case detailsTypes.DELETE_DETAIL_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        default:
            return state;
    }
};
