import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Modal, Form, Col, Row, Button as AntdButton } from 'antd';
import styled from 'styled-components';
import { BulkUploadBtn, UserSelect } from '../shared';
import {
  createAssetsStart,
  updateAssetsStart,
} from '../../redux/assets/actions';
import { usePrevious } from '../../helpers/hooks';
import { assetsBulkUpload } from '../../service';
import { FlexBetween } from '../shared/styles';
import { SetWrapper, Input } from './styles';

const Button = styled(AntdButton).attrs({ type: 'primary' })`
  width: 138px;
  height: 38px;
  background: #0071ff;
  border-radius: 8px;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: 0.02em;
  color: #ffffff;
`;

function SetAssets({
  data,
  reset,
  action,
  loading,
  reload,
  onSubmit,
  onCancel,
}) {
  const dispatch = useDispatch();
  const prevLoading = usePrevious(loading);
  const [form] = Form.useForm();
  const [modalShow, setModalShow] = useState(false);

  useEffect(() => {
    if (data?._id) {
      const { tag, model, serial, category, owner } = data;
      form.setFieldsValue({
        tag,
        model,
        serial,
        category,
        owner: owner?._id || owner,
      });
      setModalShow(true);
    }
  }, [data?._id]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (prevLoading && !loading) {
      handleClose();
    }
  }, [loading]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleClose = () => {
    setModalShow(false);
    reset && reset();
    form.resetFields();
    onCancel && onCancel();
  };

  const handleSubmit = (body) => {
    if (action === 'clone' || (!action && !data._id)) {
      dispatch(createAssetsStart(body));
    }
    if (action === 'update' && data._id) {
      dispatch(updateAssetsStart(body, data._id));
    }
  };

  const handleBulkUpload = (file) => {
    if (file) {
      const formData = new FormData();
      formData.append('file', file);
      assetsBulkUpload(formData)
        .then(() => reload())
        .catch();
    }
  };

  return (
    <SetWrapper>
      {!onCancel && (
        <FlexBetween>
          {!onSubmit && (
            <div style={{ width: '25%' }}>
              <BulkUploadBtn onSubmit={handleBulkUpload} accept=".csv" />
            </div>
          )}
          <Button onClick={() => setModalShow(true)}>Create New</Button>
        </FlexBetween>
      )}

      <Modal
        open={modalShow}
        title="Assets"
        footer={null}
        width={780}
        onCancel={handleClose}
      >
        <Form
          form={form}
          colon={false}
          layout="vertical"
          onFinish={onSubmit || handleSubmit}
        >
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item
                name="model"
                label="Model"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input placeholder="Model" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="category"
                label="Category"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input placeholder="Category" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="tag"
                label="Tag"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input placeholder="Tag" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="serial"
                label="Serial"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input placeholder="Serial" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="owner" label="Checked Out To">
                <UserSelect allowClear />
              </Form.Item>
            </Col>
            <Col span={24} style={{ textAlign: 'right' }}>
              <Button htmlType={'submit'} loading={loading}>
                Submit
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>
    </SetWrapper>
  );
}

export default SetAssets;
