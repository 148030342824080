import React, { useState, useEffect } from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';
import { debounced300 } from '../../../helpers/helpers';

const AsyncSelect = ({
  label = 'name',
  key = '_id',
  onSearch,
  value,
  onChange,
  ...props
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [selected, setSelected] = useState([]);

  useEffect(() => {
    handleSearch().finally(() => setIsLoading(false));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (options.length && !selected.length && value) {
      setSelected(options.find((item) => item[value] === value));
    }
  }, [options]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSearch = async (search) => {
    setIsLoading(true);
    const response = await onSearch({ search, offset: 0, limit: 50 });

    const options = (response?.data || response).map((item) => ({
      value: item[key],
      label: Array.isArray(label)
        ? label.reduce((acc, curr) => acc + ' ' + item[curr], '')
        : item[label],
    }));

    setOptions(options);
    setIsLoading(false);
  };

  const handleChange = (search) =>
    debounced300(() => handleSearch(search).finally(() => setIsLoading(false)));

  const handleSelect = (value) => {
    onChange(value[0]?.value);
    setSelected(value);
  };

  return (
    <Typeahead
      id="async-example"
      isLoading={isLoading}
      onChange={handleSelect}
      onInputChange={handleChange}
      options={options}
      selected={selected}
      {...props}
      renderMenuItemChildren={(option) => option.label}
    />
  );
};

export default AsyncSelect;
