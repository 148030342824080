import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  SearchOutlined,
  // MoonIcon,
  CaretDownOutlined,
} from '../../../Icons';
// import { ROUTES } from '../../../helpers/variables';
import { getShortUserName } from '../../../helpers/helpers';
import {
  Wrapper,
  DropdownWrap,
  DropdownContent,
  Input,
  LeftWrapp,
  UserName,
  Avatar,
  Dropdown,
  Menu,
  PlanWrap,
  Button,
} from './styles';
import Notifications from '../../notifications';
import { ROUTES } from '../../../helpers/variables';

function PrivateHeader() {
  const {
    profile: { firstName = '', lastName = '', email = '', image },
    company,
  } = useSelector((state) => state.global);

  const menu = (
    <Menu
      items={[
        {
          label: <Link to={ROUTES.profile.path}>My Profile</Link>,
          key: '0',
        },
        {
          label: <Link to={'/login'}>Sign Out</Link>,
          key: '1',
        },
      ]}
    />
  );

  return (
    <Wrapper>
      <Input placeholder={'Search'} prefix={<SearchOutlined />} />
      {!company.exclusionRule && company.isFreeTrial && (
        <PlanWrap>
          You are on a 14 day Business plan trial.
          <Link to={ROUTES.billingPlans.path}>
            <Button>Start Subscription</Button>
          </Link>
        </PlanWrap>
      )}
      <LeftWrapp>
        <Notifications />
        {/*<MoonIcon style={{ fontSize: 20 }} />*/}
        <UserName>
          <div>{firstName + ' ' + lastName}</div>
          <div>{email}</div>
        </UserName>
        <DropdownWrap>
          <Dropdown overlay={menu} trigger={['click']}>
            <DropdownContent>
              {image ? (
                <Avatar src={image} />
              ) : (
                <Avatar>{getShortUserName(firstName, lastName)}</Avatar>
              )}
              <CaretDownOutlined />
            </DropdownContent>
          </Dropdown>
        </DropdownWrap>
      </LeftWrapp>
    </Wrapper>
  );
}

export default PrivateHeader;
