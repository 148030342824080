import React from 'react';
import withIcon from '../withIcon';

const LinkedinIcon = () => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.92857 2.28389C4.92857 3.52898 4.06672 4.5366 2.71894 4.5366C1.42367 4.5366 0.56182 3.52898 0.588493 2.28389C0.56182 0.978283 1.42365 0 2.74476 0C4.0667 0 4.90273 0.978283 4.92857 2.28389ZM0.696853 20.8191V6.31621H4.79437V20.8181H0.696853V20.8191Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.07906 10.9446C8.07906 9.13566 8.02488 7.5935 7.9707 6.31816H11.5298L11.719 8.30499H11.7998C12.3391 7.38538 13.6869 5.99268 15.8707 5.99268C18.5663 5.99268 20.5883 7.95016 20.5883 12.219V20.821H16.4908V12.7838C16.4908 10.9144 15.8982 9.63993 14.4154 9.63993C13.2826 9.63993 12.6092 10.4999 12.34 11.3297C12.2316 11.6268 12.1783 12.0412 12.1783 12.4574V20.821H8.08072V10.9446H8.07906Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default withIcon(LinkedinIcon);
