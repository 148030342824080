import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';
import { PrivateRoute } from './privateRoute';
import { PublicRoute } from './publicRoute';
import { ROLE_VALUES, ROUTES } from '../helpers/variables';
import CookiePopUp from '../components/cookieConsent';

import AdminPanel from '../components/adminPanel';
import Dashboard from '../components/dashboard';
import Home from '../components/home/index';
import OnBoard from '../components/onboard';
import OffBoard from '../components/offboard';
import Users from '../components/users';
import Assets from '../components/assets';
import Vendors from '../components/vendors';
import Software from '../components/software';
import Login from '../components/login';
import Register from '../components/signup';
import NotFound from '../components/404';
import Passwords from '../components/passwords';
import Tickets from '../components/tickets';
import TicketsSetting from '../components/TicketsSetting';
import VerifiedEmail from '../components/verified-email';
import ForgotPassword from '../components/set-password/forgot';
import ResetPassword from '../components/set-password/reset';
import OnBoardSettings from '../components/onboard-settings';
import OffBoardSettings from '../components/offboard-settings';
import Pricing from '../components/pricing';
import ScrollToTop from '../components/shared/scrollToTop';
import Integrations from '../components/integrations-settings';
import MyProfile from '../components/MyProfile';
import Billing from '../components/billing';
import BillingPlans from '../components/BillingPlans';
import LeverIntegrations from '../components/integrations-settings/leverIntegrations';
import SlackIntegrations from '../components/integrations-settings/Slack';
import Privacy from '../components/Privacy';
import Office365Integrations from '../components/integrations-settings/Office365Integrations';

export const PRIVATE_ROUTES = [
  {
    path: ROUTES.adminPanel.path,
    component: AdminPanel,
    title: ROUTES.adminPanel.title,
    access: [ROLE_VALUES.admin],
  },
  {
    path: ROUTES.dashboard.path,
    component: Dashboard,
    title: ROUTES.dashboard.title,
    access: [ROLE_VALUES.admin, ROLE_VALUES.manager, ROLE_VALUES.hr],
  },
  {
    path: ROUTES.onboard.path,
    component: OnBoard,
    title: ROUTES.onboard.title,
    access: [ROLE_VALUES.admin, ROLE_VALUES.manager, ROLE_VALUES.hr],
  },
  {
    path: ROUTES.offboard.path,
    component: OffBoard,
    title: ROUTES.offboard.title,
    access: [ROLE_VALUES.admin, ROLE_VALUES.manager, ROLE_VALUES.hr],
  },
  {
    path: ROUTES.assets.path,
    component: Assets,
    title: ROUTES.assets.title,
    access: [ROLE_VALUES.admin, ROLE_VALUES.helpdesk],
  },
  {
    path: ROUTES.software.path,
    component: Software,
    title: ROUTES.software.title,
    access: [ROLE_VALUES.admin, ROLE_VALUES.helpdesk],
  },
  {
    path: ROUTES.vendors.path,
    component: Vendors,
    title: ROUTES.vendors.title,
    access: [ROLE_VALUES.admin, ROLE_VALUES.helpdesk],
  },
  {
    path: ROUTES.onboardSettings.path,
    component: OnBoardSettings,
    title: ROUTES.onboardSettings.title,
    access: [ROLE_VALUES.admin],
  },
  {
    path: ROUTES.offboardSettings.path,
    component: OffBoardSettings,
    title: ROUTES.offboardSettings.title,
    access: [ROLE_VALUES.admin],
  },
  {
    path: ROUTES.users.path,
    component: Users,
    title: ROUTES.users.title,
    access: [ROLE_VALUES.admin],
  },
  {
    path: ROUTES.integrations.path,
    component: Integrations,
    title: ROUTES.integrations.title,
    access: [ROLE_VALUES.admin],
  },
  {
    path: ROUTES.billing.path,
    component: Billing,
    title: ROUTES.billing.title,
  },
  {
    path: ROUTES.billingPlans.path,
    component: BillingPlans,
    title: ROUTES.billingPlans.title,
  },
  {
    path: ROUTES.profile.path,
    component: MyProfile,
    title: ROUTES.profile.title,
  },
  {
    path: ROUTES.passwords.path,
    component: Passwords,
    title: ROUTES.passwords.title,
  },
  {
    path: ROUTES.tickets.path,
    component: Tickets,
    title: ROUTES.tickets.title,
  },
  {
    path: ROUTES.ticketsSetting.path,
    component: TicketsSetting,
    title: ROUTES.ticketsSetting.title,
  },
  {
    path: ROUTES.leverIntegrations.path,
    component: LeverIntegrations,
    title: ROUTES.leverIntegrations.title,
    access: [ROLE_VALUES.admin],
  },
  {
    path: ROUTES.slackIntegrations.path,
    component: SlackIntegrations,
    title: ROUTES.slackIntegrations.title,
    access: [ROLE_VALUES.admin],
  },
  {
    path: ROUTES.office365Integrations.path,
    component: Office365Integrations,
    title: ROUTES.office365Integrations.title,
    access: [ROLE_VALUES.admin],
  },
];

const PUBLIC_ROUTES = [
  {
    path: ROUTES.home.path,
    component: Home,
    title: ROUTES.home.title,
  },
  {
    path: ROUTES.pricing.path,
    component: Pricing,
    title: ROUTES.pricing.title,
  },
  {
    path: ROUTES.privacy.path,
    component: Privacy,
    title: ROUTES.privacy.title,
  },
];

const Routes = () => {
  return (
    <Router>
      <ScrollToTop />
      <CookiePopUp />
      <Switch>
        {PUBLIC_ROUTES.map((item, index) => (
          <PublicRoute
            key={index}
            exact={!index}
            path={item.path}
            component={item.component}
            title={item.title}
          />
        ))}
        {PRIVATE_ROUTES.map((item, idx) => (
          <PrivateRoute
            key={idx}
            path={item.path}
            component={item.component}
            title={item.title}
          />
        ))}

        <Route path={ROUTES.login.path} component={Login} />
        <Route path={ROUTES.register.path} component={Register} />
        <Route path={ROUTES.verifiedEmail.path} component={VerifiedEmail} />
        <Route path={ROUTES.forgotPassword.path} component={ForgotPassword} />
        <Route path={ROUTES.resetPassword.path} component={ResetPassword} />
        <Route
          path={ROUTES.notFound.path}
          render={(props) => <NotFound {...props} />}
        />
        <Redirect from="*" to={ROUTES.notFound.path} />
      </Switch>
    </Router>
  );
};

export default Routes;
