import styled from 'styled-components';
import { Avatar as AntdAvatar } from 'antd';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const Avatar = styled(AntdAvatar).attrs({size: 38})`
  background: linear-gradient(180deg, #d0f0fe 0%, #bee9fd 100%);
  margin-right: 12px;
  color: #000;
`;

export const UserInfo = styled.div`
  div {
    &:first-child {
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      color: #313131;
    }
    &:last-child {
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
      color: #313131;
      mix-blend-mode: normal;
      opacity: 0.5;
    }
  }
`;
