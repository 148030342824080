export const assetsTypes = {
    GET_ASSETS_START: 'GET_ASSETS_START',
    GET_ASSETS_SUCCESS: 'GET_ASSETS_SUCCESS',
    GET_ASSETS_FAILURE: 'GET_ASSETS_FAILURE',
    CREATE_ASSETS_START: 'CREATE_ASSETS_START',
    CREATE_ASSETS_SUCCESS: 'CREATE_ASSETS_SUCCESS',
    UPDATE_ASSETS_START: 'UPDATE_ASSETS_START',
    UPDATE_ASSETS_SUCCESS: 'UPDATE_ASSETS_SUCCESS',
    DELETE_ASSETS_START: 'DELETE_ASSETS_START',
    DELETE_ASSETS_SUCCESS: 'DELETE_ASSETS_SUCCESS',
    SET_ASSETS_FAILURE: 'SET_ASSETS_FAILURE',
};
