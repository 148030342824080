import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Form, Button } from 'react-bootstrap';
import {
  createEmailHyperlink,
  createTextHyperLink,
  getDateAmPm,
  reduceUserName,
} from '../../../helpers/helpers';
import { singleUpload } from '../../../service';
import { createBoardNotesStart } from '../../../redux/boards/actions';
import { UsersAutoComplete } from '../../shared';
import styles from '../table.module.scss';

export default function ({ board = {}, type = 'notes', title = 'Notes' }) {
  const notes = board[type] || [];
  const dispatch = useDispatch();
  const [validated, setValidated] = useState(false);
  const [file, setFile] = useState(null);
  const [value, setValue] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    let message = value.trim();

    if (!board._id) return;

    if (!message) {
      setValue('');
      setValidated(true);
      return;
    }

    if (file) {
      const formData = new FormData();
      formData.append('file', file);
      try {
        const data = await singleUpload(formData);
        message += ` ${data.url}`;
      } catch (err) {
        setFile(null);
      }
    }

    message = createEmailHyperlink(createTextHyperLink(message));

    dispatch(
      createBoardNotesStart(
        `/${board._id}/${type === 'hrNotes' ? 'hr-notes' : 'notes'}`,
        {
          message,
        },
      ),
    );

    setValue('');
    setFile(null);
    setValidated(false);
  };

  return (
    <div className={styles.notes}>
      <div>{title}</div>
      <div>
        {notes.map((item, idx) => (
          <div key={idx} className={`${styles.itemNotes}`}>
            <div dangerouslySetInnerHTML={{ __html: item.message }}></div>
            <div className="text-right">
              {reduceUserName(item.author)} @{getDateAmPm(item.createdAt)}
            </div>
          </div>
        ))}
        <div className={`${styles.itemNotes}`}>
          <Form inline onSubmit={handleSubmit}>
            <UsersAutoComplete
              name="note"
              value={value}
              isInvalid={validated}
              placeholder="Add note"
              onChange={setValue}
              onUpload={setFile}
              clearFileInput={!file}
            />
            <Button type="submit">Save</Button>
          </Form>
        </div>
      </div>
    </div>
  );
}
