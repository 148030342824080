import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Modal } from 'antd';
import { SettingIcon } from '../../Icons';
import {
  fetchInvoicesLists,
  fetchPaymentMethod,
  deletePlan,
} from '../../service';
import { getDateByFormat } from '../../helpers/helpers';
import { ROUTES } from '../../helpers/variables';
import {
  PlanWrap,
  Wrapper,
  PlanInfo,
  PlanLabel,
  PlanText,
  Button,
  InvoicesWrap,
  Column,
  InvoceText,
  PlanName,
  IconWrap,
  ShowMore,
} from './styls';
import Loader from '../shared/loader';

function Billing() {
  const history = useHistory();
  const [modal, contextHolder] = Modal.useModal();
  const { company } = useSelector((state) => state.global);
  const [data, setData] = useState([]);
  const [paymentMethod, setPaymentMethod] = useState({});
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    fetchInvoicesLists()
      .then((res) => {
        setData(res.data);
        setShow(res.has_more);
        setLoading(false);
      })
      .catch(() => setLoading(false));
    fetchPaymentMethod()
      .then(setPaymentMethod)
      .catch(() => {});
  }, []);

  const handleClickShow = () => {
    if (loading) {
      return;
    }
    setLoading(true);
    fetchInvoicesLists({ starting_after: data[data.length - 1].id })
      .then((res) => {
        setData((prev) => prev.concat(res.data));
        setShow(res.has_more);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  };

  const handleConfirm = () => {
    modal.confirm({
      okText: 'Yes',
      title: 'Are you sure you want to cancel your plan?',
      onOk: () =>
        deletePlan()
          .then(() => history.push(ROUTES.dashboard.path))
          .catch(() => {}),
    });
  };

  return (
    <Wrapper>
      <PlanWrap>
        <IconWrap>
          <SettingIcon />
        </IconWrap>
        <PlanName>{company.plan?.name}</PlanName>
        <PlanInfo>
          <div>
            <PlanLabel>Payment Method</PlanLabel>
            <PlanText>
              <span style={{ textTransform: 'capitalize' }}>
                {paymentMethod.card?.brand}
              </span>{' '}
              ending in {paymentMethod.card?.last4}
            </PlanText>
          </div>
          <div>
            <PlanLabel>Billing Interval</PlanLabel>
            <PlanText>
              {company.interval === 'year' ? 'Annual' : 'Monthly'}
            </PlanText>
          </div>
          <div>
            <PlanLabel>Billing Address</PlanLabel>
            <PlanText></PlanText>
          </div>
          <div>
            <PlanLabel>Billing Email</PlanLabel>
            <PlanText></PlanText>
          </div>
          <div>
            <Link
              to={ROUTES.billingPlans.path}
              style={{
                textDecoration: 'none',
                display: 'block',
                width: 'max-content',
              }}
            >
              <Button>Change Plan</Button>
            </Link>
          </div>
          <div>
            <PlanLabel
              onClick={handleConfirm}
              style={{ fontSize: 16, cursor: 'pointer' }}
            >
              Cancel plan
            </PlanLabel>
          </div>
        </PlanInfo>
      </PlanWrap>
      <InvoicesWrap>
        <p>Invoices</p>
        <Column>
          <InvoceText>DATE</InvoceText>
          <InvoceText>STATUS</InvoceText>
          <InvoceText>TOTAL</InvoceText>
          <InvoceText>RECEIPT</InvoceText>
        </Column>
        {data.map((item) => (
          <Column key={item.id}>
            <InvoceText>
              {getDateByFormat(item.created * 1000, 'F/d/y')}
            </InvoceText>
            <InvoceText style={{ textTransform: 'capitalize' }}>
              {item.status}
            </InvoceText>
            <InvoceText>${item.total / 100}</InvoceText>
            <InvoceText>
              <a href={item.invoice_pdf}>PDF</a>
            </InvoceText>
          </Column>
        ))}
        {loading ? (
          <Loader />
        ) : (
          show && <ShowMore onClick={handleClickShow}>Show more</ShowMore>
        )}
      </InvoicesWrap>
      {contextHolder}
    </Wrapper>
  );
}

export default Billing;
