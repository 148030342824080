import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Select as AntdSelect } from 'antd';
import { fetchDepartments } from '../../../service';

const Select = styled(AntdSelect).attrs({ size: 'large' })`
  border-radius: 6px;

  &&& > div {
    border-radius: 6px;
  }
`;

function DepartmentsDropdown({ value, onChange, board, settings }) {
  const [options, setOptions] = useState([]);
  useEffect(() => {
    fetchDepartments(board, settings).then((res) => {
      setOptions(res.map((item) => ({ value: item._id, label: item.name })));
      !value && res[0]?._id && onChange(res[0]._id);
    });
  }, [board, settings]); // eslint-disable-line react-hooks/exhaustive-deps

  return <Select value={value} options={options} onChange={onChange} />;
}

export default DepartmentsDropdown;
