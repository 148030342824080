import styled from 'styled-components';
import {
  Input as AntdInput,
  Select as AntdSelect,
  Button as AntdButton,
} from 'antd';

export const Wrapper = styled.div`
  padding: 24px 0;
`;

export const TicketEmail = styled.div`
  margin-bottom: 54px;
`;

export const Label = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #111827;
  margin-bottom: 14px;
`;

export const SubLabel = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 6px;
  color: #374151;
`;

export const EmailInput = styled(AntdInput)`
  height: 50px;
  max-width: 510px;
  border: 1px solid #98a5cd;
  border-radius: 8px;

  input {
    font-size: 22px;
    font-weight: bold;
  }

  svg {
    font-size: 18px;
  }
`;

export const DefaultsWrap = styled.div`
  border: 1px solid #98a5cd;
  border-radius: 12px;
  max-width: 725px;
  width: 100%;
  padding: 24px 16px;
  margin-bottom: 54px;

  & > div {
    margin-bottom: 24px;
  }

  ${Label} {
    font-size: 18px;
    margin-bottom: 20px;
  }
`;

export const Select = styled(AntdSelect).attrs({ size: 'large' })`
  height: 46px;
  max-width: 406px;
  width: 100%;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 6px;

  &&& > div {
    border: 1px solid #d1d5db;
    border-radius: 6px;
    height: 46px !important;
  }
`;

export const TextArea = styled(AntdInput.TextArea)`
  border-color: #98a5cd;
  border-radius: 4px;
`;

export const EscalationsWrap = styled(DefaultsWrap)`
  max-width: 511px;
`;

export const DateWrap = styled.div`
  display: flex;
  align-items: center;
  grid-gap: 14px;

  ${Select} {
    max-width: 103px;
  }
`;

export const Button = styled(AntdButton)`
  background: #bb6bd9;
  border-color: #bb6bd9;
  border-radius: 8px;
  height: 38px;
  color: #fff;

  &:hover {
    background: #bb6bd9;
    border-color: #bb6bd9;
    color: #fff;
  }
`;
