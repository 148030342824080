import React from 'react';
import styled from 'styled-components';
import {
  Collapse as AntdCollapse,
  Input as AntdInput,
  Checkbox as AntdCheckbox,
  Form as AntdForm,
  Button as AntdButton,
} from 'antd';
import { ArrowDown } from '../../Icons';

export const Wrapper = styled.div``;

export const FoldersWrap = styled.div``;

export const FlexBetween = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const FormItemWrap = styled.div`
  display: flex;
  justify-content: space-between;

  & > div:first-child {
    width: 100%;
  }
  & > div:last-child:not(:first-child) {
    margin-left: 16px;
    cursor: pointer;
  }
`;

export const Button = styled(AntdButton)`
  padding: 10px 25px;
  height: 38px;
  background: #9b51e0;
  border-radius: 8px;
  border: none;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: 0.02em;
  color: #ffffff;

  &:hover {
    color: #ffffff;
    background: #9b51e0;
  }
  &:focus {
    color: #ffffff;
    background: #9b51e0;
  }
`;

export const Label = styled.div`
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #111827;
  margin: 30px 0;
`;

export const Collapse = styled(AntdCollapse).attrs({
  expandIcon: ({ isActive }) => (
    <ArrowDown
      style={{ transform: isActive ? 'rotate(180deg)' : 'rotate(0)' }}
    />
  ),
})``;

export const Panel = styled(AntdCollapse.Panel)`
  &:not(:last-child) {
    border-bottom: 3px solid #f5f8fe !important;
  }

  && > div {
    padding: 12px 30px 12px 0;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #313131;
  }
`;

export const IconsWrap = styled.div`
  display: flex;
  align-items: center;
  grid-gap: 10px;

  & svg {
    font-size: 24px;
  }
`;

export const Input = styled(AntdInput)`
  border: 1px solid #7984a9;
  border-radius: 4px;
  height: 38px;
`;

export const InputPassword = styled(AntdInput.Password)`
  border: 1px solid #7984a9;
  border-radius: 4px;
  height: 38px;
`;

export const Checkbox = styled(AntdCheckbox)`
  font-weight: 400;
  font-size: 16px;
  color: #4f4f4f;

  .ant-checkbox-inner {
    border-color: #7984a9 !important;
    border-radius: 5px;
    width: 20px;
    height: 20px;
    background: transparent;
  }
  .ant-checkbox-checked:after {
    border: none;
  }
  .ant-checkbox-inner::after {
    border-color: #7984a9;
  }
`;

export const FormSaveBtnWrap = styled.div`
  display: flex;
  align-items: center;
  margin-top: 22px;

  ${Button} {
    margin-left: auto;
  }
`;

export const Form = styled(AntdForm)``;

export const FormItem = styled(AntdForm.Item)`
  display: flex;
  align-items: center;

  & > div {
    width: 100%;
  }
`;

export const Textarea = styled(AntdInput.TextArea)`
  border: 1px solid #7984a9;
  border-radius: 4px;
`;

export const UserInfoWrap = styled.div`
  display: flex;
  & > div:last-child {
    & > button {
      width: 119px;
      margin-left: 28px;
    }
  }
`;

export const PasswordWrapper = styled.div`
  display: flex;
  align-items: center;
  grid-gap: 24px;
  flex-wrap: wrap;

  & > div {
    position: relative;
    & > div:last-child {
      top: 0;
    }

    &:nth-child(4n-3) {
      & > div:last-child {
        right: unset;
        left: 0;
      }
    }
  }
`;

export const ItemPassword = styled.div`
  background: #ffffff;
  border: 1px solid #7984a9;
  border-radius: 8px;
  width: 215px;
  padding: 26px;

  ${Label} {
    margin: 10px 0;
  }
`;

export const PasswordImage = styled.div`
  height: 42px;
  width: 100%;
  margin-bottom: 18px;

  img {
    height: 100%;
    width: auto;
  }
`;
