import { usersTypes } from './types';

export const getUsersStart = (payload) => ({
  type: usersTypes.GET_USERS_START,
  payload,
});

export const getUsersSuccess = (data) => ({
  type: usersTypes.GET_USERS_SUCCESS,
  data,
});

export const getUsersFailure = (error) => ({
  type: usersTypes.GET_USERS_FAILURE,
  error,
});

export const getAllUsersStart = () => ({
  type: usersTypes.GET_ALL_USERS_START,
});

export const getAllUsersSuccess = (data) => ({
  type: usersTypes.GET_ALL_USERS_SUCCESS,
  data,
});

export const createUsersStart = (body) => ({
  type: usersTypes.CREATE_USERS_START,
  body,
});

export const createUsersSuccess = (data) => ({
  type: usersTypes.CREATE_USERS_SUCCESS,
  data,
});

export const createUsersFailure = (error) => ({
  type: usersTypes.CREATE_USERS_FAILURE,
  error,
});

export const updateUsersStart = (id, body) => ({
  type: usersTypes.UPDATE_USERS_START,
  id,
  body,
});

export const updateUsersSuccess = (data) => ({
  type: usersTypes.UPDATE_USERS_SUCCESS,
  data,
});

export const deleteUsersStart = (id) => ({
  type: usersTypes.DELETE_USERS_START,
  id,
});

export const deleteUsersSuccess = (data) => ({
  type: usersTypes.DELETE_USERS_SUCCESS,
  data,
});
