import React, { useState } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { isValidEmail } from '../../helpers/helpers';
import { subscribePlan } from '../../service';

export const Subscribe = () => {
  const stripe = useStripe();
  const elements = useElements();
  const [email, setEmail] = useState('');
  const [success, setSuccess] = useState(false);

  const options = {
    style: {
      base: {
        color: '#303238',
        fontSize: '16px',
        fontFamily: 'sans-serif',
        fontSmoothing: 'antialiased',
        '::placeholder': {
          color: '#CFD7DF',
        },
      },
      invalid: {
        color: '#e5424d',
        ':focus': {
          color: '#303238',
        },
      },
    },
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!isValidEmail(email)) {
      return;
    }
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement),
    });

    if (error) {
      console.log('error  ', error);
      return;
    }
    const data = await subscribePlan({
      pmId: paymentMethod.id,
      email,
      plan: 'basic',
    });
    if (data.status === 'ok') {
      setSuccess(true);
    }
  };

  if (success) {
    return <h2>Subscribed</h2>;
  }

  return (
    <form onSubmit={handleSubmit}>
      <label>
        Email*
        <input
          type="email"
          name="email"
          onChange={(e) => setEmail(e.target.value)}
        />
      </label>
      <label>
        <CardElement options={options} />
      </label>
      <button>Subscribe</button>
    </form>
  );
};

export default Subscribe;
