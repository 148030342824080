export const ROUTES = {
  adminPanel: { path: '/admin-panel', title: 'Admin Panel' },
  home: { path: '/', title: 'Home' },
  pricing: { path: '/pricing', title: 'Pricing' },
  login: { path: '/login', title: 'Login' },
  register: { path: '/register', title: 'Register' },
  verifiedEmail: { path: '/verified-email/:token', title: 'Verified Email' },
  forgotPassword: { path: '/forgot-password', title: 'Forgot Password' },
  resetPassword: { path: '/reset-password/:token', title: 'Reset Password' },
  notFound: { path: '/404', title: 'Not Found' },
  dashboard: { path: '/dashboard', title: 'Dashboard' },
  onboard: { path: '/onboard', title: 'Onboarding Form' },
  offboard: { path: '/offboard', title: 'Offboarding Form' },
  assets: { path: '/assets', title: 'Devices' },
  software: { path: '/software', title: 'Software' },
  vendors: { path: '/vendors', title: 'Vendors' },
  onboardSettings: { path: '/onboard-settings', title: 'Onboarding Defaults' },
  offboardSettings: {
    path: '/offboard-settings',
    title: 'Offboarding Defaults',
  },
  users: { path: '/users', title: 'Users' },
  integrations: { path: '/integrations', title: 'Integrations' },
  billing: { path: '/billing', title: 'Billing' },
  billingPlans: { path: '/billing-plans', title: 'Plans' },
  profile: { path: '/profile', title: 'My Profile' },
  passwords: { path: '/passwords', title: 'Passwords' },
  tickets: { path: '/tickets', title: 'All Tickets' },
  ticketsSetting: { path: '/tickets-setting', title: 'Tickets' },
  // notifications: { path: '/notifications', title: 'Notifications' },
  leverIntegrations: {
    path: '/lever-integrations',
    title: 'Lever Integrations',
  },
  slackIntegrations: {
    path: '/slack-integrations',
    title: 'Slack Integrations',
  },
  privacy: { path: '/privacy', title: 'Privacy' },
  office365Integrations: {
    path: '/office365-integrations',
    title: 'Office 365 Integrations',
  },
};

export const STATUS_INFO = {
  notStarted: {
    label: 'Not Started',
    background: '#f9e3e6',
    color: '#EB5757',
    status: 'started',
  },
  started: {
    label: 'In Progress',
    background: '#faece0',
    color: '#F2994A',
    status: 'done',
  },
  done: {
    label: 'Done',
    background: '#DEFAEC',
    color: '#26DE81',
    status: 'notStarted',
  },
};

export const ROLE_VALUES = {
  admin: 'admin',
  manager: 'manager',
  helpdesk: 'helpdesk',
  hr: 'hr',
  standard: 'standard',
};

export const ROLE_NAMES = {
  admin: 'Owner',
  manager: 'Hiring Manager',
  helpdesk: 'Helpdesk',
  hr: 'HR',
  standard: 'Standard',
};

export const ROLES = [
  { name: ROLE_NAMES.admin, value: ROLE_VALUES.admin },
  { name: ROLE_NAMES.manager, value: ROLE_VALUES.manager },
  { name: ROLE_NAMES.helpdesk, value: ROLE_VALUES.helpdesk },
  { name: ROLE_NAMES.hr, value: ROLE_VALUES.hr },
  { name: ROLE_NAMES.standard, value: ROLE_VALUES.standard },
];

export const ASSETS_STATUS = {
  ready_to_deploy: {
    label: 'Ready to Deploy',
    color: '#26DE81',
    status: 'deployed',
  },
  deployed: { label: 'Deployed', color: '#0069d9', status: 'repair' },
  repair: { label: 'In Repair', color: '#ffeb3b', status: 'hold' },
  hold: { label: 'Hold', color: '#f44336', status: 'ready_to_deploy' },
};

export const TICKET_ISSUE = [
  { name: 'Hardware Issues', value: 'hardware_issues' },
  { name: 'Accessories', value: 'accessories' },
  { name: 'Software Issues', value: 'software_issues' },
  { name: 'Software License', value: 'software_license' },
];

export const TICKET_URGENCY = [
  { name: 'High', value: 'high' },
  { name: 'Medium', value: 'medium' },
  { name: 'Low', value: 'low' },
];

export const TICKET_STATUS = [
  { name: 'Not Started', value: 'notStarted' },
  { name: 'In Progress', value: 'started' },
  { name: 'Done', value: 'done' },
];

export const ZIP_STATE = [
  { name: 'American Samoa', value: 'AS' },
  { name: 'Guam', value: 'GU' },
  { name: 'Virgin Islands', value: 'VI' },
  { name: 'Northern Mariana Islands', value: 'MP' },
  { name: 'Puerto Rico', value: 'PR' },
  { name: 'United States Minor Outlying Islands', value: 'UM' },
];

export const USER_STATUS = {
  active: 'active',
  suspended: 'suspended',
  archived: 'archived',
};

export const BOARD_TYPE = {
  onboard: 'onboard',
  offboard: 'offboard',
};

export const TAG_TYPES = {
  default: 'default',
  googleGroups: 'googleGroups',
  googleDriveFolders: 'googleDriveFolders',
  googleDriveSharedFolders: 'googleDriveSharedFolders',
  office365Groups: 'office365Groups',
  office365Drives: 'office365Drives',
  oktaGroups: 'oktaGroups',
};

export const TAG_TYPE_NAMES = {
  [TAG_TYPES.googleGroups]: 'Google Groups',
  [TAG_TYPES.googleDriveFolders]: 'Google Drive Folders',
  [TAG_TYPES.googleDriveSharedFolders]: 'Google Shared Drive Folders',
  [TAG_TYPES.office365Groups]: 'Office 365 Groups',
  [TAG_TYPES.office365Drives]: 'Office 365 Drives',
  [TAG_TYPES.oktaGroups]: 'Okta Groups',
};

export const DAYS = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

export const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const PASSWORD_TYPES = {
  personal: 'personal',
  shared: 'shared',
};

export const PASSWORD_ROLES = {
  view: 'view',
  edit: 'edit',
  add: 'add',
  admin: 'admin',
};

export const NOTIFICATIONS_TYPE = {
  onboardSubmitted: 'onboardSubmitted',
  offboardSubmitted: 'offboardSubmitted',
  boardChecklistsComment: 'boardChecklistsComment',
  createdTicket: 'createdTicket',
  repliedTicket: 'repliedTicket',
  taskAssigned: 'taskAssigned',
  taskOverdue: 'taskOverdue',
  ticketAssigned: 'ticketAssigned',
};

export const EMPLOYMENT_TYPES = [
  'Full-time',
  'Part-time',
  'Contract',
  'Contingent',
];

export const DEPARTMENT_TYPES = {
  allCompany: 'allCompany',
  default: 'default',
};

export const GOOGLE_OFFBOARD_LISTS = [
  {
    key: 'resetPassword',
    title: 'Reset Password',
    status: STATUS_INFO.notStarted,
    completed: '',
  },
  {
    key: 'removeGroups',
    title: 'Remove from Groups',
    status: STATUS_INFO.notStarted,
    completed: '',
  },
  {
    key: 'removeSharedDrives',
    title: 'Remove from Shared Drives',
    status: STATUS_INFO.notStarted,
    completed: '',
  },
  {
    key: 'removeMobileDevices',
    title: 'Remove Mobile Devices',
    status: STATUS_INFO.notStarted,
    completed: '',
  },
  {
    key: 'revokeAppTokens',
    title: 'Revoke App Tokens',
    status: STATUS_INFO.notStarted,
    completed: '',
  },
  {
    key: 'removeMfa',
    title: 'Remove MFA',
    status: STATUS_INFO.notStarted,
    completed: '',
  },
  {
    key: 'setOutOfOffice',
    title: 'Set Out of Office',
    status: STATUS_INFO.notStarted,
    completed: '',
  },
  {
    key: 'transferDriveFiles',
    title: 'Transfer Drive Files',
    status: STATUS_INFO.notStarted,
    completed: '',
  },
  {
    key: 'suspendUser',
    title: 'Suspend User',
    status: STATUS_INFO.notStarted,
    completed: '',
  },
];

export const PLAN_FEATURES = {
  ats: 'ats',
  onBoard: 'onBoard',
  offBoard: 'offBoard',
  googleWorkspace: 'googleWorkspace',
  microsoft365: 'microsoft365',
  okta: 'okta',
  assetManager: 'assetManager',
  passwordManager: 'passwordManager',
  ticketingSystem: 'ticketingSystem',
};

export const PLAN_NAMES = {
  startup: 'Startup',
  growth: 'Growth',
  business: 'Business',
};

export const NOTIFICATION_PREFERENCES = {
  taggedComment: 'taggedComment',
  assignedTask: 'assignedTask',
  assignedTicket: 'assignedTicket',
  onboardSubmitted: 'onboardSubmitted',
  offboardSubmitted: 'offboardSubmitted',
};
