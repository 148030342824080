import { globalTypes } from './types';
import { ROLE_VALUES } from '../../helpers/variables';
import { getPlanFeatures, dateGreaterCompare } from '../../helpers/helpers';

const initialState = {
  profile: {},
  company: {},
  isAdmin: false,
  currentUserRole: '',
  isAuthenticated: false,
  companyPlanFeatures: {},
};

export const global = (state = initialState, action) => {
  switch (action.type) {
    case globalTypes.LOGOUT_START:
      localStorage.removeItem('token');
      return { ...state, isAuthenticated: false };
    case globalTypes.GET_PROFILE_SUCCESS:
      const { role = '' } = action.data;
      const isAdmin = role === ROLE_VALUES.admin;
      return {
        ...state,
        profile: action.data,
        isAuthenticated: true,
        isAdmin,
        currentUserRole: role,
      };
    case globalTypes.GET_PROFILE_FAILURE:
      return { ...state, profile: {} };

    case globalTypes.GET_CURRENT_COMPANY_SUCCESS:
      const companyPlanFeatures = getPlanFeatures(action.data.plan);
      const isFreeTrial =
        !action.data.planId && dateGreaterCompare(action.data.freeTrialEndDate);
      return {
        ...state,
        company: { ...action.data, isFreeTrial },
        companyPlanFeatures,
      };
    case globalTypes.GET_CURRENT_COMPANY_FAILURE:
      return { ...state, company: {} };
    default:
      return state;
  }
};
