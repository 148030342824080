import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import copy from 'copy-to-clipboard';
import { ActionButtons } from '../../shared';
import { Select } from '../../shared/styles';
import {
  createPassword,
  deletePassword,
  getPassword,
  updatePasswords,
} from '../../../service';
import PopUp from './popUp';
import { PencilOutlinedIcon, LaunchIcon } from '../../../Icons';
import { checkValidUrl } from '../../../helpers/helpers';
import {
  Button,
  Form,
  FormItem,
  FormItemWrap,
  Input,
  InputPassword,
  Textarea,
} from '../styles';
import { getPasswordsStart } from '../../../redux/passwords/actions';

function SetPassword({ folders, data, Component, reload, editable }) {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [initialValues, setInitialValues] = useState({});

  const handleClose = () => {
    form.resetFields();
    setOpen(false);
  };

  const handleSubmit = (values) => {
    setLoading(true);
    (data?._id ? updatePasswords(data._id, values) : createPassword(values))
      .then((res) => {
        reload
          ? reload()
          : dispatch(
              getPasswordsStart({
                folderId: res.folder,
                offset: 0,
                limit: 1000,
              }),
            );
        setLoading(false);
        handleClose();
      })
      .catch(() => setLoading(false));
  };

  const handleDelete = (id) => {
    deletePassword(id)
      .then(() => {
        reload && reload();
        handleClose();
      })
      .catch();
  };

  const handleClickLaunchIcon = () => {
    const value = form.getFieldValue('url');
    const url = checkValidUrl(value) ? value : '//' + value;
    window.open(url, '_blank');
  };

  const handleClickEditBtn = () => {
    if (data._id) {
      getPassword(data._id)
        .then((res) => {
          setInitialValues(res);
          setOpen(true);
        })
        .catch(() => setOpen(false));
    }
  };

  return (
    <PopUp
      open={open}
      onClose={handleClose}
      Component={
        data?._id
          ? () => (
              <div>
                <Component />
                {editable && (
                  <PencilOutlinedIcon
                    onClick={handleClickEditBtn}
                    style={{ position: 'absolute', top: 10, right: 10 }}
                  />
                )}
              </div>
            )
          : () => <Button onClick={() => setOpen(true)}>Add password</Button>
      }
    >
      <Form
        form={form}
        autoComplete="off"
        colon={false}
        initialValues={initialValues}
        onFinish={handleSubmit}
      >
        <FormItem name="name" rules={[{ required: true }]}>
          <Input placeholder="Name" />
        </FormItem>
        <FormItemWrap>
          <FormItem name="url" rules={[{ required: true }]}>
            <Input placeholder="URL" />
          </FormItem>
          {data?._id && (
            <div onClick={handleClickLaunchIcon}>
              <LaunchIcon />
            </div>
          )}
        </FormItemWrap>
        <FormItemWrap>
          <FormItem name="username" rules={[{ required: true }]}>
            <Input placeholder="Username" />
          </FormItem>
          {data?._id && (
            <ActionButtons
              handleClone={() => copy(form.getFieldValue('username'))}
            />
          )}
        </FormItemWrap>
        <FormItemWrap>
          <FormItem name="password" rules={[{ required: true }]}>
            <InputPassword placeholder="Password" />
          </FormItem>
          {data?._id && (
            <ActionButtons
              handleClone={() => copy(form.getFieldValue('password'))}
            />
          )}
        </FormItemWrap>
        <FormItem name="folder" rules={[{ required: true }]}>
          <Select placeholder="Folder">
            {folders.map((folder) => (
              <Select.Option key={folder._id} value={folder._id}>
                {folder.name}
              </Select.Option>
            ))}
          </Select>
        </FormItem>
        <FormItem name="notes">
          <Textarea rows={4} placeholder="Notes" />
        </FormItem>
        <FormItemWrap>
          {data?._id && (
            <ActionButtons handleDelete={() => handleDelete(data._id)} />
          )}
          <FormItem style={{ textAlign: 'right' }}>
            <Button htmlType="submit" loading={loading}>
              Save
            </Button>
          </FormItem>
        </FormItemWrap>
      </Form>
    </PopUp>
  );
}

export default SetPassword;
