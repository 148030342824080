import { vendorsTypes } from './types';
import { arrayOfObjectsMergeById } from '../../helpers/helpers';

const initialState = {
    vendors: { data: [], total: 0 },
    setData: {},
    error: '',
    loading: false,
    isLoading: false,
};

export const vendors = (state = initialState, action) => {
    switch (action.type) {
        case vendorsTypes.GET_VENDORS_START:
            return { ...state, loading: true };
        case vendorsTypes.GET_VENDORS_SUCCESS:
            return { ...state, vendors: { ...state.vendors, ...action.data }, loading: false };
        case vendorsTypes.GET_VENDORS_FAILURE:
            return { ...state, vendors: { data: [], total: 0 }, loading: false };
        case vendorsTypes.CREATE_VENDORS_START:
            return { ...state, isLoading: true };
        case vendorsTypes.CREATE_VENDORS_SUCCESS:
            return {
                ...state,
                vendors: {
                    ...state.vendors,
                    data: [action.data, ...state.vendors.data],
                    total: state.vendors.total + 1,
                },
                setData: action.data,
                isLoading: false,
                error: '',
            };
        case vendorsTypes.UPDATE_VENDORS_START:
            return { ...state, isLoading: true };
        case vendorsTypes.UPDATE_VENDORS_SUCCESS:
            return {
                ...state,
                vendors: {
                    ...state.vendors,
                    data: [...arrayOfObjectsMergeById(state.vendors.data, action.data)],
                },
                setData: action.data,
                isLoading: false,
                error: '',
            };
        case vendorsTypes.DELETE_VENDORS_START:
            return { ...state, isLoading: true };
        case vendorsTypes.DELETE_VENDORS_SUCCESS:
            return {
                ...state,
                setData: action.data,
                isLoading: false,
                error: '',
            };
        case vendorsTypes.SET_VENDORS_FAILURE:
            return { ...state, error: action.error, isLoading: false };
        default:
            return state;
    }
};
