import React, { useRef, useLayoutEffect } from 'react';
import jwt from 'jsonwebtoken';
import { oauth } from '../../../service/index';
import { useMediaQuery } from '../../../helpers/hooks';

export const GoogleLogin = ({ text = 'signin_with', loginSuccess }) => {
  const ref = useRef();

  const match = useMediaQuery('(max-width: 610px)');

  useLayoutEffect(() => {
    if (window.google?.accounts) {
      window.google.accounts.id.initialize({
        client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
        callback: onSuccess,
      });
      window.google.accounts.id.renderButton(ref.current, {
        theme: 'outline',
        size: 'larg',
        shape: 'square',
        logo_alignment: match ? 'left' : 'center',
        text,
        width: match ? 275 : 400,
        type: 'standard',
      });
    }
  }, [match, !window.google?.accounts]); // eslint-disable-line react-hooks/exhaustive-deps

  const onSuccess = (res) => {
    if (!res || !res.credential) {
      return;
    }
    const { email, family_name, given_name, sub } = jwt.decode(res.credential);
    const body = {
      email,
      firstName: given_name,
      lastName: family_name,
      oauthId: sub,
      oauth: 'googleId',
    };
    oauth(body)
      .then((res) => {
        loginSuccess(res);
        window.google?.accounts?.id &&
          window.google.accounts.id.disableAutoSelect();
      })
      .catch();
  };

  return <div ref={ref} />;
};
