export const checklistsTypes = {
    GET_CHECKLISTS_START: 'GET_CHECKLISTS_START',
    GET_CHECKLISTS_SUCCESS: 'GET_CHECKLISTS_SUCCESS',
    GET_CHECKLISTS_FAILURE: 'GET_CHECKLISTS_FAILURE',
    CREATE_CHECKLISTS_START: 'CREATE_CHECKLISTS_START',
    CREATE_CHECKLISTS_SUCCESS: 'CREATE_CHECKLISTS_SUCCESS',
    CREATE_CHECKLISTS_FAILURE: 'CREATE_CHECKLISTS_FAILURE',
    DELETE_CHECKLIST_START: 'DELETE_CHECKLIST_START',
    DELETE_CHECKLIST_SUCCESS: 'DELETE_CHECKLIST_SUCCESS',
    DELETE_CHECKLIST_FAILURE: 'DELETE_CHECKLIST_FAILURE',
    UPDATE_CHECKLIST_START: 'UPDATE_CHECKLIST_START',
    UPDATE_CHECKLIST_SUCCESS: 'UPDATE_CHECKLIST_SUCCESS',
    UPDATE_CHECKLIST_FAILURE: 'UPDATE_CHECKLIST_FAILURE',
};
