import React, { useState } from 'react';
import SetIntegration from './SetIntegration';
import { Input } from './styles';

function SimpleIntegration({ name, data, text, label }) {
  const [body, setBody] = useState({ key: '' });

  const handleChange = (e) => {
    setBody((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  return (
    <SetIntegration
      title="Add your API key."
      text={text}
      name={name}
      body={body}
      data={data}
      label={label}
    >
      <div>
        <Input
          name="key"
          value={body.key}
          onChange={handleChange}
          placeholder={'Key'}
        />
      </div>
    </SetIntegration>
  );
}

export default SimpleIntegration;
