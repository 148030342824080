import {
  Modal as AntdModal,
  Input as AntdInput,
  Avatar as AntdAvatar,
} from 'antd';
import styled from 'styled-components';

export const Modal = styled(AntdModal)`
  .ant-modal-content {
    border-radius: 4px;
    overflow: hidden;
  }

  .ant-modal-body {
    padding: 18px;
  }
`;

export const Avatar = styled(AntdAvatar).attrs({ size: 36 })`
  background: linear-gradient(rgb(208, 240, 254) 0%, rgb(190, 233, 253) 100%);
  color: rgb(0, 0, 0);
`;

export const Input = styled(AntdInput).attrs({ size: 'large' })`
  margin-bottom: 16px;
  border-radius: 4px;
`;

export const Wrapper = styled.div`
  border-radius: 4px;
  border: 1px solid #cfd4d9;
  padding: 12px;
  max-height: 310px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    height: 6px;
    border-radius: 1000px;
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 1000px;
    overflow: hidden;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 1000px;
    background: #7f7f7f;
  }
`;

export const Wrap = styled.div`
  display: flex;
  align-items: center;
  grid-gap: 12px;
  margin-bottom: 12px;
  cursor: pointer;

  &:hover {
    background-color: #f1f3f4;
  }
`;

export const UserInfo = styled.div`
  & > div {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: rgb(49, 49, 49);
    mix-blend-mode: normal;

    &:last-child {
      opacity: 0.5;
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
    }
  }
`;

export const CleareWrap = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 12px;
  font-size: 16px;
  & > span {
    cursor: pointer;
  }
`;
