import { assetsTypes } from './types';
import { arrayOfObjectsMergeById } from '../../helpers/helpers';

const initialState = {
    assets: { data: [], total: 0 },
    setData: {},
    error: '',
    loading: false,
    isLoading: false,
};

export const assets = (state = initialState, action) => {
    switch (action.type) {
        case assetsTypes.GET_ASSETS_START:
            return { ...state, loading: true };
        case assetsTypes.GET_ASSETS_SUCCESS:
            return { ...state, assets: { ...state.assets, ...action.data }, loading: false };
        case assetsTypes.GET_ASSETS_FAILURE:
            return { ...state, assets: { data: [], total: 0 }, loading: false };
        case assetsTypes.CREATE_ASSETS_START:
            return { ...state, isLoading: true };
        case assetsTypes.CREATE_ASSETS_SUCCESS:
            return {
                ...state,
                assets: {
                    ...state.assets,
                    data: [action.data, ...state.assets.data],
                    total: state.assets.total + 1,
                },
                setData: action.data,
                isLoading: false,
                error: '',
            };
        case assetsTypes.UPDATE_ASSETS_START:
            return { ...state, isLoading: true };
        case assetsTypes.UPDATE_ASSETS_SUCCESS:
            return {
                ...state,
                assets: {
                    ...state.assets,
                    data: [...arrayOfObjectsMergeById(state.assets.data, action.data)],
                },
                setData: action.data,
                isLoading: false,
                error: '',
            };
        case assetsTypes.DELETE_ASSETS_START:
            return { ...state, isLoading: true };
        case assetsTypes.DELETE_ASSETS_SUCCESS:
            return {
                ...state,
                setData: action.data,
                isLoading: false,
                error: '',
            };
        case assetsTypes.SET_ASSETS_FAILURE:
            return { ...state, error: action.error, isLoading: false };
        default:
            return state;
    }
};
