import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Accordion, Form } from 'react-bootstrap';
import { InputLabel, ConfirmModal, CustomModal } from '../../../shared';
import {
  cloneDepartmentStart,
  deleteDepartmentStart,
  updateDepartmentStart,
} from '../../../../redux/departments/actions';

const SettingsBoard = ({ department, isDeleted }) => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [showCloneModal, setShowCloneModal] = useState(false);
  const [isValid, setValid] = useState(false);
  const [value, setValue] = useState(department.name || '');

  const handleClick = (e) => {
    e.stopPropagation();
    setShow(true);
  };

  const handleDeleteDepartment = () => {
    dispatch(deleteDepartmentStart(department.id));
    setShow(false);
  };

  const handleEdit = (name) => {
    dispatch(updateDepartmentStart({ id: department._id, name }));
  };

  const handleClone = (e) => {
    e.stopPropagation();
    setValue(department.name);
    setShowCloneModal(true);
  };

  const handleCloneDepartment = () => {
    if (!value.trim()) {
      setValid(true);
    }
    dispatch(cloneDepartmentStart({ id: department._id, name: value }));
    setShowCloneModal(false);
  };

  return (
    <>
      <Accordion.Toggle eventKey={department._id}>
        <InputLabel
          title={department.name}
          onDelete={isDeleted ? undefined : handleClick}
          onEdit={handleEdit}
          onClone={handleClone}
        />
      </Accordion.Toggle>
      {show && (
        <ConfirmModal
          show={show}
          handleClose={() => setShow(false)}
          handleConfirm={handleDeleteDepartment}
        />
      )}
      {showCloneModal && (
        <CustomModal
          title="You can change the name here."
          open={showCloneModal}
          onCancel={() => setShowCloneModal(false)}
          onOk={handleCloneDepartment}
        >
          <Form.Control
            type="text"
            name="input"
            value={value}
            isInvalid={isValid}
            onChange={({ target }) => setValue(target.value)}
          />
        </CustomModal>
      )}
    </>
  );
};

export default SettingsBoard;
