import styled from 'styled-components';
import { ArrowIcon } from '../../../Icons';

export const Wrapper = styled.div`
  min-width: 260px;
  max-width: 260px;
  display: flex;
  flex-direction: column;

  ul > li {
    color: #98a5cd;

    a {
      text-decoration: none !important;
    }

    &:not(.ant-menu-submenu) {
      padding-left: 40px !important;
    }

    &.ant-menu-submenu {
      padding-left: 0 !important;

      & > div {
        padding-left: 40px !important;
      }

      ul {
        background: transparent !important;

        li {
          padding-left: 80px !important;

          &:hover {
            a {
              text-decoration: none;
              color: #9b51e0;
            }
          }
        }
      }
    }

    &:after {
      width: 4px;
      height: 36px;
      border-color: #bb6bd9 !important;
      border-radius: 0 12px 12px 0;
      left: 0;
      right: unset;
      margin: auto 0;
    }

    &.ant-menu-item-selected,
    &:active {
      background: #bb6bd910 !important;
      color: #9b51e0 !important;

      a {
        color: #9b51e0;
      }
    }

    & > div {
      padding-left: 0 !important;
    }

    svg {
      font-size: 24px;
      margin-right: 8px;
    }

    a,
    .ant-menu-submenu-title {
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      letter-spacing: -0.02em;
      color: #98a5cd;
    }

    &:hover {
      & > a,
      .ant-menu-submenu-title {
        text-decoration: none;
        color: #9b51e0;
      }

      svg {
        color: #9b51e0;
      }

      & > span > a {
        text-decoration: none;
        color: #9b51e0;
      }
    }
  }
`;

export const Title = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: -0.02em;
  text-transform: uppercase;
  color: #9497b2;
  margin-bottom: 20px;
  padding-left: 40px;
`;

export const Logo = styled.div`
  margin: 14px auto 40px;
`;

export const Logout = styled.div`
  margin-top: auto;
  margin-bottom: 75px;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  color: #eb5757;
  grid-gap: 20px;
  margin-left: 38px;
  cursor: pointer;
`;

export const ExpandIcon = styled(ArrowIcon)`
  transform: rotate(${(props) => (props.$isOpen ? 0 : 180)}deg);

  &&& svg {
    margin-right: 0;
  }
`;
