import React, { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import qs from 'qs';
import { integrationsLever, syncLeverAts } from '../../service';
import { ROUTES } from '../../helpers/variables';
import { useSelector } from 'react-redux';
import { getLeverIntegrationUrl } from '../../helpers/helpers';

export function LeverIntegrations() {
  const { profile } = useSelector((state) => state.global);
  const { search } = useLocation();
  const history = useHistory();
  const [status, setStatus] = useState('');
  const { state, code, error } = qs.parse(search, { ignoreQueryPrefix: true });

  useEffect(() => {
    if (state && code && !error) {
      integrationsLever({ state, code })
        .then(() => {
          syncLeverAts().then().catch();
          history.push(ROUTES.integrations.path);
        })
        .catch(() => setStatus('error'));
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (error) {
    return (
      <div>
        User did not authorize the request{' '}
        <a href={getLeverIntegrationUrl(profile.company)}>Please try again</a>
      </div>
    );
  }

  if (status === 'error') {
    return <div>Something went wrong</div>;
  }

  return <div>Thank you</div>;
}

export default LeverIntegrations;
