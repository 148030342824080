import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SetUser from './components/setUser';
import Tabs from './components/tabs';
import { DataTable, ConfirmModal, UserAvatar, ActionButtons } from '../shared';
import {
  deleteUsersStart,
  getUsersStart,
  updateUsersStart,
} from '../../redux/users/actions';
import { getDepartmentsStart } from '../../redux/departments/actions';
import { getUsersState } from '../../redux/users/selectors';
import { usePrevious } from '../../helpers/hooks';
import { BOARD_TYPE, ROLES } from '../../helpers/variables';
import { Select } from '../shared/styles';
import { AddNewUser } from './styles';

const Users = () => {
  const dispatch = useDispatch();
  const {
    loading,
    isLoading,
    users: { data = [], total = 0, counts = {} },
  } = useSelector(getUsersState);
  const prevLoading = usePrevious(isLoading);
  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [show, setShow] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [user, setUser] = useState(undefined);

  useEffect(() => {
    dispatch(getUsersStart({ offset: 0, limit: sizePerPage }));
    dispatch(getDepartmentsStart(BOARD_TYPE.onboard));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (prevLoading && !isLoading) {
      dispatch(getUsersStart({ offset: 0, limit: sizePerPage }));
      handleClose();
    }
  }, [isLoading]); // eslint-disable-line react-hooks/exhaustive-deps

  const columns = [
    {
      dataField: 'firstName',
      text: 'Profile',
      headerStyle: () => {
        return { width: '20%' };
      },
      formatter: (cell, row) => <UserAvatar user={row} email={true} />,
    },
    {
      dataField: 'title',
      text: 'Title',
    },
    {
      dataField: 'location',
      text: 'Location',
    },
    {
      dataField: 'department.name',
      text: 'Department',
    },
    {
      dataField: 'manager',
      text: 'Manager',
      formatter: (cell) =>
        cell && cell._id
          ? `${cell.firstName || ''} ${cell.lastName || ''}`
          : '',
    },
    {
      dataField: 'role',
      text: 'Permissions',
      headerStyle: () => {
        return { width: '180px' };
      },
      formatter: (cell, { _id }) => (
        <Select
          defaultValue={cell}
          onChange={(role) => dispatch(updateUsersStart(_id, { role }))}
        >
          {ROLES.map(({ name, value }) => (
            <Select.Option key={value} value={value}>
              {name}
            </Select.Option>
          ))}
        </Select>
      ),
    },

    {
      dataField: 'action',
      text: 'Actions',
      headerStyle: () => {
        return { width: '80px' };
      },
      formatter: (cell, row) => (
        <ActionButtons
          handleUpdate={handleClick(row, 'update')}
          handleDelete={handleClick(row, 'delete')}
        />
      ),
    },
  ];

  const handleTableChange = (type, { page, sizePerPage }) => {
    const currentIndex = (page - 1) * sizePerPage;

    if (type !== 'search' && type !== 'sort') {
      setPage(page);
      setSizePerPage(sizePerPage);
    }
    dispatch(
      getUsersStart({
        offset: currentIndex,
        limit: sizePerPage + currentIndex,
      }),
    );
  };

  const handleClick = (user, action) => () => {
    if (action === 'delete') {
      setShowConfirm(true);
    }
    if (action === 'update') {
      setShow(true);
    }
    setUser(user);
  };

  const handleDelete = () => {
    if (!user._id) return;
    dispatch(deleteUsersStart(user._id));
  };

  const handleClose = () => {
    setUser(undefined);
    setShow(false);
    setShowConfirm(false);
  };

  return (
    <div>
      <Tabs
        counts={counts}
        addUser={() => setShow(true)}
        onReload={(status) =>
          dispatch(getUsersStart({ offset: 0, limit: sizePerPage, status }))
        }
      />

      <div style={{ width: '100%', textAlign: 'right' }}>
        <AddNewUser onClick={() => setShow(true)}>Add New User</AddNewUser>
      </div>

      <DataTable
        data={data}
        page={page}
        columns={columns}
        loading={loading}
        totalSize={total}
        isSelectRow={false}
        showToolbar={false}
        sizePerPage={sizePerPage}
        handleTableChange={handleTableChange}
      />

      {show && <SetUser show={show} onClose={handleClose} user={user} />}
      {showConfirm && (
        <ConfirmModal
          show={showConfirm}
          handleClose={handleClose}
          handleConfirm={handleDelete}
        />
      )}
    </div>
  );
};

export default Users;
