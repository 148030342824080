import React, { useEffect, useState } from 'react';
import Actions from './actions';
import {
  fileUpload,
  fetchGSuiteCalendars,
  gSuiteSync,
  deleteIntegrations,
} from '../../service';
import { ConfirmModal, CustomModal } from '../shared';
import google from '../../assets/images/google-sync.png';
import { CheckCircleFilled } from '../../Icons';
import {
  Select,
  Input,
  Button,
  ButtonWrap,
  RadioGroup,
  RadioButton,
  AtsWrap,
  AtsImage,
  AtsName,
  InfoIconWrap,
  Modal,
  AtsInfoContent,
  DangerButton,
  GsuiteConfigWrap,
} from './styles';

const GSuite = (props) => {
  const [show, setShow] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [body, setBody] = useState({});
  const [isValid, setValid] = useState(false);
  const [open, setOpen] = useState(false);
  const [data, setData] = useState({});
  const [calendars, setCalendars] = useState([]);

  useEffect(() => {
    if (props.data?._id) {
      setData(props.data);
      fetchCalendar();
    }
  }, [props.data]); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchCalendar = () =>
    fetchGSuiteCalendars().then((res) =>
      setCalendars(
        res.items.map((item) => ({ value: item.id, label: item.summary })),
      ),
    );

  const handleSubmit = () => {
    if (!body.key) {
      setValid(true);
      return;
    }

    const formData = new FormData();
    formData.append('file', body.key);
    fileUpload('/gsuite', formData)
      .then((res) => {
        fetchCalendar();
        handleClose();
        setData(res);
      })
      .catch(() => handleClose());
  };
  const handleClose = () => {
    setBody({});
    setShow(false);
    setValid(false);
  };

  const handleDelete = () => {
    deleteIntegrations(data?._id)
      .then(() => {
        setShowConfirm(false);
        setData({});
      })
      .catch();
  };

  const handleUpdateInfo = (info) => {
    gSuiteSync({ info })
      .then((res) => setData(res))
      .catch(() => {});
  };

  return (
    <>
      <AtsWrap>
        <InfoIconWrap>
          {data._id && (
            <Actions
              onEdit={() => setOpen(true)}
              onDelete={() => setShowConfirm(true)}
            />
          )}
        </InfoIconWrap>
        <AtsImage
          style={{
            backgroundImage: `url(${google})`,
          }}
        />

        <ButtonWrap>
          {data._id ? (
            <CheckCircleFilled style={{ color: '#26DE81', fontSize: 30 }} />
          ) : (
            <Button onClick={() => setShow(true)}>Sync</Button>
          )}
        </ButtonWrap>
        <AtsName>Google Workspace</AtsName>
      </AtsWrap>

      <Modal
        open={open}
        title=""
        footer={null}
        width={750}
        onCancel={() => setOpen(false)}
      >
        <AtsInfoContent>
          <AtsImage>
            <img src={google} alt={'gsuite'} />
          </AtsImage>
          <div>
            <p>Connecting to Google Workspace will allow you to:</p>
            <ul>
              <li>Create or disable Google Workspace accounts</li>
              <li>Add or remove users to Groups and Shared Drives </li>
              <li>Set Out of Office auto-reply emails</li>
              <li>Transfer personal Drive folders</li>
              <li>Reset passwords</li>
              <li>Disable MFA</li>
              <li>Revoke App Tokens </li>
              <li>Remove Mobile Devices</li>
            </ul>
          </div>
          <GsuiteConfigWrap>
            <div>
              <span>Onboarding Queue</span>
              <RadioGroup
                value={data.info?.onboardingQueue || 0}
                onChange={(e) =>
                  handleUpdateInfo({ onboardingQueue: e.target.value })
                }
              >
                <RadioButton value={0}>Off</RadioButton>
                <RadioButton value={1}>On</RadioButton>
              </RadioGroup>
            </div>
            <Select
              value={data.info?.calendarId}
              options={calendars}
              onChange={(calendarId) => handleUpdateInfo({ calendarId })}
            />
            <DangerButton onClick={() => setShowConfirm(true)}>
              Disconnect
            </DangerButton>
          </GsuiteConfigWrap>
        </AtsInfoContent>
      </Modal>

      <CustomModal
        title="Add your API key."
        open={show}
        onCancel={handleClose}
        onOk={handleSubmit}
      >
        <div>JSON private key</div>
        <Input
          type="file"
          status={isValid ? 'error' : undefined}
          onChange={(e) =>
            setBody((prev) => ({ ...prev, key: e.target.files[0] }))
          }
        />
      </CustomModal>
      <ConfirmModal
        show={showConfirm}
        handleClose={() => setShowConfirm(false)}
        handleConfirm={handleDelete}
      />
    </>
  );
};

export default GSuite;
