import { departmentsTypes } from './types';
import { arrayOfObjectDeleteItem, arrayOfObjectReplaceItem } from '../../helpers/helpers';

const initialState = {
    departments: [],
    department: {},
    error: {
        getAll: '',
        create: '',
        deleted: '',
        updated: '',
    },
    loading: false,
    isLoading: false,
    createdDepartment: {},
    deleted: {},
};

export const departments = (state = initialState, action) => {
    switch (action.type) {
        case departmentsTypes.GET_DEPARTMENTS_START:
            return {
                ...state,
                loading: true,
                error: { ...state.error, getAll: '' },
                departments: [],
            };
        case departmentsTypes.GET_DEPARTMENTS_SUCCESS:
            return {
                ...state,
                departments: action.data,
                loading: false,
            };
        case departmentsTypes.GET_DEPARTMENTS_FAILURE:
            return {
                ...state,
                loading: false,
                error: { ...state.error, getAll: action.error },
            };
        case departmentsTypes.GET_DEPARTMENT_START:
            return {
                ...state,
            };
        case departmentsTypes.GET_DEPARTMENT_SUCCESS:
            return {
                ...state,
                department: action.data,
            };
        case departmentsTypes.GET_DEPARTMENT_FAILURE:
            return {
                ...state,
                department: {},
            };
        case departmentsTypes.CREATE_DEPARTMENTS_START:
            return {
                ...state,
                isLoading: true,
                deleted: {},
                error: { ...state.error, create: '' },
            };
        case departmentsTypes.CREATE_DEPARTMENTS_SUCCESS:
            return {
                ...state,
                createdDepartment: action.data,
                departments: [...state.departments, action.data],
                isLoading: false,
            };
        case departmentsTypes.CREATE_DEPARTMENTS_FAILURE:
            return {
                ...state,
                createdDepartment: {},
                isLoading: false,
                error: { ...state.error, create: action.error },
            };
        case departmentsTypes.DELETE_DEPARTMENT_START:
            return {
                ...state,
                isLoading: true,
                createdDepartment: {},
                error: { ...state.error, deleted: '' },
            };
        case departmentsTypes.DELETE_DEPARTMENT_SUCCESS:
            return {
                ...state,
                deleted: action.data,
                departments: arrayOfObjectDeleteItem(state.departments, action.data._id),
                isLoading: false,
            };
        case departmentsTypes.DELETE_DEPARTMENT_FAILURE:
            return {
                ...state,
                deleted: {},
                isLoading: false,
                error: { ...state.error, deleted: action.error },
            };
        case departmentsTypes.UPDATE_DEPARTMENT_START:
            return { ...state, updateLoading: true, error: { ...state.error, updated: '' } };
        case departmentsTypes.UPDATE_DEPARTMENT_SUCCESS:
            return {
                ...state,
                updated: action.data,
                departments: [...arrayOfObjectReplaceItem(state.departments, action.data)],
                updateLoading: false,
            };
        case departmentsTypes.UPDATE_DEPARTMENT_FAILURE:
            return {
                ...state,
                updated: {},
                updateLoading: false,
                error: { ...state.error, updated: action.error },
            };
        case departmentsTypes.CLONE_DEPARTMENT_START:
            return {
                ...state,
                isLoading: true,
                deleted: {},
                error: { ...state.error, create: '' },
            };
        default:
            return state;
    }
};
