import React from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import Checkout from './Checkout';
import Subscribe from './Subscribe';
import SubscribeForm from './SubscribeForm';

const components = {
  checkout: Checkout,
  subscribe: Subscribe,
  subscribeForm: SubscribeForm,
};

function Stripe({ component, ...props }) {
  const Component = components[component];
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY);

  if (!Component) {
    return null;
  }
  return (
    <Elements stripe={stripePromise}>
      <Component {...props} />
    </Elements>
  );
}

export default Stripe;
