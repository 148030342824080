import React from 'react';
import withIcon from '../withIcon';

const ArrowIcon = () => {
  return (
    <svg
      width="16"
      height="9"
      viewBox="0 0 16 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.5999 7.5415L9.16657 2.10817C8.5249 1.4665 7.4749 1.4665 6.83324 2.10817L1.3999 7.5415"
        stroke="#8593AD"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default withIcon(ArrowIcon);
