import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { Menu } from 'antd';
import {
  DashboardIcon,
  UserIcon,
  AssetsIcon,
  TicketIcon,
  PasswordIcon,
  SettingsIcon,
} from '../../../Icons';
import logo from '../../../assets/images/logo.png';
import {
  userHasPagesAccess,
  planHasPageAccess,
} from '../../../helpers/helpers';
import { ROUTES } from '../../../helpers/variables';
import { PRIVATE_ROUTES } from '../../../routes';
import { useMediaQuery } from '../../../helpers/hooks';
import { Wrapper, Logo, Title, ExpandIcon } from './styles';

const Sidebar = () => {
  const { pathname } = useLocation();
  const { currentUserRole, isAdmin, company } = useSelector(
    (state) => state.global,
  );
  const [collapsed, setCollapsed] = useState(false);
  const isMobile = useMediaQuery('(max-width: 992px)');

  useEffect(() => {
    setCollapsed(isMobile);
  }, [isMobile]);

  function getMenuItems() {
    const items = [];

    if (userHasPagesAccess(PRIVATE_ROUTES, '/dashboard', currentUserRole)) {
      items.push({
        label: (
          <NavLink exact={true} to={ROUTES.dashboard.path}>
            {ROUTES.dashboard.title}
          </NavLink>
        ),
        key: ROUTES.dashboard.path,
        icon: <DashboardIcon />,
      });
    }

    if (
      userHasPagesAccess(PRIVATE_ROUTES, ROUTES.onboard.path, currentUserRole)
    ) {
      items.push({
        label: 'People',
        key: 'people',
        icon: <UserIcon />,
        children: [
          {
            label: <NavLink to={ROUTES.onboard.path}>Onboard</NavLink>,
            key: ROUTES.onboard.path,
          },
          {
            label: <NavLink to={ROUTES.offboard.path}>Offboard</NavLink>,
            key: ROUTES.offboard.path,
          },
        ],
      });
    }

    if (
      userHasPagesAccess(PRIVATE_ROUTES, ROUTES.assets.path, currentUserRole) &&
      planHasPageAccess(company, ROUTES.assets.path)
    ) {
      items.push({
        label: 'Assets',
        key: 'assets',
        icon: <AssetsIcon />,
        children: [
          {
            label: (
              <NavLink to={ROUTES.assets.path}>{ROUTES.assets.title}</NavLink>
            ),
            key: ROUTES.assets.path,
          },
          {
            label: (
              <NavLink to={ROUTES.software.path}>
                {ROUTES.software.title}
              </NavLink>
            ),
            key: ROUTES.software.path,
          },
          {
            label: (
              <NavLink to={ROUTES.vendors.path}>{ROUTES.vendors.title}</NavLink>
            ),
            key: ROUTES.vendors.path,
          },
        ],
      });
    }

    if (planHasPageAccess(company, ROUTES.tickets.path)) {
      items.push({
        label: (
          <NavLink to={ROUTES.tickets.path}>{ROUTES.tickets.title}</NavLink>
        ),
        key: ROUTES.tickets.path,
        icon: <TicketIcon />,
      });
    }

    if (planHasPageAccess(company, ROUTES.passwords.path)) {
      items.push({
        label: (
          <NavLink to={ROUTES.passwords.path}>{ROUTES.passwords.title}</NavLink>
        ),
        key: ROUTES.passwords.path,
        icon: <PasswordIcon />,
      });
    }

    isAdmin &&
      items.push({
        label: 'Settings',
        key: 'settings',
        icon: <SettingsIcon />,
        children: [
          {
            label: <NavLink to={ROUTES.onboardSettings.path}>Onboard</NavLink>,
            key: ROUTES.onboardSettings.path,
          },
          {
            label: (
              <NavLink to={ROUTES.offboardSettings.path}>Offboard</NavLink>
            ),
            key: ROUTES.offboardSettings.path,
          },
          {
            label: (
              <NavLink to={ROUTES.ticketsSetting.path}>
                {ROUTES.ticketsSetting.title}
              </NavLink>
            ),
            key: ROUTES.ticketsSetting.path,
          },
          {
            label: (
              <NavLink to={ROUTES.users.path}>{ROUTES.users.title}</NavLink>
            ),
            key: ROUTES.users.path,
          },
          {
            label: (
              <NavLink to={ROUTES.integrations.path}>
                {ROUTES.integrations.title}
              </NavLink>
            ),
            key: ROUTES.integrations.path,
          },
          // {
          //   label: (
          //     <NavLink to={ROUTES.notifications.path}>
          //       {ROUTES.notifications.title}
          //     </NavLink>
          //   ),
          //   key: ROUTES.notifications.path,
          // },
          {
            label: (
              <NavLink to={ROUTES.billing.path}>{ROUTES.billing.title}</NavLink>
            ),
            key: ROUTES.billing.path,
          },
        ],
      });

    return items;
  }

  return (
    <Wrapper>
      <Link to={ROUTES.dashboard.path}>
        <Logo>
          <img src={logo} alt="logo" />
        </Logo>
      </Link>

      <Title>Menu</Title>

      <Menu
        mode="inline"
        expandIcon={({ isOpen }) => <ExpandIcon $isOpen={isOpen} />}
        selectedKeys={[pathname]}
        inlineCollapsed={collapsed}
        items={getMenuItems()}
      />
    </Wrapper>
  );
};

export default Sidebar;
