import React, { useRef } from 'react';
import styled from 'styled-components';
import { useOutsideClick } from '../../../helpers/hooks';

const Wrapper = styled.div`
  position: relative;
`;

const Wrap = styled.div`
  width: 462px;
  background: #ffffff;
  border: 1px solid #7984a9;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  padding: 18px;
  z-index: 10;
  position: absolute;
  top: 60px;
  right: 0;
`;

function PopUp({ children, onClose, open, Component }) {
  const ref = useRef();
  useOutsideClick(ref, onClose);

  return (
    <Wrapper ref={ref}>
      {Component && <Component />}
      {open && <Wrap>{children}</Wrap>}
    </Wrapper>
  );
}

export default PopUp;
