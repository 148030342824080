import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_URL;

export const fetcher = (url, method = 'GET', body = {}) => {
  const headers = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const token = localStorage.getItem('token');

  if (token) {
    headers.headers.Authorization = 'Bearer ' + token;
  }

  if (method.toLowerCase() === 'get') {
    return axios.get(apiUrl + url, headers).then((res) => res.data);
  }

  if (method.toLowerCase() === 'delete') {
    return axios.delete(apiUrl + url, headers).then((res) => res.data);
  }

  return axios[method.toLowerCase()](
    apiUrl + url,
    JSON.stringify(body),
    headers,
  ).then((res) => res.data);
};

export function uploadFetcher(url, body = {}) {
  const headers = {
    headers: { 'Content-Type': 'multipart/form-data' },
  };
  const token = localStorage.getItem('token');

  if (token) {
    headers.headers.Authorization = 'Bearer ' + token;
  }

  return axios.post(apiUrl + url, body, headers).then((res) => res.data);
}
