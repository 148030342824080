import React from 'react';
import withIcon from '../withIcon';

const ArrowLeft = () => {
  return (
    <svg
      width="1em"
      height="2.25em"
      viewBox="0 0 8 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.28963 1.21338L1.28223 7.22047L7.28963 13.2279"
        stroke="#3C475D"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default withIcon(ArrowLeft);
