import styled from 'styled-components';
import {
  Input as AntdInput,
  InputNumber as AntdInputNumber,
  Button as AntdButton,
  Col as AntdCol,
  Row as AntdRow,
  Tooltip as AntdTooltip,
} from 'antd';
import { InfoCircleFilled, ChecklistDragIcon } from '../../../../Icons';

export const Wrapper = styled.div`
  position: relative;
`;

export const Wrapp = styled.div`
  position: relative;
`;

export const Row = styled(AntdRow)`
  margin-bottom: 16px;
`;

export const Col = styled(AntdCol)`
  text-align: center;
  margin: auto;

  & > button {
    margin: auto;
  }
`;

export const Tooltip = styled(AntdTooltip)``;

export const TooltipIcon = styled(InfoCircleFilled)`
  position: absolute;
  right: 0;
  top: 0;
`;

export const DragIcon = styled(ChecklistDragIcon)`
  position: absolute;
  left: 0;
`;

export const Title = styled.div`
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #111827;
  position: relative;
`;

export const Task = styled.div`
  min-height: 38px;
  display: flex;
  align-items: center;
  padding: 0 12px 0 24px;
  background: #f5f8fe;
  border-radius: 8px;
  font-weight: 500;
  font-size: 15px;
  color: #8593ad;

  &&& label {
    background: transparent;
    border: unset;
    cursor: grab;
    margin: 0;
    padding: 0;

    &:active {
      background: transparent;
    }

    &:focus {
      border: unset;
    }
  }
`;

export const Owner = styled(AntdButton)`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 25px;
  height: 38px;
  min-width: 88px;
  border-radius: 8px;
  letter-spacing: 0.02em;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.02em;
  color: #4f4f4f !important;
  background: rgba(223, 231, 248, 0.5) !important;
  border: 2px solid #dfe7f8 !important;
  margin: auto;
`;

export const Input = styled(AntdInput)`
  border-radius: 6px;
`;

export const InputNumber = styled(AntdInputNumber)`
  border: unset !important;
  box-shadow: unset !important;
  outline: unset !important;
  background: transparent !important;

  input {
    text-align: center;
  }
`;

export const BorderedWrap = styled.div`
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #4f4f4f;
  background: rgba(223, 231, 248, 0.5);
  border: 2px solid #dfe7f8;
  border-radius: 8px;
  font-weight: 500;
  font-size: 14px;
  height: 38px;
  width: max-content;
  padding: 0 16px;
`;

export const DueDateWrap = styled(BorderedWrap)`
  width: 61px;
  padding: 0;
  overflow: hidden;
`;
