import React from 'react';
import withIcon from '../withIcon';

const SyncOnIcon = () => {
  return (
    <svg
      width="42"
      height="40"
      viewBox="0 0 42 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="28.2148" cy="18.5" r="13.5" fill="#C956FF" />
      <path
        d="M32.0957 26.6667V10.8333C32.0957 9 30.5957 7.5 28.7624 7.5H19.5957"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.7617 3.3335L18.7617 7.50016L23.7617 11.6668"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M32.0957 36.6665C34.8571 36.6665 37.0957 34.4279 37.0957 31.6665C37.0957 28.9051 34.8571 26.6665 32.0957 26.6665C29.3343 26.6665 27.0957 28.9051 27.0957 31.6665C27.0957 34.4279 29.3343 36.6665 32.0957 36.6665Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.76172 13.3335V29.1668C8.76172 31.0002 10.2617 32.5002 12.0951 32.5002H21.2617"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.0957 36.6668L22.0957 32.5002L17.0957 28.3335"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.76172 13.3335C11.5231 13.3335 13.7617 11.0949 13.7617 8.3335C13.7617 5.57207 11.5231 3.3335 8.76172 3.3335C6.00029 3.3335 3.76172 5.57207 3.76172 8.3335C3.76172 11.0949 6.00029 13.3335 8.76172 13.3335Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default withIcon(SyncOnIcon);
