import { notificationsTypes } from './types';

export const getNotificationsStart = (offset, limit) => ({
  type: notificationsTypes.GET_NOTIFICATIONS_START,
  offset,
  limit,
});

export const getNotificationsSuccess = (data) => ({
  type: notificationsTypes.GET_NOTIFICATIONS_SUCCESS,
  data,
});

export const getNotificationsFailure = (error) => ({
  type: notificationsTypes.GET_NOTIFICATIONS_FAILURE,
  error,
});

export const updateNotificationsStart = (id, body) => ({
  type: notificationsTypes.UPDATE_NOTIFICATIONS_START,
  id,
  body,
});

export const updateNotificationsSuccess = (data) => ({
  type: notificationsTypes.UPDATE_NOTIFICATIONS_SUCCESS,
  data,
});

export const updateNotificationsFailure = (error) => ({
  type: notificationsTypes.UPDATE_NOTIFICATIONS_FAILURE,
  error,
});
