import { boardsTypes } from './types';
import { arrayOfObjectDeleteItem, arrayOfObjectsMergeById } from '../../helpers/helpers';

const initialState = {
    error: '',
    loading: false,
    isLoading: false,
    updateLoading: false,
    boards: { data: [], loading: false },
    onboard: { data: [], loading: false },
    offboard: { data: [], loading: false },
};

export const boards = (state = initialState, action) => {
    switch (action.type) {
        case boardsTypes.GET_BOARDS_START:
            const { board = 'boards' } = action.payload;
            return { ...state, loading: true, [board]: { data: [], loading: true } };
        case boardsTypes.GET_BOARDS_SUCCESS:
            return { ...state, [action.board]: { ...action.data, loading: false } };
        case boardsTypes.GET_BOARDS_FAILURE:
            return { ...state, error: action.error, [action.board]: { data: [], loading: false } };
        case boardsTypes.CREATE_BOARD_START:
            return { ...state, isLoading: true };
        case boardsTypes.CREATE_BOARD_SUCCESS:
            return {
                ...state,
                [action.data.board]: {
                    ...state[action.data.board],
                    data: [action.data, ...state[action.data.board].data],
                },
                isLoading: false,
            };
        case boardsTypes.CREATE_BOARD_FAILURE:
            return { ...state, error: action.error, isLoading: false };
        case boardsTypes.UPDATE_BOARD_START:
        case boardsTypes.UPDATE_BOARD_CHECKLIST_START:
        case boardsTypes.UPDATE_BOARD_TAG_START:
        case boardsTypes.BOARD_CHECKLIST_COMMENT_START:
            return { ...state, updateLoading: true };
        case boardsTypes.UPDATE_BOARD_SUCCESS:
            return {
                ...state,
                [action.data.board]: {
                    ...state[action.data.board],
                    data: [...arrayOfObjectsMergeById(state[action.data.board].data, action.data)],
                },
                updateLoading: false,
            };
        case boardsTypes.UPDATE_BOARD_FAILURE:
            return { ...state, error: action.error, updateLoading: false };
        case boardsTypes.DELETE_BOARD_START:
            return { ...state };
        case boardsTypes.DELETE_BOARD_SUCCESS:
            return {
                ...state,
                [action.data.board]: {
                    ...state[action.data.board],
                    data: arrayOfObjectDeleteItem(state[action.data.board].data, action.data._id),
                    total: state[action.data.board].total - 1
                },
            };
        default:
            return state;
    }
};
