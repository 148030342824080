import React, { useState } from 'react';
import { Modal, Form, Col } from 'react-bootstrap';
import { SubmitButton, Select } from '../shared';
import { TICKET_URGENCY } from '../../helpers/variables';
import { CreateButton } from './styles';

function SetTickets({ onSubmit, issueTypes }) {
  const [modalShow, setModalShow] = useState(false);
  const [validated, setValidated] = useState(false);
  const initialBody = {
    subject: '',
    issue: '',
    urgency: '',
    comment: '',
  };
  const [body, setBody] = useState(initialBody);

  const handleClose = () => {
    setBody(initialBody);
    setValidated(false);
    setModalShow(false);
  };

  const handleChange = ({ target }) => {
    const { name, value } = target;
    setBody((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setValidated(true);
    const form = event.currentTarget;
    if (form.checkValidity() === true) {
      onSubmit(body);
      handleClose();
    }
  };

  return (
    <div>
      <CreateButton onClick={() => setModalShow(true)}>
        Create Ticket
      </CreateButton>
      <Modal
        size="lg"
        show={modalShow}
        onHide={handleClose}
        aria-labelledby="modal-vendors"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="modal-vendors" className="text-center">
            Create Ticket
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Form.Row>
              <Col>
                <Form.Label>Subject</Form.Label>
                <Form.Group>
                  <Form.Control
                    required
                    name="subject"
                    onChange={handleChange}
                    value={body.subject}
                  />
                </Form.Group>
              </Col>
            </Form.Row>
            <Form.Row>
              <Col>
                <Form.Group>
                  <Form.Label>Issue Type</Form.Label>
                  <Select
                    required
                    name="issue"
                    emptyOption={true}
                    value={body.issue}
                    options={issueTypes?.map((item) => ({
                      name: item,
                      value: item,
                    }))}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>Urgency</Form.Label>
                  <Select
                    required
                    name="urgency"
                    emptyOption={true}
                    value={body.urgency}
                    options={TICKET_URGENCY}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
              <Col></Col>
            </Form.Row>
            <Form.Row>
              <Col>
                <Form.Group>
                  <Form.Control
                    as="textarea"
                    rows={8}
                    required
                    name="comment"
                    onChange={handleChange}
                    value={body.comment}
                  />
                </Form.Group>
              </Col>
            </Form.Row>
            <Form.Row>
              <Col>
                <SubmitButton
                  variant="success"
                  type="submit"
                  className="float-right pl-4 pr-4"
                />
              </Col>
            </Form.Row>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default SetTickets;
