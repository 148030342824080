import styled from 'styled-components';
import { Checkbox, Input as AntdInput } from 'antd';

export const Wrapper = styled.div`
  margin: 110px 0 25px 125px;

  & > div:first-child {
    display: flex;
    align-items: center;
    grid-gap: 88px;
    margin-bottom: 80px;
  }
`;

export const ImageWrap = styled.div`
  & > div:last-child {
    font-weight: 400;
    font-size: 18px;
    color: #757b8c;
    margin-top: 8px;
    text-align: center;
    cursor: pointer;

    &:hover {
      color: #532fd8;
    }
  }
`;

export const DeleteWrap = styled.div`
  display: none;
  position: absolute;
  left: 0;
  top: 0;
  position: absolute;
  left: 0;
  top: 0;
  align-items: center;
  justify-content: center;
`;

export const Image = styled.div`
  border: 5px solid rgba(79, 79, 79, 0.5);
  border-radius: 100%;
  width: 155px;
  height: 155px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  div {
    background: rgb(204 204 204 / 32%);
    width: 100%;
    height: 100%;
  }

  img {
    height: 100%;
    object-fit: cover;
  }

  &:hover {
    ${DeleteWrap} {
      display: flex;
    }
  }
`;

export const FullName = styled.div`
  font-weight: 700;
  font-size: 28px;
  color: #313131;
  margin-bottom: 8px;
`;

export const Title = styled.div`
  font-weight: 400;
  font-size: 23px;
  color: #757b8c;
  margin-bottom: 12px;
`;

export const Text = styled.div`
  font-weight: 700;
  font-size: 18px;
  color: #313131;

  span {
    font-weight: 400;
  }
`;

export const InfoWrap = styled.div`
  & > div:first-child {
    display: flex;
    align-items: center;
    grid-gap: 64px;
    margin-bottom: 64px;
  }
`;

export const NotificationWrapper = styled.div``;

export const NotTitle = styled.div`
  font-weight: 700;
  font-size: 18px;
  color: #000000;
  margin-bottom: 32px;
`;

export const CheckboxGroup = styled(Checkbox.Group)`
  display: flex;
  flex-direction: column;

  .ant-checkbox-inner {
    width: 30px;
    height: 30px;
    border: 2px solid #757b8c;
    border-radius: 6px;
    overflow: hidden;
  }

  .ant-checkbox {
    &.ant-checkbox-checked {
      .ant-checkbox-inner {
        background: #9b51e0;
        border-color: #9b51e0;
        &::after {
          top: 45%;
          left: 24%;
          display: table;
          width: 10px;
          height: 17px;
          border-width: 3px;
        }
      }
    }
  }

  label {
    margin-bottom: 32px;
    max-width: max-content;
    display: flex;
    grid-gap: 18px;
    align-items: center;
    font-weight: 400;
    font-size: 18px;
    line-height: 160.49%;
    color: #000000;

    &:hover {
      .ant-checkbox-inner {
        border: 2px solid #757b8c;
      }
    }
  }
`;

export const Input = styled(AntdInput).attrs({ size: 'large' })`
  max-width: 400px;
`;
