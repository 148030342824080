import { departmentsTypes } from './types';

export const getDepartmentsStart = (payload, setting) => ({
    type: departmentsTypes.GET_DEPARTMENTS_START,
    payload,
    setting,
});

export const getDepartmentsSuccess = (data) => ({
    type: departmentsTypes.GET_DEPARTMENTS_SUCCESS,
    data,
});

export const getDepartmentsFailure = (error) => ({
    type: departmentsTypes.GET_DEPARTMENTS_FAILURE,
    error,
});

export const getDepartmentStart = (payload) => ({
    type: departmentsTypes.GET_DEPARTMENT_START,
    payload,
});

export const getDepartmentSuccess = (data) => ({
    type: departmentsTypes.GET_DEPARTMENT_SUCCESS,
    data,
});

export const getDepartmentFailure = (error) => ({
    type: departmentsTypes.GET_DEPARTMENT_FAILURE,
    error,
});

export const createDepartmentStart = (payload) => ({
    type: departmentsTypes.CREATE_DEPARTMENTS_START,
    payload,
});

export const createDepartmentSuccess = (data) => ({
    type: departmentsTypes.CREATE_DEPARTMENTS_SUCCESS,
    data,
});

export const createDepartmentFailure = (error) => ({
    type: departmentsTypes.CREATE_DEPARTMENTS_FAILURE,
    error,
});

export const deleteDepartmentStart = (id) => ({
    type: departmentsTypes.DELETE_DEPARTMENT_START,
    id,
});

export const deleteDepartmentSuccess = (data) => ({
    type: departmentsTypes.DELETE_DEPARTMENT_SUCCESS,
    data,
});

export const deleteDepartmentFailure = (error) => ({
    type: departmentsTypes.DELETE_DEPARTMENT_FAILURE,
    error,
});

export const updateDepartmentStart = (body) => ({
    type: departmentsTypes.UPDATE_DEPARTMENT_START,
    body,
});

export const updateDepartmentSuccess = (data) => ({
    type: departmentsTypes.UPDATE_DEPARTMENT_SUCCESS,
    data,
});

export const updateDepartmentFailure = (error) => ({
    type: departmentsTypes.UPDATE_DEPARTMENT_FAILURE,
    error,
});

export const cloneDepartmentStart = (body) => ({
    type: departmentsTypes.CLONE_DEPARTMENT_START,
    body,
});
