import { put, call, takeLatest } from 'redux-saga/effects';
import * as service from '../../service';
import { dashboardTypes } from './types';
import { getDashboardSuccess, getDashboardFailure } from './actions';

export function* getDashboard() {
  try {
    const data = yield call(() => {
      return service.fetchDashboard();
    });
    yield put(getDashboardSuccess(data));
  } catch (e) {
    yield put(getDashboardFailure(e.message));
  }
}

export function* dashboardSaga() {
  yield takeLatest(dashboardTypes.GET_DASHBOARD_START, getDashboard);
}
