import React from 'react';
import { Dropdown, Menu } from 'antd';
import { EllipsisOutlined } from '../../../Icons';

function Actions({ onSelect, data, googleSync }) {
  const menu = (
    <Menu
      onClick={onSelect}
      items={[
        {
          key: 'edit',
          label: 'Edit',
        },
        {
          key: 'contact',
          label: 'Contact',
        },
        {
          key: 'createGoogleAccount',
          label: 'Create Google Account',
          disabled: !googleSync?._id || data?.name?.googleDirectoryEmail,
        },
        {
          key: 'print',
          label: 'Print shipping label',
        },
        {
          key: 'archive',
          label: 'Archive',
        },
      ]}
    />
  );
  return (
    <Dropdown overlay={menu} trigger={['click']}>
      <EllipsisOutlined
        style={{ fontSize: 22 }}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
      />
    </Dropdown>
  );
}

export default Actions;
