export const detailsTypes = {
    GET_DETAILS_START: 'GET_DETAILS_START',
    GET_DETAILS_SUCCESS: 'GET_DETAILS_SUCCESS',
    GET_DETAILS_FAILURE: 'GET_DETAILS_FAILURE',
    CREATE_DETAIL_START: 'CREATE_DETAIL_START',
    CREATE_DETAIL_SUCCESS: 'CREATE_DETAIL_SUCCESS',
    CREATE_DETAIL_FAILURE: 'CREATE_DETAIL_FAILURE',
    UPDATE_DETAIL_START: 'UPDATE_DETAIL_START',
    UPDATE_DETAIL_SUCCESS: 'UPDATE_DETAIL_SUCCESS',
    UPDATE_DETAIL_FAILURE: 'UPDATE_DETAIL_FAILURE',
    DELETE_DETAIL_START: 'DELETE_DETAIL_START',
    DELETE_DETAIL_SUCCESS: 'DELETE_DETAIL_SUCCESS',
    DELETE_DETAIL_FAILURE: 'DELETE_DETAIL_FAILURE',
    DELETE_DETAIL_FIELD_START: 'DELETE_DETAIL_FIELD_START',
    DELETE_DETAIL_FIELD_SUCCESS: 'DELETE_DETAIL_FIELD_SUCCESS',
    DELETE_DETAIL_FIELD_FAILURE: 'DELETE_DETAIL_FIELD_FAILURE',
};
