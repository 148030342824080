import { all, spawn } from 'redux-saga/effects';
import { globalSaga } from '../redux/global/sagas';
import { usersSaga } from '../redux/users/sagas';
import { assetsSaga } from '../redux/assets/sagas';
import { passwordsSaga } from '../redux/passwords/sagas';
import { departmentsSaga } from '../redux/departments/sagas';
import { checklistsSaga } from '../redux/checklists/sagas';
import { tagsSaga } from '../redux/tags/sagas';
import { settingsSaga } from '../redux/settings/sagas';
import { detailsSaga } from '../redux/details/sagas';
import { boardsSaga } from '../redux/boards/sagas';
import { vendorsSaga } from '../redux/vendors/sagas';
import { softwareSaga } from '../redux/software/sagas';
import { dashboardSaga } from '../redux/dashboard/sagas';
import { notificationsSaga } from '../redux/notifications/sagas';

export default function* rootSaga() {
  yield all([
    spawn(globalSaga),
    spawn(usersSaga),
    spawn(passwordsSaga),
    spawn(assetsSaga),
    spawn(departmentsSaga),
    spawn(checklistsSaga),
    spawn(tagsSaga),
    spawn(settingsSaga),
    spawn(detailsSaga),
    spawn(boardsSaga),
    spawn(vendorsSaga),
    spawn(softwareSaga),
    spawn(dashboardSaga),
    spawn(notificationsSaga),
  ]);
}
