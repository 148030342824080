import { put, call, takeLatest } from 'redux-saga/effects';
import * as service from '../../service';
import {
  getProfileSuccess,
  getProfileFailure,
  getCurrentCompanySuccess,
  getCurrentCompanyFailure,
} from './actions';
import { globalTypes } from './types';

export function* getProfile() {
  try {
    const data = yield call(() => {
      return service.fetchCurrentUser();
    });
    yield put(getProfileSuccess(data));
  } catch (e) {
    yield put(getProfileFailure(e.message));
  }
}

export function* getCompany() {
  try {
    const data = yield call(() => {
      return service.fetchCurrentUserCompany();
    });
    yield put(getCurrentCompanySuccess(data));
  } catch (e) {
    yield put(getCurrentCompanyFailure(e.message));
  }
}

export function* globalSaga() {
  yield takeLatest(globalTypes.GET_PROFILE_START, getProfile);
  yield takeLatest(globalTypes.GET_CURRENT_COMPANY_START, getCompany);
}
