import React from 'react';
import withIcon from '../withIcon';

const SyncOffIcon = () => {
  return (
    <svg
      width="42"
      height="40"
      viewBox="0 0 42 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="27.9287" cy="13.5" r="13.5" fill="#C956FF" />
      <path
        d="M32.3812 26.6667V10.8333C32.3812 9 30.8812 7.5 29.0479 7.5H21.5479"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.7148 3.3335L20.7148 7.50016L25.7148 11.6668"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.04785 15V26.6667"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.46452 14.1668C12.4561 14.1668 14.8812 11.7417 14.8812 8.75016C14.8812 5.75862 12.4561 3.3335 9.46452 3.3335C6.47298 3.3335 4.04785 5.75862 4.04785 8.75016C4.04785 11.7417 6.47298 14.1668 9.46452 14.1668Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.04785 36.6665C11.8093 36.6665 14.0479 34.4279 14.0479 31.6665C14.0479 28.9051 11.8093 26.6665 9.04785 26.6665C6.28643 26.6665 4.04785 28.9051 4.04785 31.6665C4.04785 34.4279 6.28643 36.6665 9.04785 36.6665Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M32.3818 36.6665C35.1433 36.6665 37.3818 34.4279 37.3818 31.6665C37.3818 28.9051 35.1433 26.6665 32.3818 26.6665C29.6204 26.6665 27.3818 28.9051 27.3818 31.6665C27.3818 34.4279 29.6204 36.6665 32.3818 36.6665Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default withIcon(SyncOffIcon);
