export const vendorsTypes = {
    GET_VENDORS_START: 'GET_VENDORS_START',
    GET_VENDORS_SUCCESS: 'GET_VENDORS_SUCCESS',
    GET_VENDORS_FAILURE: 'GET_VENDORS_FAILURE',
    CREATE_VENDORS_START: 'CREATE_VENDORS_START',
    CREATE_VENDORS_SUCCESS: 'CREATE_VENDORS_SUCCESS',
    UPDATE_VENDORS_START: 'UPDATE_VENDORS_START',
    UPDATE_VENDORS_SUCCESS: 'UPDATE_VENDORS_SUCCESS',
    DELETE_VENDORS_START: 'DELETE_VENDORS_START',
    DELETE_VENDORS_SUCCESS: 'DELETE_VENDORS_SUCCESS',
    SET_VENDORS_FAILURE: 'SET_VENDORS_FAILURE',
};
