import React, { useState } from 'react';
import styled from 'styled-components';
import { Checkbox } from 'antd';
import { TableFilterDropdown } from '../shared';
import { TICKET_URGENCY, TICKET_STATUS } from '../../helpers/variables';

const Label = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #374151;
  margin-bottom: 4px;
`;

function Filters({ onFilter, issueTypes }) {
  const [body, setBody] = useState({ urgency: null, status: null });

  const handleChange = (name, value) => {
    const data = { ...body, [name]: value?.length ? value : null };
    setBody(data);
    onFilter(data);
  };

  return (
    <TableFilterDropdown>
      <>
        <Label>Priority</Label>
        <Checkbox.Group
          style={{ marginBottom: 8 }}
          options={TICKET_URGENCY.map((item) => ({
            label: item.name,
            value: item.value,
          }))}
          onChange={(value) => handleChange('urgency', value)}
        />
        <Label>Status</Label>
        <Checkbox.Group
          onChange={(value) => handleChange('status', value)}
          options={TICKET_STATUS.map((item) => ({
            label: item.name,
            value: item.value,
          }))}
        />
        {issueTypes && (
          <>
            <Label>Issue Type</Label>
            <Checkbox.Group
              onChange={(value) => handleChange('issue', value)}
              options={issueTypes.map((item) => ({
                label: item,
                value: item,
              }))}
            />
          </>
        )}
      </>
    </TableFilterDropdown>
  );
}

export default Filters;
