import { softwareTypes } from './types';

export const getSoftwareStart = (params) => ({
    type: softwareTypes.GET_SOFTWARE_START,
    params,
});

export const getSoftwareSuccess = (data) => ({
    type: softwareTypes.GET_SOFTWARE_SUCCESS,
    data,
});

export const getSoftwareFailure = (error) => ({
    type: softwareTypes.GET_SOFTWARE_FAILURE,
    error,
});

export const createSoftwareStart = (body) => ({
    type: softwareTypes.CREATE_SOFTWARE_START,
    body,
});

export const createSoftwareSuccess = (data) => ({
    type: softwareTypes.CREATE_SOFTWARE_SUCCESS,
    data,
});

export const updateSoftwareStart = (body, id) => ({
    type: softwareTypes.UPDATE_SOFTWARE_START,
    body,
    id,
});

export const updateSoftwareSuccess = (data) => ({
    type: softwareTypes.UPDATE_SOFTWARE_SUCCESS,
    data,
});

export const deleteSoftwareStart = (id, params) => ({
    type: softwareTypes.DELETE_SOFTWARE_START,
    id,
    params
});

export const deleteSoftwareSuccess = (data) => ({
    type: softwareTypes.DELETE_SOFTWARE_SUCCESS,
    data,
});

export const setSoftwareFailure = (error) => ({
    type: softwareTypes.SET_SOFTWARE_FAILURE,
    error,
});
