import styled from 'styled-components';
import { Col, Input as AntdInput } from 'antd';
import { Link as DomLink } from 'react-router-dom';

export const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  background-color: #5d1fe1;
  color: #fff;
`;

export const Logo = styled.div`
  width: 150px;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Link = styled(DomLink)`
  text-decoration: none;
`;

export const ResendEmail = styled.div`
  span {
    cursor: pointer;
    color: #17a2b8;
    &:hover {
      color: #007bff;
    }
  }
`;

export const Content = styled(Col)`
  padding: 15px;
  background-color: #fff;
  color: #000;
`;

export const Title = styled.div`
  font-size: 30px;
  font-weight: 600;
`;

export const Input = styled(AntdInput).attrs({ size: 'large' })`
  border-radius: 4px;
`;

export const InputPassword = styled(AntdInput.Password).attrs({
  size: 'large',
})`
  border-radius: 4px;
`;

export const LinkLogin = styled(DomLink)`
  font-size: 20px;
  color: #fff;
  text-decoration: none;

  &:hover {
    color: #fff;
    text-decoration: underline;
  }
`;
