import jwt from 'jsonwebtoken';
import crypto from 'crypto';
import {
  MONTHS,
  STATUS_INFO,
  TAG_TYPES,
  PLAN_FEATURES,
  ROUTES,
} from './variables';

export const getLeverIntegrationUrl = (company) => {
  return `https://auth.lever.co/authorize?client_id=${process.env.REACT_APP_LEVER_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_ORIGIN_URL}/lever-integrations&state=${company}&response_type=code&scope=applications:read:admin archive_reasons:read:admin offers:read:admin opportunities:read:admin postings:read:admin users:read:admin webhooks:write:admin offline_access&prompt=consent&audience=https://api.lever.co/v1/`;
};

export const getOffice365IntegrationUrl = (company) => {
  return `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=${process.env.REACT_APP_MICROSOFT_CLIENT_ID}&response_type=code&redirect_uri=${process.env.REACT_APP_ORIGIN_URL}/office365-integrations&response_mode=query&scope=offline_access User.ReadWrite.All User.ManageIdentities.All Group.ReadWrite.All Directory.ReadWrite.All Directory.AccessAsUser.All Files.ReadWrite.All Sites.ReadWrite.All mail.read&state=${company}`;
};

export const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export const debounce = (fn, wait = 500) => {
  let timeoutId;

  return (...args) => {
    if (timeoutId) clearTimeout(timeoutId);
    timeoutId = setTimeout(() => fn(...args), wait);
  };
};

export const debounced300 = debounce((fn) => fn(), 300);

export const generateRandomId = () =>
  '_' + Math.random().toString(36).substr(2, 9);

export const isCustomId = (id) => id?.startsWith('_');

export const isValidEmail = (email) => {
  const re = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
  return re.test(email);
};

export const isValidatePassword = (password) => {
  const re = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,32}$/;
  return re.test(password);
};

export const getAllEmailsFromText = (text) => {
  return text.match(/([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi);
};

export const strReplaceAll = (str = '', search = '', replacement = '') =>
  str.split(search).join(replacement);

export const arrayInsertNewItem = (arr, idx, newItem) => [
  ...arr.slice(0, idx),
  newItem,
  ...arr.slice(idx + 1),
];

export const arrayOfObjectReplaceItem = (arr, value, key = '_id') => {
  try {
    if (!Array.isArray(arr)) return [];
    return arr.map((item) => {
      if (item[key] === value[key]) {
        return value;
      }
      return item;
    });
  } catch (e) {
    return [];
  }
};

export const arrayOfObjectDeleteItem = (arr, value, key = '_id') => {
  try {
    return arr.filter((item) => item[key] !== value);
  } catch (e) {
    return [];
  }
};

export const isValidToken = () => {
  try {
    const token = localStorage.getItem('token');
    if (token) {
      const decodeJwt = jwt.decode(token);
      if (decodeJwt) {
        const exp = decodeJwt.exp;
        if (exp && exp * 1000 > Date.now()) {
          return true;
        }
      }
    }
    localStorage.removeItem('token');
    return false;
  } catch (e) {
    localStorage.removeItem('token');
    return false;
  }
};

export const getCurrentUserByToken = (
  token = localStorage.getItem('token'),
) => {
  try {
    if (token) {
      const decodeJwt = jwt.decode(token);
      if (decodeJwt) {
        return decodeJwt;
      }
    }
    return {};
  } catch (e) {
    return {};
  }
};

export const getSettingsDepartmentFields = (data, departmentId) => {
  try {
    if (!(Array.isArray(data) && data.length)) throw new Error();
    let item = {};
    if (!departmentId) {
      item = data[0];
    } else {
      item = data.find((item) => item._id === departmentId) || {};
    }

    const { checklists = [], tags = [], details = [] } = item;
    return { checklists, tags, details };
  } catch (e) {
    return { checklists: [], tags: [], details: [] };
  }
};

export const isInvalidDate = (date) => {
  return !date || date.toString() === 'Invalid Date';
};

export const getIosDateInfo = (date) => {
  if (!date || isInvalidDate(new Date(date))) {
    return {};
  }
  const [year, month, day] = date.substr(0, 10).split('-');
  return { year, month, day };
};

export const getDateByFormat = (date = Date.now(), format = '') => {
  try {
    let newDate = new Date(date);
    if (isInvalidDate(newDate)) {
      newDate = new Date();
    }
    const year = newDate.getFullYear();
    const month = ('0' + (newDate.getMonth() + 1)).slice(-2);
    const day = ('0' + newDate.getDate()).slice(-2);

    if (format === 'y-m-d') {
      return `${year}-${month}-${day}`;
    }
    if (format === 'm/d/y') {
      return `${month}/${day}/${year}`;
    }
    if (format === 'm-d-y') {
      return `${month}-${day}-${year}`;
    }
    if (format === 'd/F/y') {
      return `${day} ${MONTHS[newDate.getMonth()]} ${year}`;
    }
    if (format === 'F/d/y') {
      return `${MONTHS[newDate.getMonth()]} ${day}, ${year}`;
    }

    return `${day}-${month}-${year}`;
  } catch (e) {
    return '';
  }
};

export const getFullDate = (date, format = 'm/d/y') => {
  return getDateByFormat(date, format) + ' ' + formatAMPM(date);
};

export const getIosDateItems = (d) => {
  try {
    if (!d) throw new Error();
    const newDate = new Date(d);
    if (isInvalidDate(newDate)) {
      throw new Error();
    }

    const date = newDate.toISOString().substr(0, 10);
    const time = newDate.toISOString().substr(11, 5);
    const ampm = newDate.getHours() >= 12 ? 'pm' : 'am';

    return { date, time, ampm };
  } catch (e) {
    return {};
  }
};

export const getDate = (d) => {
  try {
    if (!d) throw new Error();
    const { date } = getIosDateItems(d);
    return date.split('-').reverse().join('/');
  } catch (e) {
    return '';
  }
};

export const formatAMPM = (d) => {
  try {
    const date = new Date(d);
    let hours = date.getHours();
    let minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12;
    minutes = minutes < 10 ? '0' + minutes : minutes;
    const strTime = hours + ':' + minutes + ampm;
    return strTime;
  } catch (e) {
    return '';
  }
};

export const getDateAmPm = (d) => {
  const { year, month, day } = getIosDateInfo(d);
  return `${formatAMPM(d)} ${month}/${day}/${year}`;
};

export const getDateInfo = (date) => {
  if (isInvalidDate(date)) {
    return {};
  }
  const year = date.getFullYear();
  const month = ('0' + (date.getMonth() + 1)).slice(-2);
  const day = ('0' + date.getDate()).slice(-2);
  const hours = date.getHours();
  const minutes = date.getMinutes();

  return { year, month, day, hours, minutes };
};

export const dateGreaterCompare = (d1, d2) => {
  const date1 = new Date(d1);
  const date2 = d2 ? new Date(d2) : new Date();

  return date1 > date2;
};

export const removeIdFromArrayOfObjects = (arr) => {
  try {
    return arr.map(({ _id, ...item }) => item);
  } catch (e) {
    return undefined;
  }
};

export const getItemByKeyFromArrayOfObjects = (arr, value, key = '_id') => {
  try {
    return arr.find((item) => item[key] === value);
  } catch (e) {
    return undefined;
  }
};

export const arrayOfObjectsMergeById = (arr, value, exclude) => {
  try {
    if (!Array.isArray(arr)) return arr;
    return arr.map((item) => {
      if (item._id === value._id) {
        if (exclude) {
          return { ...item, ...value, [exclude]: item[exclude] };
        }
        return { ...item, ...value };
      }
      return item;
    });
  } catch (e) {
    return arr;
  }
};

export const removeEmptyValueFromObject = (obj, allFalseValue = false) => {
  try {
    const object = {};
    for (let key in obj) {
      if (
        (allFalseValue && !!obj[key]) ||
        (obj[key] !== null && obj[key] !== undefined)
      ) {
        object[key] = obj[key];
      }
    }

    return object;
  } catch (e) {
    return obj;
  }
};

export const getErrorNotification = (error) => {
  if (error.response) {
    const { response } = error;
    const { status, data } = response;
    switch (status) {
      case 400: {
        return data.message || 'Unhandled 400';
      }
      case 401: {
        return 'Please re-login.';
      }
      case 403: {
        return `403 ${data.message || 'Access Denied'}`;
      }
      case 404: {
        return data.message || 'Not found';
      }
      case 422: {
        return `Validations error: ${data.message}`;
      }
      case 500: {
        if (data.message) {
          return `${data.message}`;
        } else {
          return `Unhandled error status code: ${status}`;
        }
      }
      default:
        return `Unhandled error status code: ${status}`;
    }
  } else {
    if (error && error.message === 'Network Error') {
      return 'Could not connect to server';
    }
    return 'Developer error, see console.';
  }
};

export const reduceUserName = (user = {}) => {
  try {
    const { firstName = '', lastName = '' } = user;
    return firstName + ' ' + (lastName[0] || '');
  } catch (e) {}
};

export const getShortUserName = (firstName = '', lastName = '') => {
  return (firstName[0] || '') + (lastName[0] || '');
};

export const getUserFullName = (user, text = '') => {
  const fullName = (user?.firstName || '') + ' ' + (user?.lastName || '');

  if (!fullName.trim()) {
    return text;
  }

  return fullName;
};

export const getUrlHostName = (url = '') => {
  try {
    return new URL(url).hostname;
  } catch (e) {
    return url;
  }
};

export const checkValidUrl = (url, includeWWW) => {
  if (includeWWW) {
    return /([^\S]|^)(((https?:\/\/)|(www\.))(\S+))/gi.test(url);
  }

  return /([^\S]|^)((https?:\/\/)(\S+))/gi.test(url);
};

export const createTextHyperLink = (text = '') => {
  try {
    const reg = /([^\S]|^)(((https?:\/\/)|(www\.))(\S+))/gi;
    return text.replace(reg, (match, space, url) => {
      let hyperlink = url;
      if (!hyperlink.match(/^https?:\/\//)) {
        hyperlink = 'http://' + hyperlink;
      }
      return ` <a href="${hyperlink}" target="_blank">${getUrlHostName(
        hyperlink,
      )}</a>`;
    });
  } catch (e) {
    return text;
  }
};

export const replaceHyperlinkToUrl = (text = '') => {
  try {
    const reg = /<a href=(['"])([^'"]+).+>(.+)<\/a>/gi;
    return text.replace(reg, (match, space, url) => {
      return url;
    });
  } catch (e) {
    return text;
  }
};

export const createEmailHyperlink = (text = '') => {
  try {
    return text.replace(
      /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi,
      function (email) {
        return `<a href="mailto: ${email}" target="_blank">${email}</a>`;
      },
    );
  } catch (e) {
    return text;
  }
};

export const userHasPagesAccess = (routs, path, role) => {
  try {
    const page = routs.find((item) => item.path === path);
    if (!page || !page.access) return true;
    return !!page.access.find((r) => r === role);
  } catch (e) {
    return false;
  }
};

export const planHasPageAccess = (company, path) => {
  const { companyPlanFeatures, isFreeTrial, exclusionRule } = company;
  if (exclusionRule || isFreeTrial) {
    return true;
  }

  const obj = {
    [ROUTES.assets]: PLAN_FEATURES.assetManager,
    [ROUTES.tickets]: PLAN_FEATURES.ticketingSystem,
    [ROUTES.passwords]: PLAN_FEATURES.passwordManager,
  };

  if (companyPlanFeatures && companyPlanFeatures[obj[path]]) {
    return true;
  }

  return false;
};

export const getNumberWithCommas = (num, digit = 3) => {
  try {
    if (!num) throw new Error();
    // const regExp = /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g
    const regExp = new RegExp(
      `\\B(?<!\\.\\d*)(?=(\\d{${digit}})+(?!\\d))`,
      'g',
    );
    return num.toFixed(2).replace(regExp, ',');
  } catch (e) {
    return '0.00';
  }
};

export const replaceAllBreaksToBr = (str) => {
  try {
    return str.replace(/(?:\r\n|\r|\n)/g, '<br>');
  } catch (e) {
    return '';
  }
};

export const getItemStatusInfo = (key) => {
  try {
    return STATUS_INFO[key] ? STATUS_INFO[key] : STATUS_INFO.notStarted;
  } catch (e) {
    return STATUS_INFO.notStarted;
  }
};

export function encrypt(text) {
  const iv = crypto.randomBytes(16);
  const cipher = crypto.createCipheriv(
    'aes-256-ctr',
    process.env.REACT_APP_PASSWORD_HASH_SECRET,
    iv,
  );
  const encrypted = Buffer.concat([cipher.update(text), cipher.final()]);
  return {
    iv: iv.toString('hex'),
    content: encrypted.toString('hex'),
  };
}

export function decrypt(hash) {
  const decipher = crypto.createDecipheriv(
    'aes-256-ctr',
    process.env.REACT_APP_PASSWORD_HASH_SECRET,
    Buffer.from(hash.iv, 'hex'),
  );
  const decrypted = Buffer.concat([
    decipher.update(Buffer.from(hash.content, 'hex')),
    decipher.final(),
  ]);

  return decrypted.toString();
}

export const getTagsByType = (tags = [], type = TAG_TYPES.default) => {
  if (Array.isArray(type)) {
    return tags.filter((item) => type.includes(item.type));
  }
  return tags.filter((item) => item.type === type);
};

export function getDateLabel(d) {
  const yesterday = new Date();
  const { date, time, ampm } = getIosDateItems(d);

  yesterday.setDate(yesterday.getDate() - 1);

  if (new Date().toDateString() === new Date(d).toDateString()) {
    return time + ' ' + ampm;
  }

  if (yesterday.toDateString() === new Date(d).toDateString()) {
    return 'Yesterday at ' + time + ' ' + ampm;
  }

  return date + ' ' + time + ' ' + ampm;
}

export function getNumericSelectOptions(num, start = 1) {
  const options = [];
  for (let i = start; i <= num; i++) {
    options.push({ label: i, value: i });
  }
  return options;
}

export function getRandomString(length = 8) {
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';

  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }

  return result;
}

export function toBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

export const getPlanFeatures = (plan) => {
  if (!plan) {
    return {};
  }
  const data = {};
  for (const key in PLAN_FEATURES) {
    data[key] = plan[key];
  }

  return data;
};

export const reduceArraytoObjectByKey = (arr, key = '_id') => {
  arr.reduce((acc, cur) => {
    acc[cur[key]] = cur;
    return acc;
  }, {});
};

export const checkPlanPermissionForBoard = (company, board, boardCount) => {
  const { isFreeTrial, isActiveSubscription, plan, exclusionRule } = company;
  return (
    exclusionRule ||
    isFreeTrial ||
    (isActiveSubscription && plan && plan[board] > boardCount)
  );
};
