import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { message } from 'antd';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from 'react-bootstrap-table2-paginator';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { Spinner } from 'react-bootstrap';
import BoardData from './components';
import SetBoard from './components/setBoard';
import Actions from './components/actions';
import Filters from './components/filters';
import { ConfirmModal, UserInfo } from '../shared';
import { getBoardsStart, updateBoardStart } from '../../redux/boards/actions';
import {
  checkGSuiteSync,
  gSuiteCreateAccount,
  syncMergeDev,
  fetchMergeDevSyncedAts,
} from '../../service';
import {
  getIosDateInfo,
  checkPlanPermissionForBoard,
} from '../../helpers/helpers';
import { BOARD_TYPE, ROLE_VALUES, TAG_TYPES } from '../../helpers/variables';
import { SyncOutlined } from '../../Icons';
import styles from './table.module.scss';

function BoardTable({ title, board, locations }) {
  const dispatch = useDispatch();
  const { currentUserRole, company } = useSelector((state) => state.global);
  const { boards } = useSelector((state) => state);
  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(5);
  const [show, setShow] = useState(false);
  const [syncLoading, setSyncLoading] = useState(false);
  const [isMergeDevSynced, setMergeDevSynced] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [showContactInfo, setShowContactInfo] = useState(false);
  const [filter, setFilter] = useState({});
  const [row, setRow] = useState({});
  const [googleSync, setGoogleSync] = useState({});
  const { data, total: totalSize = 0, loading } = boards[board];
  const isActivePlan = checkPlanPermissionForBoard(company, board, totalSize);
  const isEditable =
    isActivePlan &&
    [ROLE_VALUES.admin, ROLE_VALUES.hr].includes(currentUserRole);

  useEffect(() => {
    dispatch(getBoardsStart({ board, offset: 0, limit: sizePerPage }));
    checkGSuiteSync()
      .then(setGoogleSync)
      .catch(() => {});
    if (board === BOARD_TYPE.onboard) {
      fetchMergeDevSyncedAts()
        .then((res) => setMergeDevSynced(!!Object.keys(res).length))
        .catch(() => {});
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const columns = [
    {
      dataField: 'date',
      text: 'Date',
      headerStyle: () => ({ width: '105px' }),
      formatter: (date) => {
        const { year, month, day } = getIosDateInfo(date);
        return year && <div>{`${month}-${day}-${year}`}</div>;
      },
    },
    {
      dataField: 'name',
      text: 'Name',
      headerStyle: () => ({ width: '148px' }),
      formatter: (cell) =>
        cell && cell._id
          ? `${cell.firstName || ''} ${cell.lastName || ''}`
          : '',
    },
    {
      dataField: 'title',
      text: 'Title',
    },
    {
      dataField: 'manager',
      text: 'Manager',
      headerStyle: () => ({ width: '148px' }),
      formatter: (cell) =>
        cell && cell._id
          ? `${cell.firstName || ''} ${cell.lastName || ''}`
          : '',
    },
    {
      dataField: 'department.name',
      text: 'Department',
    },
    {
      dataField: 'employmentType',
      text: 'Employment Type',
      headerStyle: () => ({ width: '151px' }),
    },
    {
      dataField: '',
      text: '',
      headerFormatter: () => (
        <Filters locations={locations} onFilter={handleFilter} />
      ),
      headerStyle: () => {
        return { width: '30px' };
      },
      formatter: (cell, row) => (
        <div className="d-flex justify-content-between mt-2">
          {isEditable ? (
            <Actions
              data={row}
              googleSync={googleSync}
              onSelect={({ key, domEvent }) => handleClick(row, key, domEvent)}
            />
          ) : (
            ''
          )}
        </div>
      ),
    },
  ];
  const options = {
    page,
    totalSize,
    sizePerPage,
    custom: true,
    paginationSize: 4,
    pageStartIndex: 1,
    alwaysShowAllBtns: true,
    withFirstAndLast: false,
    hidePageListOnlyOnePage: true,
    prePageText: '<',
    nextPageText: '>',
    showTotal: true,
    disablePageTitle: true,
    hideSizePerPage: true,
  };

  const expandRow = {
    onlyOneExpanding: true,
    renderer: (row) => (
      <BoardData
        board={row}
        googleSync={googleSync}
        isEditable={isEditable}
        role={currentUserRole}
      />
    ),
    parentClassName: styles.expandRow,
    className: `cursor-default ${isEditable ? '' : 'pointer-events-none'} ${
      styles.expendedRowContent
    }`,
    showExpandColumn: true,
    expandColumnPosition: 'right',
    expandHeaderColumnRenderer: ({ isAnyExpands }) => {
      if (isAnyExpands) {
        return;
      }
      return;
    },
    expandColumnRenderer: ({ expanded, rowKey }) => {
      if (expanded) {
        return;
      }
      return;
    },
  };

  const handleClick = (row, action, e) => {
    e.preventDefault();
    e.stopPropagation();

    if (action === 'archive') {
      setShowConfirm(true);
    } else if (action === 'edit') {
      setShow(true);
    } else if (action === 'contact') {
      setShowContactInfo(true);
    } else if (action === 'createGoogleAccount') {
      const data = {
        title: row.title,
        company: row.company,
        location: row.location,
        userId: row.name?._id,
        firstName: row.name?.firstName,
        lastName: row.name?.lastName,
        departmentName: row.department?.name,
        groups: row.tags
          ?.find((tag) => tag.type === TAG_TYPES.googleGroups)
          ?.fields.map((item) => item.value),
        folders: row.tags
          ?.find((tag) => tag.type === TAG_TYPES.googleDriveFolders)
          ?.fields.map((item) => item.value),
        sharedFolders: row.tags
          ?.find((tag) => tag.type === TAG_TYPES.googleDriveSharedFolders)
          ?.fields.map((item) => item.value),
      };
      gSuiteCreateAccount(data)
        .then(() => message.success('Google account created'))
        .catch();
    }
    setRow(row);
  };

  const handleDelete = () => {
    if (!row._id) return;
    // dispatch(deleteBoardStart(row._id));
    dispatch(updateBoardStart({ _id: row._id, status: 'archived' }));
  };

  const handleClose = () => {
    setRow({});
    setShow(false);
    setShowConfirm(false);
    setShowContactInfo(false);
  };

  const handleTableChange = (type, { page, sizePerPage, searchText }) => {
    const offset = (page - 1) * sizePerPage;

    if (type === 'search') {
      if (searchText && searchText.length < 3) return;
      setPage(1);
      setSizePerPage(sizePerPage);
    } else {
      setPage(page);
      setSizePerPage(sizePerPage);
    }
    dispatch(
      getBoardsStart({
        board,
        offset: offset,
        limit: sizePerPage,
        search: searchText,
        ...filter,
      }),
    );
  };

  const handleFilter = ({ search, status, location }) => {
    const offset = (page - 1) * sizePerPage;
    setPage(1);

    dispatch(
      getBoardsStart({
        board,
        offset: offset,
        limit: sizePerPage,
        search,
        status,
        location,
      }),
    );
    setFilter({ search, status, location });
  };

  const handleSyncBoard = () => {
    if (syncLoading) {
      return;
    }
    setSyncLoading(true);
    syncMergeDev()
      .then(() =>
        dispatch(getBoardsStart({ board, offset: 0, limit: sizePerPage })),
      )
      .catch(() => {})
      .finally(() => setSyncLoading(false));
  };

  return (
    <div className={styles.boardTable}>
      <PaginationProvider pagination={paginationFactory(options)}>
        {({ paginationProps, paginationTableProps }) => (
          <ToolkitProvider
            keyField="_id"
            data={data}
            columns={columns}
            search
            bootstrap4
          >
            {(props) => (
              <div className={styles.content}>
                <div className="d-flex justify-content-between align-items-center">
                  <div className={styles.title}>
                    {title}
                    {isActivePlan &&
                      board === BOARD_TYPE.onboard &&
                      isMergeDevSynced && (
                        <SyncOutlined
                          onClick={handleSyncBoard}
                          spin={syncLoading}
                        />
                      )}
                  </div>
                </div>
                <BootstrapTable
                  remote
                  bordered={false}
                  loading={loading}
                  rowStyle={
                    isActivePlan
                      ? undefined
                      : { opacity: 0.6, pointerEvents: 'none' }
                  }
                  wrapperClasses={styles.bootstrapTable}
                  expandRow={
                    isActivePlan
                      ? expandRow
                      : {
                          renderer: () => <div />,
                        }
                  }
                  onTableChange={handleTableChange}
                  {...paginationTableProps}
                  noDataIndication={
                    loading ? (
                      <Spinner animation="border" variant="primary" />
                    ) : (
                      'There is no data to show'
                    )
                  }
                  {...props.baseProps}
                />
                <div className={styles.paginationContent}>
                  <div>
                    <SizePerPageDropdownStandalone {...paginationProps} />
                  </div>
                  <PaginationListStandalone {...paginationProps} />
                </div>
              </div>
            )}
          </ToolkitProvider>
        )}
      </PaginationProvider>

      {show && <SetBoard data={row} onClose={handleClose} />}

      {showContactInfo && (
        <UserInfo
          id={row.name ? row.name._id : ''}
          handleClose={handleClose}
          show={showContactInfo}
        />
      )}

      {showConfirm && (
        <ConfirmModal
          show={showConfirm}
          handleClose={handleClose}
          handleConfirm={handleDelete}
        />
      )}
    </div>
  );
}

export default BoardTable;
