import React from 'react';
import { getShortUserName, getUserFullName } from '../../../helpers/helpers';
import { Wrapper, Avatar, UserInfo } from './styles';

function UserAvatar({ user, email = false, style }) {
  const { firstName = '', lastName = '', image } = user || {};

  return (
    <Wrapper>
      {image ? (
        <Avatar src={image} />
      ) : (
        <Avatar>{getShortUserName(firstName, lastName)}</Avatar>
      )}
      <UserInfo style={style}>
        <div title={getUserFullName({ firstName, lastName })}>
          {getUserFullName({ firstName, lastName })}
        </div>
        {email ? <div title={user.email}>{user.email}</div> : ''}
      </UserInfo>
    </Wrapper>
  );
}

export default UserAvatar;
