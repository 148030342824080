import React from 'react';
import CookieConsent from 'react-cookie-consent';

export default function () {
    return (
        <CookieConsent
            flipButtons
            enableDeclineButton
            setDeclineCookie={false}
            buttonText="Accept"
            declineButtonText="Decline"
            cookieName="onhire_cookie_accept"
            // onAccept={() => console.log('accept')}
            // onDecline={() => console.log('decline')}
            buttonStyle={{
                textAlign: 'center',
                borderRadius: '20px',
                lineHeight: '30px',
                background: '#fff',
                border: 'none',
                padding: '0 25px'
            }}
            declineButtonStyle={{
                textAlign: 'center',
                borderRadius: '20px',
                lineHeight: '30px',
                border: 'none',
                padding: '0 25px'
            }}
        >
            This website uses cookies to enhance the user experience.
        </CookieConsent>
    );
}
