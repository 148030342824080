import React from 'react';
import { useSelector } from 'react-redux';
import { getUsersList } from '../../../redux/users/selectors';
import { Select } from '../styles';

function UserSelect({ showEmail, showDirectoryEmail, ...props }) {
  const users = useSelector(getUsersList);

  const getLabel = (user) => {
    const { firstName = '', lastName = '' } = user;
    let email = user.email || user.personalEmail;
    if (
      showDirectoryEmail &&
      (user.googleDirectoryEmail || user.office365DirectoryEmail)
    ) {
      email = user.googleDirectoryEmail || user.office365DirectoryEmail;
    }
    if ((showEmail || showDirectoryEmail) && email) {
      return `${firstName} ${lastName} (${email})`;
    }
    return `${firstName} ${lastName}`;
  };

  return (
    <Select {...props}>
      {users.map((item, idx) => (
        <Select.Option key={idx} value={item._id || ''}>
          {getLabel(item)}
        </Select.Option>
      ))}
    </Select>
  );
}

export default UserSelect;
