import React, { useState } from 'react';
import styled from 'styled-components';
import { Divider, Input, Space, Button as AntdButton } from 'antd';
import { PlusOutlined } from '../../../Icons';
import { Select } from '../styles';
const { Option } = Select;

const Button = styled(AntdButton)`
  display: flex;
  align-items: center;
`;

function DropdownAddNewItem({ onAdd, options, ...props }) {
  const [name, setName] = useState('');

  const handleAddItem = (e) => {
    e.preventDefault();
    if (!name) {
      return;
    }
    onAdd([...options, name]);
    setName('');
  };
  return (
    <Select
      {...props}
      getPopupContainer={(triggerNode) => triggerNode.parentElement}
      dropdownRender={(menu) => (
        <>
          {menu}
          {onAdd && (
            <>
              <Divider
                style={{
                  margin: '8px 0',
                }}
              />
              <Space
                style={{
                  padding: '0 8px 4px',
                }}
              >
                <Input
                  placeholder={'Please enter item'}
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
                <Button
                  type="text"
                  icon={<PlusOutlined />}
                  onClick={handleAddItem}
                >
                  Add item
                </Button>
              </Space>
            </>
          )}
        </>
      )}
    >
      {options.map((item) => (
        <Option key={item}>{item}</Option>
      ))}
    </Select>
  );
}

export default DropdownAddNewItem;
