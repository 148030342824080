import React from 'react';
import withIcon from '../withIcon';

const VendorManagerIcon = () => {
  return (
    <svg
      width="42"
      height="40"
      viewBox="0 0 42 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="28.3574" cy="18.5" r="13.5" fill="#C956FF" />
      <path
        d="M30.9998 11.9335C30.8998 11.9168 30.7831 11.9168 30.6831 11.9335C28.3831 11.8502 26.5498 9.96683 26.5498 7.6335C26.5498 5.25017 28.4665 3.3335 30.8498 3.3335C33.2331 3.3335 35.1498 5.26683 35.1498 7.6335C35.1331 9.96683 33.2998 11.8502 30.9998 11.9335Z"
        stroke="#292D32"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M29.2834 24.0666C31.5667 24.4499 34.0834 24.0499 35.8501 22.8666C38.2001 21.2999 38.2001 18.7333 35.8501 17.1666C34.0667 15.9833 31.5167 15.5833 29.2334 15.9833"
        stroke="#292D32"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.9508 11.9335C11.0508 11.9168 11.1674 11.9168 11.2674 11.9335C13.5674 11.8502 15.4008 9.96683 15.4008 7.6335C15.4008 5.25017 13.4841 3.3335 11.1008 3.3335C8.71745 3.3335 6.80078 5.26683 6.80078 7.6335C6.81745 9.96683 8.65078 11.8502 10.9508 11.9335Z"
        stroke="#292D32"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.6671 24.0666C10.3837 24.4499 7.86706 24.0499 6.10039 22.8666C3.75039 21.2999 3.75039 18.7333 6.10039 17.1666C7.88372 15.9833 10.4337 15.5833 12.7171 15.9833"
        stroke="#292D32"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.9998 24.3832C20.8998 24.3665 20.7831 24.3665 20.6831 24.3832C18.3831 24.2999 16.5498 22.4165 16.5498 20.0832C16.5498 17.6999 18.4665 15.7832 20.8498 15.7832C23.2331 15.7832 25.1498 17.7165 25.1498 20.0832C25.1331 22.4165 23.2998 24.3165 20.9998 24.3832Z"
        stroke="#292D32"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.1502 29.6331C13.8002 31.1998 13.8002 33.7664 16.1502 35.3331C18.8169 37.1164 23.1835 37.1164 25.8502 35.3331C28.2002 33.7664 28.2002 31.1998 25.8502 29.6331C23.2002 27.8664 18.8169 27.8664 16.1502 29.6331Z"
        stroke="#292D32"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default withIcon(VendorManagerIcon);
