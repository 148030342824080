import styled from 'styled-components';
import {
  DatePicker as AntdDatePicker,
  Input as AntdInput,
  InputNumber as AntdInputNumber,
  Select as AntdSelect,
} from 'antd';

export const Container = styled.div`
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;

  @media (min-width: 576px) {
    max-width: 540px;
  }

  @media (min-width: 768px) {
    max-width: 720px;
  }

  @media (min-width: 992px) {
    max-width: 960px;
  }
  @media (min-width: 1200px) {
    max-width: 1140px;
  }
`;

export const FlexBetween = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

//components

export const Input = styled(AntdInput).attrs({ size: 'large' })`
  border-radius: 4px;
`;

export const InputNumber = styled(AntdInputNumber).attrs({ size: 'large' })`
  border-radius: 4px;
  width: 100%;
`;

export const DatePicker = styled(AntdDatePicker).attrs({ size: 'large' })`
  border-radius: 4px;
  width: 100%;
`;

export const Select = styled(AntdSelect).attrs({
  size: 'large',
  getPopupContainer: (triggerNode) => triggerNode.parentElement,
})`
  width: 100%;

  .ant-select-selector {
    border-radius: 4px !important;
  }
`;

export const TextArea = styled(AntdInput.TextArea).attrs({ size: 'large' })`
  border-radius: 4px !important;
`;
