import { useRef, useEffect, useState } from 'react';

export function usePrevious(value) {
  const ref = useRef(null);
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

export function useOutsideClick(ref, callback) {
  useEffect(() => {
    const onKeyUp = (e) => {
      if (e.key === 'Escape' || e.keyCode === 27) {
        callback();
      }
    };

    const handleClickOutside = (e) => {
      if (ref && !ref.current.contains(e.target)) {
        callback();
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('keyup', onKeyUp);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keyup', onKeyUp);
    };
  }, [ref.current]); // eslint-disable-line react-hooks/exhaustive-deps
}

export function useMediaQuery(query) {
  const [matches, setMatches] = useState(false);

  useEffect(() => {
    const media = window.matchMedia(query);
    if (media.matches !== matches) {
      setMatches(media.matches);
    }
    const listener = () => setMatches(media.matches);
    window.addEventListener('resize', listener);
    return () => window.removeEventListener('resize', listener);
  }, [matches, query]);

  return matches;
}
