import styled from 'styled-components';
import { Badge as AntdBadge } from 'antd';
import { Link as DomLink } from 'react-router-dom';

export const Wrapper = styled.div`
  position: relative;
`;

export const Badge = styled(AntdBadge)`
  margin-right: 16px;
`;

export const DropdownWrapper = styled.div`
  position: absolute;
  width: 426px;
  top: 53px;
  left: -112px;
  background: #ffffff;
  border: 2px solid #f5f8fe;
  box-shadow: 0 4px 4px rgb(0 0 0 / 25%);
  border-radius: 8px;
  padding: 5px;
  z-index: 999;
  max-height: 550px;
  overflow-y: auto;
`;

export const Title = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #313131;
  text-align: center;
  padding: 16px;

  &:first-child {
    border-bottom: 2px solid #f6f7f9;
  }
`;

export const Notification = styled.div`
  padding: 7px 13px;
  border-bottom: 2px solid #f6f7f9;
  margin-bottom: 7px;

  ${(props) => props.isNew && `background: #F9F1FC;`}
`;

export const Message = styled.div`
  width: 315px;
`;

export const DateTime = styled.div`
  text-align: right;
`;

export const BoldText = styled.div`
  font-size: 16px;
  line-height: 19px;
  font-weight: 700;
  color: #292d32;
  display: flex;
  align-items: center;
  grid-gap: 12px;

  span {
    width: 7px;
    height: 7px;
    border-radius: 50%;
  }
`;

export const Text = styled.div`
  font-size: 16px;
  line-height: 19px;
  color: #313131;

  ${BoldText} {
    display: inline;
  }
`;

export const Link = styled(DomLink)`
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
`;

export const NoData = styled(Text)`
  text-align: center;
  padding: 16px;
`;
