import React from 'react';
import { Modal } from 'antd';

function ConfirmModal({
  show = false,
  title = 'Delete',
  text = 'Are you sure you wish to delete ?',
  handleConfirm,
  handleClose,
}) {
  return (
    <Modal
      open={show}
      title={title}
      onOk={() => {
        handleConfirm();
        handleClose();
      }}
      onCancel={handleClose}
      okText="Yes"
      cancelText="Cancel"
    >
      {text}
    </Modal>
  );
}

export default ConfirmModal;
