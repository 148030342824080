import React from 'react';
import withIcon from '../withIcon';

const ForwardIcon = () => {
  return (
    <svg
      width="1em"
      height="0.8em"
      viewBox="0 0 20 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.39132 15.5652C1.31306 15.5652 1.20871 15.5652 1.13045 15.5392C0.660883 15.4087 0.347839 15.0174 0.347839 14.5218C0.347839 14.3652 0.373926 10.3739 3.34784 7.40003C5.25219 5.49568 7.86088 4.45221 11.1478 4.2696V1.47829C11.1478 1.0609 11.4087 0.669597 11.8 0.513075C12.1913 0.356553 12.6348 0.434815 12.9478 0.747858L19.3652 7.2696C19.7565 7.68699 19.7565 8.33916 19.3652 8.73047L12.9478 15.2522C12.6609 15.5652 12.1913 15.6435 11.8 15.487C11.4087 15.3305 11.1478 14.9392 11.1478 14.5218V11.7044C9.06088 11.6522 4.10436 11.8609 2.30436 15.0435C2.12175 15.3566 1.75653 15.5652 1.39132 15.5652ZM10.5739 9.59134C11.5391 9.59134 12.2174 9.6696 12.2957 9.6696C12.8174 9.72177 13.2348 10.1652 13.2348 10.7131V11.9652L17.1478 8.00003L13.2348 4.03481V5.28699C13.2348 5.8609 12.7652 6.33047 12.1913 6.33047C8.98262 6.33047 6.50436 7.19134 4.8348 8.88699C4.07827 9.64351 3.55653 10.5044 3.19132 11.3131C5.6174 9.85221 8.69567 9.59134 10.5739 9.59134Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default withIcon(ForwardIcon);
