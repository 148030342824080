import React, { useState } from 'react';
import { ButtonGroup, DropdownButton, Dropdown } from 'react-bootstrap';
import repeat from '../../../assets/images/arrow-repeat.svg';
import layoutSplit from '../../../assets/images/layout-split.svg';
import downloadIcon from '../../../assets/images/download-icon.svg';
import { Button, Select, BulkActionsWrap } from './styles';
import styles from './table.module.scss';

const TableToolbar = ({
  showToolbar = true,
  searchProps,
  columnToggleProps,
  csvProps,
  refreshData,
  onBulkAction,
  disabled,
  options = ['edit', 'delete'],
  Actions,
}) => {
  const [action, setAction] = useState('');
  const { onSearch, searchText } = searchProps;
  const { onExport } = csvProps;
  const { columns, onColumnToggle, toggles } = columnToggleProps;

  const handleRefresh = () => {
    if (searchText) {
      onSearch('');
    } else {
      refreshData();
    }
  };

  if (!showToolbar) {
    return null;
  }

  return (
    <div
      className={styles.tableToolbar}
      style={{ justifyContent: onBulkAction ? 'space-between' : 'end' }}
    >
      {onBulkAction && (
        <BulkActionsWrap>
          <Select value={action} onChange={setAction}>
            <Select.Option value=""></Select.Option>
            {options.map((item, idx) => (
              <Select.Option key={idx} value={item}>
                {item}
              </Select.Option>
            ))}
          </Select>
          <Button
            type="primary"
            htmlType="submit"
            disabled={disabled || !action}
          >
            Go
          </Button>
        </BulkActionsWrap>
      )}
      <div className="d-sm-flex">
        <div className={styles.search}>
          <input
            name="search"
            aria-label="search"
            type="text"
            value={searchText}
            placeholder="Search"
            onChange={(e) => onSearch(e.target.value)}
          />
        </div>
        {Actions ? (
          <Actions />
        ) : (
          <ButtonGroup className={styles.btnGroups}>
            <Button variant="light" onClick={handleRefresh}>
              <img src={repeat} alt="icon" />
            </Button>
            <DropdownButton
              alignRight
              variant="light"
              as={ButtonGroup}
              title={<img src={layoutSplit} alt="icon" />}
            >
              {columns.map((column, idx) => (
                <Dropdown.Item
                  key={column.dataField + idx}
                  eventKey={column.dataField + idx}
                  onClick={() => onColumnToggle(column.dataField)}
                >
                  <input
                    type="checkbox"
                    id={`dropdownCheck${column.dataField}`}
                    checked={toggles[column.dataField]}
                    onChange={() => {}}
                  />
                  <label
                    className="form-check-label"
                    htmlFor={`dropdownCheck${column.dataField}`}
                  >
                    {column.text}
                  </label>
                </Dropdown.Item>
              ))}
            </DropdownButton>
            <Button variant="light" onClick={() => onExport()}>
              {<img src={downloadIcon} alt="icon" />}
            </Button>
          </ButtonGroup>
        )}
      </div>
    </div>
  );
};

export default TableToolbar;
