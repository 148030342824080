import React, { useState } from 'react';
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
} from '@stripe/react-stripe-js';
import { stripePay } from '../../service';

const options = {
  style: {
    base: {
      fontSize: '18px',
      color: '#424770',
      letterSpacing: '0.025em',
      '::placeholder': {
        color: '#aab7c4',
      },
    },
    invalid: {
      color: '#9e2146',
    },
  },
};

const Checkout = () => {
  const stripe = useStripe();
  const elements = useElements();
  const [validate, setValidate] = useState({
    email: false,
    name: false,
    number: false,
    expiry: false,
    cvc: false,
  });
  const [billingDetails, setBillingDetails] = useState({
    email: '',
    name: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setBillingDetails((prev) => ({ ...prev, [name]: value }));
  };

  const handleFocus = (e) => {
    if (!e.target && e.elementType) {
      const field = e.elementType.replace('card', '').toLowerCase();
      setValidate((prev) => ({ ...prev, [field]: false }));
      return;
    }
    const { name } = e.target;
    setValidate((prev) => ({ ...prev, [name]: false }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!billingDetails.email || !billingDetails.name) {
      setValidate((prev) => ({
        ...prev,
        name: !billingDetails.name,
        email: !billingDetails.email,
      }));
      return;
    }

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardNumberElement),
      billing_details: billingDetails,
    });
    if (error) {
      const field = error.code.replace('incomplete_', '');
      setValidate((prev) => ({
        ...prev,
        [field]: true,
      }));
      return;
    }

    const data = await stripePay({
      pmId: paymentMethod.id,
      amount: 5,
      plan: 'plan-1',
    });
    console.log('data: ', data);
  };

  return (
    <form onSubmit={handleSubmit}>
      <label style={{color: validate.name ? 'red' : 'inherit'}} >
        Full Name*
        <input
          name="name"
          placeholder="Jane Doe"
          value={billingDetails.name}
          onChange={handleChange}
          onFocus={handleFocus}
        />
      </label>
      <label style={{color: validate.email ? 'red' : 'inherit'}}>
        Email*
        <input
          type="email"
          name="email"
          placeholder="janedoe@gmail.com"
          value={billingDetails.email}
          onChange={handleChange}
          onFocus={handleFocus}
        />
      </label>
      <label style={{color: validate.number ? 'red' : 'inherit'}}>
        Card number
        <CardNumberElement options={options} onFocus={handleFocus} />
      </label>
      <label style={{color: validate.expiry ? 'red' : 'inherit'}}>
        Expiration date
        <CardExpiryElement options={options} onFocus={handleFocus} />
      </label>
      <label style={{color: validate.cvc ? 'red' : 'inherit'}}>
        CVC
        <CardCvcElement options={options} onFocus={handleFocus} />
      </label>
      <button type="submit" disabled={!stripe}>
        Pay
      </button>
    </form>
  );
};

export default Checkout;
