import styled from 'styled-components';
import { Button as AntdButton, Select as AntdSelect } from 'antd';

export const Button = styled(AntdButton).attrs({ size: 'large' })`
  border-radius: 6px;
`;

export const Select = styled(AntdSelect).attrs({
  getPopupContainer: (triggerNode) => triggerNode.parentElement,
  size: 'large',
})`
  width: 212px;
  text-transform: capitalize;

  & > div {
    border-radius: 6px !important;
  }
`;

export const BulkActionsWrap = styled.div`
  display: flex;
  align-items: center;
  grid-gap: 6px;
`;
