import { tagsTypes } from './types';

export const getTagsStart = (payload) => ({
    type: tagsTypes.GET_TAGS_START,
    payload,
});

export const getTagsSuccess = (data) => ({
    type: tagsTypes.GET_TAGS_SUCCESS,
    data,
});

export const getTagsFailure = (error) => ({
    type: tagsTypes.GET_TAGS_FAILURE,
    error,
});

export const createTagStart = (payload) => ({
    type: tagsTypes.CREATE_TAG_START,
    payload,
});

export const createTagSuccess = (data) => ({
    type: tagsTypes.CREATE_TAG_SUCCESS,
    data,
});

export const createTagFailure = (error) => ({
    type: tagsTypes.CREATE_TAG_FAILURE,
    error,
});

export const updateTagStart = (payload) => ({
    type: tagsTypes.UPDATE_TAG_START,
    payload,
});

export const updateTagSuccess = (data) => ({
    type: tagsTypes.UPDATE_TAG_SUCCESS,
    data,
});

export const updateTagFailure = (error) => ({
    type: tagsTypes.UPDATE_TAG_FAILURE,
    error,
});

export const deleteTagStart = (id, tagId) => ({
    type: tagsTypes.DELETE_TAG_START,
    tagId,
    id,
});

export const deleteTagSuccess = (data) => ({
    type: tagsTypes.DELETE_TAG_SUCCESS,
    data,
});

export const deleteTagFailure = (error) => ({
    type: tagsTypes.DELETE_TAG_FAILURE,
    error,
});

export const deleteTagsStart = (id) => ({
    type: tagsTypes.DELETE_TAGS_START,
    id,
});

export const deleteTagsSuccess = (data) => ({
    type: tagsTypes.DELETE_TAGS_SUCCESS,
    data,
});

export const deleteTagsFailure = (error) => ({
    type: tagsTypes.DELETE_TAGS_FAILURE,
    error,
});

export const updateTagFieldStart = (payload) => ({
    type: tagsTypes.UPDATE_TAG_FIELD_START,
    payload
});
