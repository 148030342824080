import { checklistsTypes } from './types';
import { arrayOfObjectDeleteItem, arrayOfObjectReplaceItem } from '../../helpers/helpers';

const initialState = {
    checklists: [],
    error: {
        getAll: '',
        create: '',
        deleted: '',
        updated: '',
    },
    loading: false,
    isLoading: false,
    created: {},
    deleted: {},
    updated: {},
};

export const checklists = (state = initialState, action) => {
    switch (action.type) {
        case checklistsTypes.GET_CHECKLISTS_START:
            return {
                ...state,
                loading: true,
                error: { ...state.error, getAll: '' },
            };
        case checklistsTypes.GET_CHECKLISTS_SUCCESS:
            return {
                ...state,
                checklists: action.data,
                loading: false,
            };
        case checklistsTypes.GET_CHECKLISTS_FAILURE:
            return {
                ...state,
                checklists: [],
                loading: false,
                error: { ...state.error, getAll: action.error },
            };
        case checklistsTypes.CREATE_CHECKLISTS_START:
            return {
                ...state,
                isLoading: true,
                deleted: {},
                updated: {},
                error: { ...state.error, create: '' },
            };
        case checklistsTypes.CREATE_CHECKLISTS_SUCCESS:
            return {
                ...state,
                created: action.data,
                checklists: [...state.checklists, action.data],
                isLoading: false,
            };
        case checklistsTypes.CREATE_CHECKLISTS_FAILURE:
            return {
                ...state,
                created: {},
                isLoading: false,
                error: { ...state.error, create: action.error },
            };
        case checklistsTypes.DELETE_CHECKLIST_START:
            return {
                ...state,
                isLoading: true,
                created: {},
                updated: {},
                error: { ...state.error, deleted: '' },
            };
        case checklistsTypes.DELETE_CHECKLIST_SUCCESS:
            return {
                ...state,
                deleted: action.data,
                checklists: [arrayOfObjectDeleteItem(state.checklists, action.data._id)],
                isLoading: false,
            };
        case checklistsTypes.DELETE_CHECKLIST_FAILURE:
            return {
                ...state,
                deleted: {},
                isLoading: false,
                error: { ...state.error, deleted: action.error },
            };
        case checklistsTypes.UPDATE_CHECKLIST_START:
            return {
                ...state,
                isLoading: true,
                created: {},
                deleted: {},
                error: { ...state.error, deleted: '' },
            };
        case checklistsTypes.UPDATE_CHECKLIST_SUCCESS:
            return {
                ...state,
                updated: action.data,
                checklists: [...arrayOfObjectReplaceItem(state.checklists, action.data)],
                isLoading: false,
            };
        case checklistsTypes.UPDATE_CHECKLIST_FAILURE:
            return {
                ...state,
                updated: {},
                isLoading: false,
                error: { ...state.error, updated: action.error },
            };
        default:
            return state;
    }
};
