import { dashboardTypes } from './types';

export const getDashboardStart = (payload) => ({
    type: dashboardTypes.GET_DASHBOARD_START,
    payload,
});

export const getDashboardSuccess = (data) => ({
    type: dashboardTypes.GET_DASHBOARD_SUCCESS,
    data,
});

export const getDashboardFailure = (error) => ({
    type: dashboardTypes.GET_DASHBOARD_FAILURE,
    error,
});

