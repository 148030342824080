import { passwordsTypes } from './types';

export const getPasswordsStart = (payload) => ({
    type: passwordsTypes.GET_PASSWORDS_START,
    payload,
});

export const getPasswordsSuccess = (data) => ({
    type: passwordsTypes.GET_PASSWORDS_SUCCESS,
    data,
});

export const getPasswordsFailure = (error) => ({
    type: passwordsTypes.GET_PASSWORDS_FAILURE,
    error,
});

export const getPasswordsFoldersStart = () => ({
    type: passwordsTypes.GET_PASSWORDS_FOLDERS_START,
});

export const getPasswordsFoldersSuccess = (data) => ({
    type: passwordsTypes.GET_PASSWORDS_FOLDERS_SUCCESS,
    data,
});

