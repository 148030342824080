import { put, call, takeLatest } from 'redux-saga/effects';
import * as service from '../../service';
import { detailsTypes } from './types';
import {
  getDetailsSuccess,
  getDetailsFailure,
  createDetailSuccess,
  createDetailFailure,
  updateDetailSuccess,
  updateDetailFailure,
  deleteDetailSuccess,
  deleteDetailFailure,
  deleteDetailFieldSuccess,
  deleteDetailFieldFailure,
} from './actions';

export function* fetchDetails({ payload }) {
  try {
    const data = yield call(() => {
      return service.fetchDepartmentDetails(payload);
    });
    yield put(getDetailsSuccess(data));
  } catch (e) {
    yield put(getDetailsFailure(e.message));
  }
}

export function* createDetail({ payload }) {
  try {
    const data = yield call(() => {
      return service.createDetail(payload);
    });
    yield put(createDetailSuccess(data));
  } catch (e) {
    yield put(createDetailFailure(e.message));
  }
}

export function* updateDetail({ payload }) {
  try {
    const data = yield call(() => {
      return service.updateDetail(payload);
    });
    yield put(updateDetailSuccess(data));
  } catch (e) {
    yield put(updateDetailFailure(e.message));
  }
}

export function* deleteDetail({ id }) {
  try {
    const data = yield call(() => {
      return service.deleteDetail(id);
    });
    yield put(deleteDetailSuccess(data));
  } catch (e) {
    yield put(deleteDetailFailure(e.message));
  }
}

export function* deleteDetailField({ id, fieldId }) {
  try {
    const data = yield call(() => {
      return service.deleteDetailField(id, fieldId);
    });
    yield put(deleteDetailFieldSuccess(data));
  } catch (e) {
    yield put(deleteDetailFieldFailure(e.message));
  }
}

export function* detailsSaga() {
  yield takeLatest(detailsTypes.GET_DETAILS_START, fetchDetails);
  yield takeLatest(detailsTypes.CREATE_DETAIL_START, createDetail);
  yield takeLatest(detailsTypes.UPDATE_DETAIL_START, updateDetail);
  yield takeLatest(detailsTypes.DELETE_DETAIL_START, deleteDetail);
  yield takeLatest(detailsTypes.DELETE_DETAIL_FIELD_START, deleteDetailField);
}
