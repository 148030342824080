import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Tags,
  DepartmentsDropdown,
  DropdownAddNewItem,
  UserSelect,
} from '../shared';

import { createBoardStart } from '../../redux/boards/actions';
import { getTagsStart } from '../../redux/tags/actions';
import {
  getDetailsStart,
  getDetailsSuccess,
} from '../../redux/details/actions';
import {
  arrayOfObjectDeleteItem,
  arrayOfObjectReplaceItem,
  generateRandomId,
  getIosDateItems,
  isCustomId,
  removeIdFromArrayOfObjects,
} from '../../helpers/helpers';
import {
  createDetail,
  fetchBoardByQueryParams,
  fetchDepartmentDetails,
  updateDetail,
} from '../../service';
import {
  BOARD_TYPE,
  EMPLOYMENT_TYPES,
  TAG_TYPES,
} from '../../helpers/variables';
import {
  SubTitle,
  Wrapper,
  FormItem,
  Form,
  Input,
  Button,
  Select,
} from './styles';

const BoardModule = ({ board, title = '' }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const {
    details: { details },
    tags: { tags: iTags },
    boards: { isLoading },
  } = useSelector((state) => state);
  const [fields, setFields] = useState([]);
  const [tags, setTags] = useState(iTags);
  const [locations, setLocations] = useState([]);
  const isOffBoard = BOARD_TYPE.offboard === board;

  useEffect(() => {
    if (details._id) {
      const { date = '' } = getIosDateItems(details.date);
      const { name, ooo, title, location, manager, employmentType } = details;
      form.setFieldsValue({
        name,
        ooo,
        title,
        location,
        manager: manager || '',
        employmentType,
        date,
      });
      setFields(details.fields);
      setLocations(details.locations);
    } else {
      setFields([]);
    }
  }, [details._id]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    return () => dispatch(getDetailsSuccess({}));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setTags(getTags(iTags));
  }, [iTags]); // eslint-disable-line react-hooks/exhaustive-deps

  const getTags = (tags) =>
    tags.map((item) => ({
      _id: item._id,
      title: item.title,
      type: item.type,
      fields: item.fields,
    }));

  const handleAddField = (e) => {
    e.stopPropagation();
    e.preventDefault();
    const val = e.target.value?.trim();
    if (val && !fields.find((item) => item.label === val)) {
      setFields([
        ...fields,
        { _id: generateRandomId(), label: val, names: [], selected: false },
      ]);
    }
    form.setFieldsValue({ addTextField: undefined });
  };

  const handleChangeDepartment = (value) => {
    dispatch(getDetailsStart(value));
    dispatch(getTagsStart(value));
  };

  const handleChangeTags = (id) => (values) => {
    setTags((prev) =>
      prev.map((item) => {
        if (item._id === id) {
          return {
            ...item,
            fields: [...item.fields, { _id: generateRandomId(), ...values }],
          };
        }
        return item;
      }),
    );
  };

  const handleDeleteTag = (id, fieldId) =>
    setTags((prev) =>
      prev.map((item) => {
        if (item._id === id) {
          return {
            ...item,
            fields: item.fields.filter((el) => el._id !== fieldId),
          };
        }
        return item;
      }),
    );

  const handleSubmit = (values) => {
    const selectedTags = tags.map((item) => ({
      title: item.title,
      type: item.type,
      fields: removeIdFromArrayOfObjects(item.fields),
    }));

    const selectedFields = fields
      .filter((i) => i.selected)
      .map(({ _id, label, selected }) => {
        if (isCustomId(_id)) {
          return { label, value: selected };
        }
        return { _id, label, value: selected };
      });

    dispatch(
      createBoardStart({
        ...values,
        fields: selectedFields,
        tags: selectedTags,
        board,
      }),
    );

    form.resetFields();
  };

  const handleChangeOffBoardUser = (name) => {
    fetchBoardByQueryParams({ name, board: BOARD_TYPE.onboard }).then(
      async (res) => {
        const { title, employmentType, location, manager, department } = res;
        const { ooo } = await fetchDepartmentDetails(res._id);
        setTags(getTags(res.tags));
        form.setFieldsValue({
          title,
          ooo,
          employmentType,
          location,
          manager: manager._id,
          department: department.equivalent,
        });
      },
    );
  };

  const handleAddLocation = (locations) => {
    (details._id
      ? updateDetail({ _id: details._id, locations })
      : createDetail({
          locations,
          department: form.getFieldValue('department'),
        })
    )
      .then((res) => {
        setLocations(res.locations);
        !details._id && dispatch(getDetailsStart(res.department));
      })
      .catch(() => {});
  };

  return (
    <Wrapper>
      <SubTitle>{title}</SubTitle>
      <Form
        form={form}
        colon={false}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        onFinish={handleSubmit}
        autoComplete="off"
      >
        <FormItem
          label="Full name"
          name="name"
          rules={[{ required: true, message: 'Please input your Full name!' }]}
        >
          {isOffBoard ? (
            <UserSelect showEmail onChange={handleChangeOffBoardUser} />
          ) : (
            <Input />
          )}
        </FormItem>
        {isOffBoard && (
          <>
            <FormItem label="Set Out of Office Email" name="ooo">
              <Input />
            </FormItem>
            <FormItem
              label="Move Google Drive To"
              name="moveGoogleDriveTo"
              rules={[{ required: true, message: 'Please input!' }]}
            >
              <UserSelect showDirectoryEmail />
            </FormItem>
          </>
        )}
        <FormItem
          label="Title"
          name="title"
          rules={[{ required: true, message: 'Please input your title!' }]}
        >
          <Input />
        </FormItem>
        <FormItem
          label="Department"
          name="department"
          rules={[{ required: true, message: 'Please select Department!' }]}
        >
          <DepartmentsDropdown
            onChange={handleChangeDepartment}
            board={board}
          />
        </FormItem>
        <FormItem
          label={`${isOffBoard ? 'End Date' : 'Start Date'}`}
          name="date"
          rules={[{ required: true, message: 'Please input date!' }]}
        >
          <Input type="date" />
        </FormItem>
        <FormItem
          label={`${isOffBoard ? 'Manager' : 'Hiring Manager'}`}
          name="manager"
          rules={[{ required: true, message: 'Please input Hiring Manager!' }]}
        >
          <UserSelect />
        </FormItem>
        <FormItem
          label="Location"
          name="location"
          disabled={!form.getFieldValue('department')}
          rules={[{ required: true, message: 'Please input Location!' }]}
        >
          <DropdownAddNewItem
            placeholder="Location"
            options={locations}
            onAdd={handleAddLocation}
          />
        </FormItem>
        <FormItem
          label="Employment Type"
          name="employmentType"
          rules={[{ required: true, message: 'Please input Employment Type!' }]}
        >
          <Select
            options={EMPLOYMENT_TYPES.map((item) => ({
              label: item,
              value: item,
            }))}
          />
        </FormItem>

        <FormItem label="Add text field" name="addTextField">
          <Input onBlur={handleAddField} onPressEnter={handleAddField} />
        </FormItem>

        {fields.map((item) => (
          <Tags
            isFields
            key={item._id}
            name={item.label}
            tags={item.names}
            activeElement={item.selected}
            onDelete={() =>
              setFields((prev) => arrayOfObjectDeleteItem(prev, item._id))
            }
            onChange={(values) =>
              setFields((prev) =>
                arrayOfObjectReplaceItem(prev, { ...item, ...values }),
              )
            }
            onEdit={(label) =>
              setFields((prev) =>
                arrayOfObjectReplaceItem(prev, {
                  ...item,
                  label,
                }),
              )
            }
          />
        ))}

        {tags.map((item) => (
          <Tags
            key={item._id}
            name={item.title}
            tags={item.fields}
            type={item.type}
            background={item.type !== TAG_TYPES.default ? '#E7DEFA' : undefined}
            onDelete={() =>
              setTags((prev) => arrayOfObjectDeleteItem(prev, item._id))
            }
            onDeleteTag={(id) => handleDeleteTag(item._id, id)}
            onChange={handleChangeTags(item._id)}
            onEdit={(title) =>
              setTags((prev) =>
                arrayOfObjectReplaceItem(prev, {
                  ...item,
                  title,
                }),
              )
            }
          />
        ))}

        <Form.Item>
          <Button type="primary" htmlType="submit" loading={isLoading}>
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Wrapper>
  );
};

export default BoardModule;
