import { call, put, takeLatest, takeEvery } from 'redux-saga/effects';
import * as service from '../../service';
import { boardsTypes } from './types';
import {
  getBoardsSuccess,
  getBoardsFailure,
  createBoardSuccess,
  createBoardFailure,
  updateBoardSuccess,
  updateBoardFailure,
  deleteBoardSuccess,
} from './actions';
import { getDashboardStart } from '../dashboard/actions';

export function* getBoards({ payload }) {
  try {
    const data = yield call(() => {
      return service.getBoards(payload);
    });
    yield put(getBoardsSuccess(data, payload.board));
  } catch (e) {
    yield put(getBoardsFailure(e.message, payload.board));
  }
}

export function* createBoard({ body }) {
  try {
    const data = yield call(() => {
      return service.createBoard(body);
    });
    yield put(createBoardSuccess(data));
  } catch (e) {
    yield put(createBoardFailure(e.message));
  }
}

export function* updateBoard({ body }) {
  try {
    const data = yield call(() => {
      return service.updateBoard(body);
    });
    if (body.status === 'archived') {
      yield put(deleteBoardSuccess(data));
      yield put(getDashboardStart());
    } else {
      yield put(updateBoardSuccess(data));
    }
  } catch (e) {
    yield put(updateBoardFailure(e.message));
  }
}

export function* deleteBoard({ id }) {
  try {
    const data = yield call(() => {
      return service.deleteBoard(id);
    });
    yield put(deleteBoardSuccess(data));
  } catch (e) {}
}

export function* updateBoardChecklist({ id, body }) {
  try {
    const data = yield call(() => {
      return service.updateBoardChecklist(id, body);
    });
    yield put(updateBoardSuccess(data));
  } catch (e) {
    yield put(updateBoardFailure(e.message));
  }
}

export function* updateBoardTag({ id, tagId, body }) {
  try {
    const data = yield call(() => {
      return service.updateBoardTag(id, tagId, body);
    });
    yield put(updateBoardSuccess(data));
  } catch (e) {
    yield put(updateBoardFailure(e.message));
  }
}

export function* addBoardChecklistComment({ id, body }) {
  try {
    const data = yield call(() => {
      return service.addBoardChecklistComment(id, body);
    });
    yield put(updateBoardSuccess(data));
  } catch (e) {
    yield put(updateBoardFailure(e.message));
  }
}

export function* createBoardNotes({ url, body }) {
  try {
    const data = yield call(() => {
      return service.createBoardNotes(url, body);
    });
    yield put(updateBoardSuccess(data));
  } catch (e) {
    yield put(updateBoardFailure(e.message));
  }
}

export function* boardsSaga() {
  yield takeEvery(boardsTypes.GET_BOARDS_START, getBoards);
  yield takeLatest(boardsTypes.CREATE_BOARD_START, createBoard);
  yield takeLatest(boardsTypes.DELETE_BOARD_START, deleteBoard);
  yield takeLatest(boardsTypes.UPDATE_BOARD_START, updateBoard);
  yield takeLatest(
    boardsTypes.UPDATE_BOARD_CHECKLIST_START,
    updateBoardChecklist,
  );
  yield takeLatest(boardsTypes.UPDATE_BOARD_TAG_START, updateBoardTag);
  yield takeLatest(
    boardsTypes.BOARD_CHECKLIST_COMMENT_START,
    addBoardChecklistComment,
  );
  yield takeLatest(boardsTypes.CREATE_BOARD_NOTES_START, createBoardNotes);
}
