import React from 'react';
import { Button, Spinner } from 'react-bootstrap';

function SubmitButton({
  text = 'Submit',
  type = 'submit',
  loading,
  variant = 'primary',
  ...restProps
}) {
  return (
    <>
      {loading ? (
        <Button variant={variant} disabled {...restProps}>
          <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
          />
          &nbsp;Loading...
        </Button>
      ) : (
        <Button type={type} variant={variant} {...restProps}>
          {text}
        </Button>
      )}
    </>
  );
}

export default SubmitButton;
