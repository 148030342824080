import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Modal, Form, Col, Row } from 'antd';
import moment from 'moment/moment';
import { BulkUploadBtn, UserSelect } from '../shared';
import {
  createSoftwareStart,
  updateSoftwareStart,
} from '../../redux/software/actions';
import { usePrevious } from '../../helpers/hooks';
import { softwareBulkUpload } from '../../service';
import { FlexBetween, InputNumber, Input, DatePicker } from '../shared/styles';
import { Button } from './styles';

function SetSoftware({ data, reset, action, loading, reload }) {
  const dispatch = useDispatch();
  const prevLoading = usePrevious(loading);
  const [form] = Form.useForm();
  const [modalShow, setModalShow] = useState(false);

  useEffect(() => {
    if (data._id) {
      const expiration_date = moment(
        new Date(data.expiration_date),
        'MM-DD-YYYY',
      );
      const owners = data.owners.map(({ _id }) => _id);
      const { office, software, license, quantity, status, cost } = data;
      form.setFieldsValue({
        office,
        software,
        license,
        quantity,
        status,
        cost,
        owners,
        expiration_date,
      });

      setModalShow(true);
    }
  }, [data._id]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (prevLoading && !loading) {
      handleClose();
    }
  }, [loading]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleClose = () => {
    setModalShow(false);
    reset();
    form.resetFields();
  };

  const handleSubmit = (values) => {
    if (action === 'clone' || (!action && !data._id)) {
      dispatch(createSoftwareStart(values));
    }
    if (action === 'update' && data._id) {
      dispatch(updateSoftwareStart(values, data._id));
    }
  };

  const handleBulkUpload = (file) => {
    if (file) {
      const formData = new FormData();
      formData.append('file', file);
      softwareBulkUpload(formData)
        .then(() => reload())
        .catch();
    }
  };

  return (
    <div>
      <FlexBetween>
        <BulkUploadBtn onSubmit={handleBulkUpload} accept=".csv" />
        <Button onClick={() => setModalShow(true)}>Create New</Button>
      </FlexBetween>

      <Modal
        open={modalShow}
        title="Software"
        footer={null}
        width={780}
        onCancel={handleClose}
      >
        <Form
          form={form}
          colon={false}
          layout="vertical"
          onFinish={handleSubmit}
        >
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item
                name="office"
                label="Company"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input placeholder="Company" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="software"
                label="Software"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input placeholder="Software" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="license"
                label="License"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input placeholder="license" />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                name="quantity"
                label="Qty"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <InputNumber placeholder="Qty" />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                name="cost"
                label="Cost"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <InputNumber placeholder="Cost" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="expiration_date"
                label="Expiration"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <DatePicker format={'MM-DD-YYYY'} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="owners" label="Assignees">
                <UserSelect allowClear mode="multiple" />
              </Form.Item>
            </Col>
            <Col span={24} style={{ textAlign: 'right' }}>
              <Button htmlType={'submit'} loading={loading}>
                Submit
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>
    </div>
  );
}

export default SetSoftware;
