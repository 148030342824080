import React from 'react';
import withIcon from '../withIcon';

const ChecklistDragIcon = () => {
  return (
    <svg
      width="18"
      height="38"
      viewBox="0 0 18 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 8C0 3.58172 3.58172 0 8 0H18V38H8C3.58172 38 0 34.4183 0 30V8Z"
        fill="#DFE7F8"
      />
      <rect x="5" y="6" width="3" height="3" fill="#F5F8FE" />
      <rect x="11" y="6" width="3" height="3" fill="#F5F8FE" />
      <rect x="5" y="12" width="3" height="3" fill="#F5F8FE" />
      <rect x="11" y="12" width="3" height="3" fill="#F5F8FE" />
      <rect x="5" y="18" width="3" height="3" fill="#F5F8FE" />
      <rect x="11" y="18" width="3" height="3" fill="#F5F8FE" />
      <rect x="5" y="24" width="3" height="3" fill="#F5F8FE" />
      <rect x="11" y="24" width="3" height="3" fill="#F5F8FE" />
      <rect x="5" y="30" width="3" height="3" fill="#F5F8FE" />
      <rect x="11" y="30" width="3" height="3" fill="#F5F8FE" />
    </svg>
  );
};

export default withIcon(ChecklistDragIcon);
