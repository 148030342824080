import React from 'react';

const AccessForbidden = () => {
    return (
        <div className="page-wrap d-flex flex-row align-items-center">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-12 text-center">
                        <h1 className="d-block mb-5">403</h1>
                        <h3 className="mb-4">Access forbidden</h3>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AccessForbidden;
