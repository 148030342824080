import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Form } from 'react-bootstrap';
import { UserAvatar, FileUploadBtn } from '..';
import { getUsersList } from '../../../redux/users/selectors';
import styles from './autocomplete.module.scss';

function UsersAutoComplete({
  onChange,
  onUpload,
  clearFileInput,
  symbol = '@',
  ...restProps
}) {
  const users = useSelector(getUsersList);
  const ref = useRef(null);
  const [value, setValue] = useState('');
  const [options, setOptions] = useState([]);
  const reg = new RegExp(`(?:^|\\W)${symbol}([^\\s]*\\s?[^\\s]*)(?!\\w)$`, 'g');

  useEffect(() => {
    const match = reg.exec(value);
    const val = match ? match[1] : '';
    if (value === symbol || value.endsWith(` ${symbol}`) || val) {
      setOptions(users.filter(({ email }) => email?.startsWith(val)));
    } else {
      setOptions([]);
    }

    if (
      ref.current &&
      parseInt(ref.current.style.height) !== ref.current.scrollHeight
    ) {
      ref.current.style.height = ref.current.scrollHeight + 'px';
    }
    onChange(value);
  }, [value]); // eslint-disable-line react-hooks/exhaustive-deps

  const onSelect = (data = {}) => {
    const { email, firstName = '', lastName = '' } = data;
    const user = email || firstName || lastName;
    const val = `${value.replace(reg, ` ${symbol}`)}${user} `;
    setOptions([]);
    setValue(val);
  };

  return (
    <div className={styles.autocompleteBox}>
      <div className={onUpload ? styles.uploadFile : ''}>
        <Form.Control
          as="textarea"
          className={styles.autocompleteTextarea}
          autoFocus
          ref={ref}
          value={value}
          onChange={({ target: { value } }) => setValue(value)}
          {...restProps}
        />
        <div className={styles.uploadBtn}>
          <FileUploadBtn onFileSelect={onUpload} clearFile={clearFileInput} />
        </div>
      </div>
      {options.length ? (
        <div className={styles.dropdown}>
          {options.map((user) => {
            return (
              <div
                key={user._id}
                className={styles.dropdownItem}
                onClick={() => onSelect(user)}
              >
                <UserAvatar user={user} email={true} />
              </div>
            );
          })}
        </div>
      ) : (
        ''
      )}
    </div>
  );
}

export default UsersAutoComplete;
