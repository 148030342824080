import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Form, Modal, Row, Button as AntdButton } from 'antd';
import moment from 'moment';
import styled from 'styled-components';
import { DropdownAddNewItem, UserSelect, Tags } from '../../shared';
import { updateBoardStart } from '../../../redux/boards/actions';
import { getDepartmentsStart } from '../../../redux/departments/actions';
import {
  arrayOfObjectReplaceItem,
  arrayOfObjectDeleteItem,
  isCustomId,
} from '../../../helpers/helpers';
import { EMPLOYMENT_TYPES } from '../../../helpers/variables';
import { DatePicker, Input, Select } from '../../shared/styles';
import { fetchDepartmentDetails } from '../../../service';

const Button = styled(AntdButton).attrs({
  type: 'primary',
  size: 'large',
})`
  border-radius: 4px;
  margin-top: 24px;
`;

function SetBoard({ data = {}, onClose }) {
  const dispatch = useDispatch();
  const { updateLoading: loading } = useSelector((state) => state.boards);
  const { departments } = useSelector((state) => state.departments);
  const [form] = Form.useForm();
  const [locations, setLocations] = useState([]);
  const [fields, setFields] = useState([]);

  useEffect(() => {
    dispatch(getDepartmentsStart(data.board, true));
    const date = moment(new Date(data.date), 'MM-DD-YYYY');
    const {
      title = '',
      manager = '',
      location = '',
      department = {},
      employmentType = '',
    } = data;
    form.setFieldsValue({
      title,
      manager: manager?._id || '',
      location,
      employmentType,
      department: department?._id || '',
      date,
    });
    if (department?._id) {
      handleChangeDepartment(department?._id);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleChangeDepartment = (value) => {
    fetchDepartmentDetails(value)
      .then((res) => {
        const selectedValues = data.fields.reduce((acc, cur) => {
          acc[cur._id] = cur.value;
          return acc;
        }, {});
        setLocations(res.locations || []);
        setFields(
          res.fields.map((field) => {
            const selected = selectedValues[field._id];
            const item = { ...field, selected };
            if (selected && !field.names?.includes(selected)) {
              item.names = [...item.names, selected];
            }
            return item;
          }),
        );
        // form.setFieldValue('location', res.location || '');
      })
      .catch(() => {});
  };

  const handleSubmit = (values) => {
    const selectedFields = fields
      .filter((i) => i.selected)
      .map(({ _id, label, selected }) => {
        if (isCustomId(_id)) {
          return { label, value: selected };
        }
        return { _id, label, value: selected };
      });
    dispatch(
      updateBoardStart({ ...values, fields: selectedFields, _id: data._id }),
    );
  };

  return (
    <Modal
      open={true}
      title="Edit"
      footer={null}
      width={780}
      bodyStyle={{ maxHeight: 'calc(100vh - 260px)', overflowY: 'auto' }}
      onCancel={onClose}
    >
      <Form form={form} colon={false} layout="vertical" onFinish={handleSubmit}>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              name="date"
              label="Start Date"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <DatePicker format={'MM-DD-YYYY'} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="title"
              label="Title"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input placeholder="Title" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="manager" label="Manager">
              <UserSelect allowClear placeholder="Manager" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="location" label="Location">
              <DropdownAddNewItem placeholder="Location" options={locations} />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item name="employmentType" label="Employment Type">
              <Select allowClear placeholder="Employment Type">
                {EMPLOYMENT_TYPES.map((item) => {
                  if (data.integration && item === 'Contract') {
                    return (
                      <Select.Option key={'Contract'} value="Contractor">
                        Contractor
                      </Select.Option>
                    );
                  }
                  return (
                    <Select.Option key={item} value={item}>
                      {item}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="department" label="Department">
              <Select
                allowClear
                placeholder="department"
                onChange={handleChangeDepartment}
              >
                {departments.map((department) => (
                  <Select.Option key={department._id} value={department._id}>
                    {department.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={24}>
            {fields?.map((item) => (
              <Tags
                isFields
                key={item._id}
                name={item.label}
                tags={item.names}
                background={'#E7DEFA'}
                activeElement={item.selected}
                onDelete={() =>
                  setFields((prev) => arrayOfObjectDeleteItem(prev, item._id))
                }
                onChange={(values) =>
                  setFields((prev) =>
                    arrayOfObjectReplaceItem(prev, {
                      ...item,
                      ...values,
                    }),
                  )
                }
              />
            ))}
          </Col>
          <Col span={24} style={{ textAlign: 'right' }}>
            <Button htmlType={'submit'} loading={loading}>
              Save
            </Button>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}

export default SetBoard;
