import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'antd';
import { Accordion } from 'react-bootstrap';
import { Loader } from '../shared';
import {
  SettingForm,
  SettingsChecklist,
  SettingsTags,
  SettingDepartment,
} from './components';
import { CreateDepartment } from '../department';
import {
  getDepartmentsStart,
  getDepartmentStart,
} from '../../redux/departments/actions';
import { DEPARTMENT_TYPES } from '../../helpers/variables';
import { allCompanyDepartment, updateDepartmentSettings } from '../../service';
import {
  Wrapper,
  SubTitle,
  Container,
  AccordionToggle,
  Content,
  ItemWrap,
  ItemContent,
  Button,
} from './styles';

const SettingsBoard = ({ board }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { departments, department, loading } = useSelector(
    (state) => state.departments,
  );
  const [value, setValue] = useState('');
  const [data, setData] = useState({});
  const [saveLoading, setSaveLoading] = useState(false);

  useEffect(() => {
    dispatch(getDepartmentsStart(board, 1));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (department._id) {
      setData(department);
    }
  }, [department._id]); // eslint-disable-line react-hooks/exhaustive-deps

  const onSelect = (val) => {
    if (val) {
      setValue(val);
      dispatch(getDepartmentStart(val));
    }
  };

  const handleChange = useCallback((name, value) => {
    setData((prev) => ({ ...prev, [name]: value }));
  }, []);

  const handleSave = async () => {
    setSaveLoading(true);
    const values = form.getFieldsValue();
    const fields =
      values.fields?.map(({ _id, names, label }) => {
        if (_id && _id.startsWith('_')) {
          return { names, label };
        }
        return { _id, names, label };
      }) || [];

    const details = {
      ...values,
      fields,
    };

    try {
      await updateDepartmentSettings(department._id, {
        details,
        tags: data.tags,
        checklists: data.checklists,
      });
      if (department.type === DEPARTMENT_TYPES.allCompany) {
        await allCompanyDepartment(department._id);
      }
      setSaveLoading(false);
    } catch (error) {
      setSaveLoading(false);
    }
  };

  return (
    <Wrapper>
      {loading ? (
        <Loader />
      ) : (
        <Container>
          <SubTitle>Departments</SubTitle>
          <div>
            <Accordion onSelect={onSelect}>
              {departments.map((item, idx) => (
                <ItemWrap key={item._id}>
                  <AccordionToggle>
                    <SettingDepartment
                      department={item}
                      isDeleted={idx === 0}
                    />
                  </AccordionToggle>
                  <Accordion.Collapse eventKey={item._id}>
                    <div>
                      {value === item._id && (
                        <>
                          <Content>
                            <ItemContent>
                              <SettingForm
                                form={form}
                                department={department._id}
                                details={
                                  (department.details &&
                                    department.details[0]) ||
                                  {}
                                }
                                board={department.board}
                              />
                            </ItemContent>
                            <div>
                              <ItemContent>
                                <SettingsChecklist
                                  board={board}
                                  checklists={data.checklists}
                                  onChange={handleChange}
                                />
                              </ItemContent>
                              <ItemContent>
                                <SettingsTags
                                  board={board}
                                  department={department._id}
                                  tags={data.tags}
                                  onChange={handleChange}
                                />
                              </ItemContent>
                              <div style={{ textAlign: 'right' }}>
                                <Button
                                  size="large"
                                  onClick={handleSave}
                                  loading={saveLoading}
                                >
                                  Save Defaults
                                </Button>
                              </div>
                            </div>
                          </Content>
                        </>
                      )}
                    </div>
                  </Accordion.Collapse>
                </ItemWrap>
              ))}
            </Accordion>
          </div>
          <CreateDepartment board={board} />
        </Container>
      )}
    </Wrapper>
  );
};

export default SettingsBoard;
