import React, { useEffect, useState } from 'react';
import { Input as AntdInput, Tag as AntdTag, Select as AntdSelect } from 'antd';
import styled from 'styled-components';
import { CloseIcon, DeleteIcon, EditIcon } from '../../../Icons';
import { generateRandomId } from '../../../helpers/helpers';
import { TAG_TYPES } from '../../../helpers/variables';
import {
  fetchGSuiteGroups,
  fetchGSuiteFolders,
  fetchGSuiteSharedFolders,
  fetchOffice365Groups,
  fetchOffice365Drives,
  fetchOktaGroups,
} from '../../../service';

const Wrapper = styled.div`
  min-height: 46px;
  background: #f5f8fe;
  border-radius: 8px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 4px;
  grid-gap: 8px;
`;

const Title = styled.div`
  font-weight: 500;
  font-size: 16px;
  color: #313131;
  margin: 16px 0;
  display: flex;
  align-items: center;
  grid-gap: 16px;
`;
const Input = styled(AntdInput)`
  max-height: 38px;
  border-radius: 8px;
  max-width: 160px;
`;
const Select = styled(AntdSelect).attrs({ size: 'large' })`
  max-height: 38px;
  border-radius: 8px;
  max-width: 200px;
  width: 100%;

  &&& > div {
    border-radius: 8px;
    max-height: 38px;
  }
`;

const Tag = styled(AntdTag)`
  padding: 10px 16px;
  background: ${(props) => props.background};
  border-radius: 8px;
  max-height: 38px;
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: 0.02em;
  color: #292d32;
  grid-gap: 8px;
  margin: 0;

  & > span {
    margin-top: -4px;
  }
`;

export const ActionWrap = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
  width: 56px;
  justify-content: space-between;

  svg {
    font-size: 20px;
  }
`;

const fetchData = {
  [TAG_TYPES.googleGroups]: fetchGSuiteGroups,
  [TAG_TYPES.googleDriveFolders]: fetchGSuiteFolders,
  [TAG_TYPES.googleDriveSharedFolders]: fetchGSuiteSharedFolders,
  [TAG_TYPES.office365Groups]: fetchOffice365Groups,
  [TAG_TYPES.office365Drives]: fetchOffice365Drives,
  [TAG_TYPES.oktaGroups]: fetchOktaGroups,
};

const dataKeys = {
  [TAG_TYPES.googleGroups]: 'groups',
  [TAG_TYPES.googleDriveFolders]: 'files',
  [TAG_TYPES.googleDriveSharedFolders]: 'drives',
};

const Tags = ({
  tags = [],
  name,
  type,
  isFields,
  isSettings,
  onChange,
  onDelete,
  onDeleteTag,
  onClick,
  onEdit,
  activeElement,
  background = '#faefde',
}) => {
  const [inputVisible, setInputVisible] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [editName, setEditName] = useState(false);
  const [options, setOptions] = useState([]);

  const isGSuite = [
    TAG_TYPES.googleGroups,
    TAG_TYPES.googleDriveFolders,
    TAG_TYPES.googleDriveSharedFolders,
  ].includes(type);
  const isOffice365 = [
    TAG_TYPES.office365Groups,
    TAG_TYPES.office365Drives,
  ].includes(type);
  const isOkta = TAG_TYPES.oktaGroups === type;

  useEffect(() => {
    if (isGSuite) {
      fetchData[type]().then(
        (res) =>
          res[dataKeys[type]] &&
          setOptions(
            res[dataKeys[type]].map((item) => ({
              value: item.id,
              label: TAG_TYPES.googleGroups === type ? item.email : item.name,
            })),
          ),
      );
    }
  }, [isGSuite]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (isOffice365) {
      fetchData[type]().then((res) =>
        setOptions(
          res.map((item) => ({
            value: item.id,
            label: item.displayName || item.name,
          })),
        ),
      );
    }
  }, [isOffice365]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (isOkta) {
      fetchData[type]().then((res) =>
        setOptions(
          res.map((item) => ({
            value: item.id,
            label: item.name,
          })),
        ),
      );
    }
  }, [isOkta]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleClose = (key) => {
    if (isFields) {
      onChange({ names: tags.filter((tag) => tag !== key) });
    } else {
      if (onDeleteTag) {
        onDeleteTag(key);
      } else {
        onChange({ fields: tags.filter((el) => el._id !== key) });
      }
    }
  };

  const showInput = () => {
    setInputVisible(true);
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleInputConfirm = (e) => {
    e.stopPropagation();
    e.preventDefault();
    const value = inputValue.trim();
    if (value) {
      if (isFields) {
        if (value && tags.indexOf(value) === -1) {
          onChange({ names: [...tags, inputValue] });
        }
      } else {
        if (tags.find((el) => el.name === inputValue)) {
          return;
        }
        const _id = generateRandomId();
        if (isSettings) {
          onChange(
            {
              fields: [...tags, { _id, name: inputValue }],
            },
            { _id, name: inputValue },
          );
        } else {
          onChange({ name: inputValue });
        }
      }
    }
    setInputVisible(false);
    setInputValue('');
  };

  const handleConfirmTitle = (e) => {
    e.stopPropagation();
    e.preventDefault();
    if (e.target.value.trim()) {
      onEdit(e.target.value.trim());
    }

    setEditName(false);
  };

  const handleChangeSelect = (value) => {
    if (tags.find((el) => el.value === value)) {
      return;
    }
    const field = options.find((item) => item.value === value);
    const _id = generateRandomId();
    if (!field) {
      return;
    }
    if (isSettings) {
      onChange(
        {
          fields: [
            ...tags,
            {
              _id,
              name: field.label,
              value,
              completed: true,
            },
          ],
        },
        {
          _id,
          name: field.label,
          value,
          completed: true,
        },
      );
    } else {
      onChange({
        name: field.label,
        value,
        completed: true,
      });
    }

    setInputVisible(false);
  };

  const handleClick = (tag) => {
    if (isFields) {
      onChange({ selected: tag });
      return;
    }
    onClick && onClick(tag);
  };

  if ((isGSuite || isOffice365 || isOkta) && !options.length) {
    return null;
  }

  return (
    <>
      {editName ? (
        <Input
          autoFocus
          defaultValue={name}
          style={{ margin: '6px 0' }}
          onBlur={handleConfirmTitle}
          onPressEnter={handleConfirmTitle}
        />
      ) : (
        <Title>{name}</Title>
      )}
      <Wrapper>
        {tags.map((tag) => {
          const key = isFields ? tag : tag._id;
          const name = isFields ? tag : tag.name;
          const active = isFields
            ? tag === activeElement
            : tag.completed && type === TAG_TYPES.default;

          return (
            <Tag
              key={key}
              closable
              checked={active}
              background={active ? '#DEFAEC' : background}
              closeIcon={<CloseIcon style={{ fontSize: 8 }} />}
              onClose={() => handleClose(key)}
              onClick={() => handleClick(tag)}
            >
              {name}
            </Tag>
          );
        })}
        {inputVisible &&
          (isGSuite || isOffice365 || isOkta ? (
            <Select
              options={options}
              value={tags.map((item) => item.value)}
              onChange={handleChangeSelect}
            />
          ) : (
            <Input
              autoFocus
              value={inputValue}
              onChange={handleInputChange}
              onBlur={handleInputConfirm}
              onPressEnter={handleInputConfirm}
            />
          ))}
        {!inputVisible && (
          <Tag
            style={{ cursor: 'pointer' }}
            background={'#fff'}
            onClick={showInput}
          >
            Add
          </Tag>
        )}

        <ActionWrap>
          {onEdit && <EditIcon onClick={() => setEditName(true)} />}
          {onDelete && <DeleteIcon onClick={() => onDelete()} />}
        </ActionWrap>
      </Wrapper>
    </>
  );
};

export default Tags;
