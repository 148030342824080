import styled from 'styled-components';
import { Input as AntdInput } from 'antd';

export const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  background-color: #5d1fe1;
  color: #fff;
`;

export const LogoWrap = styled.div`
  width: 150px;
  height: 150px;
  display: flex;
  align-items: center;
`;

export const FormWrap = styled.div`
  padding: 15px;
  background-color: #fff;
  color: #000;
  width: 50%;
  margin: auto;

  a {
    text-decoration: none;

    &:hover {
      color: #0056b3;
      text-decoration: underline;
    }
  }

  @media (max-width: 992px) {
    width: 100%;
  }
`;

export const Title = styled.div`
  font-size: 30px;
  font-weight: 600;
  margin-bottom: 16px;
`;

export const SendEmail = styled.div`
  color: #dc3545;
  span {
    cursor: pointer;
    color: #17a2b8;
    &:hover {
      color: #007bff;
    }
  }
`;

export const LoginBtnWrap = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 8px;
  align-items: center;
  margin-top: 16px;

  & > div:last-child {
    & > div > div {
      width: 525px;

      @media (max-width: 992px) {
        width: 250px;
      }
    }
  }
`;

export const Input = styled(AntdInput)`
  height: 40px;
  border-radius: 4px;
`;

export const InputPassword = styled(AntdInput.Password)`
  height: 40px;
  border-radius: 4px;
`;
