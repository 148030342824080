import React, { useEffect } from 'react';
import { useState } from 'react';
import { STATUS_INFO, GOOGLE_OFFBOARD_LISTS } from '../../../helpers/variables';
import { offBoardGSuite, fetchBoardInfo, setBoardInfo } from '../../../service';
import { getFullDate } from '../../../helpers/helpers';
import {
  BlockWrap,
  OffboardGoogleHeaderWrap,
  OffboardGoogleBodyWrap,
  StatusWrap,
} from './styles';

function OffBoardGoogleUser({ board, offBoard }) {
  const [data, setData] = useState(GOOGLE_OFFBOARD_LISTS);

  useEffect(() => {
    if (board._id) {
      fetchBoardInfo(board._id).then(updateData).catch();
    }
  }, [board._id]);

  useEffect(() => {
    if (offBoard && board._id) {
      const data = GOOGLE_OFFBOARD_LISTS.reduce((acc, cur) => {
        acc[cur.key] = {
          status: STATUS_INFO.started.status,
          completed: Date.now(),
        };
        return acc;
      }, {});
      setBoardInfo({
        boardId: board._id,
        data,
      })
        .then(updateData)
        .catch();
    }
  }, [board._id, offBoard]);

  const updateData = (res) => {
    if (res?.data) {
      setData((prev) =>
        prev.map((item) => {
          if (res.data[item.key]) {
            const obj = res.data[item.key];
            return {
              ...item,
              status: STATUS_INFO[obj.status],
              completed: obj.completed,
            };
          }
          return item;
        }),
      );
    }
  };

  const updateDataStatus = (key, status, completed) => {
    setData((prev) =>
      prev.map((item) => {
        if (item.key === key) {
          return { ...item, status, completed };
        }
        return item;
      }),
    );
  };

  const handleClick = async (key) => {
    try {
      updateDataStatus(key, STATUS_INFO.started, '');

      await offBoardGSuite({
        boardId: board._id,
        userId: board?.name?._id,
        keys: [key],
      });
      const completed = Date.now();
      await setBoardInfo({
        boardId: board._id,
        data: {
          [key]: { status: STATUS_INFO.started.status, completed },
        },
      });
      updateDataStatus(key, STATUS_INFO.done, completed);
    } catch (error) {
      updateDataStatus(key, STATUS_INFO.notStarted, '');
    }
  };

  return (
    <BlockWrap>
      <div>Google Workspace</div>
      <OffboardGoogleHeaderWrap>
        <div>Tasks</div>
        <div>Statuse</div>
        <div>Completed</div>
      </OffboardGoogleHeaderWrap>
      <OffboardGoogleBodyWrap>
        {data.map((item) => {
          return (
            <React.Fragment key={item.key}>
              <div>
                <div>{item.title}</div>
                <div>
                  <StatusWrap
                    onClick={() =>
                      item.status.status === 'started' && handleClick(item.key)
                    }
                    color={item.status.color}
                    background={item.status.background}
                  >
                    {item.status.label}
                  </StatusWrap>
                </div>
                <div>{item.completed && getFullDate(item.completed)}</div>
              </div>
            </React.Fragment>
          );
        })}
      </OffboardGoogleBodyWrap>
    </BlockWrap>
  );
}

export default OffBoardGoogleUser;
