import React from 'react';
import withIcon from '../withIcon';

const PasswordManagerIcon = () => {
  return (
    <svg
      width="42"
      height="40"
      viewBox="0 0 42 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="28.3574" cy="18.5" r="13.5" fill="#C956FF" />
      <path
        d="M11 16.6668V13.3335C11 7.81683 12.6667 3.3335 21 3.3335C29.3333 3.3335 31 7.81683 31 13.3335V16.6668"
        stroke="#292D32"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.9997 30.8333C23.3009 30.8333 25.1663 28.9679 25.1663 26.6667C25.1663 24.3655 23.3009 22.5 20.9997 22.5C18.6985 22.5 16.833 24.3655 16.833 26.6667C16.833 28.9679 18.6985 30.8333 20.9997 30.8333Z"
        stroke="#292D32"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M29.333 36.6665H12.6663C5.99967 36.6665 4.33301 34.9998 4.33301 28.3332V24.9998C4.33301 18.3332 5.99967 16.6665 12.6663 16.6665H29.333C35.9997 16.6665 37.6663 18.3332 37.6663 24.9998V28.3332C37.6663 34.9998 35.9997 36.6665 29.333 36.6665Z"
        stroke="#292D32"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default withIcon(PasswordManagerIcon);
