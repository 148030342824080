import React from 'react';
import withIcon from '../withIcon';

const AnnotationIcon = () => {
  return (
    <svg
      width="1em"
      height="0.94em"
      viewBox="0 0 16 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 10V2C16 0.895431 15.1046 0 14 0H2C0.895431 0 0 0.895431 0 2V10C0 11.1046 0.895431 12 2 12H5L8 15L11 12H14C15.1046 12 16 11.1046 16 10ZM3 4C3 3.44772 3.44772 3 4 3H12C12.5523 3 13 3.44772 13 4C13 4.55228 12.5523 5 12 5H4C3.44772 5 3 4.55228 3 4ZM4 7C3.44772 7 3 7.44772 3 8C3 8.55229 3.44772 9 4 9H7C7.55229 9 8 8.55229 8 8C8 7.44772 7.55229 7 7 7H4Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default withIcon(AnnotationIcon);
