import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NotificationIcon } from '../../Icons';
import {
  getNotificationsStart,
  updateNotificationsStart,
} from '../../redux/notifications/actions';
import {  getDateByFormat,  getFullDate } from '../../helpers/helpers';
import { useOutsideClick } from '../../helpers/hooks';
import { NOTIFICATIONS_TYPE } from '../../helpers/variables';
import {
  Wrapper,
  Badge,
  DropdownWrapper,
  Title,
  Notification,
  DateTime,
  Message,
  BoldText,
  Text,
  Link,
  NoData,
} from './styles';

function Info({ note }) {
  const dispatch = useDispatch();

  const handleClick = () => {
    if (note.status === 'new') {
      dispatch(updateNotificationsStart(note._id));
    }
  };

  if (note.type === NOTIFICATIONS_TYPE.taskAssigned) {
    return (
      <Link to={'/dashboard'} onClick={handleClick}>
        <BoldText>Task Assigned</BoldText>
        <Text>{note.message}</Text>
        <Text>
          {note.createdBy?.firstName + ' ' + note.createdBy?.lastName} - started
          - {getDateByFormat(note.updatedAt)}
        </Text>
      </Link>
    );
  }
  if (note.type === NOTIFICATIONS_TYPE.ticketAssigned) {
    return (
      <Link to={'/tickets'} onClick={handleClick}>
        <BoldText>Ticket Assigned</BoldText>
        <Text>{note.message}</Text>
        <Text>
          {note.createdBy?.firstName + ' ' + note.createdBy?.lastName}
        </Text>
      </Link>
    );
  }
  if (note.type === NOTIFICATIONS_TYPE.taskOverdue) {
    return (
      <Link to={'/tickets'} onClick={handleClick}>
        <BoldText>Task Overdue </BoldText>
        <Text>{note.message}</Text>
        <Text>
          {note.info?.owner?.firstName + ' ' + note.info?.owner?.lastName} -
          started - {getDateByFormat(note.info?.started)}
        </Text>
      </Link>
    );
  }
  if (note.type === NOTIFICATIONS_TYPE.repliedTicket) {
    return (
      <Link to={'/tickets'} onClick={handleClick}>
        <Text>
          <BoldText>
            {note.createdBy?.firstName + ' ' + note.createdBy?.lastName}
          </BoldText>{' '}
          replied to a ticket
        </Text>
        <Text
          style={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {note.message}
        </Text>
      </Link>
    );
  }
  if (note.type === NOTIFICATIONS_TYPE.boardChecklistsComment) {
    return (
      <Link to={'/dashboard'} onClick={handleClick}>
        <Text>
          <BoldText>
            {note.createdBy?.firstName + ' ' + note.createdBy?.lastName}
          </BoldText>{' '}
          tagged you in a comment
        </Text>
        <Text dangerouslySetInnerHTML={{ __html: note.message }} />
      </Link>
    );
  }
  if (
    note.type === NOTIFICATIONS_TYPE.offboardSubmitted ||
    note.type === NOTIFICATIONS_TYPE.onboardSubmitted
  ) {
    const isOff = note.type === NOTIFICATIONS_TYPE.offboardSubmitted;
    return (
      <Link to={'/dashboard'} onClick={handleClick}>
        <BoldText>
          {isOff ? 'Offboard' : 'Onboard'} Submited{' '}
          <span style={{ background: isOff ? '#eb3351' : '#26DE81' }} />
        </BoldText>
        <Text>
          {note.info?.name} - {getDateByFormat(note.info?.date)}
        </Text>
        <Text>{note.message}</Text>
        <Text>{note.info?.employmentType}</Text>
      </Link>
    );
  }
  return <Message dangerouslySetInnerHTML={{ __html: note.message }} />;
}

function Notifications() {
  const limit = 4;
  const dispatch = useDispatch();
  const ref = useRef();
  const { data, total, newNotesCount } = useSelector(
    (state) => state.notifications,
  );
  const [show, setShow] = useState(false);
  const [offset, setOffset] = useState(0);

  useOutsideClick(ref, () => setShow(false));

  useEffect(() => {
    dispatch(getNotificationsStart(offset, limit));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (newNotesCount) {
      window.document.title = `Thelma (${newNotesCount})`;
    } else {
      window.document.title = 'Thelma';
    }
  }, [newNotesCount]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleClick = () => {
    if (data.length < total) {
      const page = offset + limit;
      dispatch(getNotificationsStart(page, limit));
      setOffset(page);
    }
  };

  return (
    <Wrapper ref={ref}>
      <Badge dot={!!newNotesCount}>
        <NotificationIcon
          style={{ fontSize: 20 }}
          onClick={() => setShow((prev) => !prev)}
        />
      </Badge>
      {show && (
        <DropdownWrapper>
          <Title>Notifications</Title>
          {data.map((item, index) => {
            return (
              <Notification key={index} isNew={item.status === 'new'}>
                <Info note={item} />
                <DateTime>{getFullDate(item.createdAt)}</DateTime>
              </Notification>
            );
          })}
          {data.length ? (
            <Title style={{ cursor: 'pointer' }} onClick={handleClick}>
              See more
            </Title>
          ) : (
            <NoData> No Data</NoData>
          )}
        </DropdownWrapper>
      )}
    </Wrapper>
  );
}

export default Notifications;
