import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { Table, DatePicker, Button } from 'antd';
import { exportUserHistory, fetchUsersHistoty } from '../../service';
import { ROUTES } from '../../helpers/variables';
import { getUserFullName } from '../../helpers/helpers';
import { ExportWrap } from './styles';

const { RangePicker } = DatePicker;

const columns = [
  {
    title: 'Name',
    dataIndex: 'userId',
    render: (data) => getUserFullName(data),
  },
  {
    title: 'Email',
    dataIndex: 'userId',
    render: (data) => data.email,
  },
  {
    title: 'Company',
    dataIndex: 'company',
    render: (data) => data?.name,
  },
  {
    title: 'Login date',
    dataIndex: 'createdAt',
    render: (createdAt) => moment(createdAt).format('DD MMMM YYYY hh:mm:ss'),
  },
];

function AdminPanel() {
  const history = useHistory();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [exportLoading, setExportLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [dates, setDates] = useState([]);

  useEffect(() => {
    setLoading(true);
    fetchUsersHistoty({ offset: (page - 1) * limit, limit })
      .then((res) => {
        setData(res);
      })
      .catch((e) => {
        if (e?.response?.status === 403) {
          history.push(ROUTES.dashboard.path);
        }
      })
      .finally(() => setLoading(false));
  }, [page, limit]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleTableChange = (pagination, _, sorter, extra) => {
    const { action } = extra;
    if (action === 'paginate') {
      const { current, pageSize } = pagination;

      setLimit(pageSize);
      setPage(limit !== pageSize ? 1 : current);
    }
  };

  const handleExportCsv = () => {
    if (!dates[0] || !dates[1]) {
      return;
    }
    setExportLoading(true);
    exportUserHistory({
      startDate: moment(dates[0]).format('L'),
      endDate: moment(dates[1]).format('L'),
    })
      .then((res) => {
        setExportLoading(false);
        window.open(res.url, '_blank');
      })
      .catch(() => {
        setExportLoading(false);
      });
  };

  return (
    <div>
      <ExportWrap>
        <RangePicker value={dates} onChange={(dates) => setDates(dates)} />
        <Button
          type="primary"
          loading={exportLoading}
          disabled={!dates[0] || !dates[1]}
          onClick={handleExportCsv}
        >
          Export csv
        </Button>
      </ExportWrap>
      <Table
        columns={columns}
        rowKey={(record) => record._id}
        dataSource={data.data}
        pagination={{
          size: 'small',
          current: page,
          pageSize: limit,
          total: data.total,
          pageSizeOptions: ['10', '25', '50', '100'],
          showSizeChanger: true,
        }}
        loading={loading}
        onChange={handleTableChange}
      />
    </div>
  );
}

export default AdminPanel;
