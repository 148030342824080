import styled from 'styled-components';
import { Input, Button as AntdButton } from 'antd';

export const FlexBetween = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-gap: 24px;
`;

export const BlockWrap = styled.div`
  margin-top: 16px;
  margin-bottom: 16px;

  & > div:first-child {
    font-weight: 500;
    font-size: 16px;
    color: #313131;
    margin: 8px 0;
  }
`;

export const OffboardGoogleHeaderWrap = styled.div`
  display: flex;
  padding: 14px 26px;
  background: #f6f7f9;
  border-radius: 8px;
  align-items: center;

  & > div {
    width: 33.3%;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: 0.02em;
    color: #8593ad;
  }
`;

export const OffboardGoogleBodyWrap = styled.div`
  & > div {
    display: flex;
    color: #4f4f4f;
    padding: 14px 26px;
    margin-bottom: 10px;

    &:not(:last-child) {
      border-bottom: 1px solid #eaecf0;
    }

    & > div {
      width: 33.3%;
      font-weight: 500;
      font-size: 15px;
    }
  }
`;

export const StatusWrap = styled.div`
  text-align: center;
  border-radius: 20px;
  line-height: 30px;
  font-size: 12px;
  letter-spacing: 0.01em;
  max-width: 101px;
  height: 32px;
  cursor: pointer;
  color: ${(props) => props.color};
  background: ${(props) => props.background};
`;

export const EmailWrapper = styled.div`
  background: #fff;
  border-radius: 8px;
  position: relative;
  padding: 16px;

  .tox-tinymce {
    border: none;
  }

  .tox:not([dir='rtl']) .tox-toolbar__group {
    border-right: 1px solid #ccc !important;
  }

  .tox-toolbar-overlord {
    .tox-toolbar {
      width: 460px;
    }
  }
`;

export const EmailInputWrap = styled.div`
  display: flex;
  align-items: center;
  grid-gap: 16px;
  border-bottom: 1px solid #f5f8fe;
  height: 42px;
  padding: 8px 0;

  & > div:first-child {
    font-weight: 400;
    min-width: 60px;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: 0.005em;
    color: #8593ad;
  }
`;

export const EmailInput = styled(Input)`
  border: none !important;
  box-shadow: none !important;
`;

export const EditorFooter = styled.div`
  display: flex;
  align-items: center;
  grid-gap: 16px;
  position: absolute;
  z-index: 1;
  bottom: 18px;
  right: 8px;
  width: calc(100% - 460px);
  padding-left: 26px;
  z-index: 2;

  & > div:first-child {
    margin-right: auto;
  }
`;

export const Button = styled(AntdButton).attrs({
  type: 'primary',
  size: 'large',
})`
  border-radius: 8px;
  background: #9b51e0 !important;
  border: #9b51e0 !important;
`;

export const DropdownButton = styled.div`
  display: flex;
  align-items: center;
  grid-gap: 6px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 700;
`;

export const AssetsWrap = styled.div`
  background: #fff;
  border-radius: 8px;
  position: relative;
  padding: 16px;

  & > div:last-child {
    display: flex;
    justify-content: flex-end;
    padding: 4px 8px;
  }
`;

export const ButtonWrap = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 4px 8px;
  margin-top: 24px;
`;

export const AssetsHeader = styled.div`
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: 0.02em;
  color: #8593ad;
  border: none;
  background: #f6f7f9;
  border-radius: 8px;
  display: flex;
  align-items: center;
  padding: 12px;

  & > div {
    width: 20%;
  }
`;

export const AssetsContent = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid #eaecf0;
  padding: 16px 0;

  & > div {
    width: 20%;
  }
`;

export const GreyButton = styled(AntdButton).attrs({
  type: 'primary',
  size: 'large',
})`
  border-radius: 8px;
  background: #008f5f !important;
  border: #008f5f !important;
`;
