import React from 'react';
import withIcon from '../withIcon';

const TicketsIcon = () => {
  return (
    <svg
      width="42"
      height="40"
      viewBox="0 0 42 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="28.3574" cy="18.5" r="13.5" fill="#C956FF" />
      <path
        d="M32.517 24.4874C32.517 26.6374 34.2837 28.3874 36.4337 28.3874C36.4337 34.6374 34.867 36.2041 28.617 36.2041H12.9837C6.73366 36.2041 5.16699 34.6374 5.16699 28.3874V27.6208C7.31699 27.6208 9.08366 25.8541 9.08366 23.7041C9.08366 21.5541 7.31699 19.7874 5.16699 19.7874V19.0208C5.18366 12.7708 6.73366 11.2041 12.9837 11.2041H28.6003C34.8503 11.2041 36.417 12.7708 36.417 19.0208V20.5874C34.267 20.5874 32.517 22.3208 32.517 24.4874Z"
        stroke="#292D32"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M28.0182 11.2043H12.8682L17.7515 6.321C21.7348 2.33766 23.7348 2.33766 27.7182 6.321L28.7182 7.321C27.6682 8.371 27.4182 9.921 28.0182 11.2043Z"
        stroke="#292D32"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.4648 11.2046L17.4648 36.2046"
        stroke="#292D32"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="5 5"
      />
    </svg>
  );
};

export default withIcon(TicketsIcon);
