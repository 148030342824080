import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Col, Modal, Row, Radio } from 'antd';
import { DropdownAddNewItem, UserSelect } from '../../shared';
import { removeEmptyValueFromObject } from '../../../helpers/helpers';
import { ROLES, ROLE_VALUES, USER_STATUS } from '../../../helpers/variables';
import { getUsersLoading } from '../../../redux/users/selectors';
import {
  createUsersStart,
  updateUsersStart,
} from '../../../redux/users/actions';
import { Input, Select } from '../../shared/styles';
import { Button } from '../styles';
import {
  createDetail,
  fetchDepartmentDetails,
  updateDetail,
} from '../../../service';

function SetUser({ show, onClose, user = {} }) {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const loading = useSelector(getUsersLoading);
  const { departments } = useSelector((state) => state.departments);
  const [details, setDetails] = useState({ locations: [] });

  const iBody = {
    firstName: '',
    lastName: '',
    title: '',
    email: '',
    manager: '',
    location: '',
    status: 'active',
    role: ROLE_VALUES.standard,
    department: departments[0] ? departments[0]._id : '',
  };

  useEffect(() => {
    if (user) {
      form.setFieldsValue({
        ...iBody,
        ...removeEmptyValueFromObject(user),
        department: user.department
          ? user.department._id || user.department
          : '',
        manager: user.manager?._id || '',
      });
    }
  }, [user._id]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleChangeDepartment = (value) => {
    fetchDepartmentDetails(value)
      .then((res) => {
        setDetails({ id: res._id, locations: res.locations || [] });
        form.setFieldValue('location', res.location || '');
      })
      .catch(() => {});
  };

  const handleAddLocations = (locations) => {
    (details._id
      ? updateDetail({ _id: details._id, locations })
      : createDetail({
          locations,
          department: form.getFieldValue('department'),
        })
    )
      .then((res) =>
        setDetails({
          id: res._id,
          locations: res.locations || [],
        }),
      )
      .catch(() => {});
  };

  const handleSubmit = (values) => {
    const manager = values.manager || null;
    if (user._id) {
      dispatch(
        updateUsersStart(user._id, {
          ...values,
          manager,
          email: values.email || undefined,
        }),
      );
    } else {
      dispatch(createUsersStart({ ...values, manager }));
    }
  };

  return (
    <Modal
      open={show}
      title="Create User"
      footer={null}
      width={780}
      onCancel={onClose}
    >
      <Form form={form} colon={false} layout="vertical" onFinish={handleSubmit}>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              name="firstName"
              label="First Name"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input placeholder="First Name" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="lastName"
              label="Last Name"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input placeholder="Last Name" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="title" label="Title">
              <Input placeholder="Title" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="email"
              label="Email"
              rules={[
                {
                  type: 'email',
                },
              ]}
            >
              <Input placeholder="Email" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="department" label="Department">
              <Select
                allowClear
                placeholder="department"
                onChange={handleChangeDepartment}
              >
                {departments.map((department) => (
                  <Select.Option key={department._id} value={department._id}>
                    {department.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item name="manager" label="Manager">
              <UserSelect allowClear placeholder="Manager" />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item shouldUpdate name="location" label="Location">
              <DropdownAddNewItem
                placeholder="Location"
                disabled={!form.getFieldValue('department')}
                options={details.locations}
                onAdd={handleAddLocations}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="role" label="Role">
              <Select allowClear placeholder="Role">
                {ROLES.map(({ name, value }) => (
                  <Select.Option key={value} value={value}>
                    {name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          {user._id && (
            <Col span={12}>
              <Form.Item name="status" label="Status">
                <Radio.Group>
                  <Radio value={USER_STATUS.active}>Active</Radio>
                  <Radio value={USER_STATUS.suspended}>Suspend</Radio>
                  <Radio value={USER_STATUS.archived}>Archived</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          )}
          <Col span={24} style={{ textAlign: 'right' }}>
            <Button htmlType={'submit'} loading={loading}>
              Submit
            </Button>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}

export default SetUser;
