import React, { useEffect, useState } from 'react';
import { ActionButtons, DataTable } from '../../shared';
import { Select } from '../../shared/styles';
import PopUp from './popUp';
import {
  Button,
  Checkbox,
  Form,
  FormItem,
  FormSaveBtnWrap,
  UserInfoWrap,
} from '../styles';
import {
  deleteSharedFolder,
  getFolderPermissions,
  shareFolder,
  updateSharedFolder,
  fetchAllUsers,
  fetchSharedFolderInfo,
} from '../../../service';
import { arrayOfObjectReplaceItem } from '../../../helpers/helpers';
import { PASSWORD_ROLES } from '../../../helpers/variables';

const SharedFoldersInfo = ({ folderId }) => {
  const [form] = Form.useForm();
  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [users, setUsers] = useState([]);
  const [roles, setRoles] = useState({});
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [total, setTotal] = useState([]);

  const columns = [
    {
      dataField: 'user',
      text: 'Name',
      formatter: (cell) => cell.firstName + ' ' + cell.lastName,
    },
    {
      dataField: 'department',
      text: 'Department',
    },
    {
      dataField: 'roles',
      text: 'View',
      formatter: (cell, row) => (
        <Checkbox
          checked={cell?.includes(PASSWORD_ROLES.view)}
          onChange={handleChangeRoles(row, PASSWORD_ROLES.view)}
        />
      ),
    },
    {
      dataField: 'edit',
      text: 'Edit',
      formatter: (cell, row) => (
        <Checkbox
          checked={row.roles?.includes(PASSWORD_ROLES.edit)}
          onChange={handleChangeRoles(row, PASSWORD_ROLES.edit)}
        />
      ),
    },
    {
      dataField: 'add',
      text: 'Add',
      formatter: (cell, row) => (
        <Checkbox
          checked={row.roles?.includes(PASSWORD_ROLES.add)}
          onChange={handleChangeRoles(row, PASSWORD_ROLES.add)}
        />
      ),
    },
    {
      dataField: 'admin',
      text: 'Admin',
      formatter: (cell, row) => (
        <Checkbox
          checked={row.roles?.includes(PASSWORD_ROLES.admin)}
          onChange={handleChangeRoles(row, PASSWORD_ROLES.admin)}
        />
      ),
    },
    roles.admin
      ? {
          dataField: '',
          text: 'Remove',
          formatter: (cell, row) => (
            <>
              <ActionButtons handleDelete={handleDelete(row)} />
            </>
          ),
          headerStyle: () => ({ width: '90px' }),
        }
      : {},
  ];

  useEffect(() => {
    fetchAllUsers().then((res) => setUsers(res));
    if (folderId) {
      fetchData();
      getFolderPermissions(folderId).then((res) => {
        setRoles(res);
      });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchData = (limit = sizePerPage, offset = 0) => {
    setLoading(true);
    fetchSharedFolderInfo(limit, offset, folderId)
      .then((res) => {
        setData(res.data);
        setTotal(res.total);
      })
      .finally(() => setLoading(false));
  };

  const handleTableChange = (type, { page, sizePerPage }) => {
    const currentIndex = (page - 1) * sizePerPage;

    if (type === 'search' || type === 'sort') {
      setPage(1);
      setSizePerPage(sizePerPage);
    } else {
      setPage(page);
      setSizePerPage(sizePerPage);
    }
    fetchData(sizePerPage + currentIndex, currentIndex);
  };

  const handleChangeRoles = (item, role) => (e) => {
    if (roles.admin || roles.edit || roles.add) {
      const { checked } = e.target;
      const roles = checked
        ? [...item.roles, role]
        : item.roles.filter((item) => item !== role);
      updateSharedFolder(item._id, { roles })
        .then((res) => setData((prev) => arrayOfObjectReplaceItem(prev, res)))
        .catch();
    }
  };

  const handleDelete = (row) => () => {
    deleteSharedFolder(row._id)
      .then(() => {
        fetchData();
      })
      .catch();
  };

  const handleSubmit = (values) => {
    shareFolder({ ...values, folder: folderId })
      .then(() => {
        handleClose();
        fetchData();
      })
      .catch();
  };

  const handleClose = () => {
    setOpen(false);
    form.resetFields();
  };

  return (
    <UserInfoWrap>
      <DataTable
        showToolbar={false}
        isSelectRow={false}
        data={data}
        page={page}
        columns={columns}
        loading={loading}
        totalSize={total}
        sizePerPage={sizePerPage}
        refreshData={() => {
          setPage(1);
          fetchData();
        }}
        handleTableChange={handleTableChange}
      />
      {(roles.admin || roles.add) && (
        <PopUp
          Component={() => (
            <Button onClick={() => setOpen(true)}>Add User</Button>
          )}
          open={open}
          onClose={handleClose}
        >
          <Form form={form} onFinish={handleSubmit}>
            <FormItem name={'user'}>
              <Select placeholder="User">
                {users.map((user) => (
                  <Select.Option key={user._id} value={user._id}>
                    {user.firstName + ' ' + user.lastName}
                  </Select.Option>
                ))}
              </Select>
            </FormItem>
            <FormSaveBtnWrap>
              <FormItem name="roles">
                <Checkbox.Group>
                  <Checkbox value="view">View</Checkbox>
                  <Checkbox value="edit">Edit</Checkbox>
                  <Checkbox value="add">Add</Checkbox>
                  <Checkbox value="admin">Admin</Checkbox>
                </Checkbox.Group>
              </FormItem>
              <FormItem style={{ marginLeft: 'auto' }}>
                <Button htmlType="submit">Add</Button>
              </FormItem>
            </FormSaveBtnWrap>
          </Form>
        </PopUp>
      )}
    </UserInfoWrap>
  );
};

export default SharedFoldersInfo;
