import styled from 'styled-components';

export const Wrapper = styled.div``;

export const PlanWrap = styled.div`
  max-width: 572px;
  background: #fff;
  padding: 24px 16px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.06);
  border: 1px solid #98a5cd;
  border-radius: 12px;
  margin: 16px 0;
  position: relative;
`;

export const IconWrap = styled.div`
  position: absolute;
  top: 12px;
  right: 12px;
`;

export const PlanName = styled.div`
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
  color: #374151;
  margin-bottom: 24px;
  text-align: center;
`;

export const PlanInfo = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  grid-gap: 22px;

  & > div:nth-child(2n-1) {
    width: 60%;
  }
`;

export const PlanLabel = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #98a5cd;
  margin-bottom: 8px;
`;

export const PlanText = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #374151;
`;

export const Button = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 9px 17px;
  width: 237px;
  height: 42px;
  border: 1px solid #313131;
  filter: drop-shadow(0 1px 2px rgba(0, 0, 0, 0.05));
  border-radius: 6px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #313131;
  cursor: pointer;
  text-decoration: none;
`;

export const InvoicesWrap = styled.div`
  padding: 18px 0 40px;

  & > p {
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: #111827;
    margin-bottom: 18px;
  }
`;

export const InvoceText = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #111827;
  width: 25%;

  &&& a {
    color: #532fd8;
  }
`;

export const Column = styled.div`
  border-top: 3px solid #f5f8fe;
  padding: 12px;
  display: flex;
  align-items: center;

  &:first-child {
    ${InvoceText} {
      font-size: 16px;
    }
  }

  &:last-child {
    border-bottom: 3px solid #f5f8fe;
  }
`;

export const ShowMore = styled.div`
  text-align: center;
  padding: 24px;
  font-size: 20px;
  color: #532fd8;
  font-weight: 500;
  cursor: pointer;
`;
