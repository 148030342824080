import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { createPasswordFolder } from '../../../service';
import { getPasswordsFoldersStart } from '../../../redux/passwords/actions';
import { PASSWORD_TYPES } from '../../../helpers/variables';
import PopUp from './popUp';
import { Checkbox, Input, Button, FormSaveBtnWrap } from '../styles';

function AddFolders() {
  const dispatch = useDispatch();
  const initialBody = {
    name: '',
    type: PASSWORD_TYPES.personal,
  };
  const [body, setBody] = useState(initialBody);
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setBody(initialBody);
    setOpen(false);
  };

  const handleSubmit = () => {
    if (body.name && body.type) {
      createPasswordFolder(body)
        .then(() => {
          dispatch(getPasswordsFoldersStart());
          handleClose();
        })
        .catch();
    }
  };

  return (
    <PopUp
      Component={() => (
        <Button onClick={() => setOpen(true)}>Create Folder</Button>
      )}
      onClose={handleClose}
      open={open}
    >
      <Input
        placeholder="Name folder"
        value={body.name}
        onChange={({ target }) =>
          setBody((prev) => ({ ...prev, name: target.value }))
        }
      />
      <FormSaveBtnWrap>
        <Checkbox
          checked={body.type === PASSWORD_TYPES.personal}
          onChange={({ target }) =>
            setBody((prev) => ({
              ...prev,
              type: target.checked ? PASSWORD_TYPES.personal : '',
            }))
          }
        >
          Personal folder
        </Checkbox>
        <Checkbox
          checked={body.type === PASSWORD_TYPES.shared}
          onChange={({ target }) =>
            setBody((prev) => ({
              ...prev,
              type: target.checked ? PASSWORD_TYPES.shared : '',
            }))
          }
        >
          Shared folder
        </Checkbox>
        <Button onClick={handleSubmit}>Save</Button>
      </FormSaveBtnWrap>
    </PopUp>
  );
}

export default AddFolders;
