import { settingsTypes } from './types';

const initialState = { error: '', loading: false, data: [] };

export const settings = (state = initialState, action) => {
    switch (action.type) {
        case settingsTypes.GET_SETTINGS_DATA_START:
            return { ...state, loading: true, data: [] };
        case settingsTypes.GET_SETTINGS_DATA_SUCCESS:
            return { ...state, data: action.data, loading: false };
        case settingsTypes.GET_SETTINGS_DATA_FAILURE:
            return { ...state, error: action.error, loading: false };
        default:
            return state;
    }
};
