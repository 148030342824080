import React from 'react';
import withIcon from '../withIcon';

const LogoutIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.55914 8.6301C6.74914 8.6301 6.93914 8.7001 7.08914 8.8501C7.37914 9.1401 7.37914 9.6201 7.08914 9.9101L5.05914 11.9401L7.08914 13.9701C7.37914 14.2601 7.37914 14.7401 7.08914 15.0301C6.79914 15.3201 6.31914 15.3201 6.02914 15.0301L3.46914 12.4701C3.17914 12.1801 3.17914 11.7001 3.46914 11.4101L6.02914 8.8501C6.17914 8.7001 6.36914 8.6301 6.55914 8.6301Z"
        fill="#EB5757"
      />
      <path
        d="M4.0702 11.1899L14.2402 11.1899C14.6502 11.1899 14.9902 11.5299 14.9902 11.9399C14.9902 12.3499 14.6502 12.6899 14.2402 12.6899L4.0702 12.6899C3.6602 12.6899 3.3202 12.3499 3.3202 11.9399C3.3202 11.5299 3.6602 11.1899 4.0702 11.1899Z"
        fill="#EB5757"
      />
      <path
        d="M12.2402 3.25C17.3902 3.25 20.9902 6.85 20.9902 12C20.9902 17.15 17.3902 20.75 12.2402 20.75C11.8302 20.75 11.4902 20.41 11.4902 20C11.4902 19.59 11.8302 19.25 12.2402 19.25C16.5102 19.25 19.4902 16.27 19.4902 12C19.4902 7.73 16.5102 4.75 12.2402 4.75C11.8302 4.75 11.4902 4.41 11.4902 4C11.4902 3.59 11.8302 3.25 12.2402 3.25Z"
        fill="#EB5757"
      />
    </svg>
  );
};

export default withIcon(LogoutIcon);
