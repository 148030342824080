import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import BoardTable from '../board-table';
import { DonutChart } from '../shared';
import { getDateInfo } from '../../helpers/helpers';
import { BOARD_TYPE } from '../../helpers/variables';
import { getDashboardStart } from '../../redux/dashboard/actions';
import {
  Wrapper,
  LeftWrapp,
  Calendar,
  RightWrap,
  Title,
  ItemWrap,
} from './styles';

const Dashboard = () => {
  const dispatch = useDispatch();
  const { offboard, onboard } = useSelector((state) => state.boards);
  const {
    dashboard: { boardLocations },
  } = useSelector((state) => state.dashboard);
  const [date, setCalendar] = useState(new Date());

  useEffect(() => {
    dispatch(getDashboardStart());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const calendarCallback = useCallback(
    ({ date, view }) => {
      if (view === 'month') {
        const { year, month, day } = getDateInfo(date);
        const classes = [];
        if (Array.isArray(onboard.data) && onboard.data.length) {
          const idx = onboard.data.findIndex(
            (item) => `${year}-${month}-${day}` === item.date?.substr(0, 10),
          );
          if (idx !== -1) {
            classes.push('calendarBoards');
          }
        }
        if (Array.isArray(offboard.data) && offboard.data.length) {
          const idx = offboard.data.findIndex(
            (item) => `${year}-${month}-${day}` === item.date?.substr(0, 10),
          );
          if (idx !== -1) {
            if (classes.length) {
              return ['calendarBoards', 'twoCircles'];
            }
            classes.push('calendarBoards', 'calendarOffBoards');
          }
        }
        return classes;
      }
    },
    [offboard, onboard],
  );

  const getChartData = (board) => {
    if (Array.isArray(boardLocations[board])) {
      return boardLocations[board].map((item) => ({
        type: item._id,
        value: item.count,
      }));
    }
    return [];
  };

  const onboardChartData = getChartData(BOARD_TYPE.onboard);
  const offboardChartData = getChartData(BOARD_TYPE.offboard);

  return (
    <Wrapper>
      <RightWrap>
        <BoardTable
          key={BOARD_TYPE.onboard}
          board={BOARD_TYPE.onboard}
          title="Onboards"
          locations={boardLocations[BOARD_TYPE.onboard]}
        />
        <BoardTable
          key={BOARD_TYPE.offboard}
          board={BOARD_TYPE.offboard}
          title="Offboards"
          locations={boardLocations[BOARD_TYPE.offboard]}
        />
      </RightWrap>

      <LeftWrapp>
        <ItemWrap>
          <Calendar
            calendarType="US"
            tileClassName={calendarCallback}
            onChange={setCalendar}
            value={date}
          />
        </ItemWrap>
        {onboard.total && onboardChartData.length ? (
          <ItemWrap>
            <Title>Onboards</Title>
            <DonutChart content={onboard.total} data={onboardChartData} />
          </ItemWrap>
        ) : (
          ''
        )}
        {onboard.total && offboardChartData.length ? (
          <ItemWrap>
            <Title>Offboards</Title>
            <DonutChart content={offboard.total} data={offboardChartData} />
          </ItemWrap>
        ) : (
          ''
        )}
      </LeftWrapp>
    </Wrapper>
  );
};

export default Dashboard;
