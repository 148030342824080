import { tagsTypes } from './types';
import { arrayOfObjectDeleteItem, arrayOfObjectReplaceItem } from '../../helpers/helpers';

const initialState = {
    tags: [],
    error: {
        getAll: '',
        create: '',
        update: '',
        deleteTag: '',
        deleted: '',
    },
    loading: false,
    isLoading: false,
    deleteLoading: false,
    created: {},
    deleted: {},
};

export const tags = (state = initialState, action) => {
    switch (action.type) {
        case tagsTypes.GET_TAGS_START:
            return {
                ...state,
                loading: true,
                error: { ...state.error, getAll: '' },
            };
        case tagsTypes.GET_TAGS_SUCCESS:
            return {
                ...state,
                tags: action.data,
                loading: false,
            };
        case tagsTypes.GET_TAGS_FAILURE:
            return {
                ...state,
                tags: [],
                loading: false,
                error: { ...state.error, getAll: action.error },
            };
        case tagsTypes.CREATE_TAG_START:
            return {
                ...state,
                isLoading: true,
                error: { ...state.error, create: '' },
            };
        case tagsTypes.CREATE_TAG_SUCCESS:
            return {
                ...state,
                created: action.data,
                tags: [...state.tags, action.data],
                isLoading: false,
            };
        case tagsTypes.CREATE_TAG_FAILURE:
            return {
                ...state,
                created: {},
                isLoading: false,
                error: { ...state.error, create: action.error },
            };
        case tagsTypes.UPDATE_TAG_START:
        case tagsTypes.UPDATE_TAG_FIELD_START:
            return {
                ...state,
                error: { ...state.error, update: '' },
                isLoading: true,
            };
        case tagsTypes.UPDATE_TAG_SUCCESS:
            return {
                ...state,
                created: action.data,
                isLoading: false,
                tags: [...arrayOfObjectReplaceItem(state.tags, action.data)],
            };
        case tagsTypes.UPDATE_TAG_FAILURE:
            return {
                ...state,
                error: { ...state.error, update: action.error },
                isLoading: false,
            };
        case tagsTypes.DELETE_TAG_START:
            return {
                ...state,
                error: { ...state.error, deleteTag: '' },
                isLoading: true,
            };
        case tagsTypes.DELETE_TAG_SUCCESS:
            return {
                ...state,
                created: action.data,
                isLoading: false,
                tags: [...arrayOfObjectReplaceItem(state.tags, action.data)],
            };
        case tagsTypes.DELETE_TAG_FAILURE:
            return {
                ...state,
                error: { ...state.error, update: action.error },
                isLoading: false,
            };
        case tagsTypes.DELETE_TAGS_START:
            return {
                ...state,
                error: { ...state.error, update: '' },
                deleteLoading: true,
            };
        case tagsTypes.DELETE_TAGS_SUCCESS:
            return {
                ...state,
                deleted: action.data,
                deleteLoading: false,
                tags: [...arrayOfObjectDeleteItem(state.tags, action.data._id)],
            };
        case tagsTypes.DELETE_TAGS_FAILURE:
            return {
                ...state,
                deleted: {},
                error: { ...state.error, deleted: action.error },
                deleteLoading: false,
            };
        default:
            return state;
    }
};
