import React, { useState, useEffect } from 'react';
import { useLocation, useHistory, Link } from 'react-router-dom';
import qs from 'qs';
import SetIntegration from './SetIntegration';
import {
  fetchSlackAts,
  createIntegration,
  slackOauthIntegration,
} from '../../service';
import { Input, Text, Select, Button, FlexWrapper } from './styles';
import { ROUTES } from '../../helpers/variables';

function SlackIntegrations() {
  const { search } = useLocation();
  const history = useHistory();
  const [err, setError] = useState('');
  const [options, setOptions] = useState([]);
  const [body, setBody] = useState({ onBoardChannel: '', offBoardChannel: '' });
  const { error, code, state } = qs.parse(search, { ignoreQueryPrefix: true });

  useEffect(() => {
    if (code && state === process.env.REACT_APP_SLACK_OAUTH_STATE) {
      slackOauthIntegration(code)
        .then((res) => {
          setOptions(res);
          history.push(ROUTES.slackIntegrations.path);
        })
        .catch((e) => {
          setError(e.message);
        });
    } else {
      fetchSlackAts()
        .then((res) => {
          setOptions(res.channels);
          setBody(res.result);
        })
        .catch((e) => {
          setError(e.response?.data?.message || e.message);
        });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSave = () => {
    createIntegration(body, 'slack')
      .then((res) => {
        if (res?.info) {
          const { onBoardChannel = '', offBoardChannel = '' } = res.info;
          setBody({ onBoardChannel, offBoardChannel });
        }
      })
      .catch((e) => {
        setError(e.response?.data?.message || e.message);
      });
  };

  if (code && state !== process.env.REACT_APP_SLACK_OAUTH_STATE) {
    return 'Invalid data';
  }

  if (error || err) {
    return error || err;
  }

  return (
    <div>
      <Text>Select On / Off board chanels</Text>
      <FlexWrapper>
        <div>
          <Text>On board chanel</Text>
          <Select
            size={'large'}
            style={{ width: 300 }}
            value={body.onBoardChannel}
            onChange={(onBoardChannel) =>
              setBody((prev) => ({ ...prev, onBoardChannel }))
            }
          >
            {options.map((item) => (
              <Select.Option key={item.id} value={item.id}>
                {item.name}
              </Select.Option>
            ))}
          </Select>
        </div>
        <div>
          <Text>Off board chanel</Text>
          <Select
            size={'large'}
            style={{ width: 300 }}
            value={body.offBoardChannel}
            onChange={(offBoardChannel) =>
              setBody((prev) => ({ ...prev, offBoardChannel }))
            }
          >
            {options.map((item) => (
              <Select.Option key={item.id} value={item.id}>
                {item.name}
              </Select.Option>
            ))}
          </Select>
        </div>
        <Button
          size="large"
          style={{ width: 200, marginTop: 10 }}
          onClick={handleSave}
        >
          Save
        </Button>
      </FlexWrapper>
    </div>
  );
}

export function Slack({ data }) {
  const redirectUrl = `${process.env.REACT_APP_ORIGIN_URL}/slack-integrations`;
  const [body, setBody] = useState({ clientId: '', clientSecret: '' });
  const handleChange = (e) => {
    setBody((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  return (
    <SetIntegration
      title="Add your OAuth info."
      name={'slack'}
      body={body}
      data={data}
      label="Slack"
      text={
        <Text>
          Bot Token Scopes: channels:read, channels:manage, chat:write. User
          Token Scopes: channels:write, channels:read, chat:write. Redirect
          URLs: {redirectUrl}
          <br />
          <Link to={ROUTES.slackIntegrations.path}>
            Select On / Off board Channels
          </Link>
        </Text>
      }
      onSuccess={(res) =>
        (window.location.href = `https://slack.com/oauth/v2/authorize?client_id=${res.info?.clientId}&scope=channels:read,channels:manage,chat:write&user_scope=channels:write,channels:read,chat:write&redirect_uri=${redirectUrl}&state=${process.env.REACT_APP_SLACK_OAUTH_STATE}&granular_bot_scope=1&single_channel=0&install_redirect=&tracked=1&team=`)
      }
      editConfigs={<SlackIntegrations />}
    >
      <div>
        <Input
          name="clientId"
          value={body.clientId}
          onChange={handleChange}
          placeholder={'Client Id'}
        />
        <Input
          name="clientSecret"
          value={body.clientSecret}
          onChange={handleChange}
          placeholder={'Client Secret'}
        />
      </div>
    </SetIntegration>
  );
}

export default SlackIntegrations;
