import { put, call, takeLatest } from 'redux-saga/effects';
import * as service from '../../service';
import { departmentsTypes } from './types';
import {
  getDepartmentsSuccess,
  getDepartmentsFailure,
  getDepartmentSuccess,
  getDepartmentFailure,
  createDepartmentSuccess,
  createDepartmentFailure,
  deleteDepartmentSuccess,
  deleteDepartmentFailure,
  updateDepartmentSuccess,
  updateDepartmentFailure,
} from './actions';

export function* fetchDepartments({ payload, setting }) {
  try {
    const data = yield call(() => {
      return service.fetchDepartments(payload, setting);
    });
    yield put(getDepartmentsSuccess(data));
  } catch (e) {
    yield put(getDepartmentsFailure(e.message));
  }
}

export function* fetchDepartment({ payload }) {
  try {
    const data = yield call(() => {
      return service.fetchDepartment(payload);
    });
    yield put(getDepartmentSuccess(data));
  } catch (e) {
    yield put(getDepartmentFailure(e.message));
  }
}

export function* createDepartment({ payload }) {
  try {
    const data = yield call(() => {
      return service.createDepartment(payload);
    });
    yield put(createDepartmentSuccess(data));
  } catch (e) {
    yield put(createDepartmentFailure(e.message));
  }
}

export function* deleteDepartment({ id }) {
  try {
    const data = yield call(() => {
      return service.deleteDepartment(id);
    });
    yield put(deleteDepartmentSuccess(data));
  } catch (e) {
    yield put(deleteDepartmentFailure(e.message));
  }
}

export function* updateDepartment({ body }) {
  try {
    const data = yield call(() => {
      return service.updateDepartment(body);
    });
    yield put(updateDepartmentSuccess(data));
  } catch (e) {
    yield put(updateDepartmentFailure(e.message));
  }
}

export function* cloneDepartment({ body }) {
  try {
    const data = yield call(() => {
      return service.cloneDepartment(body);
    });
    yield put(createDepartmentSuccess(data));
  } catch (e) {
    yield put(createDepartmentFailure(e.message));
  }
}

export function* departmentsSaga() {
  yield takeLatest(departmentsTypes.GET_DEPARTMENTS_START, fetchDepartments);
  yield takeLatest(departmentsTypes.GET_DEPARTMENT_START, fetchDepartment);
  yield takeLatest(departmentsTypes.CREATE_DEPARTMENTS_START, createDepartment);
  yield takeLatest(departmentsTypes.DELETE_DEPARTMENT_START, deleteDepartment);
  yield takeLatest(departmentsTypes.UPDATE_DEPARTMENT_START, updateDepartment);
  yield takeLatest(departmentsTypes.CLONE_DEPARTMENT_START, cloneDepartment);
}
