import React from 'react';
import { Form } from 'react-bootstrap';

export default function ({
  options = [],
  label = 'name',
  label2,
  emptyOption = false,
  ...props
}) {
  return (
    <Form.Control as="select" {...props}>
      {emptyOption && <option value=""></option>}
      {options.map((item, idx) => (
        <option key={idx} value={item._id || item.value}>
          {item[label]} {label2 && item[label2] ? item[label2] : ''}
        </option>
      ))}
    </Form.Control>
  );
}
