import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { Form, Row, Col } from 'antd';
import { GoogleLogin } from '../oauth/google';
import MicrosoftLogin from '../oauth/microsoft';
import { SubmitButton } from '../shared';
import { login, sendPasswordToken } from '../../service';
import { getCurrentUserByToken } from '../../helpers/helpers';
import { logoutStart } from '../../redux/global/actions';
import { ROLE_VALUES, ROUTES } from '../../helpers/variables';
import logo from '../../assets/images/white-logo.png';
import { Container } from '../shared/styles';
import {
  LoginBtnWrap,
  Wrapper,
  LogoWrap,
  FormWrap,
  Title,
  SendEmail,
  Input,
  InputPassword,
} from './styles';

const Login = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [verified, setVerified] = useState();
  const [validate, setValidate] = useState({});

  useEffect(() => {
    dispatch(logoutStart());
    window.google?.accounts?.id &&
      window.google.accounts.id.disableAutoSelect();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSubmit = (body) => {
    setLoading(true);
    setEmail(body.email);
    setValidate({});
    login(body)
      .then(loginSuccess)
      .catch((e) => {
        setLoading(false);
        if (
          e.response &&
          e.response.data &&
          e.response.data.type === 'verified'
        ) {
          setVerified(e.response.data.message);
          return;
        }

        if (e.response && e.response.data && e.response.data.type) {
          setValidate((prev) => ({
            ...prev,
            [e.response.data.type]: e.response.data.message,
          }));
        }
      });
  };

  const loginSuccess = (res) => {
    localStorage.setItem('token', res.token);
    const { role } = getCurrentUserByToken(res.token);
    if (role === ROLE_VALUES.standard || role === ROLE_VALUES.helpdesk) {
      history.push(ROUTES.tickets.path);
    } else {
      history.push(ROUTES.dashboard.path);
    }
  };

  const confirmEmail = async () => {
    try {
      await sendPasswordToken({ email });
    } catch (e) {}
  };

  return (
    <Wrapper>
      <Container>
        <LogoWrap>
          <Link to={ROUTES.home.path}>
            <img src={logo} alt="logo" />
          </Link>
        </LogoWrap>
        <FormWrap>
          <Title>Sign In</Title>
          {verified && (
            <Row>
              <Col span={24}>
                <SendEmail>
                  {verified}{' '}
                  <span onClick={confirmEmail}>Confirm Your Email.</span>
                </SendEmail>
              </Col>
            </Row>
          )}
          <Form onFinish={handleSubmit}>
            <Form.Item
              name="email"
              placeholder="Email"
              validateStatus={validate.email && 'error'}
              help={validate.email}
              rules={[
                {
                  required: true,
                  type: 'email',
                  message: 'Please provide a valid email.',
                },
              ]}
            >
              <Input placeholder="Email" />
            </Form.Item>
            <Form.Item
              name="password"
              validateStatus={validate.password && 'error'}
              help={validate.password}
              rules={[
                {
                  required: true,
                  message: 'Please provide a valid password.',
                },
              ]}
            >
              <InputPassword placeholder="Password" />
            </Form.Item>
            <Row style={{ marginBottom: 12 }}>
              <Col span={16}>
                <span>Don't have an account? </span>
                <Link to={ROUTES.register.path}>Create an account.</Link>
              </Col>
              <Col span={8} style={{ textAlign: 'right' }}>
                <Link to={ROUTES.forgotPassword.path}>Forgot password?</Link>
              </Col>
            </Row>
            <SubmitButton text="Sign In" loading={loading} />
          </Form>
          <LoginBtnWrap>
            <GoogleLogin loginSuccess={loginSuccess} />
            <MicrosoftLogin loginSuccess={loginSuccess} />
          </LoginBtnWrap>
        </FormWrap>
      </Container>
    </Wrapper>
  );
};

export default Login;
