import styled from 'styled-components';
import { Link } from 'react-router-dom';
import {
  Button as AntdButton,
  Form as AntdForm,
  Input as AntdInput,
} from 'antd';

export const Wrapper = styled.div`
  padding: 48px;

  ${(props) => !props.isHome && `width: 50%; margin: auto`};

  @media (max-width: 992px) {
    ${(props) => !props.isHome && `width: 100%;`};
  }
`;

export const LogoWrap = styled.div``;

export const Title = styled.div`
  font-weight: 700;
  font-size: 40px;
  line-height: 48px;
  color: #222833;
  margin-bottom: 60px;
`;

export const Form = styled(AntdForm)``;

export const FormItem = styled(Form.Item)`
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #374151;
`;

export const Input = styled(AntdInput)`
  max-width: 446px;
  width: 100%;
  height: 54px;
  border: 1px solid #8593ad;
  border-radius: 8px;
`;

export const Button = styled(AntdButton)`
  max-width: 446px;
  width: 100%;
  height: 54px;
  background: #5d1fe1;
  border-radius: 8px;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  margin-top: 16px;
`;

export const FlexWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 446px;
  margin-bottom: 40px;
  margin-top: 40px;
`;

export const Line = styled.div`
  max-width: 194px;
  width: 100%;
  border: 1px solid #8593ad;
`;

export const Text = styled(Link)`
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #8593ad;
  text-decoration: none;
  display: flex;
  justify-content: center;

  &:hover {
    text-decoration: none;
  }

  @media (max-width: 1100px) {
    width: 440px;
  }

  @media (max-width: 610px) {
    width: 95%;
  }
`;

export const OauthWrap = styled.div`
  margin: 0 auto 27px;
  width: 440px;

  @media (max-width: 610px) {
    max-width: 440px;
    width: 100%;
    margin: 0 auto 27px;
  }
`;

export const Logo = styled.div`
  margin-bottom: 72px;
  width: 227px;
`;

export const FormWrap = styled.div`
  margin-left: 18%;

  @media (max-width: 610px) {
    margin-left: 0;
  }
`;

export const OauthButtons = styled.div`
  max-width: 446px;

  & > div {
    &:nth-child(2) {
      iframe {
        margin: auto !important;
      }
    }
  }
`;
