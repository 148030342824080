import React from 'react';
import { useDispatch } from 'react-redux';
import {
  updateBoardStart,
  updateBoardTagStart,
} from '../../../redux/boards/actions';
import { InputForm, Tags } from '../../shared';
import styles from '../table.module.scss';
import { arrayOfObjectDeleteItem } from '../../../helpers/helpers';
import { TAG_TYPES } from '../../../helpers/variables';

export default function ({
  board: { tags = [], _id: boardId = '' } = {},
  isEditable,
}) {
  const dispatch = useDispatch();

  const handleClick = (id) => (item) => {
    if (!isEditable) return;
    dispatch(
      updateBoardTagStart(boardId, id, {
        _id: item._id,
        completed: !item.completed,
      }),
    );
  };

  const handleDeleteTag = (id) => (_id) => {
    if (!isEditable) return;
    dispatch(updateBoardTagStart(boardId, id, { _id }));
  };

  const handleAddTag = (id) => (fields) => {
    if (!isEditable) return;
    dispatch(updateBoardTagStart(boardId, id, fields));
  };

  const handleDelete = (id) => () => {
    if (!isEditable) return;
    dispatch(
      updateBoardStart({
        _id: boardId,
        tags: arrayOfObjectDeleteItem(tags, id),
      }),
    );
  };

  const handleSubmit = (title) => {
    if (!isEditable) return;
    dispatch(updateBoardStart({ _id: boardId, tags: [...tags, { title }] }));
  };

  return (
    <>
      {tags.map((item) => (
        <React.Fragment key={item._id}>
          <Tags
            key={item._id}
            name={item.title}
            tags={item.fields}
            type={item.type}
            background={'#E7DEFA'}
            onDelete={item.type === TAG_TYPES.default && handleDelete(item._id)}
            onChange={handleAddTag(item._id)}
            onDeleteTag={handleDeleteTag(item._id)}
            onClick={item.type === TAG_TYPES.default && handleClick(item._id)}
          />
        </React.Fragment>
      ))}
      {isEditable && (
        <div className={styles.tagButtonsGroup}>
          <InputForm
            name="tags"
            placeholder="Add Tag Picklist"
            onSubmit={handleSubmit}
          />
        </div>
      )}
    </>
  );
}
