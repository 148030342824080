import React, { useState, useEffect, useRef } from 'react';
import { Form, Button, Col } from 'react-bootstrap';

export default function ({
  onSubmit,
  btnName,
  className = '',
  value: inputVal = '',
  focus = false,
  col = 10,
  ...restProps
}) {
  const [validated, setValidated] = useState(false);
  const [value, setValue] = useState(inputVal);
  const ref = useRef(null);

  useEffect(() => {
    if (focus) {
      ref.current.focus();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setValue(inputVal);
  }, [inputVal]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const val = value.trim();
    if (!val) {
      setValue('');
      setValidated(true);
      return;
    }
    onSubmit(val);
    setValue('');
    setValidated(false);
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      handleSubmit(e);
    }
  };

  return (
    <Form.Row>
      <Col className={className} xs={btnName ? col : 12}>
        <Form.Control
          ref={ref}
          value={value}
          isInvalid={validated}
          onChange={(e) => setValue(e.target.value)}
          onKeyDown={handleKeyDown}
          {...restProps}
        />
      </Col>
      {btnName && (
        <Col className={className} xs={12 - col}>
          <Button className="ml-2" type="button" onClick={handleSubmit}>
            {btnName}
          </Button>
        </Col>
      )}
    </Form.Row>
  );
}
