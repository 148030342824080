import styled from 'styled-components';
import ReactCalendar from 'react-calendar';

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  grid-gap: 24px;
`;

export const LeftWrapp = styled.div`
  width: 307px;
`;

export const Calendar = styled(ReactCalendar)`
  border-radius: 20px;
  width: auto;
  font-size: 13px;
  .calendarBoards {
    position: relative;
    &:after {
      width: 7px;
      height: 7px;
      border-radius: 50%;
      background-color: #28a745;
      position: absolute;
      content: '';
      left: 1px;
      top: 1px;
      border-radius: 50%;
    }
    &.calendarOffBoards:after {
      background-color: red;
    }
    &.twoCircles {
      &:before {
        width: 7px;
        height: 7px;
        border-radius: 50%;
        background: red;
        position: absolute;
        content: '';
        left: 8px;
        top: 1px;
      }
    }
  }
`;

export const RightWrap = styled.div`
  font-size: 15px;
  @media (max-width: 1430px) {
    font-size: 13px;
  }
  .itemTable {
    background-color: #fff;
    padding: 10px;
  }
  .title {
    font-size: 25px;
    margin-bottom: 15px;
  }
`;

export const Title = styled.div`
  font-weight: 600;
  font-size: 22px;
  line-height: 27px;
  color: #3c475d;
  padding-top: 30px;
  padding-left: 36px;
  margin-bottom: -80px;
`;

export const ItemWrap = styled.div`
  background: #ffffff;
  border-radius: 16px;
  margin-bottom: 22px;
`;
