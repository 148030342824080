import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import PrivateLayout from '../components/layouts/private';
import AccessForbidden from '../components/403';
import Loader from '../components/shared/loader';
import {
  isValidToken,
  planHasPageAccess,
  userHasPagesAccess,
} from '../helpers/helpers';
import {
  getProfileStart,
  getCurrentCompanyStart,
} from '../redux/global/actions';
import { getAllUsersStart } from '../redux/users/actions';
import { PRIVATE_ROUTES } from './index';
import { ROUTES } from '../helpers/variables';

export const PrivateRoute = ({ component: Component, ...rest }) => {
  const { isAuthenticated, currentUserRole, company } = useSelector(
    (state) => state.global,
  );
  const dispatch = useDispatch();
  const token = isValidToken();
  useEffect(() => {
    if (token && !isAuthenticated) {
      dispatch(getProfileStart());
      dispatch(getAllUsersStart());
      dispatch(getCurrentCompanyStart());
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (
    [ROUTES.assets.path, ROUTES.tickets.path, ROUTES.passwords.path].includes(
      rest.path,
    ) &&
    !planHasPageAccess(company, rest.path)
  ) {
    return <AccessForbidden />;
  }

  return (
    <PrivateLayout>
      {token && !isAuthenticated ? (
        <Loader />
      ) : (
        <Route
          {...rest}
          render={(props) =>
            isValidToken() && isAuthenticated ? (
              userHasPagesAccess(PRIVATE_ROUTES, rest.path, currentUserRole) ? (
                <Component {...props} />
              ) : (
                <AccessForbidden />
              )
            ) : (
              <Redirect
                to={{
                  pathname: ROUTES.login.path,
                  state: { from: props.location },
                }}
              />
            )
          }
        />
      )}
    </PrivateLayout>
  );
};
