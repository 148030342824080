export const departmentsTypes = {
    GET_DEPARTMENTS_START: 'GET_DEPARTMENTS_START',
    GET_DEPARTMENTS_SUCCESS: 'GET_DEPARTMENTS_SUCCESS',
    GET_DEPARTMENTS_FAILURE: 'GET_DEPARTMENTS_FAILURE',
    GET_DEPARTMENT_START: 'GET_DEPARTMENT_START',
    GET_DEPARTMENT_SUCCESS: 'GET_DEPARTMENT_SUCCESS',
    GET_DEPARTMENT_FAILURE: 'GET_DEPARTMENT_FAILURE',
    CREATE_DEPARTMENTS_START: 'CREATE_DEPARTMENTS_START',
    CREATE_DEPARTMENTS_SUCCESS: 'CREATE_DEPARTMENTS_SUCCESS',
    CREATE_DEPARTMENTS_FAILURE: 'CREATE_DEPARTMENTS_FAILURE',
    DELETE_DEPARTMENT_START: 'DELETE_DEPARTMENT_START',
    DELETE_DEPARTMENT_SUCCESS: 'DELETE_DEPARTMENT_SUCCESS',
    DELETE_DEPARTMENT_FAILURE: 'DELETE_DEPARTMENT_FAILURE',
    UPDATE_DEPARTMENT_START: 'UPDATE_DEPARTMENT_START',
    UPDATE_DEPARTMENT_SUCCESS: 'UPDATE_DEPARTMENT_SUCCESS',
    UPDATE_DEPARTMENT_FAILURE: 'UPDATE_DEPARTMENT_FAILURE',
    CLONE_DEPARTMENT_START: 'CLONE_DEPARTMENT_START',
};
