import React, { useState } from 'react';
import { Modal, Form, Button } from 'antd';
import {
  updateManyAssets,
  deleteManyAssets,
  singleUpload,
} from '../../service';
import { Input } from './styles';
import { ToolbarButtonsGroup } from '../shared';

function Actions({ selected, reload }) {
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isComment, setComment] = useState(false);

  const handleDelete = async () => {
    await deleteManyAssets({ ids: selected.map(({ _id }) => _id) });
    reload && reload();
  };

  const handleUploadImage = async (file) => {
    const { url } = await singleUpload(file);
    handleSubmit({ image: url });
  };

  const handleSubmit = (values) => {
    const body = { ids: selected.map(({ _id }) => _id) };
    for (let key in values) {
      if (values[key]) {
        body[key] = values[key];
      }
    }
    updateManyAssets(body)
      .then(() => {
        reload && reload();
        setVisible(false);
        setComment(false);
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      <ToolbarButtonsGroup
        disabled={!selected?.length}
        onEdit={() => setVisible(true)}
        onUpload={handleUploadImage}
        onAnnotate={() => {
          setComment(true);
          setVisible(true);
        }}
        onDelete={handleDelete}
      />
      <Modal
        title="Assets"
        footer={null}
        open={visible}
        onOk={handleSubmit}
        onCancel={() => setVisible(false)}
      >
        <Form
          colon={false}
          layout="vertical"
          autoComplete="off"
          onFinish={handleSubmit}
        >
          {isComment ? (
            <Form.Item label="Comment" name="comment">
              <Input />
            </Form.Item>
          ) : (
            <>
              <Form.Item label="Model" name="model">
                <Input />
              </Form.Item>
              <Form.Item label="Tag" name="tag">
                <Input />
              </Form.Item>
            </>
          )}

          <Form.Item>
            <Button type="primary" htmlType="submit" loading={loading}>
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}

export default Actions;
