import { notificationsTypes } from './types';
import {  arrayOfObjectReplaceItem } from '../../helpers/helpers';

const initialState = {
  error: '',
  loading: false,
  data: [],
  total: 0,
  newNotesCount: 0,
};

export const notifications = (state = initialState, action) => {
  switch (action.type) {
    case notificationsTypes.GET_NOTIFICATIONS_START:
      return { ...state, loading: true };
    case notificationsTypes.GET_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        data: [...state.data, ...action.data.data],
        total: action.data.total,
        newNotesCount: +action.data.newNotesCount,
        loading: false,
      };
    case notificationsTypes.GET_NOTIFICATIONS_FAILURE:
      return { ...state, error: action.error, loading: false };
    case notificationsTypes.UPDATE_NOTIFICATIONS_START:
      return { ...state };
    case notificationsTypes.UPDATE_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        data: arrayOfObjectReplaceItem(state.data, action.data),
        newNotesCount: (state.newNotesCount - 1) || 0,
      };
    case notificationsTypes.UPDATE_NOTIFICATIONS_FAILURE:
      return { ...state, error: action.error };
    default:
      return state;
  }
};
