import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getPasswordFoldersState } from '../../redux/passwords/selectors';
import { getPasswordsFoldersStart } from '../../redux/passwords/actions';
import PasswordsList from './components/passwordsList';
import { FolderIcon, UserGroupIcon } from '../../Icons';
import {
  Wrapper,
  FoldersWrap,
  FlexBetween,
  Label,
  Collapse,
  Panel,
  IconsWrap,
} from './styles';
import SharedFoldersInfo from './components/sharedFoldersInfo';
import SetPasswords from './components/setPasswords';
import AddFolders from './components/addFolders';

function Passwords() {
  const dispatch = useDispatch();
  const { folders = [], shared = [] } = useSelector(getPasswordFoldersState);
  const [folderId, setFolderId] = useState();
  const [shareId, setShareId] = useState();
  const [sharedType, setSharedType] = useState('folder');

  useEffect(() => {
    dispatch(getPasswordsFoldersStart());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Wrapper>
      <FoldersWrap>
        <FlexBetween>
          <Label>Personal Folders</Label>
          <FlexBetween>
            <AddFolders />
            {!!(folders?.length || shared?.length) && (
              <div style={{ marginLeft: 28 }}>
                <SetPasswords folders={[...folders, ...shared]} />
              </div>
            )}
          </FlexBetween>
        </FlexBetween>

        <div>
          <Collapse
            accordion
            onChange={(key) => {
              setShareId();
              setFolderId(key);
            }}
            activeKey={folderId}
            expandIconPosition={'end'}
            ghost={true}
          >
            {folders.map((folder) => (
              <Panel
                header={
                  <IconsWrap>
                    <FolderIcon style={{ color: '#9b51e0' }} />
                    {folder.name}
                  </IconsWrap>
                }
                key={folder._id}
              >
                {folder._id === folderId && (
                  <PasswordsList
                    folderId={folderId}
                    folders={[...folders, ...shared]}
                  />
                )}
              </Panel>
            ))}
          </Collapse>
        </div>
      </FoldersWrap>
      <FoldersWrap>
        <FlexBetween>
          <Label>Shared Folders</Label>
        </FlexBetween>

        <div>
          <Collapse
            accordion
            onChange={(key) => {
              setFolderId();
              setShareId(key);
            }}
            activeKey={shareId}
            expandIconPosition={'end'}
            ghost={true}
          >
            {shared.map((item) => (
              <Panel
                header={
                  <IconsWrap>
                    <UserGroupIcon
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setSharedType('user');
                      }}
                      style={{ color: '#333333' }}
                    />
                    <FolderIcon
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setSharedType('folder');
                      }}
                      style={{ color: '#9b51e0' }}
                    />
                    {item.name}
                  </IconsWrap>
                }
                key={item._id}
              >
                {item._id === shareId &&
                  (sharedType === 'folder' ? (
                    <PasswordsList
                      folderId={shareId}
                      folders={[...folders, ...shared]}
                    />
                  ) : (
                    <SharedFoldersInfo folderId={shareId} />
                  ))}
              </Panel>
            ))}
          </Collapse>
        </div>
      </FoldersWrap>
    </Wrapper>
  );
}

export default Passwords;
