import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Modal, Form, Col, Row } from 'antd';
import { BulkUploadBtn, UserSelect } from '../shared';
import {
  createVendorsStart,
  updateVendorsStart,
} from '../../redux/vendors/actions';
import { usePrevious } from '../../helpers/hooks';
import { vendorsBulkUpload } from '../../service';
import { Input, FlexBetween, Select } from '../shared/styles';
import { Button } from './styles.js';

function SetVendors({ data, reset, action, loading, reload }) {
  const dispatch = useDispatch();
  const prevLoading = usePrevious(loading);
  const [form] = Form.useForm();
  const [modalShow, setModalShow] = useState(false);

  useEffect(() => {
    if (data._id) {
      const {
        office,
        description,
        manager,
        email,
        phone,
        status,
        owner,
      } = data;

      form.setFieldsValue({
        office,
        description,
        manager: manager && manager._id ? manager._id : '',
        email,
        phone,
        status,
        owner: owner && owner._id ? owner._id : '',
      });
      setModalShow(true);
    }
  }, [data._id]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (prevLoading && !loading) {
      handleClose();
    }
  }, [loading]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleClose = () => {
    setModalShow(false);
    reset();
    form.resetFields();
  };

  const handleSubmit = (values) => {
    if (action === 'clone' || (!action && !data._id)) {
      dispatch(createVendorsStart({ ...values, owner: values.owner || null }));
    }
    if (action === 'update' && data._id) {
      dispatch(
        updateVendorsStart(
          { ...values, owner: values.owner || null },
          data._id,
        ),
      );
    }
  };

  const handleBulkUpload = (file) => {
    if (file) {
      const formData = new FormData();
      formData.append('file', file);
      vendorsBulkUpload(formData)
        .then(() => reload())
        .catch();
    }
  };

  return (
    <div>
      <FlexBetween>
        <BulkUploadBtn onSubmit={handleBulkUpload} accept=".csv" />
        <Button onClick={() => setModalShow(true)}>Create New</Button>
      </FlexBetween>

      <Modal
        open={modalShow}
        title="Vendors"
        footer={null}
        width={780}
        onCancel={handleClose}
      >
        <Form
          form={form}
          colon={false}
          layout="vertical"
          onFinish={handleSubmit}
        >
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item
                name="office"
                label="Company"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input placeholder="Company" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="description"
                label="Description"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input placeholder="Description" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="manager" label="Account Manager">
                <UserSelect allowClear />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="email"
                label="Email"
                rules={[
                  {
                    type: 'email',
                    required: true,
                  },
                ]}
              >
                <Input placeholder="Email" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="phone" label="Phone Number">
                <Input placeholder="Phone Number" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="status" label="Open Contract">
                <Select placeholder="Open Contract">
                  <Select.Option value="yes">Yes</Select.Option>
                  <Select.Option value="no">No</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="owner" label="Owner">
                <UserSelect allowClear />
              </Form.Item>
            </Col>
            <Col span={24} style={{ textAlign: 'right' }}>
              <Button htmlType={'submit'} loading={loading}>
                Submit
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>
    </div>
  );
}

export default SetVendors;
