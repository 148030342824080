import styled from 'styled-components';
import { Button as AntdButton } from 'antd';

export const Wrapper = styled.div`
  background: #fff;
  padding: 15px;
`;
export const Actions = styled.div`
  min-width: 110px;
`;

export const Button = styled(AntdButton).attrs({ type: 'primary' })`
  width: 138px;
  height: 38px;
  background: #0071ff;
  border-radius: 8px;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: 0.02em;
  color: #ffffff;
`;
