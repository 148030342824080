import { usersTypes } from './types';

const initialState = {
    users: { data: [], total: 0, counts: {} },
    usersList: [],
    error: '',
    loading: false,
    isLoading: false,
    setData: {},
};

export const users = (state = initialState, action) => {
    switch (action.type) {
        case usersTypes.GET_USERS_START:
            return { ...state, loading: true };
        case usersTypes.GET_USERS_SUCCESS:
            return { ...state, users: { ...state.users, ...action.data }, loading: false };
        case usersTypes.GET_ALL_USERS_START:
            return { ...state };
        case usersTypes.GET_ALL_USERS_SUCCESS:
            return { ...state, usersList: action.data };
        case usersTypes.GET_USERS_FAILURE:
            return { ...state, users: { data: [] }, loading: false };
        case usersTypes.CREATE_USERS_START:
            return { ...state, isLoading: true };
        case usersTypes.CREATE_USERS_SUCCESS:
            return {
                ...state,
                setData: action.data,
                isLoading: false,
                error: '',
            };
        case usersTypes.CREATE_USERS_FAILURE:
            return { ...state, error: action.error, isLoading: false };
        case usersTypes.UPDATE_USERS_START:
            return { ...state, isLoading: true };
        case usersTypes.UPDATE_USERS_SUCCESS:
            return {
                ...state,
                setData: action.data,
                isLoading: false,
                error: '',
            };
        case usersTypes.DELETE_USERS_START:
            return { ...state, isLoading: true };
        case usersTypes.DELETE_USERS_SUCCESS:
            return {
                ...state,
                setData: action.data,
                isLoading: false,
                error: '',
            };
        default:
            return state;
    }
};
