import React from 'react';
import withIcon from '../withIcon';

const ArrowIcon = () => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.483 7.99545C20.8715 8.56429 20.3962 9.25 19.7073 9.25H2.94898C2.39669 9.25 1.94897 8.80228 1.94897 8.25V6.42C1.94897 3.98 3.87843 2 6.25614 2H8.51691C10.1053 2 10.6023 2.53 11.2357 3.4L12.6 5.26C12.902 5.67 12.941 5.72 13.5062 5.72H16.225C17.9851 5.72 19.5426 6.6187 20.483 7.99545Z"
        fill="currentColor"
      />
      <path
        d="M20.4222 10.7495C20.9732 10.7495 21.4204 11.1952 21.4222 11.7462L21.4384 16.6498C21.4384 19.5998 19.0997 21.9998 16.225 21.9998H7.1624C4.28771 21.9998 1.94897 19.5998 1.94897 16.6498V11.7498C1.94897 11.1975 2.39668 10.7498 2.94896 10.7498L20.4222 10.7495Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default withIcon(ArrowIcon);
