import React from 'react';
import withIcon from '../withIcon';

const PencilOutlinedIcon = () => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.43025 11.7045L12.119 2.01574C12.9102 1.22457 14.193 1.22457 14.9841 2.01574C15.7753 2.80692 15.7753 4.08965 14.9841 4.88082L5.29533 14.5696C5.0743 14.7906 4.79278 14.9413 4.48627 15.0026L1.375 15.6249L1.99725 12.5136C2.05856 12.2071 2.20922 11.9256 2.43025 11.7045Z"
        stroke="#8593AD"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M10.479 4.146L12.854 6.521" stroke="#8593AD" strokeWidth="2" />
    </svg>
  );
};

export default withIcon(PencilOutlinedIcon);
