import styled from 'styled-components';

import { Button as AntdButton, Tabs as AntdTabs } from 'antd';

export const Wrapper = styled.div`
  background-color: #ffffff;
  padding: 15px;
`;

export const FormWrapper = styled.div`
  width: 376px;
  background: #fff;
  padding: 24px 16px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.06);
  border: 1px solid #98a5cd;
  border-radius: 12px;

  button {
    width: 100%;
  }
`;

export const Button = styled(AntdButton).attrs({
  type: 'primary',
  size: 'large',
})`
  border-radius: 4px;
  margin-top: 24px;
`;

export const AddNewUser = styled(Button)`
  padding: 10px 25px;
  width: 157px;
  height: 38px;
  background-color: #0071ff;
  border-radius: 8px;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: 0.02em;
  color: #ffffff;
  border: unset;
  margin-bottom: 18px;
`;

export const Tabs = styled(AntdTabs)`
  & > div:first-child {
    &:before {
      border-bottom: unset;
    }
  }

  &&& {
    .ant-tabs-tab {
      background: none;
      border: none;
      padding: 8px 12px;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #4b5563;
      margin-right: 24px;
    }

    .ant-tabs-tab-active {
      background: #e5e7eb;
      border-radius: 6px;

      div {
        color: #1f2937;
      }
    }
  }
`;
