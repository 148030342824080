import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import SignUp from '../signup';
import {
  AssetManagerIcon,
  PasswordManagerIcon,
  SyncAtsIcon,
  SyncOffIcon,
  SyncOnIcon,
  TicketsIcon,
  VendorManagerIcon,
} from '../../Icons';
import { ROUTES } from '../../helpers/variables';
import dashboardImg from '../../assets/images/thelma-dashboard.png';
import {
  Wrapper,
  FlexWrap,
  BoardInfoWrap,
  BoardInfoTitle,
  BoardInfoText,
  DashboardImg,
  SyncInfoWrap,
  Title,
  SyncContent,
  SyncContentTitle,
  SyncContentText,
  SyncImgWrap,
  OtherToolsWrap,
  OtherToolsContent,
  ItemContent,
  ShowLessWrapper,
} from './styles';

const syncInfo = [
  {
    icon: SyncAtsIcon,
    title: 'Sync your ATS',
    text:
      'Bring your ATS with you. Pull in all the new hire details you need to get onboarding started.',
  },
  {
    icon: SyncOnIcon,
    title: 'Automated Onboarding',
    text:
      'Create email accounts, add to distribution lists and shared drives without lifting a finger.',
  },
  {
    icon: SyncOffIcon,
    title: 'Automated Offboarding',
    text:
      'Disable accounts, set out of office emails, move personal drives and much more.',
  },
];

const otherTools = [
  {
    icon: PasswordManagerIcon,
    title: 'Team Password Manager',
    text:
      'Share team passwords using secure shared folders. Create and store personal passwords as well.',
  },
  {
    icon: AssetManagerIcon,
    title: 'Asset Manager',
    text:
      'Manage computers, software, and other assets on the fly. Assign and checkout devices to new hires quickly and easily.',
  },
  {
    icon: TicketsIcon,
    title: 'Tickets',
    text:
      'Be a first responder to team requests to give your staff the rapid support they need. Update and respond to tickets without having to swivel between applications.',
  },
  {
    icon: VendorManagerIcon,
    title: 'Vendor Manager',
    text:
      'Forget the spreadsheets and manage contracts and vendors with Thelma. Never be in the dark on who owns what again.',
  },
];

const images = [
  'google',
  'microsoft',
  'okta',
  'applicantstack',
  'ashby',
  'bamboohr',
  'bizmerlinhr',
  'breezy',
  'cats',
  'clockwork',
  'comeet',
  'cornerstone',
  'engageats',
  'employ',
  'fountain',
  'freshteam',
  'greenhouse',
  'hr',
  'harbour',
  'homerun',
  'icims',
  'jazzhr',
  'jobadder',
  'jobsoid',
  'jobvite',
  'lano',
  'lever',
  'taleo',
  'personio',
  'polymer',
  'recruitee',
  'recruiterflow',
  'recruitive',
  'sapsuccessfactors',
  'saga',
  'smartrecruiters',
  'talantlyft',
  'talantreef',
  'teamtailor',
  'ukg',
  'workable',
  'workday',
];

const Home = () => {
  const [showLess, setShowLess] = useState(false);

  return (
    <Wrapper>
      <FlexWrap>
        <SignUp isHome />
        <BoardInfoWrap>
          <BoardInfoTitle>Employee onboarding made easy.</BoardInfoTitle>
          <BoardInfoText>
            Simplify your employee lifecycle with Thelma.
          </BoardInfoText>
          <DashboardImg src={dashboardImg} />
        </BoardInfoWrap>
      </FlexWrap>
      <SyncInfoWrap>
        <Title>Automated Onboarding and Offboarding</Title>
        <SyncContent>
          <div>
            {syncInfo.map((item, index) => {
              const Icon = item.icon;
              return (
                <div key={index}>
                  <Icon />
                  <SyncContentTitle>{item.title}</SyncContentTitle>
                  <SyncContentText>{item.text}</SyncContentText>
                </div>
              );
            })}
          </div>
          <SyncImgWrap showLess={showLess}>
            {images.map((name, index) => (
              <div key={index}>
                <Link to={ROUTES.integrations.path}>
                  <img
                    src={require(`../../assets/images/ats/${name}.png`)}
                    alt={'ats sync'}
                  />
                </Link>
              </div>
            ))}
          </SyncImgWrap>
          <ShowLessWrapper onClick={() => setShowLess((prev) => !prev)}>
            {showLess ? 'SHOW LESS' : 'SHOW MORE'}
          </ShowLessWrapper>
        </SyncContent>
      </SyncInfoWrap>
      <OtherToolsWrap>
        <Title>All The Other Tools You Need</Title>
        <OtherToolsContent>
          {otherTools.map((item, index) => {
            const Icon = item.icon;
            return (
              <div key={index}>
                <ItemContent>
                  <Icon />
                  <SyncContentTitle>{item.title}</SyncContentTitle>
                  <SyncContentText>{item.text}</SyncContentText>
                </ItemContent>
                <div />
              </div>
            );
          })}
        </OtherToolsContent>
      </OtherToolsWrap>
    </Wrapper>
  );
};
export default Home;
