import { put, call, takeLatest } from 'redux-saga/effects';
import * as service from '../../service';
import { usersTypes } from './types';
import {
  getUsersSuccess,
  getUsersFailure,
  getAllUsersSuccess,
  createUsersSuccess,
  createUsersFailure,
  updateUsersSuccess,
  deleteUsersSuccess,
} from './actions';

export function* getUsers({ payload }) {
  try {
    const data = yield call(() => {
      return service.fetchUsers(payload);
    });
    yield put(getUsersSuccess(data));
  } catch (e) {
    yield put(getUsersFailure(e.message));
  }
}

export function* getAllUsers() {
  try {
    const data = yield call(() => {
      return service.fetchAllUsers();
    });
    yield put(getAllUsersSuccess(data));
  } catch (e) {}
}

export function* create({ body }) {
  try {
    const data = yield call(() => {
      return service.createUsers(body);
    });
    yield put(createUsersSuccess(data));
  } catch (e) {
    yield put(createUsersFailure(e.message));
  }
}

export function* update({ id, body }) {
  try {
    const data = yield call(() => {
      return service.updateUsers(id, body);
    });
    yield put(updateUsersSuccess(data));
  } catch (e) {
    yield put(createUsersFailure(e.message));
  }
}

export function* deleteUser({ id }) {
  try {
    const data = yield call(() => {
      return service.deleteUser(id);
    });
    yield put(deleteUsersSuccess(data));
  } catch (e) {
    yield put(createUsersFailure(e.message));
  }
}

export function* usersSaga() {
  yield takeLatest(usersTypes.GET_USERS_START, getUsers);
  yield takeLatest(usersTypes.GET_ALL_USERS_START, getAllUsers);
  yield takeLatest(usersTypes.CREATE_USERS_START, create);
  yield takeLatest(usersTypes.UPDATE_USERS_START, update);
  yield takeLatest(usersTypes.DELETE_USERS_START, deleteUser);
}
