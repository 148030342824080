import { put, call, takeLatest } from 'redux-saga/effects';
import * as service from '../../service';
import { checklistsTypes } from './types';
import {
  getChecklistsSuccess,
  getChecklistsFailure,
  createChecklistSuccess,
  createChecklistFailure,
  deleteChecklistSuccess,
  deleteChecklistFailure,
  updateChecklistSuccess,
  updateChecklistFailure,
} from './actions';

export function* fetchChecklists({ payload }) {
  try {
    const data = yield call(() => {
      return service.fetchDepartmentChecklists(payload);
    });
    yield put(getChecklistsSuccess(data));
  } catch (e) {
    yield put(getChecklistsFailure(e.message));
  }
}

export function* createChecklist({ payload }) {
  try {
    const data = yield call(() => {
      return service.createChecklist(payload);
    });
    yield put(createChecklistSuccess(data));
  } catch (e) {
    yield put(createChecklistFailure(e.message));
  }
}

export function* deleteChecklist({ id }) {
  try {
    const data = yield call(() => {
      return service.deleteChecklist(id);
    });
    yield put(deleteChecklistSuccess(data));
  } catch (e) {
    yield put(deleteChecklistFailure(e.message));
  }
}

export function* updateChecklist({ body }) {
  try {
    const data = yield call(() => {
      return service.updateChecklist(body);
    });
    yield put(updateChecklistSuccess(data));
  } catch (e) {
    yield put(updateChecklistFailure(e.message));
  }
}

export function* checklistsSaga() {
  yield takeLatest(checklistsTypes.GET_CHECKLISTS_START, fetchChecklists);
  yield takeLatest(checklistsTypes.CREATE_CHECKLISTS_START, createChecklist);
  yield takeLatest(checklistsTypes.DELETE_CHECKLIST_START, deleteChecklist);
  yield takeLatest(checklistsTypes.UPDATE_CHECKLIST_START, updateChecklist);
}
