import React from 'react';
import withIcon from '../withIcon';

const SyncAtsIcon = () => {
  return (
    <svg
      width="41"
      height="40"
      viewBox="0 0 41 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M41 13.5C41 20.9558 34.9558 27 27.5 27C20.0442 27 14 20.9558 14 13.5C14 6.04416 20.0442 0 27.5 0C34.9558 0 41 6.04416 41 13.5Z"
        fill="#C956FF"
      />
      <path
        d="M33.3333 23.3332C35.1743 23.3332 36.6667 21.8408 36.6667 19.9998C36.6667 18.1589 35.1743 16.6665 33.3333 16.6665C31.4924 16.6665 30 18.1589 30 19.9998C30 21.8408 31.4924 23.3332 33.3333 23.3332Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M33.3333 10.0002C35.1743 10.0002 36.6667 8.50778 36.6667 6.66683C36.6667 4.82588 35.1743 3.3335 33.3333 3.3335C31.4924 3.3335 30 4.82588 30 6.66683C30 8.50778 31.4924 10.0002 33.3333 10.0002Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M33.3333 36.6667C35.1743 36.6667 36.6667 35.1743 36.6667 33.3333C36.6667 31.4924 35.1743 30 33.3333 30C31.4924 30 30 31.4924 30 33.3333C30 35.1743 31.4924 36.6667 33.3333 36.6667Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.66634 23.3332C8.50729 23.3332 9.99967 21.8408 9.99967 19.9998C9.99967 18.1589 8.50729 16.6665 6.66634 16.6665C4.82539 16.6665 3.33301 18.1589 3.33301 19.9998C3.33301 21.8408 4.82539 23.3332 6.66634 23.3332Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 20H30"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M29.9997 6.6665H23.333C19.9997 6.6665 18.333 8.33317 18.333 11.6665V28.3332C18.333 31.6665 19.9997 33.3332 23.333 33.3332H29.9997"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default withIcon(SyncAtsIcon);
