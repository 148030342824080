import styled from 'styled-components';
import { Button as AntdButton, Input as AntdInput } from 'antd';

export const Wrapper = styled.div`
  padding: 15px 0;

  .tickets-message-content {
    background: #f6f7f9;
    border-radius: 18px;
    border: unset;
  }
`;

export const Button = styled.button`
  width: 101px;
  height: 32px;
  border-radius: 40px;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.01em;
  cursor: pointer;
  border: unset;
`;

export const CreateButton = styled.button`
  padding: 0 25px;
  height: 38px;
  background: #9b51e0;
  border-radius: 8px;
  border: none;
  font-weight: 500;
  font-size: 15px;
  color: #ffffff;
`;

export const ExpandRowWrap = styled.div`
  width: 100%;
  padding: 15px;
`;

export const ShareButtonsWrap = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  background: #ffffff;
  height: 61px;

  & > div {
    border-right: 3px solid #f9fafd;
    padding: 0 72px;
  }
`;

export const ShareButtons = styled(AntdButton)`
  display: flex;
  align-items: center;
  border: unset;
  color: #4f4f4f;
  font-weight: 700;
  box-shadow: none;

  svg {
    font-size: 20px;

    &:last-child {
      font-size: 18px;
    }
  }
`;

export const MessageWrap = styled.div`
  margin: 30px 0;

  & > div {
    background: #fff;
    padding: 15px;
    margin-bottom: 25px;
  }
`;

export const ActionsWrap = styled.div`
  background: #ffffff;
  display: flex;
  align-items: center;
  margin: 10px 0 10px 10px;

  ${(props) => props.disabled && `opacity: 0.7; pointer-events: none;`}

  & > div {
    width: 46px;
    height: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: 1px solid #d1d5db;
    border-right: unset;

    &:first-child {
      border-radius: 6px 0 0 6px;
    }

    &:last-child {
      border-right: 1px solid #d1d5db;
      border-radius: 0 6px 6px 0;
    }

    svg {
      color: #9ca3af;
      font-size: 14px;
    }
  }
`;

export const Input = styled(AntdInput)`
  border-radius: 6px;
  height: 38px;
`;

export const ReplyForm = styled.div`
  position: relative;
  margin-bottom: 10px;

  & > div {
    display: flex;
    align-items: center;
    position: absolute;
    right: 14px;
    bottom: 12px;
    grid-gap: 21px;

    & > div {
      width: auto;
      color: #9ca3af;
      cursor: pointer;
    }
  }
`;

export const TextArea = styled(AntdInput.TextArea)`
  border: none;
  padding: 18px;

  &:focus {
    outline: none;
    box-shadow: none;
  }
`;

export const ReplySubmit = styled(AntdButton)`
  background: #9b51e0;
  border-radius: 8px;
  height: 38px;
  color: #fff;

  &:hover {
    background: #9b51e0;
    color: #fff;
  }
`;

export const ForwardForm = styled.div`
  max-width: 539px;
  width: 100%;
  height: 66px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px;

  & > div {
    display: flex;
    align-items: center;
    grid-gap: 20px;

    & > span {
      font-size: 16px;
      font-weight: 700;
      color: #4f4f4f;
    }

    input {
      width: 250px;
    }
  }
`;
