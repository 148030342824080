import React, { useRef, useState } from 'react';
import { Button as AntdButton } from 'antd';
import styled from 'styled-components';
import { DeleteIcon } from '../../../Icons';

const Button = styled(AntdButton).attrs({
  htmlType: 'button',
  type: 'primary',
})`
  width: 284px;
  height: 42px;
  background: #4f46e5;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
`;

const Text = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 10px;
`;

function BulkUploadBtn({ onSubmit, loading, ...rest }) {
  const fileInput = useRef(null);
  const [name, setName] = useState('');

  const handleChange = (e) => {
    setName(e.target.files[0].name);
  };

  const handleReset = () => {
    if (fileInput.current) {
      fileInput.current.value = null;
      setName('');
    }
  };

  const handleClick = () => {
    fileInput.current && fileInput.current.click();
  };

  const handleSubmit = () => {
    fileInput.current?.files && onSubmit(fileInput.current?.files[0]);
    handleReset();
  };

  return (
    <>
      <input
        ref={fileInput}
        type="file"
        onChange={handleChange}
        style={{ display: 'none' }}
        {...rest}
      />
      {name ? (
        <>
          <Button onClick={handleSubmit} loading={loading}>
            Upload
          </Button>
          <Text>
            {name}
            <DeleteIcon onClick={handleReset} />
          </Text>
        </>
      ) : (
        <Button onClick={handleClick}>Bulk Upload</Button>
      )}
    </>
  );
}

export default BulkUploadBtn;
