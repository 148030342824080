import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { FilterIcon } from '../../../Icons';
import { useOutsideClick } from '../../../helpers/hooks';

const Wrapper = styled.div`
  position: relative;
`;

const Wrap = styled.div`
  z-index: 9;
  position: absolute;
  top: 35px;
  right: -45px;
  padding: 16px 24px;
  background: #ffffff;
  border: 2px solid #f6f7f9;
  border-radius: 4px;
  outline: none;
  display: ${(props) => (props.show ? 'block' : 'none')};
  box-shadow: 0 3px 6px -4px #0000001f, 0 6px 16px #00000014,
    0 9px 28px 8px #0000000d;

  input {
    min-width: 300px;
    border-radius: 4px !important;
    margin-bottom: 16px;
  }

  .ant-checkbox-inner {
    border-radius: 4px !important;
  }
`;

function TableFilterDropdown({ children }) {
  const ref = useRef();
  const [show, setShow] = useState(false);

  useOutsideClick(ref, () => setShow(false));

  return (
    <Wrapper ref={ref}>
      <FilterIcon
        style={{ fontSize: 22 }}
        onClick={() => setShow((prev) => !prev)}
      />

      <Wrap show={show}>{children}</Wrap>
    </Wrapper>
  );
}

export default TableFilterDropdown;
