import styled from 'styled-components';
import {
  Button as AntdButton,
  Form as AntdForm,
  Input as AntdInput,
  Select as AntdSelect,
} from 'antd';

export const Form = styled(AntdForm)``;

export const FormItem = styled(Form.Item)``;

export const Input = styled(AntdInput).attrs({ size: 'large' })`
  border-radius: 6px;
`;

export const Button = styled(AntdButton).attrs({ size: 'large' })`
  border-radius: 6px;
  margin-top: 20px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #9b51e0 !important;
  border-color: #9b51e0 !important;
  background: #ffffff !important;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.06);
  border-radius: 10px;
  padding: 10px 18px;
`;

export const Select = styled(AntdSelect).attrs({ size: 'large' })`
  border-radius: 6px;

  &&& > div {
    border-radius: 6px;
  }
`;
