import React from 'react';
// import Header from '../../layouts/header';
import Footer from '../../layouts/footer';
import { strReplaceAll } from '../../../helpers/helpers';
import styles from './public.module.scss';

const PublicLayout = ({ children }) => {
  const { path = '' } = children.props;
  return (
    <div
      className={`${styles.publicLayout} ${
        path.length > 1 ? strReplaceAll(path, '/', 'public_') : 'public_home'
      }`}
    >
      {/*<Header />*/}
      {children}
      <Footer />
    </div>
  );
};

export default PublicLayout;
