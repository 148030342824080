import React, { useState } from 'react';
import { Form, Col } from 'antd';
import { BulkUploadBtn } from '../../shared';
import { usersBulkUpload } from '../../../service';
import { ROLE_VALUES, ROLES, USER_STATUS } from '../../../helpers/variables';
import { Select, TextArea } from '../../shared/styles';
import { FormWrapper, Button, Tabs } from '../styles';

export default function ({ onReload, counts = {} }) {
  const tabs = [
    {
      key: USER_STATUS.active,
      title: `Active Users (${counts[USER_STATUS.active] || 0})`,
    },
    {
      key: USER_STATUS.archived,
      title: `Disabled Users (${counts[USER_STATUS.archived] || 0})`,
    },
    {
      key: USER_STATUS.suspended,
      title: `Imported Users (${counts[USER_STATUS.suspended] || 0})`,
    },
  ];

  const [key, setKey] = useState(USER_STATUS.active);
  const [loading, setLoading] = useState(false);

  const handleChangeTab = (key) => {
    setKey(key);
    onReload(key);
  };

  const handleSubmit = (values) => {
    console.log('values: ', values);
  };

  const handleBulkUpload = (file) => {
    if (file) {
      setLoading(true);
      const formData = new FormData();
      formData.append('file', file);
      usersBulkUpload(formData)
        .then(() => {
          setLoading(false);
          onReload();
        })
        .catch((e) => {
          setLoading(false);
        });
    }
  };

  const TabContent = () => (
    <FormWrapper>
      <Form
        colon={false}
        layout="vertical"
        initialValues={{ role: ROLE_VALUES.standard, text: '' }}
        onFinish={handleSubmit}
      >
        <Col>
          <Form.Item name="role" label="Role">
            <Select placeholder="Role">
              {ROLES.map(({ name, value }) => (
                <Select.Option key={value} value={value}>
                  {name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col>
          <Form.Item name="text" label="Add Text">
            <TextArea />
          </Form.Item>
        </Col>
        <Col>
          <BulkUploadBtn
            onSubmit={handleBulkUpload}
            accept=".csv"
            loading={loading}
          />

          <Button htmlType={'submit'}>Send</Button>
        </Col>
      </Form>
    </FormWrapper>
  );

  return (
    <Tabs
      activeKey={key}
      type="card"
      onChange={handleChangeTab}
      items={tabs.map(({ key, title }, idx) => ({
        label: title,
        key,
        children: <TabContent />,
      }))}
    />
  );
}
