import React, { useEffect, useState } from 'react';
import { PasswordWrapper, ItemPassword, Label, PasswordImage } from '../styles';
import SetPassword from './setPasswords';
import { useDispatch, useSelector } from 'react-redux';
import { getPasswordsState } from '../../../redux/passwords/selectors';
import { getPasswordsStart } from '../../../redux/passwords/actions';
import { getFolderPermissions } from '../../../service';
import { Loader } from '../../shared';

const PasswordsList = ({ folderId, folders = [] }) => {
  const dispatch = useDispatch();
  const { passwords, loading } = useSelector(getPasswordsState);
  const { data = [] } = passwords;
  const [roles, setRoles] = useState({});

  useEffect(() => {
    fetchData();
    getFolderPermissions(folderId).then((res) => {
      setRoles(res);
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchData = () => {
    if (folderId) {
      dispatch(getPasswordsStart({ folderId, offset: 0, limit: 1000 }));
    }
  };

  if (loading) {
    return (
      <PasswordWrapper>
        <Loader />
      </PasswordWrapper>
    );
  }
  return (
    <PasswordWrapper>
      {data.map((item) => {
        return (
          <SetPassword
            key={item._id}
            data={item}
            editable={roles.admin || roles.edit || roles.add}
            folders={folders}
            reload={fetchData}
            Component={() => (
              <ItemPassword key={item._id}>
                <PasswordImage>
                  <img
                    src={`https://logo.clearbit.com/${item.url}`}
                    alt={item.name}
                  />
                </PasswordImage>
                <Label>{item.name}</Label>
              </ItemPassword>
            )}
          />
        );
      })}
    </PasswordWrapper>
  );
};

export default PasswordsList;
