import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Input, Checkbox } from 'antd';
import {TableFilterDropdown} from '../../shared';
import { debounced300 } from '../../../helpers/helpers';

const Label = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #374151;
  margin-bottom: 4px;
`;

function Filters({ locations, onFilter }) {
  const [body, setBody] = useState({});
  const [options, setOptions] = useState([]);


  const handleSearch = (e) => {
    const search = e.target.value;
    setBody((prev) => ({ ...prev, search }));
    debounced300(() => onFilter({ ...body, search }));
  };

  const handleChange = (name, value) => {
    const data = { ...body, [name]: value };
    setBody(data);
    onFilter(data);
  };

  useEffect(() => {
    if (locations) {
      setOptions(
        locations.map((item) => ({
          label: item._id,
          value: item._id,
        })),
      );
    }
  }, [locations?.length]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <TableFilterDropdown>
      <>
        <Label>Search</Label>
        <Input value={body.search} onChange={handleSearch} />
        <Label>Location</Label>
        <Checkbox.Group
          style={{ marginBottom: 8 }}
          options={options}
          onChange={(value) => handleChange('location', value)}
        />
        <Label>Status</Label>
        <Checkbox.Group
          defaultValue={['active']}
          onChange={(value) => handleChange('status', value)}
          options={[
            {
              label: 'Archived',
              value: 'archived',
            },
            {
              label: 'Active',
              value: 'active',
            },
          ]}
        />
      </>
    </TableFilterDropdown>
  );
}

export default Filters;
