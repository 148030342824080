import React from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { createDepartmentStart } from '../../redux/departments/actions';
import { FormInput } from '../shared';

const Wrapper = styled.div`
  form {
    input {
      width: 582px;
      height: 50px;
    }
  }
`;

const Label = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #374151;
  margin-bottom: 4px;
`;

export default function ({ board = '' }) {
  const dispatch = useDispatch();
  const handleSubmit = (value) => {
    dispatch(createDepartmentStart({ name: value, board }));
  };

  return (
    <Wrapper>
      <Label>Click to add a department</Label>
      <FormInput placeholder="Type department name" onSubmit={handleSubmit} />
    </Wrapper>
  );
}
