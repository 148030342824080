import React, { useState, useCallback, useEffect } from 'react';
import { useMergeLink } from '@mergeapi/react-merge-link';
import Actions from './actions';
import { CheckCircleFilled } from '../../Icons';
import {
  createMergeDevLinkToken,
  fetchMergeDevAtsIntegrations,
  createMergeDevAccountToken,
  deleteMergeDevAtsIntegration,
  fetchMergeDevSyncedAts,
} from '../../service';
import {
  AtsWrap,
  AtsName,
  AtsImage,
  Button,
  InfoIconWrap,
  ButtonWrap,
} from './styles';

function Ats({ data, isSynced }) {
  const [linkToken, setLinkToken] = useState('');
  const [isIntegrated, setIsIntegrated] = useState(false);

  useEffect(() => {
    setIsIntegrated(isSynced);
  }, [isSynced]);

  const onSuccess = useCallback((public_token) => {
    createMergeDevAccountToken({ publicToken: public_token })
      .then(() => setIsIntegrated(true))
      .catch();
  }, []);

  const { open } = useMergeLink({
    linkToken,
    onSuccess,
  });

  useEffect(() => {
    if (linkToken) {
      open();
    }
  }, [linkToken]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleClick = () => {
    createMergeDevLinkToken(data.slug)
      .then((res) => {
        setLinkToken(res.link_token);
      })
      .catch();
  };

  const handleDelete = (slug) => {
    deleteMergeDevAtsIntegration(slug)
      .then(() => setIsIntegrated(false))
      .catch();
  };

  return (
    <AtsWrap>
      <InfoIconWrap>
        {isIntegrated && <Actions onDelete={() => handleDelete(data.slug)} />}
      </InfoIconWrap>
      <AtsImage
        style={{
          backgroundImage: `url(${data.image})`,
        }}
      />
      <ButtonWrap>
        {isIntegrated ? (
          <CheckCircleFilled style={{ color: '#26DE81', fontSize: 30 }} />
        ) : (
          <Button onClick={handleClick}>Sync</Button>
        )}
      </ButtonWrap>

      <AtsName>{data.name}</AtsName>
    </AtsWrap>
  );
}

function MergeDev() {
  const [data, setData] = useState([]);
  const [synced, setSynced] = useState({});
  useEffect(() => {
    fetchMergeDevAtsIntegrations()
      .then((res) =>
        setData(
          res?.filter(
            (item) =>
              item.enabled_categories?.includes('ats') &&
              !['lever', 'greenhouse'].includes(item.slug),
          ),
        ),
      )
      .catch();
    fetchMergeDevSyncedAts().then(setSynced).catch();
  }, []);

  return data.map((ats) => (
    <Ats key={ats.slug} data={ats} isSynced={!!synced[ats.slug]} />
  ));
}

export default MergeDev;
