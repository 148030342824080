import { call, put, takeLatest } from 'redux-saga/effects';
import { getSettingsDataFailure, getSettingsDataSuccess } from './actions';
import { settingsTypes } from './types';
import * as service from '../../service';

export function* getSettingsDataStart({ board }) {
    try {
        const data = yield call(() => {
            return service.fetchAllDepartmentsData(board);
        });
        yield put(getSettingsDataSuccess(data));
    } catch (e) {
        yield put(getSettingsDataFailure(e.message));
    }
}

export function* settingsSaga() {
    yield takeLatest(
        settingsTypes.GET_SETTINGS_DATA_START,
        getSettingsDataStart,
    );
}
