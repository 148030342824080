import React, { useRef, useState, useEffect } from 'react';
import icon from '../../../assets/images/paperclip.svg';
import trashFile from '../../../assets/images/trash-file.svg';

function FileUploadBtn({ onFileSelect, clearFile, ...rest }) {
  const fileInput = useRef(null);
  const [name, setName] = useState('');

  useEffect(() => {
    if (name && clearFile) {
      handleReset();
    }
  }, [clearFile]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleFileInput = (e) => {
    // handle validations
    if (typeof onFileSelect === 'function') {
      setName(e.target.files[0].name);
      onFileSelect(e.target.files[0]);
    }
  };

  const handleReset = () => {
    if (fileInput.current) {
      fileInput.current.value = null;
      onFileSelect(null);
      setName('');
    }
  };

  const handleClick = (e) => {
    fileInput.current && fileInput.current.click();
  };

  return (
    <div className="file-uploader">
      <img
        src={trashFile}
        className={`icon-20 ${name ? '' : 'd-none'}`}
        onClick={handleReset}
        alt="Attach file"
        title={`Clear ${name}`}
      />
      <div className={name ? 'd-none' : ''}>
        <input
          ref={fileInput}
          type="file"
          onChange={handleFileInput}
          className="d-none"
          {...rest}
        />
        <img
          src={icon}
          className="icon-20"
          onClick={handleClick}
          alt="Attach file"
          title="Attach file"
        />
      </div>
    </div>
  );
}

export default FileUploadBtn;
