import React, { useState, useRef } from 'react';
import { Form, Button, Toast } from 'react-bootstrap';
import { UsersAutoComplete, UserAvatar } from '../../shared';
import {
  createEmailHyperlink,
  createTextHyperLink,
  getCurrentUserByToken,
} from '../../../helpers/helpers';
import { useOutsideClick } from '../../../helpers/hooks';
import { singleUpload } from '../../../service';
import styles from '../table.module.scss';

function ChecklistComment({ onSubmit, onCancel, data = {} }) {
  const ref = useRef(null);
  const [value, setValue] = useState('');
  const [file, setFile] = useState(null);

  useOutsideClick(ref, onCancel);

  const handleSubmit = async (e) => {
    e.preventDefault();
    let comment = value.trim();
    if (file) {
      const formData = new FormData();
      formData.append('file', file);
      try {
        const data = await singleUpload(formData);
        comment += ` ${data.url}`;
      } catch (err) {}
    }
    comment = createEmailHyperlink(createTextHyperLink(comment));
    onSubmit(comment);
  };

  return (
    <div className={styles.checklistComment} ref={ref}>
      <Toast show={true} position={'middle-center'}>
        <Toast.Body>
          {Array.isArray(data.comments) && data.comments.length ? (
            data.comments.map((item) => {
              return (
                <Form.Group key={item._id}>
                  <UserAvatar user={item.author} />
                  <div
                    dangerouslySetInnerHTML={{
                      __html: item.comment,
                    }}
                  />
                </Form.Group>
              );
            })
          ) : (
            <Form.Group>
              <UserAvatar user={getCurrentUserByToken()} />
            </Form.Group>
          )}
          <Form.Group>
            <UsersAutoComplete
              placeholder="Enter comment"
              onChange={(val) => setValue(val)}
              onUpload={setFile}
            />
          </Form.Group>
          <Form.Group className="d-flex">
            <Button
              className="mr-2"
              size="sm"
              disabled={!value.trim()}
              variant="primary"
              type="button"
              onClick={handleSubmit}
            >
              Comment
            </Button>
            <Button
              size="sm"
              variant="outline-primary"
              type="button"
              onClick={onCancel}
            >
              Cancel
            </Button>
          </Form.Group>
        </Toast.Body>
      </Toast>
    </div>
  );
}

export default ChecklistComment;
