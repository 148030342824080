import { put, call, takeLatest } from 'redux-saga/effects';
import {
  getVendorsSuccess,
  getVendorsFailure,
  createVendorsSuccess,
  updateVendorsSuccess,
  setVendorsFailure,
  deleteVendorsSuccess,
  getVendorsStart,
} from './actions';
import { vendorsTypes } from './types';
import * as service from '../../service';

export function* getVendors({ params }) {
  const { limit, offset, search } = params;
  try {
    const data = yield call(() => service.fetchVendors(offset, limit, search));
    yield put(getVendorsSuccess(data));
  } catch (e) {
    yield put(getVendorsFailure(e.message));
  }
}

export function* create({ body }) {
  try {
    const data = yield call(() => {
      return service.createVendors(body);
    });
    yield put(createVendorsSuccess(data));
  } catch (e) {
    yield put(setVendorsFailure(e.message));
  }
}

export function* update({ body, id }) {
  try {
    const data = yield call(() => {
      return service.updateVendors(body, id);
    });
    yield put(updateVendorsSuccess(data));
  } catch (e) {
    yield put(setVendorsFailure(e.message));
  }
}

export function* deleteAsset({ id, params }) {
  try {
    const data = yield call(() => {
      return service.deleteVendors(id);
    });
    yield put(deleteVendorsSuccess(data));
    if (params && params.offset >= 0 && params.limit) {
      yield put(getVendorsStart(params));
    }
  } catch (e) {
    yield put(setVendorsFailure(e.message));
  }
}

export function* vendorsSaga() {
  yield takeLatest(vendorsTypes.GET_VENDORS_START, getVendors);
  yield takeLatest(vendorsTypes.CREATE_VENDORS_START, create);
  yield takeLatest(vendorsTypes.UPDATE_VENDORS_START, update);
  yield takeLatest(vendorsTypes.DELETE_VENDORS_START, deleteAsset);
}
