import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SetSoftware from './setSoftware';
import { DataTable, ConfirmModal, ActionButtons } from '../shared';
import {
  deleteSoftwareStart,
  getSoftwareStart,
} from '../../redux/software/actions';
import { getDateByFormat, getNumberWithCommas } from '../../helpers/helpers';
import { ActionsWrap, Wrapper } from './styles';

const Software = () => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [softwareID, setVendorId] = useState('');
  const [software, setVendor] = useState({});
  const [action, setAction] = useState('');
  const [show, setShow] = useState(false);
  const {
    loading,
    isLoading,
    software: { data = [], total = 0 },
  } = useSelector((state) => state.software);

  const columns = [
    {
      dataField: 'office',
      text: 'Company',
    },
    {
      dataField: 'software',
      text: 'Software',
    },
    {
      dataField: 'license',
      text: 'License',
    },
    {
      dataField: 'quantity',
      text: 'QTY',
    },
    {
      dataField: 'createdAt',
      text: 'Date Added',
      formatter: (cell) => getDateByFormat(cell, 'm/d/y'),
    },
    {
      dataField: 'expiration_date',
      text: 'Expiration',
      formatter: (cell) => getDateByFormat(cell, 'm/d/y'),
    },
    {
      dataField: 'owners',
      text: 'Assignees',
      formatter: (cell) =>
        Array.isArray(cell)
          ? cell.map((item, idx) => {
              if (item?._id) {
                const { firstName = '', lastName = '' } = item;
                return (
                  <span key={item._id}>{`${firstName} ${lastName}${
                    idx < cell.length - 1 ? ', ' : ''
                  }`}</span>
                );
              }
              return <React.Fragment key={item._id} />;
            })
          : '',
    },
    {
      dataField: 'cost',
      text: 'Cost',
      formatter: (cell) => `$${getNumberWithCommas(cell)}`,
    },
    {
      dataField: 'action',
      text: 'Actions',
      csvExport: false,
      formatter: (cell, row) => (
        <ActionsWrap>
          <ActionButtons
            handleClone={handleClick(row, 'clone')}
            handleUpdate={handleClick(row, 'update')}
            handleDelete={handleClick(row, 'delete')}
          />
        </ActionsWrap>
      ),
    },
    {
      dataField: 'updatedAt',
      text: 'Updated',
      formatter: (cell) => getDateByFormat(cell, 'm/d/y'),
      hidden: true,
    },
  ];

  useEffect(() => {
    dispatch(getSoftwareStart({ offset: 0, limit: sizePerPage }));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleClick = (row, action) => (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (action === 'delete') {
      setVendorId(row._id);
      setShow(true);
      return;
    }
    setVendor(row);
    setAction(action);
  };

  const handleDelete = () => {
    setVendorId('');
    if (!softwareID) return;
    const pagination = total > 1 && data.length === 1 ? page - 1 : page;
    const params = {
      offset: (pagination - 1) * sizePerPage,
      limit: sizePerPage,
    };
    setPage(pagination);
    dispatch(deleteSoftwareStart(softwareID, params));
  };

  const handleBulkAction = (rows, action) => {
    console.log(action, rows);
  };

  const handleTableChange = (type, { page, sizePerPage, searchText }) => {
    const currentIndex = (page - 1) * sizePerPage;

    if (type === 'search') {
      setPage(1);
      setSizePerPage(sizePerPage);
    } else {
      setPage(page);
      setSizePerPage(sizePerPage);
    }
    dispatch(
      getSoftwareStart({
        offset: currentIndex,
        limit: sizePerPage + currentIndex,
        search: searchText,
      }),
    );
  };
  return (
    <Wrapper>
      <SetSoftware
        data={software}
        action={action}
        loading={isLoading}
        reload={() =>
          dispatch(getSoftwareStart({ offset: 0, limit: sizePerPage }))
        }
        reset={() => {
          setVendor({});
          setAction('');
        }}
      />
      <DataTable
        data={data}
        page={page}
        columns={columns}
        loading={loading}
        totalSize={total}
        sizePerPage={sizePerPage}
        csvFileName="software.csv"
        refreshData={() => {
          setPage(1);
          dispatch(getSoftwareStart({ offset: 0, limit: sizePerPage }));
        }}
        handleTableChange={handleTableChange}
        handleBulkAction={(rows, action) => handleBulkAction(rows, action)}
      />
      <ConfirmModal
        show={show}
        handleClose={() => setShow(false)}
        handleConfirm={handleDelete}
      />
    </Wrapper>
  );
};

export default Software;
