import React, { useState } from 'react';
import { Input as AntdInput, Tag as AntdTag } from 'antd';
import styled from 'styled-components';
import { CloseIcon } from '../../../Icons';
import { generateRandomId } from '../../../helpers/helpers';

const Wrapper = styled.div`
  min-height: 46px;
  background: #f5f8fe;
  border-radius: 8px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 4px;
  grid-gap: 8px;
`;

const Input = styled(AntdInput)`
  max-height: 38px;
  border-radius: 8px;
  max-width: 160px;
`;

const Tag = styled(AntdTag)`
  padding: 10px 16px;
  background: ${(props) => props.background};
  border-radius: 8px;
  max-height: 38px;
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: 0.02em;
  color: #292d32;
  grid-gap: 8px;
  margin: 0;

  & > span {
    margin-top: -4px;
  }
`;

const SetTags = ({
  tags = [],
  onChange,
  background = '#E7DEFA',
  fieldNames = { label: 'label', value: 'value' },
}) => {
  const [inputVisible, setInputVisible] = useState(false);
  const [inputValue, setInputValue] = useState('');

  const handleClose = (key) => {
    onChange(tags.filter((tag) => tag[fieldNames.value] !== key));
  };

  const showInput = () => {
    setInputVisible(true);
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleInputConfirm = (e) => {
    e.stopPropagation();
    e.preventDefault();
    const label = inputValue.trim();
    const id = generateRandomId();
    label &&
      onChange([
        ...tags,
        { [fieldNames.value]: id, [fieldNames.label]: label },
      ]);

    setInputVisible(false);
    setInputValue('');
  };

  return (
    <>
      <Wrapper>
        {tags.map((tag) => {
          return (
            <Tag
              closable
              key={tag[fieldNames.value]}
              background={background}
              closeIcon={<CloseIcon style={{ fontSize: 8 }} />}
              onClose={() => handleClose(tag[fieldNames.value])}
            >
              {tag[fieldNames.label]}
            </Tag>
          );
        })}
        {inputVisible && (
          <Input
            autoFocus
            value={inputValue}
            onChange={handleInputChange}
            onBlur={handleInputConfirm}
            onPressEnter={handleInputConfirm}
          />
        )}
        {!inputVisible && (
          <Tag
            style={{ cursor: 'pointer' }}
            background={'#fff'}
            onClick={showInput}
          >
            Add
          </Tag>
        )}
      </Wrapper>
    </>
  );
};

export default SetTags;
