import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SetVendors from './setVendors';
import { DataTable, ConfirmModal, ActionButtons } from '../shared';
import {
  deleteVendorsStart,
  getVendorsStart,
} from '../../redux/vendors/actions';
import { getDateByFormat } from '../../helpers/helpers';
import { Wrapper, Actions } from './styles';

function Vendors() {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [vendorID, setVendorId] = useState('');
  const [vendor, setVendor] = useState({});
  const [action, setAction] = useState('');
  const [show, setShow] = useState(false);
  const {
    loading,
    isLoading,
    vendors: { data = [], total = 0 },
  } = useSelector((state) => state.vendors);

  const columns = [
    {
      dataField: 'office',
      text: 'Company',
    },
    {
      dataField: 'description',
      text: 'Description',
    },
    {
      dataField: 'manager',
      text: 'Account Manager',
      formatter: (cell) =>
        cell && cell._id
          ? `${cell.firstName || ''} ${cell.lastName || ''}`
          : '',
    },
    {
      dataField: 'email',
      text: 'Email',
    },
    {
      dataField: 'phone',
      text: 'Phone number',
    },
    {
      dataField: 'status',
      text: 'Open Contract',
      formatter: (cell, row) => (
        <div
          className="cursor-pointer"
          onClick={handleChangeStatus(row._id, cell)}
        >
          {cell}
        </div>
      ),
    },
    {
      dataField: 'owner',
      text: 'Owner',
      formatter: (cell) => {
        const { firstName = '', lastName = '' } = cell || {};
        return <span>{`${firstName} ${lastName}`}</span>;
      },
    },
    {
      dataField: 'action',
      text: 'Actions',
      csvExport: false,
      formatter: (cell, row) => (
        <Actions>
          <ActionButtons
            handleClone={handleClick(row, 'clone')}
            handleUpdate={handleClick(row, 'update')}
            handleDelete={handleClick(row, 'delete')}
          />
        </Actions>
      ),
    },
    {
      dataField: 'updatedAt',
      text: 'Updated',
      formatter: (cell) => getDateByFormat(cell, 'm/d/y'),
      hidden: true,
    },
    {
      dataField: 'createdAt',
      text: 'Created',
      formatter: (cell) => getDateByFormat(cell, 'm/d/y'),
      hidden: true,
    },
  ];

  useEffect(() => {
    dispatch(getVendorsStart({ offset: 0, limit: sizePerPage }));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleChangeStatus = (id, status) => (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (!id) return;
  };

  const handleClick = (row, action) => (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (action === 'delete') {
      setVendorId(row._id);
      setShow(true);
      return;
    }
    setVendor(row);
    setAction(action);
  };

  const handleDelete = () => {
    setVendorId('');
    if (!vendorID) return;
    const pagination = total > 1 && data.length === 1 ? page - 1 : page;
    const params = {
      offset: (pagination - 1) * sizePerPage,
      limit: sizePerPage,
    };
    setPage(pagination);
    dispatch(deleteVendorsStart(vendorID, params));
  };

  const handleBulkAction = (rows, action) => {
    console.log(action, rows);
  };

  const handleTableChange = (type, { page, sizePerPage, searchText }) => {
    const currentIndex = (page - 1) * sizePerPage;

    if (type === 'search') {
      setPage(1);
      setSizePerPage(sizePerPage);
    } else {
      setPage(page);
      setSizePerPage(sizePerPage);
    }
    dispatch(
      getVendorsStart({
        offset: currentIndex,
        limit: sizePerPage + currentIndex,
        search: searchText,
      }),
    );
  };
  return (
    <Wrapper>
      <SetVendors
        data={vendor}
        action={action}
        loading={isLoading}
        reload={() =>
          dispatch(getVendorsStart({ offset: 0, limit: sizePerPage }))
        }
        reset={() => {
          setVendor({});
          setAction('');
        }}
      />
      <DataTable
        data={data}
        page={page}
        columns={columns}
        loading={loading}
        totalSize={total}
        sizePerPage={sizePerPage}
        csvFileName="vendors.csv"
        refreshData={() => {
          setPage(1);
          dispatch(getVendorsStart({ offset: 0, limit: sizePerPage }));
        }}
        handleTableChange={handleTableChange}
        handleBulkAction={(rows, action) => handleBulkAction(rows, action)}
      />
      <ConfirmModal
        show={show}
        handleClose={() => setShow(false)}
        handleConfirm={handleDelete}
      />
    </Wrapper>
  );
}

export default Vendors;
