export const softwareTypes = {
    GET_SOFTWARE_START: 'GET_SOFTWARE_START',
    GET_SOFTWARE_SUCCESS: 'GET_SOFTWARE_SUCCESS',
    GET_SOFTWARE_FAILURE: 'GET_SOFTWARE_FAILURE',
    CREATE_SOFTWARE_START: 'CREATE_SOFTWARE_START',
    CREATE_SOFTWARE_SUCCESS: 'CREATE_SOFTWARE_SUCCESS',
    UPDATE_SOFTWARE_START: 'UPDATE_SOFTWARE_START',
    UPDATE_SOFTWARE_SUCCESS: 'UPDATE_SOFTWARE_SUCCESS',
    DELETE_SOFTWARE_START: 'DELETE_SOFTWARE_START',
    DELETE_SOFTWARE_SUCCESS: 'DELETE_SOFTWARE_SUCCESS',
    SET_SOFTWARE_FAILURE: 'SET_SOFTWARE_FAILURE',
};
