import React from 'react';
import withIcon from '../withIcon';

const PaperClipIcon = () => {
  return (
    <svg
      width="1em"
      height="1.14em"
      viewBox="0 0 14 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 2C3.34315 2 2 3.34315 2 5V9C2 11.7614 4.23858 14 7 14C9.76142 14 12 11.7614 12 9V5C12 4.44772 12.4477 4 13 4C13.5523 4 14 4.44772 14 5V9C14 12.866 10.866 16 7 16C3.13401 16 0 12.866 0 9V5C0 2.23858 2.23858 0 5 0C7.76142 0 10 2.23858 10 5V9C10 10.6569 8.65685 12 7 12C5.34315 12 4 10.6569 4 9V5C4 4.44772 4.44772 4 5 4C5.55228 4 6 4.44772 6 5V9C6 9.55228 6.44772 10 7 10C7.55228 10 8 9.55228 8 9V5C8 3.34315 6.65685 2 5 2Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default withIcon(PaperClipIcon);
