import React, { useEffect, useState } from 'react';
import SetIntegration from './SetIntegration';
import { fetchIntegration, createIntegration } from '../../service';
import { Input, Text, Button, FlexWrapper } from './styles';

function SetOkta() {
  const [body, setBody] = useState({});

  useEffect(() => {
    fetchIntegration('okta')
      .then((res) => setBody({ apiUrl: res?.info?.apiUrl, key: res?.key }))
      .catch(() => {});
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setBody((prev) => ({ ...prev, [name]: value }));
  };

  const handleSave = () => {
    createIntegration(body, 'okta').catch(() => {});
  };

  return (
    <div>
      <Text style={{ marginBottom: 8 }}>
        Your Domain https://dev-********.okta.com
      </Text>
      <FlexWrapper>
        <Input
          name="apiUrl"
          value={body.apiUrl}
          onChange={handleChange}
          placeholder={'Your Domain'}
        />
        <Input
          name="key"
          value={body.key}
          onChange={handleChange}
          placeholder={'Key'}
        />
        <Button onClick={handleSave} style={{ marginBottom: 8 }} size="large">
          Save
        </Button>
      </FlexWrapper>
    </div>
  );
}

function Okta({ data }) {
  const [body, setBody] = useState({ apiUrl: '', key: '' });

  const handleChange = (e) => {
    setBody((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  return (
    <SetIntegration
      title="Add your OAuth info."
      name={'okta'}
      body={body}
      data={data}
      label={'Okta'}
      editConfigs={<SetOkta />}
    >
      <div>
        <Text style={{ marginBottom: 8 }}>
          Your Domain https://dev-********.okta.com
        </Text>
        <Input
          name="apiUrl"
          value={body.apiUrl}
          onChange={handleChange}
          placeholder={'Your Domain'}
        />
        <Input
          name="key"
          value={body.key}
          onChange={handleChange}
          placeholder={'Key'}
        />
      </div>
    </SetIntegration>
  );
}

export default Okta;
