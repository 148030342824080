import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Stripe from '../stripe';
import { fetchPlans, updatePlan, subscribePlan } from '../../service';
import { getCurrentCompanyStart } from '../../redux/global/actions';
import { ROUTES, PLAN_NAMES } from '../../helpers/variables';
import {
  CloseOutlined,
  CheckCircleIcon,
  LoadingOutlined,
  StarFilled,
} from '../../Icons';
import {
  SwitchWrap,
  Wrapper,
  Switch,
  PlanWrap,
  Title,
  PlanOptions,
  SubTitle,
  PriceWrap,
  PlansWrapper,
  PlanWrapper,
  Button,
  IconWrap,
} from './styles';

function BillingPlans({ isPricingPage }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const { company } = useSelector((state) => state.global);
  const [type, setType] = useState('year');
  const [plan, setPlan] = useState('');
  const [plans, setPlans] = useState([]);
  const [loading, setLoading] = useState(false);
  const currentPlan = company.planId;

  useEffect(() => {
    fetchPlans()
      .then(setPlans)
      .catch(() => {});
  }, []);

  useEffect(() => {
    company.plan?.interval && setType(company.plan.interval);
  }, [company.plan?.interval]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleChangeType = (type) => {
    setType(type ? 'year' : 'month');
    setPlan('');
  };

  const handleClick = (id) => {
    if (isPricingPage) {
      history.push(ROUTES.login.path);
    } else {
      setPlan(id);
    }
  };

  const handleSubmit = async (body) => {
    setLoading(true);
    const data = plans.find((item) => item._id === plan);
    (currentPlan ? updatePlan : subscribePlan)({
      ...body,
      price: data[type + 'PriceId'],
      planType: type,
      planId: plan,
    })
      .then(() => {
        setPlan('');
        setLoading(false);
        dispatch(getCurrentCompanyStart());
      })
      .catch(() => setLoading(false));
  };

  return (
    <Wrapper>
      <SwitchWrap>
        <div>Monthly</div>
        <div>
          <Switch
            onChange={handleChangeType}
            checked={type === 'year' ? true : false}
          />
        </div>
        <div>Annual Pricing</div>
      </SwitchWrap>
      <PlansWrapper gutter={[32, 32]} justify="center" align="top">
        {plans.map((item) => (
          <PlanWrapper key={item._id} xxl={7} md={8} sm={12} xs={24}>
            <PlanWrap>
              {currentPlan === item._id && plan !== item._id && (
                <IconWrap>
                  <StarFilled style={{ color: '#ffd41d' }} />
                </IconWrap>
              )}
              <Title>{item.name}</Title>
              {plan === item._id ? (
                <>
                  <IconWrap>
                    <CloseOutlined onClick={() => setPlan('')} />
                  </IconWrap>
                  <Stripe component="subscribeForm" onSubmit={handleSubmit}>
                    <PriceWrap>
                      <span>${item[type + 'Price']}</span>
                      <span>/month</span>
                    </PriceWrap>
                    {loading ? (
                      <Button type="button">
                        <LoadingOutlined />
                      </Button>
                    ) : (
                      <Button type="submit">Subscribe</Button>
                    )}
                  </Stripe>
                </>
              ) : (
                <>
                  <SubTitle>What You’ll Get</SubTitle>
                  <PlanOptions>
                    <CheckCircleIcon />
                    {item.name === PLAN_NAMES.business
                      ? 'Unlimited'
                      : item.onboard}{' '}
                    Onboards
                  </PlanOptions>
                  <PlanOptions>
                    <CheckCircleIcon />
                    {item.name === PLAN_NAMES.business
                      ? 'Unlimited'
                      : item.offboard}{' '}
                    Offboards
                  </PlanOptions>
                  {item.ats && (
                    <PlanOptions>
                      <CheckCircleIcon />
                      Bring your own ATS
                    </PlanOptions>
                  )}
                  {item.googleWorkspace && (
                    <PlanOptions>
                      <CheckCircleIcon />
                      Google Workspace Automation
                    </PlanOptions>
                  )}
                  {item.microsoft365 && (
                    <PlanOptions>
                      <CheckCircleIcon />
                      Microsoft 365 Automation
                    </PlanOptions>
                  )}
                  {item.okta && (
                    <PlanOptions>
                      <CheckCircleIcon />
                      Okta Automation
                    </PlanOptions>
                  )}
                  {item.assetManager && (
                    <PlanOptions>
                      <CheckCircleIcon />
                      Asset Manager
                    </PlanOptions>
                  )}
                  {item.passwordManager && (
                    <PlanOptions>
                      <CheckCircleIcon />
                      Team Password Manager
                    </PlanOptions>
                  )}
                  {item.ticketingSystem && (
                    <PlanOptions>
                      <CheckCircleIcon />
                      Helpdesk Ticketing System
                    </PlanOptions>
                  )}
                  <PriceWrap>
                    <span>${item[type + 'Price']}</span>
                    <span>/month</span>
                  </PriceWrap>
                  <Button
                    style={
                      currentPlan === item._id
                        ? { background: '#26DE81' }
                        : undefined
                    }
                    disabled={plan && plan !== item.id}
                    onClick={() => handleClick(item._id)}
                  >
                    {currentPlan === item._id ? 'Current Plan' : 'Choose'}
                  </Button>
                </>
              )}
            </PlanWrap>
          </PlanWrapper>
        ))}
      </PlansWrapper>
    </Wrapper>
  );
}

export default BillingPlans;
