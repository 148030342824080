export const usersTypes = {
    GET_USERS_START: 'GET_USERS_START',
    GET_USERS_SUCCESS: 'GET_USERS_SUCCESS',
    GET_ALL_USERS_START: 'GET_ALL_USERS_START',
    GET_ALL_USERS_SUCCESS: 'GET_ALL_USERS_SUCCESS',
    GET_USERS_FAILURE: 'GET_USERS_FAILURE',
    CREATE_USERS_START: 'CREATE_USERS_START',
    CREATE_USERS_SUCCESS: 'CREATE_USERS_SUCCESS',
    CREATE_USERS_FAILURE: 'CREATE_USERS_FAILURE',
    UPDATE_USERS_START: 'UPDATE_USERS_START',
    UPDATE_USERS_SUCCESS: 'UPDATE_USERS_SUCCESS',
    DELETE_USERS_START: 'DELETE_USERS_START',
    DELETE_USERS_SUCCESS: 'DELETE_USERS_SUCCESS',
};
