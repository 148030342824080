import React from 'react';
import withIcon from '../withIcon';

const UserGroupIcon = () => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.5 12C10.4338 12 12 10.4338 12 8.5C12 6.56625 10.4338 5 8.5 5C6.56625 5 5 6.56625 5 8.5C5 10.4338 6.56625 12 8.5 12Z"
        fill="currentColor"
      />
      <path
        d="M19.5 10.25C19.5 11.7694 18.2694 13 16.75 13C15.2306 13 14 11.7694 14 10.25C14 8.7306 15.2306 7.5 16.75 7.5C18.2694 7.5 19.5 8.7306 19.5 10.25Z"
        fill="currentColor"
      />
      <path
        d="M8.5 13C9.8668 13 12.0915 13.4256 13.5506 14.2726C14.1465 14.8793 14.5 15.5407 14.5 16.2V19H2V16.2C2 14.072 6.3306 13 8.5 13Z"
        fill="currentColor"
      />
      <path
        d="M21.9999 19H15.4999V16.2C15.4999 15.4919 15.2442 14.84 14.8381 14.2583C15.6086 14.0862 16.3671 14 16.9463 14C18.8105 14 21.9999 14.8934 21.9999 16.6667V19Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default withIcon(UserGroupIcon);
