import React, { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import qs from 'qs';
import { integrationsOffice365 } from '../../service';
import { ROUTES } from '../../helpers/variables';
import { getOffice365IntegrationUrl } from '../../helpers/helpers';

function Office365Integrations() {
  const { search } = useLocation();
  const history = useHistory();
  const { profile } = useSelector((state) => state.global);

  const [status, setStatus] = useState('');
  const { state, code, error } = qs.parse(search, { ignoreQueryPrefix: true });

  useEffect(() => {
    if (state && code && !error) {
      integrationsOffice365({ state, code })
        .then(() => {
          history.push(ROUTES.integrations.path);
        })
        .catch(() => setStatus('error'));
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (error) {
    return (
      <div>
        User did not authorize the request{' '}
        <a href={getOffice365IntegrationUrl(profile.company)}>
          Please try again
        </a>
      </div>
    );
  }

  if (status === 'error') {
    return <div>Something went wrong</div>;
  }

  return <div>Thank you</div>;
}

export default Office365Integrations;
