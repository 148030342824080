import React, { useState } from 'react';
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
} from '@stripe/react-stripe-js';
import styled from 'styled-components';

const CardInputWrapper = styled.div`
  width: 100%;
  height: 40px;
  padding: 12px;
  background: #ffffff;
  border: 1px solid #98a5cd;
  border-radius: 8px;
  margin: 12px 0;
`;

const Input = styled.input`
  border: 1px solid #98a5cd;
  padding: 8px 12px;
  border-radius: 8px;
  width: 100%;
  font-weight: 400;
  font-size: 14px;
  color: #111827;
  height: 40px;
  margin: 12px 0;

  &:focus {
    outline: none;
  }
`;

const Button = styled.button`
  padding: 9px 17px;
  width: 344px;
  height: 42px;
  background: #9b51e0;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  flex: none;
  order: 1;
  flex-grow: 0;
  border: unset;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  margin-top: 12px;
`;

const options = {
  style: {
    base: {
      fontWeight: '500',
      fontSize: '14px',
      color: '#111827',
    },
    invalid: {
      color: '#9e2146',
    },
  },
};

const setOptionsElement = (obj) => ({ ...options, ...obj });

function SubscribeForm({ children, onSubmit }) {
  const stripe = useStripe();
  const elements = useElements();
  const [data, setData] = useState('');
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    setData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!stripe || !elements || loading) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    setLoading(true);

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardNumberElement),
      billing_details: { address: { postal_code: data.zip }, name: data.name },
    });

    if (error) {
      const validate = error.code.replace('incomplete_', '');
      console.log('validate: ', validate);
      setLoading(false);
      return;
    }

    await onSubmit({
      pmId: paymentMethod.id,
    }).finally(() => {
      setLoading(false);
    });
  };

  return (
    <form onSubmit={handleSubmit}>
      <Input placeholder="Full Name" name="name" onChange={handleChange} />
      <CardInputWrapper style={{ width: '100%' }}>
        <CardNumberElement
          options={setOptionsElement({
            placeholder: 'Card Number',
          })}
        />
      </CardInputWrapper>
      <div style={{ display: 'flex', gridGap: 12 }}>
        <CardInputWrapper>
          <CardExpiryElement
            options={setOptionsElement({ placeholder: 'MM/YY' })}
          />
        </CardInputWrapper>
        <CardInputWrapper>
          <CardCvcElement options={setOptionsElement({ placeholder: 'CVV' })} />
        </CardInputWrapper>
      </div>
      <Input placeholder="Zip Code" name="zip" onChange={handleChange} />
      {children ? (
        children
      ) : (
        <Button type="submit" disabled={!stripe}>
          Subscribe
        </Button>
      )}
    </form>
  );
}

export default SubscribeForm;
