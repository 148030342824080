import React from 'react';
import { Dropdown, Menu } from 'antd';
import { EllipsisOutlinedIcon } from './styles';

function Actions({ onEdit, onDelete }) {
  const handleClick = ({ key, domEvent }) => {
    domEvent.preventDefault();
    domEvent.stopPropagation();
    if (key === 'edit') {
      onEdit();
    } else {
      onDelete();
    }
  };

  const menu = (
    <Menu
      onClick={handleClick}
      items={
        onEdit
          ? [
              {
                key: 'edit',
                label: 'Edit',
              },
              {
                key: 'disconnect',
                label: 'Disconnect',
              },
            ]
          : [
              {
                key: 'disconect',
                label: 'Disconect',
              },
            ]
      }
    />
  );

  return (
    <Dropdown overlay={menu} trigger={['click']}>
      <EllipsisOutlinedIcon />
    </Dropdown>
  );
}

export default Actions;
