import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getProfileSuccess } from '../../redux/global/actions';
import { DeleteIcon, PencilOutlinedIcon, PictureOutlined } from '../../Icons';
import { getUserFullName } from '../../helpers/helpers';
import {
  NOTIFICATION_PREFERENCES,
  ROLE_NAMES,
  ROLE_VALUES,
} from '../../helpers/variables';
import {
  fetchUserProperties,
  setUserProperties,
  imageUpload,
  updateCurrentUser,
} from '../../service';
import {
  Wrapper,
  ImageWrap,
  Image,
  InfoWrap,
  FullName,
  Title,
  Text,
  NotificationWrapper,
  NotTitle,
  CheckboxGroup,
  Input,
  DeleteWrap,
} from './styles';

const getOptions = (role) => {
  const options = [
    { label: 'Email when tagged in a comment', value: 'taggedComment' },
    { label: 'Email when assigned a task', value: 'assignedTask' },
    { label: 'Email when assigned a ticket', value: 'assignedTicket' },
  ];

  if (
    [ROLE_VALUES.admin, ROLE_VALUES.helpdesk, ROLE_VALUES.hr].includes(role)
  ) {
    return [
      ...options,
      { label: 'Email when Onboard is submitted', value: 'onboardSubmitted' },
      { label: 'Email when Offboard is submitted', value: 'offboardSubmitted' },
    ];
  }

  return options;
};

const Profile = () => {
  const dispatch = useDispatch();
  const fileInput = useRef(null);
  const { profile } = useSelector((state) => state.global);
  const [notificationPreferences, setNotificationPreferences] = useState([]);
  const [show, setShow] = useState(false);
  const {
    image,
    title,
    role,
    location,
    manager = {},
    department = {},
  } = profile;
  const fullName = getUserFullName(profile);

  useEffect(() => {
    fetchUserProperties()
      .then((res) =>
        setNotificationPreferences(
          res?.notificationPreferences ||
            Object.values(NOTIFICATION_PREFERENCES),
        ),
      )
      .catch(() => {});
  }, []);

  const handleChangePreferences = (values) => {
    setNotificationPreferences(values);
    setUserProperties({ notificationPreferences: values })
      .then((res) => setNotificationPreferences(res.notificationPreferences))
      .catch(() => {});
  };

  const handleChangeImage = async (e) => {
    try {
      if (e.target.files) {
        const formData = new FormData();
        formData.append('file', e.target.files[0]);
        const { url } = await imageUpload(formData);
        if (url) {
          const profile = await updateCurrentUser({ image: url });
          dispatch(getProfileSuccess(profile));
        }
        if (fileInput.current) {
          fileInput.current.value = null;
        }
      }
    } catch (error) {}
  };

  const handleUpdate = (data) => {
    updateCurrentUser(data)
      .then((res) => {
        dispatch(getProfileSuccess(res));
      })
      .catch(() => {});
  };

  const handleUpdateLocation = (e) => {
    if (e.target.value !== location) {
      handleUpdate({ location: e.target.value });
    }
    setShow(false);
  };

  return (
    <div>
      <Wrapper>
        <div>
          <ImageWrap>
            <Image>
              {image ? (
                <img src={image} alt={fullName} />
              ) : (
                <PictureOutlined style={{ fontSize: 50 }} />
              )}
              {image && (
                <DeleteWrap>
                  <DeleteIcon
                    style={{ fontSize: 28 }}
                    onClick={() => handleUpdate({ image: '' })}
                  />
                </DeleteWrap>
              )}
            </Image>
            <input
              ref={fileInput}
              type="file"
              accept="image/png, image/jpeg"
              onChange={handleChangeImage}
              style={{ display: 'none' }}
            />
            <div onClick={() => fileInput.current?.click()}>
              {image ? 'Edit' : 'Upload'}
            </div>
          </ImageWrap>
          <div>
            <FullName>{fullName}</FullName>
            <Title>{title}</Title>
            <Text>
              Permission: <span>{ROLE_NAMES[role]}</span>
            </Text>
          </div>
        </div>
        <InfoWrap>
          <div>
            <Text>
              Department: <span>{department?.name}</span>
            </Text>
            <Text>
              Manager: <span>{getUserFullName(manager)}</span>
            </Text>
          </div>
          <Text>
            Mailing Address:{' '}
            {show ? (
              <Input
                autoFocus
                defaultValue={location}
                onPressEnter={handleUpdateLocation}
              />
            ) : (
              <>
                <span>{location}</span>
                <PencilOutlinedIcon
                  onClick={() => setShow(true)}
                  style={{
                    display: 'inline-flex',
                    marginLeft: 8,
                    cursor: 'pointer',
                  }}
                />
              </>
            )}
          </Text>
        </InfoWrap>
      </Wrapper>
      <NotificationWrapper>
        <NotTitle>Notification Preferences</NotTitle>
        <CheckboxGroup
          options={getOptions(profile.role)}
          onChange={handleChangePreferences}
          value={notificationPreferences}
        />
      </NotificationWrapper>
    </div>
  );
};

export default Profile;
