import React, { useEffect, useState } from 'react';
import copy from 'copy-to-clipboard';
import { CopyIcon } from '../../Icons';
import { SetTags } from '../shared';
import { useSelector } from 'react-redux';
import { getUsersList } from '../../redux/users/selectors';
import {
  generateRandomId,
  getNumericSelectOptions,
  getUserFullName,
} from '../../helpers/helpers';
import { createTicketSettings, fetchTicketSettings } from '../../service';
import {
  Wrapper,
  TicketEmail,
  Label,
  EmailInput,
  DefaultsWrap,
  SubLabel,
  Select,
  TextArea,
  EscalationsWrap,
  DateWrap,
  Button,
} from './styles';

function TicketsSetting() {
  const users = useSelector(getUsersList);
  const [body, setBody] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchTicketSettings().then(convertApiData);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const convertApiData = (res) => {
    const {
      email,
      issueTypes,
      defaultOwner,
      autoReplyMessage,
      reassignOwner,
      reassignAfter,
    } = res;

    setBody({
      email,
      defaultOwner,
      autoReplyMessage,
      reassignOwner,
      reassignAfter,
      issueTypes: issueTypes?.map((item) => ({
        value: generateRandomId(),
        label: item,
      })),
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setBody((prev) => ({ ...prev, [name]: value }));
  };

  const handleChangeIssueTypes = (issueTypes) => {
    setBody((prev) => ({ ...prev, issueTypes }));
  };

  const handleChangeDate = (date) => {
    setBody((prev) => ({
      ...prev,
      reassignAfter: { ...body.reassignAfter, ...date },
    }));
  };

  const handleSubmit = () => {
    setLoading(true);
    const data = {
      ...body,
      email: undefined,
      issueTypes: body.issueTypes?.map((item) => item.label) || [],
    };
    createTicketSettings(data)
      .then(convertApiData)
      .catch()
      .finally(() => setLoading(false));
  };

  return (
    <Wrapper>
      <TicketEmail>
        <Label>Email to ticket</Label>
        <EmailInput
          disabled
          value={body.email}
          suffix={<CopyIcon onClick={() => copy(body.email)} />}
        />
      </TicketEmail>
      <DefaultsWrap>
        <Label>Defaults</Label>
        <div>
          <SubLabel>Issue Types</SubLabel>
          <SetTags tags={body.issueTypes} onChange={handleChangeIssueTypes} />
        </div>
        <div>
          <SubLabel>Default Assignee</SubLabel>
          <Select
            value={body.defaultOwner}
            placeholder="Select User"
            options={users.map((user) => ({
              label: getUserFullName(user),
              value: user._id,
            }))}
            onChange={(value) =>
              handleChange({ target: { name: 'defaultOwner', value } })
            }
          />
        </div>
        <div>
          <SubLabel>Ticket Open Reply</SubLabel>
          <TextArea
            autoFocus
            rows={9}
            name={'autoReplyMessage'}
            value={body.autoReplyMessage}
            onChange={handleChange}
          />
        </div>
      </DefaultsWrap>
      <EscalationsWrap>
        <Label>Escalations</Label>
        <div>
          <SubLabel>Reassign after</SubLabel>
          <DateWrap>
            <Select
              placeholder="Days"
              value={body.reassignAfter?.day}
              options={getNumericSelectOptions(30)}
              onChange={(day) => handleChangeDate({ day })}
            />
            <Select
              placeholder="Hours"
              value={body.reassignAfter?.hours}
              options={getNumericSelectOptions(23)}
              onChange={(hours) => handleChangeDate({ hours })}
            />
            <Select
              placeholder="Minutes"
              value={body.reassignAfter?.minute}
              options={getNumericSelectOptions(59)}
              onChange={(minute) => handleChangeDate({ minute })}
            />
          </DateWrap>
        </div>
        <div>
          <SubLabel>Reassign to</SubLabel>
          <Select
            placeholder="Select User"
            value={body.reassignOwner}
            options={users.map((user) => ({
              label: getUserFullName(user),
              value: user._id,
            }))}
            onChange={(value) =>
              handleChange({ target: { name: 'reassignOwner', value } })
            }
          />
        </div>
      </EscalationsWrap>
      <Button loading={loading} onClick={handleSubmit}>
        Save
      </Button>
    </Wrapper>
  );
}

export default TicketsSetting;
