import React from 'react';
import styled from 'styled-components';
import {
  Button as AntdButton,
  Form as AntdForm,
  Input as AntdInput,
} from 'antd';

const Form = styled(AntdForm)`
  align-items: center;
`;

const Input = styled(AntdInput)`
  padding: 9px 13px;
  width: 100%;
  height: 38px;
  background: #ffffff;
  border: 1px solid #d1d5db;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
`;

const Button = styled(AntdButton)`
  width: 62px;
  height: 38px;
  background: #0071ff;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
`;

function FormInput({ onSubmit, placeholder, buttonText = 'Add' }) {
  const [form] = Form.useForm();

  const handleFinish = (values) => {
    onSubmit(values.field);
    form.setFieldsValue({ field: '' });
  };

  return (
    <Form layout="inline" form={form} onFinish={handleFinish}>
      <Form.Item name={'field'}>
        <Input placeholder={placeholder} />
      </Form.Item>
      <Form.Item>
        <Button htmlType={'submit'}>{buttonText}</Button>
      </Form.Item>
    </Form>
  );
}

export default FormInput;
