import React from 'react';
import withIcon from '../withIcon';

const MoonIcon = () => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.25 4.75C10.25 3.3315 10.5695 1.9905 11.127 0.7815C10.919 0.7675 10.7115 0.75 10.5 0.75C5.3915 0.75 1.25 4.8915 1.25 10C1.25 15.1085 5.3915 19.25 10.5 19.25C14.097 19.25 17.207 17.1925 18.737 14.1945C13.967 13.6885 10.25 9.6545 10.25 4.75Z"
        stroke="#757B8C"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default withIcon(MoonIcon);
