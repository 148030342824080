import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Modal, Select, Input } from 'antd';
import styled from 'styled-components';
import { ToolbarButtonsGroup } from '../shared';
import SetTickets from './setTickets';
import {
  deleteManyTickets,
  singleUpload,
  updateManyTickets,
} from '../../service';
import { getUserFullName } from '../../helpers/helpers';
import { getUsersList } from '../../redux/users/selectors';
import { STATUS_INFO, TICKET_URGENCY } from '../../helpers/variables';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  grid-gap: 32px;
  margin-left: 22px;
`;

const SelectWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  grid-gap: 15px;

  & > div {
    width: 48%;
  }
`;

function Actions({ selected, reload, onSubmit, issueTypes }) {
  const users = useSelector(getUsersList);
  const [visible, setVisible] = useState('');
  const [body, setBody] = useState({});

  const handleDelete = () => {
    selected?.length &&
      deleteManyTickets({ ids: selected.map((item) => item._id) }).then(() =>
        reload(),
      );
  };

  const handleUpload = async (file) => {
    try {
      const { url } = await singleUpload(file);
      await updateManyTickets({
        ids: selected.map((item) => item._id),
        attachment: url,
      });
      reload();
    } catch (e) {}
  };

  const handleUpdate = () => {
    if (Object.keys(body).length) {
      updateManyTickets({
        ids: selected.map((item) => item._id),
        ...body,
      })
        .then(() => reload())
        .catch();
    }
  };

  const handleClick = (action) => {
    setBody({});
    setVisible(action);
  };

  return (
    <Wrapper>
      <ToolbarButtonsGroup
        disabled={!selected.length}
        onEdit={() => handleClick('edit')}
        onUpload={handleUpload}
        onAnnotate={() => handleClick('note')}
        onDelete={handleDelete}
      />
      <SetTickets onSubmit={onSubmit} issueTypes={issueTypes} />

      <Modal
        title="Modal"
        open={!!visible}
        onOk={handleUpdate}
        onCancel={() => setVisible(false)}
        okText="Update"
      >
        <>
          {visible === 'edit' ? (
            <SelectWrap>
              <Select
                showSearch
                placeholder="Assignee"
                optionFilterProp="children"
                onChange={(owner) => setBody((prev) => ({ ...prev, owner }))}
                options={users.map((user) => ({
                  label: getUserFullName(user),
                  value: user._id,
                }))}
              />
              <Select
                placeholder="Priority"
                onChange={(urgency) =>
                  setBody((prev) => ({ ...prev, urgency }))
                }
                options={TICKET_URGENCY.map((item) => ({
                  label: item.name,
                  value: item.value,
                }))}
              />
              <Select
                placeholder="Issue Type"
                onChange={(issue) => setBody((prev) => ({ ...prev, issue }))}
                options={issueTypes?.map((item) => ({
                  label: item,
                  value: item,
                }))}
              />
              <Select
                placeholder="Status"
                onChange={(status) => setBody((prev) => ({ ...prev, status }))}
                options={Object.keys(STATUS_INFO)?.map((item) => ({
                  label: STATUS_INFO[item].label,
                  value: item,
                }))}
              />
            </SelectWrap>
          ) : (
            <Input.TextArea
              autoFocus
              rows={5}
              onChange={(e) => setBody({ message: e.target.value })}
            />
          )}
        </>
      </Modal>
    </Wrapper>
  );
}

export default Actions;
