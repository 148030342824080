import React from 'react';
import withIcon from '../withIcon';

const NoteIcon = () => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.75 0C15.612 0 16.4386 0.34241 17.0481 0.951903C17.6576 1.5614 18 2.38805 18 3.25V10H13.25C12.388 10 11.5614 10.3424 10.9519 10.9519C10.3424 11.5614 10 12.388 10 13.25V18H3.25C2.38805 18 1.5614 17.6576 0.951903 17.0481C0.34241 16.4386 0 15.612 0 14.75V3.25C0 2.38805 0.34241 1.5614 0.951903 0.951903C1.5614 0.34241 2.38805 0 3.25 0H14.75ZM17.56 11.5L11.5 17.56V13.25C11.5 12.284 12.284 11.5 13.25 11.5H17.56Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default withIcon(NoteIcon);
