import styled from 'styled-components';
import {
  Radio,
  Button as AntdButton,
  Input as AntdInput,
  Select as AntdSelect,
  Modal as AntdModale,
} from 'antd';
import { EllipsisOutlined } from '../../Icons';

export const Wrapper = styled.div``;

export const Title = styled.div`
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  color: #111827;
`;

export const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
  grid-gap: 16px;
  margin: 16px 0;
`;

export const Container = styled.div`
  padding: 40px 0;
`;

export const ItemWrap = styled.div`
  width: 31%;
  background: #ffffff;
  border: 1px solid #7984a9;
  border-radius: 8px;
  padding: 24px 20px;
  height: 323px;
  display: flex;
  flex-direction: column;

  @media (max-width: 1365px) {
    width: 46%;
  }

  @media (max-width: 767px) {
    width: 100%;
  }
`;

export const ImageWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 25px;
  height: 60px;

  img {
    max-height: 60px;
    width: auto;
  }
`;

export const Text = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  color: #7984a9;
`;

export const Button = styled(AntdButton)`
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  margin-top: auto;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #fff;
  padding: 0 20px;
  background: #9b51e0;
`;

export const Input = styled(AntdInput)`
  height: 38px;
  border-radius: 4px;
  margin-bottom: 8px;
`;

export const Select = styled(AntdSelect)`
  border-radius: 6px;
  margin-top: auto;
  width: 100%;
  margin-bottom: 10px;

  &&& > div {
    border-radius: 6px;
  }
`;

export const ButtonWrap = styled.div`
  display: flex;
  align-items: center;
  margin-top: auto;
`;

export const RadioGroup = styled(Radio.Group)`
  margin-left: 12px;
`;

export const RadioButton = styled(Radio.Button)`
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  font-weight: 400;
  font-size: 14px;
  color: #292d32;
  box-shadow: none !important;

  &&&.ant-radio-button-wrapper-checked {
    border-color: #26de81;
    color: #26de81 !important;

    .ant-radio-button {
      border-color: #26de81;
    }
  }
`;

export const AtsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  grid-gap: 46px;
  padding: 24px 34px;
`;

export const AtsWrap = styled.div`
  position: relative;
  min-width: 200px;
  max-width: 22%;
  width: 100%;
  height: 210px;
  background: #fff;
  border: 1px solid #7984a9;
  border-radius: 8px;
  padding: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const AtsName = styled.div`
  margin-top: 10px;
`;

export const AtsImage = styled.div`
  width: 80%;
  max-height: 55px;
  height: 100%;
  margin-top: 25px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
`;

export const InfoIconWrap = styled.div`
  position: absolute;
  right: 12px;
`;

export const EllipsisOutlinedIcon = styled(EllipsisOutlined)`
  color: #757b8c;
  font-size: 20px;
`;

export const Modal = styled(AntdModale)``;

export const AtsInfoContent = styled.div`
  padding: 10px 56px;

  ${AtsImage} {
    width: 320px;
    margin: auto;
    height: auto;
    max-height: unset;
    padding: 36px;
  }
`;

export const DangerButton = styled(AntdButton).attrs({ size: 'large' })`
  background: #ec4f4f !important;
  border-color: #ec4f4f !important;
  border-radius: 10px;
  padding: 0 20px;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #fff !important;
`;

export const GsuiteConfigWrap = styled.div`
  display: flex;
  align-items: center;
  grid-gap: 30px;
  margin-bottom: 30px;

  ${Select} {
    margin: unset;
    width: 180px;
  }
`;
