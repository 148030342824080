import styled from 'styled-components';

export const TopWrap = styled.div`
  padding: 40px 80px;
  border-bottom: 1px solid #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Logo = styled.div`
  width: 183px;
  height: 57px;
  background: #ffffff;
  border-radius: 7px;
  padding: 0 25px;
  display: flex;
  align-items: center;
`;

export const Text = styled.div`
  font-weight: 500;
  font-size: 20px;
  text-align: center;
  color: #ffffff;
`;

export const IconWrap = styled.div`
  display: flex;
  align-items: center;
  grid-gap: 28px;

  svg {
    font-size: 22px;
  }

  a {
    color: #ffffff;
    &:nth-child(2) {
      svg {
        font-size: 11px;
      }
    }
  }
`;

export const BottomWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 36px 0;
  grid-gap: 48px;

  div:last-child {
    font-weight: 400;
    font-size: 18px;
    line-height: 140%;
    color: #ffffff;
    margin-left: auto;
    opacity: 0.8;
  }

  @media (max-width: 768px) {
    flex-direction: column;

    div:last-child {
      margin-left: unset;
      text-align: center;
      font-size: 16px;
    }
  }
`;

export const LinkWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 48px;

  a {
    font-weight: 400;
    font-size: 18px;
    line-height: 140%;
    color: #ffffff;
    text-decoration: none;
  }

  @media (max-width: 576px) {
    grid-gap: 20px;
  }
`;

export const HiddenBlock = styled.div`
  display: none;
`;

export const Wrapper = styled.div`
  background: #000000;
  padding: 0 120px;

  @media (max-width: 992px) {
    padding: 0 40px;

    ${TopWrap} {
      padding: 40px 0;
    }
  }

  @media (max-width: 768px) {
    justify-content: center;
    padding: 0 16px;

    ${TopWrap} {
      justify-content: center;

      ${(props) =>
        props.isHide &&
        `${Logo} {
          display: none;
          };
          ${IconWrap} {
          display: none}`}
    }

    ${HiddenBlock} {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 18px;
      padding-bottom: 56px;

      ${Logo} {
        margin-right: 20px;
      }
    }

    ${IconWrap} {
      grid-gap: 18px;
    }

    ${BottomWrap} {
      padding: 34px 0;
    }
  }
`;
