import { settingsTypes } from './types';

export const getSettingsDataStart = (board) => ({
    type: settingsTypes.GET_SETTINGS_DATA_START,
    board,
});

export const getSettingsDataSuccess = (data) => ({
    type: settingsTypes.GET_SETTINGS_DATA_SUCCESS,
    data,
});

export const getSettingsDataFailure = (error) => ({
    type: settingsTypes.GET_SETTINGS_DATA_FAILURE,
    error
});