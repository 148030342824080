import React from 'react';
import withIcon from '../withIcon';

const CheckCircleIcon = () => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.40332 0.164062C4.69488 0.164062 0.864258 3.99469 0.864258 8.70313C0.864258 13.4116 4.69488 17.2422 9.40332 17.2422C14.1118 17.2422 17.9424 13.4116 17.9424 8.70313C17.9424 3.99469 14.1118 0.164062 9.40332 0.164062ZM7.69636 12.4714L4.52581 9.30769L5.73152 8.09856L7.69465 10.0583L12.2152 5.5377L13.4227 6.74512L7.69636 12.4714Z"
        fill="#35353F"
      />
    </svg>
  );
};

export default withIcon(CheckCircleIcon);
