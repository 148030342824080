import { call, put, takeLatest } from 'redux-saga/effects';
import {
  getNotificationsFailure,
  getNotificationsSuccess,
  updateNotificationsSuccess,
  updateNotificationsFailure,
} from './actions';
import { notificationsTypes } from './types';
import { fetchNotifications, updateNotifications } from '../../service';

export function* getNotificationsStart({offset, limit}) {
  try {
    const data = yield call(() => {
      return fetchNotifications(offset, limit);
    });
    yield put(getNotificationsSuccess(data));
  } catch (e) {
    yield put(getNotificationsFailure(e.message));
  }
}

export function* updateNotificationsStart({ id, body }) {
  try {
    const data = yield call(() => {
      return updateNotifications(id, body);
    });
    yield put(updateNotificationsSuccess(data));
  } catch (e) {
    yield put(updateNotificationsFailure(e.message));
  }
}

export function* notificationsSaga() {
  yield takeLatest(
    notificationsTypes.GET_NOTIFICATIONS_START,
    getNotificationsStart,
  );
  yield takeLatest(
    notificationsTypes.UPDATE_NOTIFICATIONS_START,
    updateNotificationsStart,
  );
}
