import React, { useRef, useEffect } from 'react';
import styled from 'styled-components';
import { PaperClipIcon, DeleteIcon } from '../../../Icons';

const Wrap = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  input {
    display: none;
  }
`;

function UploadFile({ onSelect, reset, isDeleted, ...rest }) {
  const fileInput = useRef(null);

  useEffect(() => {
    if (reset) {
      handleReset();
    }
  }, [reset]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleChange = (e) => {
    if (onSelect && e.target.files) {
      const formData = new FormData();
      formData.append('file', e.target.files[0]);
      onSelect(formData);
    }
  };

  const handleReset = () => {
    if (fileInput.current) {
      fileInput.current.value = null;
      onSelect(null);
    }
  };

  const handleClick = () => {
    fileInput.current && fileInput.current.click();
  };

  return (
    <>
      <Wrap onClick={handleClick}>
        <PaperClipIcon />
        <input ref={fileInput} type="file" onChange={handleChange} {...rest} />
      </Wrap>
      {isDeleted && fileInput.current?.value && (
        <DeleteIcon title={'Delete file'} onClick={handleReset} />
      )}
    </>
  );
}

export default UploadFile;
