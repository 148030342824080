import React from 'react';
import { Provider } from 'react-redux';
import { notification } from 'antd';
import axios from 'axios';
import { configureStore } from './store/configure-store';
import Routes from './routes';
import { logoutStart } from './redux/global/actions';
import { getErrorNotification } from './helpers/helpers';
import 'antd/dist/antd.css';
import 'react-calendar/dist/Calendar.css';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import './App.scss';

const store = configureStore();

axios.interceptors.response.use(
  (res) => res,
  function (err) {
    if (err.response?.status === 401) {
      store.dispatch(logoutStart());
    }
    notification.error({
      message: 'Error',
      description: getErrorNotification(err),
    });
    return Promise.reject(err);
  },
);

function App() {
  return (
    <Provider store={store}>
      <Routes />
    </Provider>
  );
}

export default App;
