import React, { useState } from 'react';
import MicrosoftLogin from 'react-microsoft-login';
import styled from 'styled-components';
import logo from '../../../assets/images/microsoft-login-icon.png';
import { oauth } from '../../../service';

const Button = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  border: 1px solid #dadce0;
  color: #3c4043;
  border-radius: 4px;
  max-width: 400px;
  height: 40px;
  margin: auto;

  &:hover {
    border-color: #d2e3fc;
    background: rgb(248 250 255);
    cursor: pointer;
  }

  img {
    width: 28px;
    height: 28px;
    margin-right: 22px;
  }

  @media (max-width: 610px) {
    max-width: 275px;
  }
`;

export default ({ loginSuccess }) => {
  const [show, setShow] = useState(false);
  const authHandler = (err, data) => {
    setShow(false);
    if (err) {
      console.error(err);
      return;
    }

    const { userPrincipalName, account = {} } = data;
    const { accountIdentifier, name = '', userName } = account;
    const idx = name.indexOf(' ');
    const firstName = idx ? name.slice(0, idx) : name || userName;
    const lastName = idx ? name.slice(idx) : '';

    if (!userName) return;

    const body = {
      email: userPrincipalName || userName,
      firstName,
      lastName,
      oauthId: accountIdentifier,
      oauth: 'microsoftId',
    };

    sessionStorage.clear();

    oauth(body)
      .then(loginSuccess)
      .catch(() => setShow(true));
  };

  return (
    <div>
      <MicrosoftLogin
        clientId={process.env.REACT_APP_MICROSOFT_CLIENT_ID}
        prompt="select_account"
        redirectUri={process.env.REACT_APP_ORIGIN_URL + '/login'}
        withUserData={true}
        useLocalStorageCache={false}
        authCallback={authHandler}
        children={
          <Button>
            <img src={logo} alt={'microsoft login'} />
            <div>Sign in with Microsoft</div>
          </Button>
        }
      />

      {show && (
        <div className="text-danger text-center mt-2">Login failed.</div>
      )}
    </div>
  );
};
