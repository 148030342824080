import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import BillingPlans from '../BillingPlans';
import logo from '../../assets/images/logo.png';

const Logo = styled.div`
  width: 227px;
  margin: 48px;
`;

const Wrapper = styled.div`
  width: 100%;
  padding: 24px;
`;

function Pricing() {
  return (
    <div>
      <Logo>
        <Link to="/">
          <img src={logo} alt={'Thelma logo'} />
        </Link>
      </Logo>

      <Wrapper>
        <BillingPlans isPricingPage />
      </Wrapper>
    </div>
  );
}
export default Pricing;
