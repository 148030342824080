import React, { useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import MicrosoftLogin from '../oauth/microsoft';
import { GoogleLogin } from '../oauth/google';
import { register, sendPasswordToken } from '../../service';
import { ROUTES } from '../../helpers/variables';
import logo from '../../assets/images/logo.png';
import {
  Wrapper,
  Form,
  FormItem,
  Input,
  Button,
  FlexWrap,
  Line,
  Title,
  Text,
  OauthWrap,
  Logo,
  FormWrap,
  OauthButtons,
} from './styles';

const SignUp = ({ isHome }) => {
  const [form] = Form.useForm();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [responseData, setResponseData] = useState({});

  const handleSubmit = (value) => {
    const [firstName, ...lastName] = value.name?.split(' ') || [];
    const body = {
      firstName,
      lastName: lastName?.join(' '),
      email: value.email,
      password: value.password,
      confirmPassword: value.password,
    };

    // if (!isValidatePassword(values.password)) {
    // }
    //
    // if (!isValidEmail(values.email)) {
    // }

    setLoading(true);
    register(body)
      .then((res) => {
        setResponseData(res);
      })
      .catch(() => setLoading(false));
  };
  const registerSuccess = (res) => {
    localStorage.setItem('token', res.token);
    history.push(ROUTES.dashboard.path);
  };

  return (
    <Wrapper isHome={isHome}>
      <Link to="/">
        <Logo>
          <img src={logo} alt="logo" />
        </Logo>
      </Link>
      {responseData.message ? (
        <div>
          {responseData.message}{' '}
          <span
            style={{ color: '#5d1fe1', cursor: 'pointer' }}
            onClick={() =>
              sendPasswordToken({ email: responseData.data?.email })
            }
          >
            Resend email.
          </span>
        </div>
      ) : (
        <FormWrap>
          <Title>Sign up or sign in</Title>
          <Form form={form} layout="vertical" onFinish={handleSubmit}>
            <FormItem
              label="Name"
              name="name"
              rules={[{ required: true, message: 'Please input your Name!' }]}
            >
              <Input placeholder="Enter your name" />
            </FormItem>
            <FormItem
              label="Email"
              name="email"
              rules={[
                {
                  required: true,
                  message: 'Please input correct email',
                  type: 'email',
                },
              ]}
            >
              <Input placeholder="Enter your email" type="email" />
            </FormItem>
            <FormItem
              label="Password"
              name="password"
              help="Must be at least 8 characters."
              rules={[
                {
                  required: true,
                  message: 'Must be at least 8 characters.',
                  min: 8,
                },
              ]}
            >
              <Input placeholder="Create a password" type="password" />
            </FormItem>
            <FormItem>
              <Button type="primary" htmlType="submit" loading={loading}>
                Create account
              </Button>
            </FormItem>
          </Form>
          <OauthButtons>
            <FlexWrap>
              <Line />
              <div>OR</div>
              <Line />
            </FlexWrap>

            <OauthWrap>
              <GoogleLogin text="signup_with" loginSuccess={registerSuccess} />
            </OauthWrap>
            <OauthWrap>
              <MicrosoftLogin loginSuccess={registerSuccess} />
            </OauthWrap>
            <Text to={'/login'}>Sign in with email and password</Text>
          </OauthButtons>
        </FormWrap>
      )}
    </Wrapper>
  );
};

export default SignUp;
