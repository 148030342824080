import React, { useRef, memo } from 'react';
import { Input as AntdInput } from 'antd';
import styled from 'styled-components';
import { Tags } from '../../../shared';
import {
  arrayOfObjectDeleteItem,
  getItemByKeyFromArrayOfObjects,
  arrayOfObjectsMergeById,
  generateRandomId,
} from '../../../../helpers/helpers';
import {
  BOARD_TYPE,
  TAG_TYPE_NAMES,
  TAG_TYPES,
} from '../../../../helpers/variables';
import { Title } from '../../styles';

const Input = styled(AntdInput)`
  background: #f5f8fe;
  border: 1px dashed #000000;
  border-radius: 8px;
  height: 46px;
  margin-top: 24px;
`;

function SettingsTags({ department, tags = [], board, onChange }) {
  const inputRef = useRef();
  const handleDeleteTag = (_id) => (tagId) => {
    const fields = arrayOfObjectDeleteItem(
      getItemByKeyFromArrayOfObjects(tags, _id)?.fields,
      tagId,
    );
    onChange('tags', arrayOfObjectsMergeById(tags, { _id, fields }));
  };

  const handleAddTag = (_id) => ({ fields }, field) => {
    // const fields = arrayOfObjectsMergeById(tags.fields, {
    //   ...field,
    //   completed: false,
    //   value: field.name,
    // });

    onChange('tags', arrayOfObjectsMergeById(tags, { _id, fields }));
  };

  const handleEditTag = (id) => (title) => {
    onChange(
      'tags',
      tags.map((item) => (item._id === id ? { ...item, title } : item)),
    );
  };

  const handleDelete = (id) => {
    onChange('tags', arrayOfObjectDeleteItem(tags, id));
  };

  const handleSubmit = (e) => {
    onChange('tags', [
      ...tags,
      {
        _id: generateRandomId(),
        title: e.target.value,
        department,
        type: TAG_TYPES.default,
        fields: [],
      },
    ]);

    if (inputRef.current?.input?.value) {
      inputRef.current.input.value = '';
    }
  };

  return (
    <>
      <Title>Quick Tags</Title>
      <div>
        {board === BOARD_TYPE.onboard &&
          [
            TAG_TYPES.googleGroups,
            TAG_TYPES.googleDriveFolders,
            TAG_TYPES.googleDriveSharedFolders,
            TAG_TYPES.office365Groups,
            TAG_TYPES.office365Drives,
            TAG_TYPES.oktaGroups,
          ].map((type) => {
            const item = tags.find((tag) => tag.type === type) || {};

            return item._id ? (
              <Tags
                key={type}
                isSettings
                name={item.title}
                tags={item.fields}
                type={item.type}
                background={'#E7DEFA'}
                onChange={handleAddTag(item._id)}
                onDeleteTag={handleDeleteTag(item._id)}
              />
            ) : (
              <Tags
                key={type}
                isSettings
                name={TAG_TYPE_NAMES[type]}
                type={type}
                background={'#E7DEFA'}
                onChange={({ fields }) =>
                  onChange('tags', [
                    ...tags,
                    {
                      _id: generateRandomId(),
                      title: TAG_TYPE_NAMES[type],
                      type: type,
                      department,
                      fields,
                    },
                  ])
                }
              />
            );
          })}

        {tags
          .filter((tag) => tag.type === TAG_TYPES.default)
          .map((item) => (
            <Tags
              isSettings
              key={item._id}
              name={item.title}
              tags={item.fields}
              type={item.type}
              background={'#E7DEFA'}
              onDelete={() => handleDelete(item._id)}
              onChange={handleAddTag(item._id)}
              onDeleteTag={handleDeleteTag(item._id)}
              onEdit={handleEditTag(item._id)}
            />
          ))}

        <div>
          <Input
            placeholder="Add Tag Picklist"
            ref={inputRef}
            onPressEnter={handleSubmit}
          />
        </div>
      </div>
    </>
  );
}

export default memo(SettingsTags);
