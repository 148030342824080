import { detailsTypes } from './types';

export const getDetailsStart = (payload) => ({
    type: detailsTypes.GET_DETAILS_START,
    payload,
});

export const getDetailsSuccess = (data) => ({
    type: detailsTypes.GET_DETAILS_SUCCESS,
    data,
});

export const getDetailsFailure = (error) => ({
    type: detailsTypes.GET_DETAILS_FAILURE,
    error,
});

export const createDetailStart = (payload) => ({
    type: detailsTypes.CREATE_DETAIL_START,
    payload,
});

export const createDetailSuccess = (data) => ({
    type: detailsTypes.CREATE_DETAIL_SUCCESS,
    data,
});

export const createDetailFailure = (error) => ({
    type: detailsTypes.CREATE_DETAIL_FAILURE,
    error,
});

export const updateDetailStart = (payload) => ({
    type: detailsTypes.UPDATE_DETAIL_START,
    payload,
});

export const updateDetailSuccess = (data) => ({
    type: detailsTypes.UPDATE_DETAIL_SUCCESS,
    data,
});

export const updateDetailFailure = (error) => ({
    type: detailsTypes.UPDATE_DETAIL_FAILURE,
    error,
});

export const deleteDetailStart = (id) => ({
    type: detailsTypes.DELETE_DETAIL_START,
    id,
});

export const deleteDetailSuccess = (data) => ({
    type: detailsTypes.DELETE_DETAIL_SUCCESS,
    data,
});

export const deleteDetailFailure = (error) => ({
    type: detailsTypes.DELETE_DETAIL_FAILURE,
    error,
});

export const deleteDetailFieldStart = (id, fieldId) => ({
    type: detailsTypes.DELETE_DETAIL_FIELD_START,
    id,
    fieldId
});

export const deleteDetailFieldSuccess = (data) => ({
    type: detailsTypes.DELETE_DETAIL_FIELD_SUCCESS,
    data,
});

export const deleteDetailFieldFailure = (error) => ({
    type: detailsTypes.DELETE_DETAIL_FIELD_FAILURE,
    error,
});
