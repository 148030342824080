import React, { useState } from 'react';
import { Link, Redirect, useHistory } from 'react-router-dom';
import { Form } from 'antd';
import { resetPassword } from '../../service';
import { isValidToken } from '../../helpers/helpers';
import logo from '../../assets/images/white-logo.png';
import SubmitButton from '../shared/submitButton';
import { ROUTES } from '../../helpers/variables';
import { Container, FlexBetween } from '../shared/styles';
import {
  Input,
  Content,
  Wrapper,
  Logo,
  InputPassword,
  LinkLogin,
  Title,
} from './styles';

const ResetPassword = (props) => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [validationMessages, setValidationMessages] = useState({});

  const handleSubmit = (body) => {
    if (props.match.params.token) {
      setLoading(true);
      resetPassword({
        ...body,
        token: props.match.params.token,
      })
        .then(() => history.push(ROUTES.login.path))
        .catch((e) => {
          setLoading(false);
          if (e.response.data.type) {
            setValidationMessages((prev) => ({
              ...prev,
              [e.response.data.type]: e.response.data.message,
            }));
          }
        });
    }
  };

  if (isValidToken()) {
    return <Redirect to={ROUTES.home.path} />;
  }

  return (
    <Wrapper>
      <Container>
        <FlexBetween>
          <Logo>
            <Link to={ROUTES.home.path}>
              <img src={logo} alt="logo" />
            </Link>
          </Logo>
          <div>
            <LinkLogin to={ROUTES.login.path}>Sign In</LinkLogin>
          </div>
        </FlexBetween>
        <Content md={{ span: 12, offset: 6 }}>
          <Title>Reset Password</Title>
          <Form onFinish={handleSubmit}>
            <Form.Item
              name="email"
              placeholder="Email"
              validateStatus={validationMessages.email && 'error'}
              help={validationMessages.email}
              rules={[
                {
                  required: true,
                  type: 'email',
                  message: 'Please provide a valid email.',
                },
              ]}
            >
              <Input placeholder="Email" />
            </Form.Item>
            <Form.Item
              name="password"
              validateStatus={validationMessages.password && 'error'}
              help={validationMessages.password}
              rules={[
                {
                  required: true,
                  message: 'Please provide a valid password.',
                },
              ]}
            >
              <Input.Password placeholder="Password" />
            </Form.Item>

            <Form.Item
              name="confirmPassword"
              validateStatus={validationMessages.password && 'error'}
              help={validationMessages.password}
              rules={[
                {
                  required: true,
                  message: 'Please provide a valid password.',
                },
              ]}
            >
              <InputPassword placeholder="Confirm Password" />
            </Form.Item>
            <SubmitButton loading={loading} />
          </Form>
        </Content>
      </Container>
    </Wrapper>
  );
};
export default ResetPassword;
