import { put, call, takeLatest } from 'redux-saga/effects';
import {
  getAssetsSuccess,
  getAssetsFailure,
  createAssetsSuccess,
  updateAssetsSuccess,
  setAssetsFailure,
  deleteAssetsSuccess,
  getAssetsStart,
} from './actions';
import { assetsTypes } from './types';
import * as service from '../../service';

export function* getAssets({ params }) {
  const { limit, offset, search } = params;
  try {
    const data = yield call(() => service.fetchAssets(offset, limit, search));
    yield put(getAssetsSuccess(data));
  } catch (e) {
    yield put(getAssetsFailure(e.message));
  }
}

export function* create({ body }) {
  try {
    const data = yield call(() => {
      return service.createAssets(body);
    });
    yield put(createAssetsSuccess(data));
  } catch (e) {
    yield put(setAssetsFailure(e.message));
  }
}

export function* update({ body, id }) {
  try {
    const data = yield call(() => {
      return service.updateAssets(body, id);
    });
    yield put(updateAssetsSuccess(data));
  } catch (e) {
    yield put(setAssetsFailure(e.message));
  }
}

export function* deleteAsset({ id, params }) {
  try {
    const data = yield call(() => {
      return service.deleteAssets(id);
    });
    yield put(deleteAssetsSuccess(data));
    if (params && params.offset >= 0 && params.limit) {
      yield put(getAssetsStart(params));
    }
  } catch (e) {
    yield put(setAssetsFailure(e.message));
  }
}

export function* assetsSaga() {
  yield takeLatest(assetsTypes.GET_ASSETS_START, getAssets);
  yield takeLatest(assetsTypes.CREATE_ASSETS_START, create);
  yield takeLatest(assetsTypes.UPDATE_ASSETS_START, update);
  yield takeLatest(assetsTypes.DELETE_ASSETS_START, deleteAsset);
}
