import React from 'react';
import { Modal } from 'antd';

function CustomModal({ open = false, children, onOk, onCancel, ...restProps }) {
  return (
    <Modal
      open={open}
      okText="Submit"
      footer={!onOk ? null : undefined}
      onCancel={onCancel}
      onOk={onOk}
      {...restProps}
    >
      {children}
    </Modal>
  );
}

export default CustomModal;
