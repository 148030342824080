import styled from 'styled-components';

export const FlexWrap = styled.div`
  display: flex;
  justify-content: space-between;

  & > div {
    width: 50%;
  }
`;

export const BoardInfoWrap = styled.div`
  background: #5d1fe1;
  padding: 90px 52px 90px;
`;

export const BoardInfoTitle = styled.div`
  font-weight: 500;
  font-size: 35px;
  line-height: 42px;
  color: #ffffff;
  margin-bottom: 20px;
`;

export const BoardInfoText = styled.div`
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #ffffff;
  margin-bottom: 120px;
`;

export const DashboardImg = styled.img`
  width: 100%;
  height: auto;
  border-radius: 15px;
  max-width: 574px;
`;

export const SyncInfoWrap = styled.div`
  background: linear-gradient(
    267.67deg,
    #000000 18.83%,
    rgba(0, 0, 0, 0.83) 105.01%
  );
  padding: 50px 120px 40px;
`;

export const Title = styled.div`
  font-weight: 500;
  font-size: 32px;
  text-align: center;
  color: #ffffff;
  max-width: 540px;
  margin: auto auto 32px;
`;

export const SyncContent = styled.div`
  & > div:first-child {
    display: flex;
    align-items: center;

    & > div {
      width: 33.3%;
      padding: 0 26px;
      margin-bottom: 56px;

      &:not(:last-child) {
        border-right: 1px solid rgba(255, 255, 255, 0.26);
      }
    }
  }
`;

export const SyncContentTitle = styled.div`
  margin-top: 24px;
  margin-bottom: 24px;
  font-weight: 500;
  font-size: 24px;
  line-height: 116%;
  color: #ffffff;
`;

export const SyncContentText = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 116%;
  color: rgba(255, 255, 255, 0.7);
`;

export const SyncImgWrap = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  grid-gap: 36px 24px;
  overflow: hidden;
  height: ${(props) => (props.showLess ? 'auto' : '278px')};
  /* transition-property: height;
  transition-duration: 0.8s; */

  div {
    background: #2a303b;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px;
    height: 68px;

    img {
      width: 100%;
      height: auto;
    }
  }

  @media (max-width: 768px) {
    grid-gap: 24px 16px;
    height: ${(props) => (props.showLess ? 'auto' : '438px')};
  }
`;

export const ShowLessWrapper = styled.div`
  color: #fff;
  width: 100%;
  text-align: center;
  font-weight: 700;
  font-size: 18px;
  margin-top: 32px;
  cursor: pointer;
`;

export const OtherToolsWrap = styled.div`
  padding: 96px;

  ${Title} {
    color: #000;
  }
`;

export const OtherToolsContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: auto;
  max-width: 940px;

  & > div:nth-child(2n) {
    margin-right: 40px;
  }

  ${SyncContentTitle} {
    color: #313131;
  }

  ${SyncContentText} {
    color: #313131;
    min-height: 76px;
  }
`;

export const ItemContent = styled.div`
  width: 405px;
  margin-bottom: 32px;
  padding-right: 32px;

  & + div {
    border-bottom: 1px solid #8593ad;
    margin-bottom: 28px;
  }
`;

export const Wrapper = styled.div`
  @media (max-width: 1100px) {
    ${FlexWrap} {
      flex-direction: column;

      & > div {
        width: 100%;
      }
    }

    ${OtherToolsWrap} {
      padding: 20px;
    }
  }

  @media (max-width: 992px) {
    ${FlexWrap} {
      flex-direction: column;
    }

    ${OtherToolsWrap} {
      padding: 95px 20px;
      flex-direction: column;
    }

    ${OtherToolsContent} {
      padding: 0 110px;
    }

    ${SyncContent} {
      & > div:first-child {
        flex-direction: column;

        & > div {
          width: 100%;
          padding: 0 0 26px;
          margin-bottom: 30px;

          &:not(:last-child) {
            border-bottom: 1px solid rgba(255, 255, 255, 0.26);
            border-right: none;
          }
        }
      }
    }
    ${ItemContent} {
      width: 100%;
    }
  }

  @media (max-width: 610px) {
    ${SyncInfoWrap} {
      padding: 40px;
    }

    ${OtherToolsContent} {
      padding: 0;
      width: 80%;
    }

    ${FlexWrap} {
      & > div {
        padding: 40px 20px;
      }
    }
  }
`;
