import { put, call, takeLatest } from 'redux-saga/effects';
import { passwordsTypes } from './types';
import * as service from '../../service';
import {
  getPasswordsSuccess,
  getPasswordsFailure,
  getPasswordsFoldersSuccess,
} from './actions';

export function* getPasswords({ payload }) {
  try {
    const data = yield call(() => service.getPasswords(payload));
    yield put(getPasswordsSuccess(data));
  } catch (e) {
    yield put(getPasswordsFailure(e.message));
  }
}

export function* getPasswordFolders() {
  try {
    const data = yield call(() => service.getPasswordFolders());
    yield put(getPasswordsFoldersSuccess(data));
  } catch (e) {
    yield put(getPasswordsFailure(e.message));
  }
}

export function* passwordsSaga() {
  yield takeLatest(passwordsTypes.GET_PASSWORDS_START, getPasswords);
  yield takeLatest(
    passwordsTypes.GET_PASSWORDS_FOLDERS_START,
    getPasswordFolders,
  );
}
