import React from 'react';
import { Link } from 'react-router-dom';
import { useMediaQuery } from '../../../helpers/hooks';
import {
  InstagramOutlined,
  TwitterOutlined,
  FbIcon,
  LinkedinIcon,
} from '../../../Icons';
import logo from '../../../assets/logo.png';
import {
  Wrapper,
  TopWrap,
  IconWrap,
  BottomWrap,
  Text,
  Logo,
  HiddenBlock,
  LinkWrap,
} from './styles';

const Footer = () => {
  const match = useMediaQuery('(max-width: 768px)');

  return (
    <Wrapper isHide={match}>
      <TopWrap>
        <Logo>
          <img src={logo} alt={'Thelma logo'} />
        </Logo>
        <Text>Delivering happier employee outcomes.</Text>
        <IconWrap>
          <a
            href="https://www.instagram.com/thelma.ai/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <InstagramOutlined />
          </a>
          <a
            href="https://www.facebook.com/thelmaai/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FbIcon />
          </a>
          <a
            href="https://twitter.com/team_thelma"
            target="_blank"
            rel="noopener noreferrer"
          >
            <TwitterOutlined />
          </a>
          <a
            href="https://linkedin.com/company/teamthelma"
            target="_blank"
            rel="noopener noreferrer"
          >
            <LinkedinIcon />
          </a>
        </IconWrap>
      </TopWrap>
      <BottomWrap>
        <LinkWrap>
          <Link to="/pricing">Pricing</Link>
          <Link to="/privacy">Privacy</Link>
          <a href="mailto:careers@thelma.ai">Careers</a>
          <a href="mailto:contact@thelma.ai">Contact Us</a>
        </LinkWrap>
        <div>{`@${new Date().getFullYear()} Thelma Software Inc. | All Rights Reserved`}</div>
      </BottomWrap>
      <HiddenBlock>
        <Logo>
          <img src={logo} alt={'Thelma logo'} />
        </Logo>
        <IconWrap>
          <a
            href="https://www.instagram.com/thelma.ai/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <InstagramOutlined />
          </a>
          <a
            href="https://www.facebook.com/thelmaai/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FbIcon />
          </a>
          <a
            href="https://twitter.com/team_thelma"
            target="_blank"
            rel="noopener noreferrer"
          >
            <TwitterOutlined />
          </a>
          <a
            href="https://linkedin.com/company/teamthelma"
            target="_blank"
            rel="noopener noreferrer"
          >
            <LinkedinIcon />
          </a>
        </IconWrap>
      </HiddenBlock>
    </Wrapper>
  );
};

export default Footer;
