import { assetsTypes } from './types';

export const getAssetsStart = (params) => ({
    type: assetsTypes.GET_ASSETS_START,
    params,
});

export const getAssetsSuccess = (data) => ({
    type: assetsTypes.GET_ASSETS_SUCCESS,
    data,
});

export const getAssetsFailure = (error) => ({
    type: assetsTypes.GET_ASSETS_FAILURE,
    error,
});

export const createAssetsStart = (body) => ({
    type: assetsTypes.CREATE_ASSETS_START,
    body,
});

export const createAssetsSuccess = (data) => ({
    type: assetsTypes.CREATE_ASSETS_SUCCESS,
    data,
});

export const updateAssetsStart = (body, id) => ({
    type: assetsTypes.UPDATE_ASSETS_START,
    body,
    id,
});

export const updateAssetsSuccess = (data) => ({
    type: assetsTypes.UPDATE_ASSETS_SUCCESS,
    data,
});

export const deleteAssetsStart = (id, params) => ({
    type: assetsTypes.DELETE_ASSETS_START,
    id,
    params
});

export const deleteAssetsSuccess = (data) => ({
    type: assetsTypes.DELETE_ASSETS_SUCCESS,
    data,
});

export const setAssetsFailure = (error) => ({
    type: assetsTypes.SET_ASSETS_FAILURE,
    error,
});
