import React from 'react';
import withIcon from '../withIcon';

const AssetManagerIcon = () => {
  return (
    <svg
      width="42"
      height="40"
      viewBox="0 0 42 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="28.3574" cy="18.5" r="13.5" fill="#C956FF" />
      <path
        d="M11.733 3.3335H30.2497C36.183 3.3335 37.6663 4.81683 37.6663 10.7335V21.2835C37.6663 27.2168 36.183 28.6835 30.2663 28.6835H11.733C5.81634 28.7002 4.33301 27.2168 4.33301 21.3002V10.7335C4.33301 4.81683 5.81634 3.3335 11.733 3.3335Z"
        stroke="#292D32"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21 28.7002V36.6669"
        stroke="#292D32"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.33301 21.6665H37.6663"
        stroke="#292D32"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.5 36.6665H28.5"
        stroke="#292D32"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default withIcon(AssetManagerIcon);
