import React from 'react';
import { Spinner } from 'react-bootstrap';

export default function () {
    return (
        <div className="w-100 h-100 d-flex justify-content-center align-items-center">
            <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
            />
            &nbsp;Loading...
        </div>
    );
}
