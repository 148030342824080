import { passwordsTypes } from './types';

const initialState = {
    passwords: { data: [], total: 0 },
    error: '',
    loading: false,
    passwordFolders: { folders: [], shared: [] },
};

export const passwords = (state = initialState, action) => {
    switch (action.type) {
        case passwordsTypes.GET_PASSWORDS_START:
            return { ...state, loading: true };
        case passwordsTypes.GET_PASSWORDS_SUCCESS:
            return { ...state, passwords: action.data, loading: false };
        case passwordsTypes.GET_PASSWORDS_FAILURE:
            return { ...state, error: action.error, loading: false };
        case passwordsTypes.GET_PASSWORDS_FOLDERS_SUCCESS:
            return { ...state, passwordFolders: action.data };
        default:
            return state;
    }
};
