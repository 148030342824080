import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Button, Modal, Form, Col, Row } from 'react-bootstrap';
import { fetchUserContacts, updateUserContacts } from '../../../service';
import { SubmitButton, Select } from '../index';
import { ROLE_VALUES, ZIP_STATE } from '../../../helpers/variables';
import contactIcon from '../../../assets/images/contact.svg';

export default function ({ id, handleClose, show }) {
  const { currentUserRole } = useSelector((state) => state.global);
  const isAccess = [
    ROLE_VALUES.admin,
    ROLE_VALUES.hr,
    ROLE_VALUES.helpdesk,
  ].includes(currentUserRole);

  if (!isAccess) return null;
  const [modalShow, setModalShow] = useState(false);
  const initialBody = {
    address1: '',
    address2: '',
    city: '',
    state: '',
    zip: '',
    phone: '',
    personalEmail: '',
  };
  const [body, setBody] = useState(initialBody);

  useEffect(() => {
    if (id) {
      fetchUserContacts(id)
        .then((res) => setBody((prev) => ({ ...prev, ...res })))
        .catch();
    }
  }, [id]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleShow = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setModalShow(true);
  };

  const onHide = () => {
    setBody(initialBody);
    setModalShow(false);
    if (typeof handleClose === 'function') {
      handleClose();
    }
  };

  const handleChange = ({ target }) => {
    const { name, value } = target;
    setBody((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const { _id, ...data } = body;
    updateUserContacts(_id, data).then(onHide).catch();
  };

  return (
    <>
      {typeof handleClose === 'function' && typeof show === 'boolean' ? (
        ''
      ) : (
        <img
          src={contactIcon}
          onClick={handleShow}
          className="icon-20 ml-1"
          alt="icon"
        />
      )}
      <Modal
        dialogClassName="w-xl-50 w-sm-75"
        show={modalShow || show}
        onHide={onHide}
        aria-labelledby="modal-contact-info"
        centered
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group as={Row} controlId="id_address1">
              <Form.Label column sm={4} className="text-right">
                Address Line 1
              </Form.Label>
              <Col sm={8}>
                <Form.Control
                  name="address1"
                  value={body.address1}
                  onChange={handleChange}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="id_address2">
              <Form.Label column sm={4} className="text-right">
                Address Line 2
              </Form.Label>
              <Col sm={8}>
                <Form.Control
                  name="address2"
                  value={body.address2}
                  onChange={handleChange}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="id_city">
              <Form.Label column sm={4} className="text-right">
                City
              </Form.Label>
              <Col sm={8}>
                <Form.Control
                  name="city"
                  value={body.city}
                  onChange={handleChange}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="id_state">
              <Form.Label column sm={4} className="text-right">
                State
              </Form.Label>
              <Col sm={8}>
                <Select
                  required
                  name="state"
                  emptyOption={true}
                  value={body.state}
                  options={ZIP_STATE}
                  onChange={handleChange}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="id_zip">
              <Form.Label column sm={4} className="text-right">
                Zip
              </Form.Label>
              <Col sm={8}>
                <Form.Control
                  name="zip"
                  value={body.zip}
                  onChange={handleChange}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="id_phone">
              <Form.Label column sm={4} className="text-right">
                Phone Number
              </Form.Label>
              <Col sm={8}>
                <Form.Control
                  name="phone"
                  value={body.phone}
                  onChange={handleChange}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="id_email">
              <Form.Label column sm={4} className="text-right">
                Personal Email
              </Form.Label>
              <Col sm={8}>
                <Form.Control
                  type="email"
                  name="personalEmail"
                  value={body.personalEmail}
                  onChange={handleChange}
                />
              </Col>
            </Form.Group>

            <Modal.Footer>
              <Button variant="outline-secondary" onClick={onHide}>
                Close
              </Button>
              <SubmitButton
                variant="primary"
                type="submit"
                className="float-right pl-4 pr-4"
              />
            </Modal.Footer>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}
