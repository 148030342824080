import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { DataTable, UploadFile, UserAvatar } from '../shared';
import {
  arrayOfObjectsMergeById,
  formatAMPM,
  getDateByFormat,
  getItemStatusInfo,
  replaceAllBreaksToBr,
} from '../../helpers/helpers';
import {
  fetchTickets,
  createTickets,
  updateTickets,
  replyTicketMessage,
  fetchTicketSettings,
  singleUpload,
  ticketForward,
} from '../../service';
import {
  DownOutlined,
  UpOutlined,
  ForwardIcon,
  ReplyIcon,
  NoteIcon,
} from '../../Icons';
import UpdateRowItem from './UpdateRowItem';
import { getUsersList } from '../../redux/users/selectors';
import Actions from './actions';
import Filters from './filters';
import {
  Button,
  Wrapper,
  ExpandRowWrap,
  ShareButtons,
  ShareButtonsWrap,
  MessageWrap,
  ReplyForm,
  TextArea,
  ReplySubmit,
  ForwardForm,
  Input,
} from './styles';

const ExpandRowContent = ({ row }) => {
  const [data, setData] = useState({});
  const [body, setBody] = useState({ message: '' });
  const [show, setShow] = useState('');
  const [file, setFile] = useState();
  const [email, setEmail] = useState('');

  useEffect(() => {
    setData(row);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleReply = async () => {
    const request = { ...body };

    if (show === 'note') {
      request.type = 'private';
    }

    if (file) {
      const { url } = await singleUpload(file);
      request.message = `${request.message} <a href=${url}>${
        file.get('file')?.name
      }</a>`;
    }

    replyTicketMessage(data._id, request).then((res) => {
      setData(res);
      setShow('');
    });
  };

  const handleForward = () => {
    ticketForward({ id: row._id, email })
      .then(() => setShow(''))
      .catch((e) => {});
  };

  return (
    <ExpandRowWrap>
      <MessageWrap>
        <>
          {data.comment && (
            <div>
              <div
                dangerouslySetInnerHTML={{
                  __html: replaceAllBreaksToBr(data.comment),
                }}
              />
              <div className="text-right">
                <b>
                  {formatAMPM(data.createdAt)} |{' '}
                  {getDateByFormat(data.createdAt, 'm/d/y')}
                </b>
              </div>
            </div>
          )}
          {Array.isArray(data.messages)
            ? data.messages.map((item, idx) => (
                <div key={idx}>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: replaceAllBreaksToBr(item.message),
                    }}
                  />
                  <div className="text-right">
                    <b>
                      {formatAMPM(item.createdAt)} |{' '}
                      {getDateByFormat(item.createdAt, 'm/d/y')}
                      {item.type === 'private' && ' Private note'}
                    </b>
                  </div>
                </div>
              ))
            : ''}
        </>
      </MessageWrap>

      <ShareButtonsWrap>
        <div>
          <ShareButtons icon={<ReplyIcon />} onClick={() => setShow('reply')}>
            Reply
          </ShareButtons>
        </div>
        <div>
          <ShareButtons
            icon={<ForwardIcon />}
            onClick={() => setShow('forward')}
          >
            Forward
          </ShareButtons>
        </div>
        <div>
          <ShareButtons icon={<NoteIcon />} onClick={() => setShow('note')}>
            Add Note
          </ShareButtons>
        </div>
      </ShareButtonsWrap>

      {(show === 'reply' || show === 'note') && (
        <ReplyForm>
          <TextArea
            autoFocus
            rows={9}
            onChange={(e) => setBody({ message: e.target.value })}
          />
          <div>
            <UploadFile isDeleted onSelect={setFile} reset={false} />
            <ReplySubmit onClick={handleReply}>Send</ReplySubmit>
          </div>
        </ReplyForm>
      )}

      {show === 'forward' && (
        <ForwardForm>
          <div>
            <span>To: </span>
            <Input name="email" onChange={(e) => setEmail(e.target.value)} />
          </div>
          <ReplySubmit disabled={email.length < 5} onClick={handleForward}>
            Forward
          </ReplySubmit>
        </ForwardForm>
      )}
    </ExpandRowWrap>
  );
};

const Tickets = () => {
  const { isAdmin } = useSelector((state) => state.global);
  const users = useSelector(getUsersList);
  const [settings, setSettings] = useState({ issueTypes: [] });
  const [tickets, setTickets] = useState({});
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);
  const { data = [], total = 0 } = tickets;

  const columns = [
    {
      dataField: 'createdBy',
      text: 'Requester',
      headerStyle: () => ({ width: 200 }),
      formatter: (cell) => (
        <UserAvatar
          user={cell}
          email={true}
          style={{
            width: 'calc(100% - 50px)',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        />
      ),
    },
    {
      dataField: 'subject',
      text: 'Subject',
    },
    {
      dataField: 'owner',
      text: 'Assignee',
      headerStyle: () => ({ width: 180 }),
      formatter: (_, row) => (
        <UpdateRowItem
          onClick={handleUpdate}
          field={'owner'}
          data={users}
          row={row}
        />
      ),
    },
    {
      dataField: 'urgency',
      text: 'Priority',
      headerStyle: () => ({ width: 100 }),
      formatter: (_, row) => (
        <UpdateRowItem onClick={handleUpdate} field={'urgency'} row={row} />
      ),
    },

    {
      dataField: 'issue',
      text: 'Issue Type',
      headerStyle: () => ({ width: 150 }),
      formatter: (_, row) => (
        <UpdateRowItem
          onClick={handleUpdate}
          field={'issue'}
          row={row}
          issueTypes={settings.issueTypes}
        />
      ),
    },
    {
      dataField: 'status',
      text: 'Status',
      headerStyle: () => ({ width: '120px', textAlign: 'center' }),
      formatter: (cell, row) => {
        const itemStatus = getItemStatusInfo(cell);
        return (
          <Button
            style={{
              backgroundColor: itemStatus.background,
              color: itemStatus.color,
            }}
            onClick={handleUpdate(row._id, { status: itemStatus.status })}
          >
            {itemStatus.label}
          </Button>
        );
      },
    },
    {
      dataField: 'messages',
      text: 'Last Updated',
      headerStyle: () => ({ width: 140 }),
      formatter: (cell) => getDateByFormat(cell && cell[0]?.createdAt, 'd/F/y'),
    },
    {
      dataField: 'filter',
      text: '',
      headerFormatter: () => (
        <Filters issueTypes={settings.issueTypes} onFilter={handleFilter} />
      ),
      headerStyle: () => ({ width: 30, overflow: 'unset' }),
    },
  ];

  const expandRow = {
    onlyOneExpanding: true,
    renderer: (row) => <ExpandRowContent row={row} />,
    className: `tickets-message-content cursor-default ${
      isAdmin ? '' : 'pointer-events-none'
    }`,
    showExpandColumn: true,
    expandColumnPosition: 'right',
    expandHeaderColumnRenderer: ({ isAnyExpands }) => {
      if (isAnyExpands) {
        return;
      }
      return;
    },
    expandColumnRenderer: ({ expanded }) => {
      if (expanded) {
        return <UpOutlined style={{ cursor: 'pointer' }} />;
      }
      return <DownOutlined style={{ cursor: 'pointer' }} />;
    },
  };

  useEffect(() => {
    setLoading(true);
    fetchData(0, sizePerPage);
    fetchTicketSettings().then(setSettings);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchData = (offset, sizePerPage, filters) => {
    fetchTickets(offset, sizePerPage, filters)
      .then((res) => {
        setTickets(res);
      })
      .catch((e) => {})
      .finally(() => setLoading(false));
  };

  const handleFilter = (filters) => {
    const offset = (page - 1) * sizePerPage;
    fetchData(offset, sizePerPage, filters);
  };

  const handleTableChange = (type, { page, sizePerPage, searchText }) => {
    const offset = (page - 1) * sizePerPage;

    if (type === 'search') {
      if (searchText && searchText.length < 3) {
        return;
      }
      setPage(1);
      setSizePerPage(sizePerPage);
    } else {
      setPage(page);
      setSizePerPage(sizePerPage);
    }
    fetchData(offset, sizePerPage, { searchText });
  };

  const createTicket = (body) => {
    createTickets(body)
      .then(() => fetchData(0, sizePerPage))
      .catch((e) => {});
  };

  const handleUpdate = (id, body) => (e) => {
    e.preventDefault();
    e.stopPropagation();
    updateTickets(body, id)
      .then((res) => {
        setTickets((prev) => ({
          ...prev,
          data: [...arrayOfObjectsMergeById(prev.data, res)],
        }));
      })
      .catch((e) => {});
  };

  return (
    <Wrapper>
      <DataTable
        expandRow={expandRow}
        data={data}
        page={page}
        columns={columns}
        loading={loading}
        totalSize={total}
        sizePerPage={sizePerPage}
        handleTableChange={handleTableChange}
        actionButtons={(selected) => (
          <Actions
            issueTypes={settings.issueTypes}
            selected={selected}
            reload={() => fetchData(0, sizePerPage)}
            onSubmit={createTicket}
          />
        )}
      />
    </Wrapper>
  );
};

export default Tickets;
