import React, { memo } from 'react';
import { Pie } from '@ant-design/plots';

const DonutChart = ({ data, content }) => {
  const config = {
    data,
    height: 360,
    appendPadding: [0, 10, 0, 10],
    padding: [0, 10, 0, 10],
    angleField: 'value',
    colorField: 'type',
    innerRadius: 0.7,
    radius: 0.8,
    limitInPlot: true,
    label: false,
    tooltip: {
      enterable: true,
    },
    legend: {
      layout: ' vertical',
      position: 'bottom-left',
      offsetX: 20,
      offsetY: 0,
      showRadio: false,
      selected: false,
      itemName: {
        formatter: (name, listItem, index) => `${name} ${data[index].value}`,
        style: {
          fontSize: 18,
          color: '#545F74',
          fontWeight: 500,
        },
      },
      marker: {
        symbol: 'square',
        spacing: 12,
        style: {
          fontSize: 30,
        },
      },
    },
    // interactions: [
    //   {
    //     type: 'element-selected',
    //   },
    //   {
    //     type: 'element-active',
    //   },
    // ],
    statistic: {
      title: false,
      content: {
        content,
      },
    },
    theme: {
      colors10: [
        '#EB3351',
        '#532FD8',
        '#FFBE0A',
        '#9FB40F',
        '#76523B',
        '#DAD5B5',
        '#0E8E89',
        '#E19348',
        '#F383A2',
        '#247FEA',
      ],
    },
  };
  return <Pie {...config} />;
};

export default memo(
  DonutChart,
  (prevProps, nextProps) => prevProps.content === nextProps.content,
);
