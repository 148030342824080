import React, { useState, memo } from 'react';
import { useSelector } from 'react-redux';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { InputLabel, SelectUserModal } from '../../../shared';
import { getUsersInfo } from '../../../../redux/users/selectors';
import {
  arrayOfObjectDeleteItem,
  arrayOfObjectsMergeById,
  createTextHyperLink,
  getUserFullName,
  generateRandomId,
} from '../../../../helpers/helpers';
import { BOARD_TYPE } from '../../../../helpers/variables';
import {
  Wrapper,
  Wrapp,
  Owner,
  Task,
  Title,
  Tooltip,
  TooltipIcon,
  DragIcon,
  Input,
  Col,
  Row,
  InputNumber,
  DueDateWrap,
  BorderedWrap,
} from './styles';

function SettingsChecklist({ checklists = [], board, onChange }) {
  const users = useSelector(getUsersInfo);
  const [value, setValue] = useState('');

  const handleAdd = () => {
    if (value) {
      const title = createTextHyperLink(value);
      onChange('checklists', [
        ...checklists,
        { _id: generateRandomId(), title, order: checklists.length },
      ]);
      setValue('');
    }
  };

  const handleDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const { source, destination } = result;
    const arr = Array.from(checklists);
    const [removed] = arr.splice(source.index, 1);
    arr.splice(destination.index, 0, removed);
    onChange(
      'checklists',
      arr.map((item, index) => ({ ...item, order: index })),
    );
  };

  const handleChange = (id, value) => {
    onChange('checklists', [
      ...arrayOfObjectsMergeById(checklists, {
        _id: id,
        ...value,
      }),
    ]);
  };

  const handleDelete = (id) =>
    onChange('checklists', arrayOfObjectDeleteItem(checklists, id));

  return (
    <Wrapper>
      <Title>
        {board === BOARD_TYPE.onboard
          ? 'Onboarding Checklist'
          : 'Offboarding Checklist'}
      </Title>
      <Row style={{ marginTop: 16 }} gutter={10}>
        <Col span={12}>
          <Input
            size="large"
            value={value}
            placeholder="Type task item"
            onChange={(e) => setValue(e.target.value)}
            onPressEnter={handleAdd}
          />
        </Col>
        <Col span={5}>
          <Title>Owner</Title>
        </Col>
        <Col span={4}>
          <div style={{ position: 'relative' }}>
            <Title>Due Date</Title>
            <Tooltip
              title={
                board === BOARD_TYPE.onboard
                  ? 'Choose how many days prior to the Start Date a task should be completed, and the Thelma Dashboard will automatically assign a Due Date.'
                  : 'Choose how many days after an End Date a task should be completed, and the Thelma Dashboard will automatically assign a Due Date.'
              }
            >
              <TooltipIcon />
            </Tooltip>
          </div>
        </Col>
        <Col span={3} />
      </Row>
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="tasks">
          {(provided) => (
            <Wrapp ref={provided.innerRef}>
              {checklists?.map((item, index) => {
                return (
                  <Draggable
                    key={item._id}
                    draggableId={item._id}
                    index={index}
                  >
                    {(provided) => (
                      <Row
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        gutter={10}
                      >
                        <Col span={12}>
                          <DragIcon />
                          <Task>
                            <InputLabel
                              title={item.title}
                              onDelete={() => handleDelete(item._id)}
                              onEdit={(title) =>
                                handleChange(item._id, {
                                  title: createTextHyperLink(title),
                                })
                              }
                            />
                          </Task>
                        </Col>
                        <Col span={5}>
                          <SelectUserModal
                            showHiringManager
                            value={item.owner?._id}
                            onSelect={(owner) =>
                              handleChange(item._id, { owner })
                            }
                          >
                            <Owner>
                              {users[item.owner]
                                ? getUserFullName(users[item.owner])
                                : item.owner === null
                                ? 'Hiring Manager'
                                : 'Add'}
                            </Owner>
                          </SelectUserModal>
                        </Col>
                        <Col span={4}>
                          <DueDateWrap>
                            <InputNumber
                              defaultValue={0}
                              value={item.dueDate}
                              min={0}
                              max={99}
                              size="large"
                              controls={false}
                              bordered={false}
                              onChange={(dueDate) =>
                                handleChange(item._id, { dueDate })
                              }
                            />
                          </DueDateWrap>
                        </Col>
                        <Col span={3}>
                          <BorderedWrap>Days</BorderedWrap>
                        </Col>
                      </Row>
                    )}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </Wrapp>
          )}
        </Droppable>
      </DragDropContext>
    </Wrapper>
  );
}

export default memo(SettingsChecklist);
