import { globalTypes } from './types';

export const logoutStart = () => {
  return { type: globalTypes.LOGOUT_START };
};

export const getProfileStart = () => ({
  type: globalTypes.GET_PROFILE_START,
});

export const getProfileSuccess = (data) => ({
  type: globalTypes.GET_PROFILE_SUCCESS,
  data,
});

export const getProfileFailure = (error) => ({
  type: globalTypes.GET_PROFILE_FAILURE,
  error,
});

export const getCurrentCompanyStart = () => ({
  type: globalTypes.GET_CURRENT_COMPANY_START,
});

export const getCurrentCompanySuccess = (data) => ({
  type: globalTypes.GET_CURRENT_COMPANY_SUCCESS,
  data,
});

export const getCurrentCompanyFailure = (error) => ({
  type: globalTypes.GET_CURRENT_COMPANY_FAILURE,
  error,
});
